import { HourCycle } from "../../../components/time-input/constants";
import { parseTime } from "../../../utilities/date-utils"

export type TimeFormatterOptions = {
  hour2Digit?: boolean
  hourCycle?: HourCycle 
}

export class TimeFormatter {
  options: TimeFormatterOptions;

  constructor(options?: TimeFormatterOptions) {
    this.options = options || {};
  }

  format(value: Date | string | number, locale?: string) {
    if (!value ) {
      return '';
    }
    const {hourCycle, hour2Digit} = this.options;

    let date: Date;;
    if (typeof value === 'string') {
      // todo: invalid strings like -44- parse as a valid date, (See validator)
      date = parseTime(value)?.date;
    } else if (typeof value === 'number') {
      date = new Date(value);
    } else {
      date = value;
    }

    const formatOptions: Intl.DateTimeFormatOptions = {
      hour: hour2Digit ? '2-digit' : 'numeric',
      minute: '2-digit',
    };

    if (hourCycle) {
      //should be able to just set hourCycle, but this formats midnight as 24:00
      formatOptions.hourCycle = hourCycle === '12' ? 'h12' : 'h23'; 
    }

    // if date is null, it's an invalid date. Return value as is.
    return date ? new Intl.DateTimeFormat(locale || 'default', formatOptions).format(date) 
      : value.toString();
  }
}

import { SelectContext } from "../select/SelectContext";
import { NamedSelectReference, SelectReference, SelectedOptionGroupData } from "./types";
import { isIncluded } from "../select/utils";
import { SelectOption } from "../select/SelectState";

export interface SelectionGroup {
  selectedOptions: SelectOption[],
  name?: string,
  context: SelectContext
}

/**
 * This is a controller to that aggregates selected options for one or more option selectors (e.g. tds-select) for a single
 * tds-selected-options component. For example, if using a tds-select-options component to list the selections across
 * multiple tds-select components used to select filtering options. Since this logic works for one or more option selectors,
 * it also works for the tds-selected-option component when rendered inside the tds-select component.
 *
 * @param params {
 *   forSelect: SelectReference | NamedSelectReference[]:
 *     - (As SelectReference ) a reference to the one associated option selector , or a space delimited list of one or more ids to the
 *       associated option selectors, or the SelectContext for the component
 *     - An array of NamedSelectReference, each having:
 *       - SelectReference: a refernce to an associated option selector as an HTMLElement of its string id, or the SelectContext for the component
 *       - name: an optional name for each group. When provided, displayed as a prefix to each selected option for that group, Using the multiple filter
 *         example, the name could be the filter category
 *   updateSelectedOptions: (options: SelectedOptionGroupData[]) => void, A callback function to the tds-selected-options component to re-render on updates
 *   orderAsListed: boolean: When true, orders options as listed rather than in the order selected
 *   getSelectContext: (el: HTMLElement) => SelectContext; A callback for resovling the SelectContext for the component.
 *   doc: Document - The document element to query from
 * }
 * @returns an object with 3 functions:
 * - unsub A function to call on wrapup to unsubscribe from the related stores
 * - clear: A convenience function to clear  selections across all option selectors
 * - listenForClear: A function to add a click event to a control for clearing the selections across all option selectors. Parameters are:
 *   - clearControl: An HTMLElement or an id to an HTMLElement to listen to
 *   - currentControl: The currently bound control, if any. The click handler is removed from this if passed
 */

export function aggregateSelectionGroups(params:{
  forSelect: SelectReference | NamedSelectReference[],
  updateSelectedOptions: (options: SelectedOptionGroupData[]) => void,
  orderAsListed: boolean,
  getSelectContext: (el: HTMLElement) => SelectContext;
  doc: Document
}): {unsub: Function, clear: Function, listenForClear: (clearControl?: string | HTMLElement | undefined | null, currentControl?: HTMLElement) => HTMLElement} {
  const { forSelect, updateSelectedOptions, orderAsListed, doc, getSelectContext } = params;
  // convert forSelect into an array of NamedSelectReference resolved to an element if needed
  let namedSelect: NamedSelectReference[];
  if (Array.isArray(forSelect) ) {
    namedSelect = forSelect as NamedSelectReference[];
  } else if (typeof forSelect === 'string') {
    // split into unnamed groups. If only one id, will be an array of 1
    namedSelect = forSelect.split(' ').filter(s => s.trim()).map(s => { return { select: s } as NamedSelectReference});
  } else {
    namedSelect = [{ select: forSelect }];
  }

  // resolve each namedSelect to its SelectContext (and its actions)
  namedSelect.forEach(namedSelect => {
    let select = namedSelect.select;
    if (typeof select === 'string') {
      try {
        select = doc.getElementById(select);
      } catch {
        /* istanbul ignore next */
        select = null;
      }
    }
    if (select instanceof HTMLElement) {
      // let caller determine how to get SelectContext from an element
      select = getSelectContext(select);
    }
    namedSelect.select = select;
  });

  const groups: SelectionGroup[] = namedSelect
    .filter(namedSelect => !!namedSelect.select)
    .map(namedSelect => {
      const context = namedSelect.select as SelectContext;
      const group: SelectionGroup = {
        selectedOptions: [],
        name: namedSelect.name,
        context
      };
      return group;
    });

  function update() {
    if (updateSelectedOptions) {
      updateSelectedOptions(flattenSelectionGroupOptions(groups))
    }
  }
  const unsubs: Function[] = [];
  groups.forEach(group => {
    unsubs.push(subscribeToContextStore(group, orderAsListed, update));
  });

  update();

  return {unsub , clear, listenForClear}

  function unsub() {
    while(unsubs.length) {
      unsubs.pop()();
    }
  }

  function clear() {
    namedSelect.forEach(namedSelect => {
      const select = namedSelect.select;
      if (select instanceof SelectContext) {
        select.selectionActions.clear();
      }
    });
  }

  function listenForClear(clearControl: string | HTMLElement | undefined | null, currentControl?: HTMLElement): HTMLElement {
    if (currentControl) {
      currentControl.removeEventListener('click', clear);
    }
    if (typeof clearControl === 'string') {
      clearControl = doc.getElementById(clearControl);
    }
    if (clearControl instanceof HTMLElement) {
      clearControl.addEventListener('click', clear);
      return clearControl;
    }
    return undefined;
  }

}

function subscribeToContextStore(group: SelectionGroup, orderAsListed: boolean, callback: Function): Function {
  let lastSelectedOptions: SelectOption[];
  return group.context.store.subscribe(s => {
    if (s.selectedOptions !== lastSelectedOptions) {
      group.selectedOptions = orderAsListed ?
        s.orderedOptions.filter((o: SelectOption) => isIncluded(o, s.selectedOptions)) :
        s.selectedOptions;
      // only call callback on updates, not on subscribe
      if (lastSelectedOptions) {
        callback();
      }
    }
    lastSelectedOptions = s.selectedOptions;
  })
}

function flattenSelectionGroupOptions(groups: SelectionGroup[]): SelectedOptionGroupData[] {
  return groups.reduce((arr, group) => {
    group.selectedOptions.forEach(option => {
      arr.push({
        option,
        groupName: group.name,
        context: group.context
      })
    })
    return arr;
  }, []);
}
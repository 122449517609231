import { CellValidator } from "./CellValidator";
import { DateCellValidator } from "./DateCellValidator";
import { LengthCellValidator } from "./LengthCellValidator";
import { NumberCellValidator } from "./NumberCellValidator";
import { PatternCellValidator } from "./PatternCellValidator";
import { RangeCellValidator } from "./RangeCellValidator";
import { RequiredCellValidator } from "./RequiredCellValidator";
import { TimeCellValidator } from "./TimeCellValidator";

export type ValidationMessage = {
  message: string
  noconfigure?: boolean
}

export type PatternValidatorOptions = ValidationMessage & {
  pattern: string | RegExp
}

export type RangeValidatorOptions = ValidationMessage & {
  min?: number
  max?: number
}

export type LengthValidatorOptions = ValidationMessage & {
  min?: number
  max?: number
  restrict?: boolean
}

const validatorsMap: [string, any][] = [
  ['required', RequiredCellValidator],
  ['pattern', PatternCellValidator],
  ['length', LengthCellValidator],
  ['number', NumberCellValidator],
  ['date', DateCellValidator],
  ['time', TimeCellValidator],
  ['range', RangeCellValidator],
];

export function validatorsFactory(config: any): CellValidator[] {
  const validators: CellValidator[] = [];
  validatorsMap.forEach(([name]) => {
    const validatorOptions = config[name];
    if (validatorOptions) {
      const validator = validatorFactory(name, validatorOptions);
      if (validator) {
        validators.push(validator);
      }
    }
  });
  
  return validators;
}

export function validatorFactory(type: string, options: any): CellValidator {
  let validator: CellValidator;
    const validatorEntry = validatorsMap.find(([name]) => name === type);
    if (validatorEntry) {
      const Validator = validatorEntry[1];
      if (typeof options === 'string' && options.length && options[0].match(/\"|{|\[/)) {
        try {
          options = JSON.parse(options);
        } catch {
          throw new Error(`Validators options for ${type} are not valid: ${options}`)
        }
      }
      if (options) {
        validator = new Validator(options) as CellValidator;
      }
    }
    return validator
}


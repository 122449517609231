import { Store } from "../../utilities/store";
import { SelectOption, SelectState } from "./SelectState";
import { IsOptionDisabledCallback, SelectChangeAction } from "./types";
import { isSame, isIncluded, getExactMatchIndex, isDisabled } from "./utils";

interface SelectActionsContext {
  multiple?: boolean;
  optionDisabled?: string | IsOptionDisabledCallback;
  maxSelection?: number;
}

export function createSelectActions(context: SelectActionsContext, store: Store<SelectState>) {

  return {
    updateOption,
    selectOptions,
    deselectOption,
    selectRange,
    toggleSelectAll,
    clear,
    selectAll,
    onEnter,
    shiftArrow,
    textEntry
  }

  function updateOption(option: SelectOption) {
    const { multiple } = context;
    const selected = isIncluded(option, store.get().selectedOptions);
    if (multiple) {
      if (selected) {
        deselectOption(option);
      } else {
        selectOptions([option], option.other && !option.otherHasBeenSelected ? 'create' : 'select');
      }
    } else if (!selected && optionEnabled(option)) {
      updateSelectedOptions([option], {
        type: 'select',
        affectedOptions: toOptionObjectArray([option])
      });
    }
  }

  function selectOptions(options: SelectOption[], action: 'select' | 'select-all' | 'select-range' | 'create' = 'select') {
    const selectedOptions = store.get().selectedOptions;
    options = options.filter(optionEnabled).filter(o => !isIncluded(o, selectedOptions));
    if (!options.length) {
      return;
    }
    updateSelectedOptions([...selectedOptions, ...options], {
      type: action,
      affectedOptions: toOptionObjectArray(options)
    });
  }

  function deselectOption(option: SelectOption) {
    if (optionEnabled(option)) {
      updateSelectedOptions(store.get().selectedOptions.filter(o => !isSame(o, option)), {
        type: 'deselect',
        affectedOptions: toOptionObjectArray([option])
      });
    }
  }

  function selectRange(option1: SelectOption | number, option2: SelectOption | number, action: 'select-all' | 'select-range' = 'select-range') {
    const { orderedOptions } = store.get();
    const addedOptions: SelectOption[] = [];
    const index1: number = typeof option1 === 'number' ? option1 : orderedOptions.indexOf(option1);
    const index2: number = typeof option2 === 'number' ? option2 : orderedOptions.indexOf(option2);
    const from = Math.max(Math.min(index1, index2), 0);
    const to = Math.min(Math.max(index1, index2, 0), orderedOptions.length);
    for (let i = from; i <= to; i++) {
      const option = orderedOptions[i];
      addedOptions.push(option);
    }
    if (addedOptions.length) {
      selectOptions(addedOptions, action);
    }
  }

  function toggleSelectAll() {
    const orderedOptions = store.get().orderedOptions.filter(optionEnabled);
    const selectedOptions = store.get().selectedOptions.filter(optionEnabled);
    if (orderedOptions.length === selectedOptions.length) {
      clear();
    } else {
      selectAll();
    }
  }

  function clear() {
    const selectedOptions = store.get().selectedOptions;
    const clearEnabledSelectedOptions = selectedOptions.filter(optionEnabled);
    const keepDisabledSelectedOptions = selectedOptions.filter(o => !isIncluded(o, clearEnabledSelectedOptions));
    if (clearEnabledSelectedOptions.length) {
      updateSelectedOptions(keepDisabledSelectedOptions, {
        type: 'clear',
        affectedOptions: toOptionObjectArray(clearEnabledSelectedOptions)
      });
    }
  }

  function selectAll() {
    selectRange(0, store.get().orderedOptions.length - 1, 'select-all');
  }

  function onEnter(shiftKey: boolean) {
    const { multiple } = context;
    const { activeIndex, selectedOptions, orderedOptions } = store.get();
    const activeOption = orderedOptions[activeIndex];
    if (!activeOption) return;

    if (multiple) {
      if (shiftKey && selectedOptions.length) {
        const lastSelectedOption = selectedOptions[selectedOptions.length - 1];
        selectRange(activeOption, lastSelectedOption);
        return;
      }
    }
    if (activeOption) {
      updateOption(activeOption);
    }
  }

  function shiftArrow(down: boolean) {
    const { orderedOptions, activeIndex } = store.get();
    const fromIndex = activeIndex;
    const toIndex = fromIndex + (down ? 1 : -1);
    const fromOption = orderedOptions[fromIndex];
    const toOption = orderedOptions[toIndex];
    if (!fromOption || !toOption) return;

    const selectedOptions = store.get().selectedOptions;
    // if 'from' is selected and 'to' is not selected, select 'to'
    // if both 'from' and 'to' are selected, deselect 'from'
    // if 'from' is not selected, select both 'to' and 'from'?
    if (isIncluded(fromOption, selectedOptions)) {
      if (isIncluded(toOption, selectedOptions)) {
        deselectOption(fromOption);
      } else {
        selectOptions([toOption]);
      }
    } else {
      selectOptions([fromOption, toOption], 'select-range');
    }
  }

  function textEntry(value: string) {
    // Note: disabled options are ignored here. Not sure how to handle a custom entry that matches
    // a disabled entry.
    if (context.multiple) return;
    const state = store.get();
    const exactOption = state.orderedOptions[getExactMatchIndex(state.orderedOptions, value)];
    let selectedOptions = state.selectedOptions;
    let affectedOptions: SelectOption[] = [];
    if (exactOption) {
      if (isIncluded(exactOption, selectedOptions)) {
        return; // if selected, nothing to do
      } else {
        // if not selected, select it
        affectedOptions = [exactOption];
        selectedOptions = [exactOption];
      }
    } else if (selectedOptions.length) {
      affectedOptions = [...selectedOptions];
      selectedOptions = [];
    }
    updateSelectedOptions(selectedOptions, {
      type: 'text-entry',
      affectedOptions: toOptionObjectArray(affectedOptions)
    })
  }

  function updateSelectedOptions(selectedOptions: SelectOption[], selectChangeAction: SelectChangeAction) {
    selectedOptions.forEach(option => {
      if (option.other) {
        // listbox needs to know when added option has been selected, even if no longer selected
        option.otherHasBeenSelected = true;
      }
    })
    store.update({ selectedOptions, selectChangeAction });
  }

  function optionEnabled(option: SelectOption): boolean {
    return !isDisabled(option, {
      optionDisabled: context.optionDisabled,
      maxSelection: context.maxSelection,
      selectedOptions: store.get().selectedOptions
    });
  }
}

function toOptionObjectArray(options: SelectOption[]): any[] {
  return options.map(o => o.option);
}

import { HourCycle } from '../components/time-input/constants';
import { TimeInputValidity } from '../components/time-input/utils';
import { getConfigObjectFromString, to2Digits } from './helpers';

/**
 * Tests whether or not the parameter passed in is a valid date instance
 * Only validates against a Date object. Does not validate strings
 * @param date The date instance to evaluate
 * @return {boolean} True if the parameter is a Date instance with a valid date
 */
function isValidDateInstance(date: Date): boolean {
  return !!(date && date instanceof Date && !isNaN(date.getDate()));
}

/**
 * Parses a string into a date.
 * @param str {string} The string to parse
 * @param localeFormat {string} The locale format, e.g. MM-DD-YYYY
 * @return Date or null if not valid date
 */
function parseDate(str: string, localeFormat: string): Date {
  const ISO_RX = /^(\d{4})-(\d{2})-(\d{2})/; // YYYY-MM-DD
  const DELIMITED_RX = /^(\d+)\D(\d+)\D(\d+)$/; // 3 sets of numbers with any delimiter
  const NOT_DELIMITER_RX = /^\d{8}$/; // 8 numbers
  const ISO_ATTEMPT_RX = /^([^-]{0,4})-([^-]{0,2})-([^-]{0,2})$/; // has 2 dashes with optional characters in between

  let dateStr = str;
  let date = null;
  if (str) {
    if (!ISO_RX.test(str)) {
      const formatParts = localeFormat.split('/');
      let parts;

      // try to convert other formats into an ISO formatted string
      const delimitedMatch = DELIMITED_RX.exec(str);
      if (delimitedMatch) {
        // try locally formatted date such as MM/DD/YYYY or DD.MM.YYYY
        parts = formatParts.reduce((map, placeholder, i) => {
          map[placeholder] = delimitedMatch[i + 1];
          return map;
        }, <any>{});
      } else if (NOT_DELIMITER_RX.test(str)) {
        // 8-digits
        // try local date formatted without
        let offset = 0;
        parts = formatParts.reduce((map, placeholder) => {
          map[placeholder] = dateStr.substring(
            offset,
            offset + placeholder.length
          );
          offset += placeholder.length;
          return map;
        }, <any>{});
      }
      if (parts) {
        dateStr = `${parts.YYYY}-${`0${parts.MM}`.slice(
          -2
        )}-${`0${parts.DD}`.slice(-2)}`;
      }
    }

    const ISO_MATCH = ISO_RX.exec(dateStr);
    if (ISO_MATCH) {
      date = new Date(
        parseInt(ISO_MATCH[1]),
        parseInt(ISO_MATCH[2]) - 1,
        parseInt(ISO_MATCH[3]),
        0,
        0,
        0,
        0
      );
      const dateStr2 = toISODate(date);
      if (dateStr.substring(0, 10) !== dateStr2.substring(0, 10)) {
        // if date parts change, e.g 2020-04-31 becomes 2020-05-01,
        // then it is not a valid date
        date = null;
      }
    }

    if (!isValidDateInstance(date) && !ISO_ATTEMPT_RX.test(str)) {
      // try string as entered
      date = new Date(str);
      if (!isValidDateInstance(date)) {
        date = null;
      }
    }
  }

  return date;
}

function getHours(
  hour: string,
  period?: string
): { hour12: string; hour24: string } {
  const hourNum = parseInt(hour, 10);
  if (period) {
    if (period.toLowerCase() === 'am' && hour === '12') {
      return {
        hour12: hour,
        hour24: '00',
      };
    }
    if (period.toLowerCase() === 'pm' && hourNum < 12) {
      return {
        hour12: hour,
        hour24: (hourNum + 12).toString(),
      };
    }
  } else {
    if (hourNum === 0) {
      return {
        hour12: '12',
        hour24: hour,
      };
    }
    if (hourNum > 12) {
      return {
        hour12: (hourNum - 12).toString(),
        hour24: hour,
      };
    }
  }
  return {
    hour12: hour,
    hour24: hour,
  };
}

type TimeParts = {
  hour12: string;
  hour24: string;
  minute: string;
  period: string;
};
function parseTime(str: string): {
  date?: Date;
  parts?: TimeParts;
} {
  const TIME_RX = /^(?<hour>\d?\d)?:?(?<minute>\d\d)? ?(?<period>AM|PM)?$/i;
  const result = TIME_RX.exec(str.replace(/\s/g, ''));
  if (!result) {
    return null;
  }
  const {
    groups: { hour, minute, period },
  } = result;
  if (
    !hour ||
    !minute ||
    (period && parseInt(hour, 10) > 12) ||
    (!period && parseInt(hour, 10) > 23) ||
    parseInt(minute, 10) > 59
  ) {
    return {
      parts: {
        hour12: hour ?? '',
        hour24: hour ?? '',
        minute: minute ?? '',
        period: period?.toUpperCase() ?? '',
      },
    };
  }
  const date = new Date();
  const { hour12, hour24 } = getHours(hour, period);
  date.setHours(parseInt(hour24, 10), parseInt(minute, 10), 0, 0);
  const periodValue = period
    ? period.toUpperCase()
    : parseInt(hour24, 10) < 12
    ? 'AM'
    : 'PM';
  return {
    date,
    parts: {
      hour12: to2Digits(hour12),
      hour24: to2Digits(hour24),
      minute: to2Digits(minute),
      period: periodValue,
    },
  };
}

function toISOTime(date: Date): string {
  return date.toISOString().split('T')[1];
}

/**
 * Formats a Date in ISO format without time (YYYY-MM-DD)
 * @param date {Date} The date to format
 * @return {string} The formatted date
 */
function toISODate(date: Date): string {
  const yy = date.getFullYear();
  const mm = `0${date.getMonth() + 1}`.slice(-2);
  const dd = `0${date.getDate()}`.slice(-2);
  return [yy, mm, dd].join('-');
}

/**
 * Formats a date into a readable string - day date month year. e.g. Monday 4 February 2019
 * @param date
 * @param lang
 * @return {*}
 */
function toReadableDate(date: Date, lang: any): string {
  if (!date || isNaN(date.getDate())) {
    return '';
  }
  const day = lang.days[date.getDay()].name;
  const month = lang.months[date.getMonth()].name;
  return `${day} ${date.getDate()} ${month} ${date.getFullYear()}`;
}

function configureDates(config: any, format: string): any {
  const dateConfig = {
    dueDate: stringifyConfigDate(config.dueDate, format),
    ...setMinMaxOptions(config.min, config.max, format),
    ...configureDisabledDates(config),
  };
  return { ...config, ...removeUndefined(dateConfig) };
}

function stringifyConfigDate(date: Date | string, format?: string): string {
  if (date) {
    switch (typeof date) {
      case 'string':
        const dt = parseDate(date, format);
        return (dt && toISODate(dt)) || undefined;
      default:
        return (isValidDateInstance(date) && toISODate(date)) || undefined;
    }
  }
  return undefined;
}

function setMinMaxOptions(
  minIn: Date | string,
  maxIn: Date | string,
  format: string
): any {
  const min_max = [minIn, maxIn].map((d) => stringifyConfigDate(d, format));
  let [min, max] = min_max;
  if (min && max && min > max) {
    min = max = null;
  }
  return { min, max };
}

function configureDisabledDates(config: any): any {
  const DATE_RANGE_RX = /(\d{4}-\d{2}-\d{2})\s*-\s*(\d{4}-\d{2}-\d{2})/;
  let { disabledDates } = config;
  if (
    typeof disabledDates === 'string' &&
    disabledDates.length &&
    disabledDates[0].match(/\"|{|\[/)
  ) {
    try {
      disabledDates = JSON.parse(disabledDates);
    } catch {}
  }

  if (typeof disabledDates === 'string') {
    disabledDates = disabledDates
      .split(',')
      .filter((s) => !!s)
      .map((s) => s.trim());
  }

  if (disabledDates) {
    disabledDates = [].concat(disabledDates);
    for (let i = 0; i < disabledDates.length; i++) {
      if (typeof disabledDates[i] === 'string') {
        // if formatted as a range (YYYY-MM-DD - YYYY-MM-DD), convert to range object
        const rangeMatch = DATE_RANGE_RX.exec(disabledDates[i]);
        if (rangeMatch) {
          disabledDates[i] = { from: rangeMatch[1], to: rangeMatch[2] };
        } else {
          // check if it's a disable function
          const fn = getConfigObjectFromString(disabledDates[i]);
          if ('function' === typeof fn) {
            disabledDates[i] = fn;
          }
        }
      } else if (disabledDates[i] instanceof Date) {
        disabledDates[i] = stringifyConfigDate(disabledDates[i]);
      } else if (typeof disabledDates[i] === 'object') {
        // assume a range object, convert dates to YYYY-MM-DD format
        if (disabledDates[i].from instanceof Date) {
          disabledDates[i].from = stringifyConfigDate(disabledDates[i].from);
        }
        if (disabledDates[i].to instanceof Date) {
          disabledDates[i].to = stringifyConfigDate(disabledDates[i].to);
        }
      }
    }
  }
  return disabledDates ? { disabledDates: [].concat(disabledDates) } : {};
}

function getDateValidity(
  config: any,
  valueAsDate: Date,
  valueAsISOString: string
): any {
  const { min, max, disabledDates, required } = config;
  const validity: any = { valid: true };

  if (valueAsISOString) {
    if (!valueAsDate) {
      validity.badInput = true;
      validity.valid = false;
    } else {
      if (min && valueAsISOString < min) {
        validity.rangeUnderflow = true;
        validity.valid = false;
      }
      if (max && valueAsISOString > max) {
        validity.rangeOverflow = true;
        validity.valid = false;
      }
      if (
        isDateDisabled(disabledDates as any[], valueAsDate, valueAsISOString)
      ) {
        validity.unavailable = true;
        validity.valid = false;
      }
    }
  } else if (required) {
    validity.valueMissing = true;
    validity.valid = false;
  }

  return validity;
}

function getTimeValidity(
  value: string | null,
  config: {
    min?: string;
    max?: string;
    required?: boolean;
    minuteIncrement?: number;
    cycle?: HourCycle;
  }
): TimeInputValidity {
  const { min, max, required, minuteIncrement, cycle } = config;
  const date = typeof value === 'string' ? parseTime(value)?.date : value;
  const minAsDate = min && parseTime(min)?.date;
  const maxAsDate = max && parseTime(max)?.date;
  const timeEntered = !!value && !!value.trim();
  if (required && !timeEntered) {
    return { valid: false, valueMissing: true };
  }

  if (!date) {
    if (timeEntered) {
      return { valid: false, badInput: true };
    }
  } else {
    if (isNaN(date.getTime())) {
      return { valid: false, badInput: true };
    }
    if (cycle === '12' && !value.match(/AM|PM/)) {
      return { valid: false, badInput: true };
    }
    if (minuteIncrement > 1) {
      const minutes = date.getMinutes();
      if (minutes % minuteIncrement > 0) {
        return { valid: false, badInput: true };
      }
    }
    if (minAsDate && date < minAsDate) {
      return { valid: false, rangeUnderflow: true };
    }
    if (maxAsDate && date > maxAsDate) {
      return { valid: false, rangeOverflow: true };
    }
  }

  return { valid: true };
}

function isDateDisabled(
  disabledDates: Array<any>,
  date: Date,
  dateStr = toISODate(date)
): boolean {
  let disabled = false;
  if (disabledDates) {
    for (let i = 0; i < disabledDates.length; i++) {
      switch (typeof disabledDates[i]) {
        case 'string': {
          disabled = disabledDates[i] === dateStr;
          break;
        }
        case 'object': {
          const from = disabledDates[i].from;
          const to = disabledDates[i].to;
          disabled = from && dateStr >= from && to && dateStr <= to;
          break;
        }
        case 'function': {
          const fn: Function = <Function>disabledDates[i];
          disabled = fn(date);
          break;
        }
      }
      if (disabled) break;
    }
  }
  return disabled;
}

function endOfMonth(month: number, year: number): Date {
  return new Date(year, month + 1, 0);
}

function startOfMonth(month: number, year: number): Date {
  return new Date(year, month, 1);
}

function removeUndefined(config: any) {
  const configOut: any = {};
  for (const key of Object.keys(config)) {
    const val = config[key];
    if ('undefined' !== typeof val) {
      configOut[key] = val;
    }
  }
  return configOut;
}

export {
  isValidDateInstance,
  parseDate,
  parseTime,
  toISODate,
  toReadableDate,
  isDateDisabled,
  configureDates,
  getDateValidity,
  getTimeValidity,
  startOfMonth,
  endOfMonth,
  toISOTime,
};

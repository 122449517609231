import { EventListeners } from "../../utilities/EventListeners";
import { SelectContext } from "./SelectContext";

interface OptionsFilterContext {
  readonly filterDelay?: number
  readonly applyFilter: boolean,
  readonly disabled?: boolean,
  readonly readonly?: boolean,
  readonly selectContext: SelectContext;
}

export default function bindFilter(context: OptionsFilterContext, inputScope: HTMLElement, callback?: (options: any[], filterText: string) => void) {
  const eventListeners = new EventListeners();
  let debounceTimeout: any;
  let lastGetOptionsTick = 0;
  let lastOptionsResponseTick = 0;
  eventListeners.addListener(inputScope, 'input', onInput);

  function unbind() {
    eventListeners.removeListeners();
  }

  function onInput(e: Event) {
    if (context.applyFilter && !context.disabled && !context.readonly) {
      const input = e.target as HTMLInputElement;
      const value = input.value;
      if (debounceTimeout) {
        clearTimeout(debounceTimeout);
        debounceTimeout = undefined;
      }
      const delay = context.filterDelay;
      if (delay > 0) {
        debounceTimeout = setTimeout(() => {
          applyFilter(value)
          debounceTimeout = undefined;
        }, delay)
      } else {
        applyFilter(value);
      }
    }
  }

  function applyFilter(filterText: string) {
    const thisGetOptionsTick = ++lastGetOptionsTick;
    context.selectContext.getOptions(filterText).then(results => {
      if (thisGetOptionsTick < lastOptionsResponseTick) {
        // this response returned out of order. Ignore it
        return;
      }
      lastOptionsResponseTick = thisGetOptionsTick;
      applyResults(results, filterText);
    })
  }

  function applyResults(filteredOptions: any[], filterText: string) {
    if (callback) {
      callback(filteredOptions, filterText);
    }
  }

  return unbind;
}

import watchForChanges from './watchForChanges';

const ID_PARSER_RX = /\b\S+\b/g;

type ConversionDef = {
  names: string[];
  convert: ((value: string, name: string, config: any) => any) | string;
};
export const hasShadowDom = (el: Element) => {
  return !!el.shadowRoot && !!(el as any).attachShadow;
};

/**
 * The CustomElement polyfill StencilJS provides does something odd with the `children` property
 * of elements that would be in the shadow DOM. This function works around that by using `childNodes`
 * and filtering nodes that are Elements.
 * @param parentEl The parent element to return chuld elements of
 * @param selector Optional. if provided, returns just those elements that match the selector
 * @returns An arry of child elements
 */
export function getChildElements(
  parentEl: Element,
  selector?: string
): Element[] {
  const children = parentEl
    ? (Array.from(parentEl.childNodes).filter(
        (node) => node.nodeType === Node.ELEMENT_NODE
      ) as Element[])
    : [];

  return selector ? children.filter((el) => el.matches(selector)) : children;
}

export function configFromDataAttributes(
  el: HTMLElement,
  defaultConfig: any = {},
  conversions?: ConversionDef[]
): any {
  const config = { ...defaultConfig };
  for (let data in el.dataset) {
    const value = el.dataset[data];
    config[data] =
      value === 'true' || value === ''
        ? true
        : value === 'false'
        ? false
        : value;
    if (conversions) {
      convertDataAttribute(config, data, value, conversions);
    }
  }
  return config;
}

export function updateDataAttributesFromConfig(
  el: HTMLElement,
  config: any
): void {
  for (let prop in config) {
    let value = config[prop];
    switch (typeof value) {
      case 'string':
      case 'boolean':
      case 'number':
        value = `${value}`;
        break;
      case 'object':
        const validDate = !!(value instanceof Date && !isNaN(value.getDate()));
        if (validDate) {
          value = (value as Date).toISOString().substr(0, 10);
        }
        break;
    }

    if (typeof value === 'string' && el.dataset[prop] !== value) {
      el.dataset[prop] = value;
    } else if (value === null) {
      delete el.dataset[prop];
    }
  }
}

export function watchDataAttributeChange(
  element: HTMLElement,
  callback: (configData: { [name: string]: any }) => void,
  conversions?: ConversionDef[]
) {
  return watchForChanges(
    element,
    { attributes: true },
    (records: MutationRecord[]) => {
      records.forEach(({ type, attributeName }) => {
        if (type === 'attributes' && attributeName) {
          const match = /^data-(.+)/.exec(attributeName);
          if (match) {
            const prop = match[1].replace(/-./g, (m) => m[1].toUpperCase());
            const value = element.getAttribute(attributeName);
            const config = { [prop]: value };
            if (conversions) {
              convertDataAttribute(config, prop, value, conversions);
            }
            callback(config);
          }
        }
      });
    }
  );
}

function convertDataAttribute(
  config: any,
  property: string,
  value: string,
  conversions: {
    names: string[];
    convert: ((value: string, name: string, config: any) => any) | string;
  }[]
): any {
  const propConversions = conversions.filter(
    (conversion) => conversion.convert && conversion.names?.includes(property)
  );
  propConversions.forEach((propConversion) => {
    const { convert } = propConversion;
    let convertedValue: any;
    if (typeof convert === 'function') {
      convertedValue = convert(value, property, config);
    } else if (typeof convert === 'string') {
      switch (convert) {
        case 'integer':
          convertedValue = parseInt(value);
          break;
        case 'number':
          convertedValue = parseFloat(value);
          break;
        case 'function':
        case 'object':
          convertedValue = convertStringRef(value, convert);
          break;
        case 'json':
          if (/^[\{\[\"].+[\}\]\"]$/.test(value)) {
            try {
              convertedValue = JSON.parse(value);
            } catch {}
          }
          break;
      }
    }
    if (typeof convertedValue !== 'undefined') {
      config[property] = convertedValue;
    }
  });
}

export function getConfigObjectFromString(
  name: string,
  context: any = window
): any {
  const parts = name.split('.');
  let ref = context;
  while (ref && parts.length > 1) {
    ref = ref[parts.shift()];
  }
  return ref && ref[parts[0]];
}

export function convertStringRefs(config: any, names: string[], type: string) {
  names.forEach((name) => {
    const obj = convertStringRef(config[name], type);
    if (typeof obj !== 'undefined') {
      config[name] = obj;
    }
  });
}

function convertStringRef(value: string, type: string): any {
  if ('string' === typeof value) {
    const obj = getConfigObjectFromString(value);
    if (type === typeof obj) {
      return obj;
    }
  }
  return undefined;
}

export function getLabelledByLabel(el: HTMLElement) {
  const labelledByIds = el.getAttribute('aria-labelledby');
  return labelledByIds
    ? labelledByIds
        .match(ID_PARSER_RX)
        .map((id) => {
          const labelledByEl = el.ownerDocument.getElementById(id);
          return (
            labelledByEl &&
            (
              labelledByEl.getAttribute('aria-label') ||
              labelledByEl.textContent ||
              ''
            ).trim()
          );
        })
        .filter((label) => label)
        .join(' ')
        .trim()
    : '';
}

export function getLabelFor(el: HTMLElement): {
  label?: string;
  labelledby?: string;
  describedby?: string;
} {
  // start with aria label attributes
  let label = el.getAttribute('aria-label');
  let labelledby = el.getAttribute('aria-labelledby');
  if (!label && !labelledby && (el as any).labels instanceof NodeList) {
    const labels = Array.from((el as any).labels) as HTMLElement[];
    const ids = labels.map((label) => label.id).filter(Boolean);
    if (ids.length === labels.length) {
      // all labels have ids, so set labelledby
      labelledby = ids.join(' ');
    } else {
      label = labels.map((label) => label.textContent?.trim()).join(' ');
    }
  }

  return {
    label: label || undefined,
    labelledby: labelledby || undefined,
    describedby: el.getAttribute('aria-describedby') || undefined,
  };
}

export function htmlEncode(text: string) {
  return (text || '')
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/'/g, '&#39;')
    .replace(/"/g, '&quot;');
}

let _positionStickySupport: boolean;
export function positionStickySupported(): boolean {
  if (typeof _positionStickySupport === 'undefined') {
    const style = document.createElement('div').style;
    style.cssText =
      'position:sticky;position:-webkit-sticky;position:-ms-sticky;';
    _positionStickySupport = style.position.indexOf('sticky') !== -1;
  }
  return _positionStickySupport;
}

let _pointerEventsSupport: boolean;
export function pointerEventsSupported() {
  if (typeof _pointerEventsSupport === 'undefined') {
    const style = document.createElement('div').style;
    style.cssText = 'pointer-events:none';
    _pointerEventsSupport =
      !!style.pointerEvents && style.pointerEvents == 'none';
  }
  return _pointerEventsSupport;
}

/**
 * Returns the text an array of strings where split by the matching text.
 * The array items alternate between text before the matching text, then the matching text.
 * e.g. text = 'Banana Cream' and match = 'a' returns:
 * ['B', 'a', 'n', 'a', 'Cre', 'a', 'm']
 * This function is useful for highlighting matched text in a filter list.
 * When processing the array, (index % 2 === 0) is non-matching text, (index % 2 === 1) is the matching text
 * @param text The full text string
 * @param match The string to match on
 * @returns An array of alternating non-match and matching text
 */
export function splitTextByMatch(text: string, match: string): string[] {
  if (!match) {
    return [text];
  }
  const result: string[] = [];
  const parts = text.toLowerCase().split(match.toLowerCase());
  let length = 0;
  parts.forEach((part) => {
    result.push(text.substr(length, part.length));
    length += part.length;
    if (length < text.length) {
      result.push(text.substr(length, match.length));
      length += match.length;
    }
  });
  return result;
}

/**
 * Visually hides an element so it is only read by a screen reader. Updates the element's style property
 * Use this when creating elements and you cann;t be certain that the `tds-sr-only` or similar class
 * is implemented.
 *
 * @param el
 */
export function visuallyHide(el: HTMLElement) {
  el.style.position = 'absolute';
  el.style.left = '-10000px';
  el.style.top = 'auto';
  el.style.width = '1px';
  el.style.height = '1px';
  el.style.overflow = 'hidden';
}

//////////////////////////////////////////////////////////////////////////////////////////
// courtesy of https://github.com/microsoft/sonder-ui/blob/master/src/shared/utils.ts

// check if an element is currently scrollable
export function isScrollable(element: HTMLElement): boolean {
  return element && element.clientHeight < element.scrollHeight;
}

// ensure given child element is within the parent's visible scroll area
export function maintainScrollVisibility(
  activeElement: HTMLElement,
  scrollParent: HTMLElement,
  includeMargin?: boolean,
  scrollToTop?: boolean,
) {
  const { offsetHeight, offsetTop } = activeElement;
  const { marginTop, marginBottom } = getComputedStyle(activeElement);
  const { clientHeight: parentClientHeight, scrollTop: parentScrollTop } =
    scrollParent;

  const isAbove = offsetTop < parentScrollTop;
  const isBelow =
    offsetTop + offsetHeight > parentScrollTop + parentClientHeight;

  if (isAbove || scrollToTop) {
    scrollParent.scrollTo(
      0,
      includeMargin ? offsetTop - parseInt(marginTop, 10) : offsetTop
    );
  } else if (isBelow) {
    scrollParent.scrollTo(
      0,
      offsetTop -
        parentClientHeight +
        (includeMargin
          ? offsetHeight + parseInt(marginBottom, 10)
          : offsetHeight)
    );
  }
}
//////////////////////////////////////////////////////////////////////////////////////////

/**
 * Iterates through an array of class names applying each class name to the element until
 * it finds the one that positions element fully within the browser window. (Or, if in
 * a container with overflow not visible, that container's boundaries) If a class name is
 * not found, reverts to the first setting in the array. This is used primarily for
 * positioning popups for components such as combobox.
 * @param element The element whose position is checked as classes are applied
 * @param classes: string[] An array of classes to apply. An array item could be an empty string,
 * meaning check the element without any class names applied (likely the first item).
 * An array item could also be a space delimited list of multiple classes to apply.
 * @param applyClassTo The element to apply the class names to. If not passed, applies the class names to element.
 */
export function bestPositionElement(
  element: HTMLElement,
  classes: string[],
  applyClassTo = element
) {
  const windowHeight = window.innerHeight;
  const windowWidth = window.innerWidth;
  classes.forEach((classNames) => {
    classNames
      .split(' ')
      .forEach((cls) => cls && applyClassTo.classList.remove(cls));
  });
  // add first option to end of list to fallback to the default
  classes = [...classes, classes[0]];

  let minTop = 0;
  let minLeft = 0;
  let maxRight = windowWidth;
  let maxBottom = windowHeight;

  // if the element is contained in an element that does not show overflow, constrain the element to those dimensions;
  // This logic is not 100%. An absolutely position element can break from its clipping ancestor its offset parent is also
  // positioned absolute. But that's too complicated and this serves our purpose for now
  let container = getOverflowParent(element);
  if (container) {
    const restoreHidden = element.hidden;
    element.hidden = true; // hide the element so it does not add scrollbars and change the dimensions of the container
    const {
      top: pTop,
      bottom: pBottom,
      left: pLeft,
      right: pRight,
    } = container.getBoundingClientRect();
    minTop = Math.max(pTop, 0);
    minLeft = Math.max(pLeft, 0);
    maxRight = Math.min(pRight, windowWidth);
    maxBottom = Math.min(pBottom, windowHeight);
    element.hidden = restoreHidden;
  }

  for (let i = 0; i < classes.length; i++) {
    const classNames = classes[i];
    classNames
      .split(' ')
      .forEach((cls) => cls && applyClassTo.classList.add(cls));
    // if we are on the last (default) setting, keep that setting and don't retest
    if (i < classes.length - 1) {
      const { top, bottom, left, right } = element.getBoundingClientRect();
      if (
        top >= minTop &&
        left >= minLeft &&
        right <= maxRight &&
        bottom <= maxBottom
      ) {
        // fits
        break;
      }
      classNames
        .split(' ')
        .forEach((cls) => cls && applyClassTo.classList.remove(cls));
    }
  }
}

/**
 * Finds the parent element that clips overflow. Recognizes if element is slotted and steps through shadow DOM if needed
 * @param element The element to start from
 * @returns overflow parent or null if none found
 */
function getOverflowParent(element: Element): Element | null {
  let parent: Element = element.parentElement;
  if (element.assignedSlot) {
    parent = element.assignedSlot.parentElement;
  }
  if (!parent && element.parentNode) {
    parent = (element.parentNode as ShadowRoot).host;
  }
  if (parent) {
    let { overflowX, overflowY } = window.getComputedStyle(parent);
    overflowX = overflowX || 'visible'; // to make the unit test pass
    overflowY = overflowY || 'visible'; // to make the unit test pass
    if (overflowX === 'visible' && overflowY == 'visible') {
      parent = getOverflowParent(parent);
    }
  }
  return parent;
}

/**
 * Applies attributes to an element using key value pairs. For each key in the attribute map,
 * if the key's value is undefined or null, removes the attribute; otherwise it sets the attribute,
 * converting the value to a string. Keys can be either camelCase or kebab-case.
 *
 * @param el The element to update.
 * @param attributes The attributes to set as a key/value map.
 */
export function applyAttributes(
  el: HTMLElement,
  attributes: { [key: string]: any }
) {
  const { innerHTML, ...theRest } = attributes;
  attributes = theRest;
  Object.keys(attributes).forEach((key) => {
    const attr = toKebabCase(key);
    const value = attributes[key]?.toString();
    if (typeof value === 'undefined' || value === null) {
      el.removeAttribute(attr);
    } else if (el.getAttribute(attr) !== value) {
      el.setAttribute(attr, value);
    }
  });
}

/**
 * Uses a map to add or remove classes from an element. If the value of the key is true,
 * the key is added as a class; otherwise it is removed. Keys must be the exact class name to apply.
 *
 * @param el The element to update
 * @param classes A map of class names mapped to either true to add the class or false to remove it
 */

export function applyClasses(
  el: HTMLElement,
  classes: { [key: string]: boolean }
) {
  Object.keys(classes).forEach((cls) => {
    const mthd = classes[cls] ? 'add' : 'remove';
    el.classList[mthd](cls);
  });
}

/**
 * Updates the style property of an element using a map of key/values, where the key
 * is a style property. If the property is falsy, it is removed from the style; otherise
 * it is set.
 *
 * @param el The lement to update
 * @param styles The styles to apply as a key/value map
 */
export function applyStyles(
  el: HTMLElement,
  styles: { [key: string]: string | undefined }
) {
  const cssStyles = Object.keys(styles);
  cssStyles.forEach((cssStyle: string) => {
    const value = styles[cssStyle];
    const prop = toKebabCase(cssStyle);
    if (!value) {
      el.style.removeProperty(prop);
    } else {
      el.style.setProperty(prop, value);
    }
  });
}

/**
 * This function is a work around for jsdom, which throws the error "':focus-within' is not a valid selector".
 * (https://github.com/jsdom/jsdom/issues/3055)
 * This seems to be related to an issue with the nwsapi package. (https://github.com/dperini/nwsapi/issues/47)
 * We could update nwsapi to the latest version, but we can't be certain other applications
 * using TDS will be current. So, to prevent their unit tests from failing, we'll fallback
 * to checking if it contains a focused element.
 */
export function hasFocus(element: Element) {
  return element.matches(':focus') || !!element.querySelector(':focus');
}

/**
 * Replaces the content of an element with the content passed. Content can either by a string or an element,
 * or an array of these.
 *
 * @param el The element to update.
 * @param content The content to apply
 */
export function applyContent(
  el: HTMLElement,
  content: HTMLElement | string | (HTMLElement | string)[]
) {
  while (el.lastChild) {
    el.lastChild.remove();
  }

  if (!Array.isArray(content)) {
    content = [content];
  }

  content.forEach((c: HTMLElement | string) => {
    if (c instanceof Element) {
      el.append(c);
    } else if (typeof c === 'string') {
      el.append((el.ownerDocument || document).createTextNode(c));
    }
  });
}

function toKebabCase(str: string) {
  return str
    .replace(/[A-Z]/g, function (M, i) {
      const m = M.toLowerCase();
      return i === 0 ? m : `-${m}`;
    })
    .replace(/--/g, '-');
}

/**
 * Adds a leading 0 to single digits. Returns one of three strings:
 * - If the number is less than 10, returns the number with a leading 0
 * - If the number is greater than 10, returns the number unchanged
 * - If a string is provided and it evaluates to NaN, returns an empty string
 *
 * @param value The digit to append to. Can be a string or a number.
 */
export function to2Digits(value: string | number) {
  const num = typeof value === 'string' ? parseInt(value, 10) : value;
  return !isNaN(num) ? (num < 10 ? `0${num}` : num.toString()) : '';
}

import { CSS_NS, NAMESPACE } from '../../../utilities/constants';
import { Store } from '../../../utilities/store';
import { createStore, TimeInputState } from '../TimeInputState';
import { instances } from '../../../utilities/instances';
import {
  bindTimePicker,
  focusOnFirstList,
  updateActiveOption,
} from './timePickerBehavior';
import { TimeParts } from '../constants';
import { TimePickerComponent } from './TimePickerComponent';
import { TimePickerConfig } from './TimePickerConfig';
import { generateOptions } from '../utils';
import { translations } from '../../../utilities/i18n';

const BASE_CLASS = `${CSS_NS}time-picker`;
const LIST_CLASS = `${BASE_CLASS}__list`;
const ITEM_CLASS = `${LIST_CLASS}-item`;
const INSTANCE_KEY = `${NAMESPACE}TimePicker`;
const ACTIVE_CLASS = `${LIST_CLASS}-item--active`;

class _TimePickerInstance implements TimePickerComponent {
  el: HTMLElement;
  store: Store<TimeInputState>;
  config: TimePickerConfig;
  onDestroy: Function[] = [];

  constructor(
    element: HTMLElement,
    config: TimePickerConfig,
    store?: Store<TimeInputState>
  ) {
    this.el = element;
    this.config = config;
    this.store = store ?? createStore();

    instances.set(this.el, INSTANCE_KEY, this);

    this.render();
    this.onDestroy.push(this.store.subscribe(this.onStateUpdate.bind(this)));
    this.onDestroy.push(bindTimePicker(this));
  }

  onStateUpdate(state: TimeInputState) {
    const { hourValue, minuteValue, periodValue } = state;
    updateActiveOption(this.el, TimeParts.Hour, hourValue, ACTIVE_CLASS);
    updateActiveOption(this.el, TimeParts.Minute, minuteValue, ACTIVE_CLASS);
    updateActiveOption(this.el, TimeParts.Period, periodValue, ACTIVE_CLASS);
  }

  render() {
    const { t } = translations(this.el)
    const options = generateOptions(this.config.cycle, this.config.minuteIncrement);
    this.el.classList.add(BASE_CLASS);
    this.el.setAttribute('role', 'dialog');
    this.el.setAttribute('aria-label', t('timePicker'));
    options.forEach((set, i) => {
      if (set.length) {
        const list = document.createElement('ul');
        const part = Object.values(TimeParts)[i];
        list.classList.add(LIST_CLASS);
        list.setAttribute('role', 'listbox');
        list.setAttribute('aria-label', t(part));
        list.dataset.part = part;
        set.forEach((option, i) => {
          const item = document.createElement('li');
          item.classList.add(ITEM_CLASS);
          item.innerHTML = option;
          item.setAttribute('tabindex', i === 0 ? '0' : '-1');
          item.setAttribute('role', 'option');
          item.setAttribute('aria-selected', 'false');
          if (option[0] === '0') item.setAttribute('aria-label', option[1]);
          list.appendChild(item);
        });
        this.el.appendChild(list);
      }
    });
  }

  focus() {
    focusOnFirstList(this.el, this.store);
  }

  destroy() {
    while (this.onDestroy.length) {
      this.onDestroy.pop()();
    }
    instances.remove(this.el, INSTANCE_KEY);
    this.el = this.store = this.config = null;
  }
}

export class TimePicker {
  _instance: _TimePickerInstance;
  constructor(
    element: HTMLElement,
    config: TimePickerConfig,
    store?: Store<TimeInputState>
  ) {
    this._instance =
      <_TimePickerInstance>instances.get(element, INSTANCE_KEY) ||
      new _TimePickerInstance(element, config, store);
  }

  focus() {
    this._instance.focus();
  }

  destroy() {
    if (this._instance) {
      this._instance.destroy();
      delete this._instance;
    }
  }
}

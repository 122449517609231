import { DataTableComponent } from './DataTableComponent';
import { inNestedTable, getExpansionRows } from './dataTableUtils';
import { Collapsible } from '../../utilities/collapsible/Collapsible';
import { EventListeners } from '../../utilities/EventListeners';
import { createCustomEvent } from '../../utilities/customEvent';
import {
  ROW_EXPANDER_DATA_ATTR,
  EXPANSION_ROW_DATA_ATTR,
  EXPANDED_ROW_CLASS,
  EXPANDING_ROW_CLASS,
  COLLAPSING_ROW_CLASS,
  ROW_HOVERED_CLASS
} from './dataTableConstants';
import watchForChanges from '../../utilities/watchForChanges';

const events = {
  expanding: 'tdsRowExpanding',
  expanded: 'tdsRowExpanded',
  collapsed: 'tdsRowCollapsed',
  collapsing: 'tdsRowCollapsing'
}
const classMap = {
  [events.expanding]: EXPANDING_ROW_CLASS,
  [events.expanded]: EXPANDED_ROW_CLASS,
  [events.collapsing]: COLLAPSING_ROW_CLASS
}
const toggleSelector = `[${ROW_EXPANDER_DATA_ATTR}]`;

export function bindRowExpansion(dataTable: DataTableComponent): Function {
  const { root } = dataTable;
  const eventListeners = new EventListeners();
  eventListeners.addListener(root, 'click', (event: Event) => {
    const button = (event.target as HTMLElement).closest(toggleSelector);
    /* istanbul ignore else */
    if (button && !inNestedTable(button as HTMLElement, root)) {
      toggleRow(button);
    }
  });
  const hoverHandler = onHoverEvent(dataTable);
  eventListeners.addListener(root, 'mouseover', hoverHandler);
  eventListeners.addListener(root, 'mouseout', hoverHandler);
  const unwatchForChanges = watchForChanges(root, { childList: true, subtree: true },
    (records: MutationRecord[]) => {
      onElementsAdded(dataTable, records);
    });
  configureExpansionElements(dataTable);

  return function unbindRowExpansion() {
    eventListeners.removeListeners();
    unwatchForChanges();
  }
}

function toggleRow(toggle: Element) {
  const expand = toggle.getAttribute('aria-expanded') !== 'true';
  const tr = toggle.closest('tr');
  const startEvent = expand ? events.expanding : events.collapsing;
  let lastContent: Element;
  if (fireEvent(tr, startEvent)) {
    const endEvent = expand ? events.expanded : events.collapsed;
    const collapsibles: Element[] = [];
    const expansionRows = getExpansionRows(tr);
    applyClass(startEvent, tr, expansionRows);
    expansionRows.forEach(row => {
      row.removeAttribute('aria-hidden');
      getExpansionElements(row).forEach((el: Element) => {
        collapsibles.push(el);
      });
    });
    collapsibles.forEach((el: HTMLElement) => {
      if (new Collapsible(el).toggle(toggle as HTMLElement)) {
        lastContent = el;
      }
    });

    const finish = () => {
      fireEvent(tr, endEvent);
      applyClass(endEvent, tr, expansionRows);
      if (!expand) {
        expansionRows.forEach(row => {
          row.setAttribute('aria-hidden', 'true');
        });
      }
    }

    if (lastContent) {
      const collapseEvent = expand ? 'tdsExpanded' : 'tdsCollapsed';
      const onEnd = () => {
        lastContent.removeEventListener(collapseEvent, onEnd);
        finish();
      }
      lastContent.addEventListener(collapseEvent, onEnd);
    } else {
      toggle.setAttribute('aria-expanded', `${expand}`);
      finish();
    }
  }
}

function getExpansionElements(expansionRow: HTMLElement): HTMLElement[] {
  const elements: HTMLElement[] = [];
  for (let i = 0; i < expansionRow.children.length; i++) {
    const cell = expansionRow.children[i];
    if (cell.tagName === 'TD') {
      for (let j = 0; j < cell.children.length; j++) {
        elements.push(cell.children[j] as HTMLElement);
      }
    }
  }
  return elements;
}

/**
 * Returns an array of an expandable row and its expansion rows
 * @param tr any row in the expansion group
 */
function getExpansionRowGroup(tr: HTMLElement): HTMLElement[] {
  let row = tr;
  while (row && row.matches(`[${EXPANSION_ROW_DATA_ATTR}]`)) {
    row = row.previousElementSibling as HTMLElement
  }
  return row ? [row].concat(getExpansionRows(row)) : [tr];
}

function fireEvent(tr: HTMLElement, type: string): boolean {
  const expansionRows = getExpansionRows(tr);
  const detail: any = {
    expansionRow: expansionRows.length === 1 ? expansionRows[0] : expansionRows
  }
  return tr.dispatchEvent(createCustomEvent(type, {
    bubbles: true,
    cancelable: type === events.expanding || type === events.collapsing,
    detail
  }));
}

function applyClass(eventType: string, tr: HTMLElement, expansionRows: HTMLElement[]) {
  const classNameKeys = Object.keys(classMap);
  expansionRows.concat([tr]).forEach(row => {
    classNameKeys.forEach(key => {
      const method = (key === eventType) ? 'add' : 'remove';
      row.classList[method](classMap[key]);
    });
  });
}

function rowForThisTable(el: HTMLElement, root: HTMLElement): HTMLElement {
  let tr = el && el.closest('tbody > tr');
  while (tr && tr.parentElement && inNestedTable(tr as HTMLElement, root)) {
    tr = tr.parentElement.closest('tbody > tr');
  }
  return tr as HTMLElement;
}

function onHoverEvent(dataTableComponent: DataTableComponent) {
  const { root } = dataTableComponent;
  return (event: MouseEvent) => {
    const tr = rowForThisTable(event.target as HTMLElement, root);
    // ignore if moving within same row
    if (tr && tr !== rowForThisTable(event.relatedTarget as HTMLElement, root)) {
      const method = event.type === 'mouseover' ? 'add' : 'remove';
      const rows = getExpansionRowGroup(tr as HTMLElement);
      // if just one row, not an expansion row group, no need to set hover class
      if (rows.length > 1) {
        rows.forEach(row => row.classList[method](ROW_HOVERED_CLASS));
      }
    }
  }
}

function onElementsAdded(dataTable: DataTableComponent, records: MutationRecord[]) {
  const selector = `[${ROW_EXPANDER_DATA_ATTR}], [${EXPANSION_ROW_DATA_ATTR}]`;
  const runConfig = !!records.find(record => {
    const { addedNodes } = record;
    return addedNodes && Array.prototype.find.call(addedNodes, (node: Element) => {
      return (node instanceof Element) && node.matches(selector);
    });
  });
  if (runConfig) {
    configureExpansionElements(dataTable);
  }
}

/**
 * Sychronizes the hidden state of expansion row for each row expander toggle in the table
 * @param dataTable - DataTableComponent
 */
function configureExpansionElements(dataTable: DataTableComponent) {
  const { root } = dataTable;
  const toggles = root ? Array.from(root.querySelectorAll(toggleSelector)) : [];
  toggles.forEach((toggle: Element) => {
    const hidden = toggle.getAttribute('aria-expanded') !== 'true';
    toggle.setAttribute('aria-expanded', `${!hidden}`);
    const tr = toggle.closest('tr');
    if (tr) {
      getExpansionRows(tr).forEach(expansionRow => {
        if (hidden) {
          expansionRow.setAttribute('aria-hidden', 'true');
        } else {
          expansionRow.removeAttribute('aria-hidden');
          expansionRow.classList.add(classMap[events.expanded]);
        }
        getExpansionElements(expansionRow).forEach((el: HTMLElement) => {
          el.hidden = hidden;
        });
      });
      if (!hidden) {
        tr.classList.add(classMap[events.expanded]);
      }
    }
  });
}
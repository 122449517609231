import { NavigationComponent } from './NavigationComponent';
import { EventListeners } from '../../utilities/EventListeners';
import doWhileEventing from '../../utilities/doWhileEventing';
import { hasFocus } from '../../utilities/helpers';

const PACE = 2; //ms per pixel height, increase to make slower
const transitionStyle = {
  overflow: 'hidden',
  height: '',
  opacity: '',
  transitionProperty: 'opacity, height',
  transitionDuration: '',
  transitionTimingFunction: ''
}

export function bindNavigation(navigation: NavigationComponent): Function {
  const { host } = navigation;
  const eventListeners = new EventListeners();

  eventListeners.addListener(host, 'click', onClick(navigation));
  eventListeners.addListener(document, 'keydown', onEscape(navigation));
  eventListeners.addListener(document, 'focusout', onFocusOut(navigation));
  const endController = secondLevelNavigationController(navigation);

  const unbind = () => {
    eventListeners.removeListeners();
    endController();
  }
  return unbind;
}

function onFocusOut(navigation: NavigationComponent) {
  return (e: FocusEvent) => {
    const { host } = navigation;
    const topToggle = navigation.topToggle;
    const expanded = topToggle && navigation.isToggleExpanded(topToggle);
    if (expanded) {
      const target = <Node>e.target;
      const relatedTarget = <Node>e.relatedTarget;
      if (host.contains(target) && (!relatedTarget || !host.contains(relatedTarget))) {
        doToggle(navigation, topToggle);
      }
    }
  }
}

function onEscape(navigation: NavigationComponent) {
  return (e: KeyboardEvent) => {
    switch (e.key || e.code) {
      case 'Esc':
      case 'Escape':
        const topToggle = navigation.topToggle;
        const expanded = topToggle && navigation.isToggleExpanded(topToggle);
        if (expanded) {
          doToggle(navigation, topToggle);
          topToggle.focus();
        }
    }
  }
}

function onClick(navigation: NavigationComponent): EventListener {
  return (e: MouseEvent) => {
    const toggle = navigation.getToggleElement(e);
    if (toggle) {
      doToggle(navigation, toggle);
    } else {
      const topToggle = navigation.topToggle;
      if (topToggle && navigation.isToggleExpanded(topToggle)) {
        const link = (e.target as HTMLElement).closest('a, tds-nav-item[href]');
        if (link) {
          doToggle(navigation, topToggle);
        }
      }
    }
  }
}

function doToggle(navigation: NavigationComponent, toggle: HTMLElement) {
  const expand = !navigation.isToggleExpanded(toggle);

  if (navigation.isTopLevelDropdownToggle(toggle)) {
    navigation.setToggleExpanded(toggle, expand);
    return;
  }

  const navList: HTMLElement = navigation.getNavList(toggle);
  if (navList && !navList.dataset.isTransitioning) {
    navList.dataset.isTransitioning = 'true';
    navigation.setToggleExpanded(toggle, expand);
    toggleNavList(navList, expand, () => {
      delete navList.dataset.isTransitioning;
      if (!expand) {
        navList.style.display = '';
      }
    })
  }
}

function toggleNavList(navList: HTMLElement, expand: boolean, done: Function) {
  const navItems: HTMLElement[] = <HTMLElement[]>Array.from(navList.childNodes)
    .filter(node => node.nodeType === Node.ELEMENT_NODE)
    .filter(isVisible);

  if (!expand) {
    navItems.reverse();
  }

  navItems.forEach((navitem) => {
    prepareForTransition(navitem, expand);
  });

  window.setTimeout(() => {
    recurseNavItems(navItems, 0, expand, () => {
      if (!expand) {
        navList.style.display = 'none';
      }
      navItems.forEach(navitem => {
        restoreStyles(navitem, transitionStyle);
      });
      done();
    })
  }, 10);
}

function recurseNavItems(navitems: HTMLElement[], index: number, expand: boolean, done: Function) {
  const navitem = navitems[index];
  if (navitem) {
    toggleNavItem(navitem, expand, index === 0, index === navitems.length - 1, () => {
      recurseNavItems(navitems, index + 1, expand, done);
    });
  } else {
    done();
  }
}

function prepareForTransition(navitem: HTMLElement, expand: boolean) {
  const height = expand ? '0' : navitem.scrollHeight + 'px';
  const opacity = expand ? '0' : '1';
  const style = { ...transitionStyle, height, opacity };
  applyStyles(navitem, style);
}

function toggleNavItem(navitem: HTMLElement, expand: boolean, first: boolean, last: boolean, done: Function) {
  const height = expand ? navitem.scrollHeight + 'px' : '0';
  const opacity = expand ? '1' : '0';
  const duration = (PACE * navitem.scrollHeight) + (first ? 60 : last ? 120 : 0);
  const transitionDuration = `${duration * 1.5}ms, ${duration}ms`;
  const transitionTimingFunction = `ease, ${first ? 'ease-in' : last ? 'ease-out' : 'linear'}`;
  const style = { ...transitionStyle, height, opacity, transitionDuration, transitionTimingFunction };
  applyStyles(navitem, style);
  window.setTimeout(done, duration);
}

function applyStyles(el: HTMLElement, style: any) {
  const elStyle: any = el.style;
  for (let prop in style) {
    elStyle[prop] = style[prop];
  }
}

function restoreStyles(el: HTMLElement, style: any) {
  const elStyle: any = el.style;
  for (let prop in style) {
    elStyle[prop] = '';
  }
}

function isVisible(el: HTMLElement) {
  const style = window.getComputedStyle(el)
  return style.display !== 'none' && style.visibility !== 'hidden'
}

function secondLevelNavigationController(navigation: NavigationComponent) {
  const navEl = navigation.host;
  const eventListeners = new EventListeners();
  let activeNavItem: HTMLElement;
  let potentialNavItem: HTMLElement;
  let onExitTimeout: any;
  let onEnterTimeout: any;
  let isRow = false;

  const undoWhile = doWhileEventing(window, 'resize', () => {
    if (isRow !== navigation.isRowOrientation()) {
      isRow = !isRow;
      closeTopLevelToggles();
      if (isRow) {
        eventListeners.addListener(navEl, 'focusin', onEnter);
        eventListeners.addListener(navEl, 'focusout', onExit);
        eventListeners.addListener(navEl, 'mouseover', onEnter);
        eventListeners.addListener(navEl, 'mouseout', onExit);
        eventListeners.addListener(navEl, 'click', onClick);
      } else {
        setActiveNavItem(undefined);
        eventListeners.removeListeners();
      }
    }
  });

  return function unbindSecondLevelNavigationController() {
    undoWhile();
    eventListeners.removeListeners();
  }

  function onEnter(event: Event) {
    const target = event.target as HTMLElement;
    const topNavItem = getTopNavItem(target);
    if (topNavItem) {
      if (onExitTimeout) {
        onExitTimeout = clearTimeout(onExitTimeout);
      }
      if (topNavItem !== activeNavItem && topNavItem !== potentialNavItem) {
        if (activeNavItem) {
          // nav currently shown, switch immediately
          setActiveNavItem(topNavItem);
        } else {
          // save this item so we ignore other mouse events
          potentialNavItem = topNavItem;
          // wait awhile in case just passing through
          onEnterTimeout = setTimeout(() => {
            onEnterTimeout = undefined;
            potentialNavItem = undefined;
            if (itemIsFocusedOrHovered(topNavItem)) {
              setActiveNavItem(topNavItem);
            }
          }, 800);
        }
      }
    }
  }

  function onExit(event: Event) {
    const target = event.target as HTMLElement;
    const topNavItem = getTopNavItem(target);
    if (!topNavItem || (topNavItem !== activeNavItem && topNavItem !== potentialNavItem)) {
      return;
    }

    if (itemIsFocusedOrHovered(topNavItem)) {
      // movement within the active element
      return;
    }

    // on mouse event, delay longer to allow mouse to move from top nav to the nav list
    if (onEnterTimeout && topNavItem === potentialNavItem) {
      onEnterTimeout = clearTimeout(onEnterTimeout);
      potentialNavItem = undefined;
    }
    if (!onExitTimeout && topNavItem === activeNavItem) {
      const delay = event.type === 'focusout' ? 10 : 200;
      onExitTimeout = setTimeout(() => {
        onExitTimeout = undefined;
        if (topNavItem === activeNavItem && !itemIsFocusedOrHovered(topNavItem)) {
          setActiveNavItem(undefined);
        }
      }, delay);
    }
  }

  function onClick(event: MouseEvent) {
    const toggle = navigation.getToggleElement(event);
    const topNavItem = toggle && getTopNavItem(toggle, true);
    if (topNavItem && navigation.getChildToggleElement(topNavItem) === toggle) {
      const expanded = navigation.isToggleExpanded(toggle);
      setActiveNavItem(expanded ? topNavItem : undefined);
    } else if (activeNavItem && !toggle) {
      const link = (event.target as HTMLElement).closest('a, tds-nav-item[href]');
      if (link) {
        setActiveNavItem(undefined);
      }
    }
  }

  function getTopNavItem(element: HTMLElement, includeHasToggle = false) {
    const topNavItems = navigation.getTopNavItemsWithNavList();
    return topNavItems
      .filter(item => {
        if (includeHasToggle) {
          return true;
        }
        const toggle = navigation.getChildToggleElement(item);
        const hasToggle = (toggle && toggle.clientHeight && toggle.clientWidth);
        return !hasToggle;
      })
      .find(item => item.contains(element));
  }

  function setActiveNavItem(itemEl: HTMLElement) {
    const SHOW_LIST_CLS = 'show-second-level';
    if (itemEl !== activeNavItem) {
      if (activeNavItem) {
        activeNavItem.classList.remove(SHOW_LIST_CLS);
        const toggle = navigation.getChildToggleElement(activeNavItem);
        if (toggle && toggle.clientHeight && toggle.clientWidth) {
          navigation.setToggleExpanded(toggle, false);
        }
      }
      activeNavItem = itemEl;
      if (activeNavItem) {
        activeNavItem.classList.add(SHOW_LIST_CLS);
      }
      potentialNavItem = undefined;
    }
  }

  function closeTopLevelToggles() {
    navigation.getTopNavItemsWithNavList().forEach(item => {
      const toggle = navigation.getChildToggleElement(item);
      if (toggle) {
        navigation.setToggleExpanded(toggle, false);
      }
    })
  }

  function itemIsFocusedOrHovered(item: HTMLElement): boolean {
    return item.matches(':hover') || hasFocus(item);
  }
}
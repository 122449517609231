import { instances } from '../../../utilities/instances';
import { SelectOption, SelectState } from '../SelectState';
import { Store } from "../../../utilities/store";
import { applyAttributes, configFromDataAttributes } from '../../../utilities/helpers';
import { NAMESPACE } from '../../../utilities/constants';
import { translations } from '../../../utilities/i18n';
import { SelectContext } from '../SelectContext';
import { isDisabled } from '../utils';
import debounce from '../../../utilities/debounce';
import { SelectDialogConfig } from './SelectDialogConfig';
import { bindSelectDialog } from './selectDialogBehavior';
import { Listbox } from '../listbox';
import { FilterPredicateCallback, IsOptionDisabledCallback, LoadOptions } from '../types';

const ENHANCED_FLAG = 'enhancedSelectDialog';
const INSTANCE_KEY = `${NAMESPACE}SelectDialog`;
const BASE_CLASS = 'tds-select-dialog';
const CLASSES = {
  filterPanel: `${BASE_CLASS}__filter-panel`,
  actions: `${BASE_CLASS}__actions`,
  selectAll: `${BASE_CLASS}__select-all`,
  clear: `${BASE_CLASS}__clear`
}

let nextId = 1;

class _SelectDialogInstance {
  host: HTMLElement;
  store: Store<SelectState>;
  state: SelectState;

  onDestroy: Function[] = [];

  _selectContext: SelectContext;
  _baseId: string;
  _descriptionId: string;
  _rendered: boolean;
  _apiConfig: SelectDialogConfig;
  _config: SelectDialogConfig;
  _translate: (key: string, ...replacements: string[]) => string;
  _allSelected: boolean;
  _someSelected: boolean;
  _updateListbox = true;
  _filterHandoff: string;

  constructor(element: HTMLElement, config: SelectDialogConfig = {}) {
    this.onStateUpdate = this.onStateUpdate.bind(this);
    this.onSelectAll = this.onSelectAll.bind(this);
    this.onClear = this.onClear.bind(this);
    this.host = element;
    this._apiConfig = config;
    element.dataset[ENHANCED_FLAG] = "true";
    this._baseId = element.id || `__tds-select-dialog-${nextId++}`;
    this._descriptionId = `${this._baseId}__description`;
    const passedContext = this.config.selectContext;
    const context = this._selectContext = passedContext || SelectContext.getInstance(element, this);
    this.store = context.store;
    const unbind = bindSelectDialog(this);
    const unsubscribe = this.store.subscribe(this.onStateUpdate);
    this.onDestroy = [unbind, unsubscribe, () => {
      if (!passedContext) {
        context.destroy();
      }
    }];
    this.render();
    this.render = debounce(this.render.bind(this), 0);
    instances.set(element, INSTANCE_KEY, this);
  }

  onStateUpdate(state: SelectState) {
    this.state = state;
    const { orderedOptions, selectedOptions } = state;
    const config = this.config;
    const optionEnabled = (option: SelectOption) => {
      return !isDisabled(option, {
        optionDisabled: config.optionDisabled,
        maxSelection: config.maxSelection,
        selectedOptions: state.selectedOptions
      });
    }
    const enabledOptions = orderedOptions.filter(optionEnabled);
    const enabledSelectedOptions = selectedOptions.filter(optionEnabled);
    this._someSelected = !!enabledSelectedOptions.length;
    this._allSelected = enabledOptions.length === enabledSelectedOptions.length;
    if (this._rendered) {
      this.render();
    }
  }

  updateConfig(config: SelectDialogConfig) {
    if (this._apiConfig !== config) {
      this._apiConfig = { ...this._apiConfig, ...config };
      this._config = undefined;
      this._updateListbox = true;
      if (this._rendered) {
        this.render();
      }
    }
  }

  clearFilter() {
    const { searchInput } = this;
    if (searchInput) {
      searchInput.value = '';
    }
    this._filterHandoff = '';
  }

  get config(): SelectDialogConfig {
    return this._config || (this._config = {
      optionName: 'name',
      optionValue: 'value',
      options: [],
      // only using data attributes for testing as standalone component
      ...configFromDataAttributes(this.host),
      ...this._apiConfig
    })
  }

  set filterHandoff(value: string) {
    this._filterHandoff = value;
    const { searchInput } = this;
    if (searchInput) {
      searchInput.value = value;
    }
  }

  render() {
    if (!this.host) return; // instance destroyed before debounce timeout
    const config = this.config;
    this._translate = translations(this.host).t;
    if (!this._rendered) {
      this.host.innerHTML = this.renderHTMLFrame();
    }
    this.renderFilter();
    this.updateList();
    this.updateDescription();
    this.renderActions();
    applyAttributes(this.host, {
      role: "dialog",
      'aria-describedby': this._descriptionId,
      'aria-label': config.label,
      'aria-labelledby': config.labelledby,
      'aria-modal': 'true'
    });
    this._rendered = true;
  }

  renderHTMLFrame(): string {
    return [
      '<div tabindex="0" data-returnto-trap></div>',
      `<div id="${this._descriptionId}" hidden></div>`,
      `<div id="${this._baseId}__listbox" class="tds-listbox"></div>`,
      '<div tabindex="0" data-returnto-trap></div>'
    ].join('');
  }

  renderFilter() {
    const { host, config } = this;
    let filterPanel = host.querySelector<HTMLElement>(`.${CLASSES.filterPanel}`);
    if (this.filter) {
      if (!filterPanel) {
        const listbox = host.querySelector<HTMLElement>('.tds-listbox');
        listbox.insertAdjacentHTML('beforebegin', `<div class="${CLASSES.filterPanel}"><input type="search"/></div>`);
        filterPanel = listbox.previousElementSibling as HTMLElement;
      }
      const input = filterPanel.querySelector('input')!;
      input.setAttribute('placeholder', config.searchPlaceholder ?? this._translate('search'));
      input.setAttribute('aria-label', config.searchLabel || this._translate('optionsSearchLabel'));
    } else if (filterPanel) {
      filterPanel.remove();
    }
  }

  updateList() {
    if (this._updateListbox) {
      const config = { ...this.config }
      if (config.options) {
        delete config.options;
      }
      config.selectContext = this._selectContext;
      new Listbox(this.listbox, config);
      this._updateListbox = false;
    }
  }

  updateDescription() {
    const { filter, _translate } = this;
    const { selectAll, multiple } = this.config
    const description = this.host.querySelector(`#${this._descriptionId}`);
    const textContent = [
      _translate('selectDialogDescriptionBase'),
      filter && _translate('selectDialogDescriptionFilter'),
      selectAll && multiple && _translate('selectDialogDescriptionActions')
    ].filter(Boolean).join(', ');
    if (textContent !== description.textContent) {
      description.textContent = textContent;
    }
  }

  renderActions() {
    const { host, config } = this;
    let actionsPanel = host.querySelector<HTMLElement>(`.${CLASSES.actions}`);
    if (config.selectAll && config.multiple) {
      if (!actionsPanel) {
        const listbox = host.querySelector<HTMLElement>('.tds-listbox');
        listbox.insertAdjacentHTML('afterend', [
          `<div class="${CLASSES.actions}">`,
          `<button type="button" class="${CLASSES.selectAll}"></button>`,
          `<button type="button" class="${CLASSES.clear}"></button>`,
          '</div>'
        ].join(''));
        actionsPanel = listbox.nextElementSibling as HTMLElement;
      }
      const selectAll = host.querySelector<HTMLElement>(`button.${CLASSES.selectAll}`);
      applyAttributes(selectAll, { ariaDisabled: this._allSelected ? 'true' : undefined });
      selectAll.textContent = config.selectAllLabel || this._translate('selectAll');
      selectAll.onclick = this.onSelectAll;

      const clear = host.querySelector<HTMLElement>(`button.${CLASSES.clear}`);
      applyAttributes(clear, { ariaDisabled: this._someSelected ? undefined : 'true' });
      clear.textContent = config.clearLabel || this._translate('clear');
      clear.onclick = this.onClear;
    } else if (actionsPanel) {
      actionsPanel.remove();
    }
  }

  onSelectAll() {
    if (!this._allSelected) {
      this.selectContext.selectionActions.selectAll();
    }
  }

  onClear() {
    if (this._someSelected) {
      this.selectContext.selectionActions.clear();
    }
  }

  focus() {
    setTimeout(() => {
      const { host, searchInput } = this;
      if (host) {
        const focusOn = (this.filter && this._filterHandoff && searchInput) || this.listbox;
        if (focusOn) {
          focusOn.focus();
          if (focusOn === searchInput) {
            (focusOn as HTMLInputElement).selectionStart = (focusOn as HTMLInputElement).value.length;
          }
        }
      }
    });
  }

  destroy() {
    while (this.onDestroy && this.onDestroy.length) {
      const fn = this.onDestroy.pop();
      fn();
    }
    if (this.host) {
      delete this.host.dataset[ENHANCED_FLAG];
    }
    instances.remove(this.host, INSTANCE_KEY);
    this.host = this.store = null;
  }

  get listbox() { return this.host.querySelector<HTMLElement>('.tds-listbox') }

  get searchInput(): HTMLInputElement {
    return this.host?.querySelector<HTMLInputElement>(`.${CLASSES.filterPanel} input`)
  }

  // many of these config getters are to satisfy SelectContext when this is a standalone component,
  // But, when used as a popup as it normally will be, many will be satisfied by the select component

  get applyFilter(): boolean { return !!this.config.filter; }

  get selectContext(): SelectContext { return this._selectContext }

  get filter(): boolean { return this.config.filter }

  get filterDelay(): number { return this.config.filterDelay }

  get filterPredicate(): FilterPredicateCallback { return this.config.filterPredicate }

  get multiple(): boolean { return this.config.multiple }

  get optionName(): string { return this.config.optionName }

  get optionValue(): string { return this.config.optionValue }

  get optionDisabled(): string | IsOptionDisabledCallback { return this.config.optionDisabled }

  get optionId(): string { return this.config.optionId }

  get options(): any[] | LoadOptions { return this.config.options }

  get groupBy(): string { return this.config.groupBy }

  get selectedFirst(): boolean { return this.config.selectedFirst }
}

class SelectDialog {
  _instance: _SelectDialogInstance;
  constructor(element: HTMLElement, config?: SelectDialogConfig) {
    this._instance = <_SelectDialogInstance>instances.get(element, INSTANCE_KEY) || new _SelectDialogInstance(element, config);
    if (config) {
      this.updateConfig(config);
    }
  }

  updateConfig(config: SelectDialogConfig) {
    this._instance?.updateConfig(config);
  }

  set filterHandoff(value: string) {
    if (this._instance) {
      this._instance.filterHandoff = value;
    }
  }

  focus() {
    this._instance?.focus();
  }

  clearFilter() {
    this._instance?.clearFilter();
  }

  destroy() {
    return this._instance.destroy();
  }
}

export { SelectDialog }
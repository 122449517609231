/** Callback to inform of a value updates. */
type Subscriber<T> = (value: T) => void;

/** Unsubscribes from value updates. */
type Unsubscriber = () => void;

/** Callback to update a value. */
type Updater<T> = (value: T) => T;

export interface Store<T> {
  set(newValue: T): void;
  get(): T;
  update(fn: Updater<T> | Partial<T>): void;
  subscribe(run: Subscriber<T>): Unsubscriber;
}

export function store<T>(initialValue: T) : Store<T> {
  let value:T = initialValue;
  const subscribers: Subscriber<T>[] = [];

  function set(newValue: T) {
    if (newValue !== value) {
      value = newValue;
      subscribers.forEach(run => run(value))
    }
  }

  function update(v: Updater<T> | Partial<T>) {
    if (typeof v === 'function') {
      const fn: Updater<T> = v;
      set(fn(value));
    } else {
      set({...value, ...v});
    }
  }

  function get(): T {
    return value;
  }

  function subscribe(run: Subscriber<T>): Unsubscriber {
    subscribers.push(run);
    run(value);

    return () => {
      const index = subscribers.indexOf(run);
			if (index !== -1) {
				subscribers.splice(index, 1);
			}
    };
  }

  return { set, get, update, subscribe };
}


/**
 * Functional wrapper around MutationObserver so we only have to check if it is defined once.
 * This is to prevent code does not run in the DOM (e.g. SSR, jsdom tests) from failing.
 * It also reduces the boilerplate code for working with the MutationObserver
 * @param target {node} The node to watch
 * @param options {MutationObserverInit} The options for MutationObserver
 * @param callback {MutationCallback} The callback function
 * @returns A function to disconnect the observer.
 */
function watchForChanges(target: Node | Node[], options: MutationObserverInit, callback: MutationCallback): Function {
  let observer: MutationObserver;
  const targets = Array.isArray(target) ? target : [target]; 
  if (typeof MutationObserver !== 'undefined') {
    observer = new MutationObserver(callback)
    targets.forEach(t => observer.observe(t, options));
  }
  return () => {
    observer && observer.disconnect();
    observer = undefined;
  }
}

export default watchForChanges;
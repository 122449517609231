import '../calendar/Calendar';
import { DateInputComponent } from './DateInputComponent';
import { DateInputState, createStore } from './DateInputState';
import { bindDateInput, getValueAsDate, getValueAsISOString, setDateValue } from './dateInputBehavior';
import { Calendar } from '../calendar/Calendar';
import { CSS_NS, NAMESPACE } from '../../utilities/constants'
import { createCustomEvent } from '../../utilities/customEvent'
import onDOMChanges from '../../utilities/onDOMChanges'
import { instances } from '../../utilities/instances'
import { Store } from '../../utilities/store';
import getTargetElement from '../../utilities/getTargetElement';
import { getLocale } from '../../utilities/localization';
import { toReadableDate, configureDates } from '../../utilities/date-utils';
import { configFromDataAttributes } from '../../utilities/helpers';
import { DateInputConfig } from './DateInputConfig';
import uid from '../../utilities/uid';

const CALENDAR_CLASS = `${CSS_NS}calendar`;
const DATE_INPUT_CLASS = `${CSS_NS}date-input`;
const WITH_CALENDAR_CLASS = `${DATE_INPUT_CLASS}--with-calendar`;
const CALENDAR_OPEN = `${DATE_INPUT_CLASS}--calendar-open`;
const HAS_FOCUS_CLS = `${DATE_INPUT_CLASS}--focus-within`;
const INSTANCE_KEY = `${NAMESPACE}DateInput`;
const ESCAPE_LABEL = uid(`__${CSS_NS}calendar-escape-label--`);
const TRIGGER_SELECTOR = '[data-trigger="calendar"]';

let nextId = 1;

class _DateInputInstance implements DateInputComponent {
  el: HTMLElement;
  host: HTMLElement;
  state: DateInputState;
  store: Store<DateInputState>;
  _calendar: HTMLElement;
  config: DateInputConfig;

  onDestroy: Function[] = [];

  constructor(element: HTMLElement) {
    this.el = this.host = element;
    this.setConfig(configFromDataAttributes(element));
    this.store = createStore(element);  
    element.dataset.enhancedDate = "true";
    // it is important that subscribe occurs before bind so that, when behavior throws a change event
    // when the store updates, this module will have the current state when calling get value or valueAsDate 
    const unsubscribe = this.store.subscribe(this.onStateUpdate.bind(this));
    const unbind = bindDateInput(this);
    this.onDestroy = [unbind, unsubscribe];
    instances.set(element, INSTANCE_KEY, this)

    const trigger: HTMLElement = element.querySelector(TRIGGER_SELECTOR);
    if (trigger) {
      trigger.hidden = false;
      element.classList.add(WITH_CALENDAR_CLASS);
    }
  }

  get calendar(): HTMLElement {
    return this._calendar || (this._calendar = getCalendarElement(this));
  }

  get calendarDialog(): HTMLElement {
    return this.calendar;
  }

  get legacyPopover() {
    return !this.config?.optimizedPopover;
  }

  setConfig(config: DateInputConfig = {}) {
    const currentConfig = this.config || {};
    this.config = this.configureRequired({ ...currentConfig, ...config, ...configureDates(config, getLocale(this.el).format) });
    if (this._calendar) {
      new Calendar(this._calendar).setConfig(this.config);
    }
  }

  configureRequired(config: DateInputConfig): DateInputConfig {
    const inputs = Array.from(this.el.querySelectorAll<HTMLInputElement>(`[data-date-part]`));
    // this logic accounts for required being set to false afterwards
    // assuming if any input is required, the date is required. Not getting into complexity of only some inputs required 
    const required = typeof config.required === 'boolean' ? config.required : !!inputs.find(input => input.required); 
    inputs.forEach(input => input.required = required);
    return {...config, required};
  }

  /**
   * Returns a concatenated date in ISO format (YYYY-MM-DD) based input values.
   * The value may or may not be a valid date string.
   *
   * If using this value as input to new Date() or Date.parse(), append 'T00:00' to return a date for the current
   * timezone; otherwise, the date will set for UTC
   */
  get value() {
    return getValueAsISOString(this.state);
  }

  set value(value: string | Date) {
    setDateValue(value, this);
  }

  /**
   * If inputs contain valid date values, returns input as a Date object; otherwise returns null
   */
  get valueAsDate() {
    return getValueAsDate(this.state);
  }

  get isValid() {
    return this.state ? this.state.validity.valid : true;
  }

  get validity() {
    return this.state ? this.state.validity : { valid: true };
  }

  toReadableDate() {
    const s = toReadableDate(this.valueAsDate, getLocale(this.el).lang);
    return s;
  }

  onStateUpdate(state: DateInputState) {
    const { el } = this;
    const prevState = this.state || {};
    this.state = state;
    const calendar = this.calendar;
    if (calendar) {
      calendar.dataset.dialogOpenState = state.calendarOpenState;
    }
    const expanded = `${state.calendarOpenState === 'opening' || state.calendarOpenState === 'opened'}`;
    let trigger: HTMLElement = this.el.querySelector(TRIGGER_SELECTOR)
    if (trigger) {
      if (expanded !== trigger.getAttribute('aria-expanded')) {
        trigger.setAttribute('aria-expanded', expanded);
      }
    }
    el.classList[expanded === 'true' ? 'add' : 'remove'](CALENDAR_OPEN);
    el.classList[state.hasFocus? 'add' : 'remove'](HAS_FOCUS_CLS);
    const parts = ['month', 'day', 'year'];
    parts.forEach((part: string) => {
      const input = <HTMLInputElement>el.querySelector(`[data-date-part="${part}"]`);
      const value =  (<any>state)[`${part}Value`];
      const prevValue =  (<any>prevState)[`${part}Value`];
      if (value !== prevValue && input && input.value !== value) {
        input.value = value;
      }
    });

    const invalids = [];
    const validity: any = state.validity;
    for (let prop in validity) {
      if (prop !== 'valid' && validity[prop]) {
        invalids.push(prop);
      }
    }
    if (invalids.length) {
      el.dataset.validity = invalids.join();
    }
    else {
      el.removeAttribute('data-validity');
    }
  }

  dispatchEvent(eventType: string, eventInit: any) : boolean {
    return this.el.dispatchEvent(createCustomEvent(eventType, eventInit));
  }

  destroy() {
    while (this.onDestroy && this.onDestroy.length) {
      const fn = this.onDestroy.pop();
      fn();
    }
    if (this.el) {
      delete this.el.dataset.enhancedDate
    }
    instances.remove(this.el, INSTANCE_KEY);
    this.el = this.host = this.state = this.store = this._calendar = this.config = null;
  }
}
/////////////////////////////////////////////////////////////////
// - helper functions
function getCalendarElement(dateInput: DateInputComponent): HTMLElement {
  const container = dateInput.el;
  let calendarEl: HTMLElement = null;
  let trigger: HTMLElement = container.querySelector(TRIGGER_SELECTOR);
  if (trigger) {
    calendarEl = getTargetElement(trigger) || container.querySelector(`.${CALENDAR_CLASS}`);
    if (!calendarEl) {
      calendarEl = document.createElement('div');
      new Calendar(calendarEl, dateInput.config);
      container.appendChild(calendarEl);
    } else {
      new Calendar(calendarEl).setConfig(dateInput.config);
    }
    if (!calendarEl.id) {
      calendarEl.id = container.id ? `${container.id}__${CALENDAR_CLASS}` : `${CALENDAR_CLASS}-${nextId++}`;
    }
    calendarEl.setAttribute('role', 'dialog');
    if (!calendarEl.hasAttribute('aria-label') && !calendarEl.hasAttribute('aria-labelledby')) {
      calendarEl.setAttribute('aria-label', getLocale(container).lang.calendar);
    }
    const describedBy =  `${calendarEl.getAttribute('aria-describedby') || ''} ${ESCAPE_LABEL}`.trim(); 
    calendarEl.setAttribute('aria-describedby', describedBy);
    calendarEl.classList.add(CALENDAR_CLASS);
    trigger.setAttribute('aria-controls', calendarEl.id);
    
    if (document.getElementById(ESCAPE_LABEL) === null) {
      var escapeLabel = document.createElement('span');
      escapeLabel.setAttribute("id", ESCAPE_LABEL);
      escapeLabel.innerText = getLocale(container).lang.escapeLabel;
      escapeLabel.hidden = true;
      document.body.appendChild(escapeLabel);
    }
  }
  return calendarEl;
}


//////////////////////////////////////////////////////////////////////////////////////////////////////////

onDOMChanges(`.${DATE_INPUT_CLASS}`,
  function onDateAdded(element: HTMLElement) {
    if (!element.dataset.enhancedDate) {
      new _DateInputInstance(element)
    }
  },
  function onDateRemoved(element: HTMLElement) {
    if (element.dataset.enhancedDate === 'true') {
      new DateInput(element).destroy();
    }
  });

// ////////////////////////////////////////////////////////////////////////////////////////////////////////

class DateInput {
  _instance: _DateInputInstance;
  constructor(element: HTMLElement, config?: any) {
    this._instance = <_DateInputInstance>instances.get(element, INSTANCE_KEY) || new _DateInputInstance(element)
    if (config) {
      this.setConfig(config)
    }
  }

  setConfig(config: any) {
    this._instance.setConfig(config)
  }

  /**
   * Returns a concatenated date in ISO format (YYYY-MM-DD) based input values.
   * The value may or may not be a valid date string.
   *
   * If using this value as input to new Date() or Date.parse(), append 'T00:00' to return a date for the current
   * timezone; otherwise, the date will set for UTC
   */
  get value() {
    return this._instance.value
  }

  set value(date) {
    this._instance.value = date
  }

  /**
   * If inputs contain valid date values, returns inout as a Date object; otherwise returns null
   */
  get valueAsDate() {
    return this._instance.valueAsDate
  }

  get isValid() {
    return this._instance.isValid
  }

  get validity() {
    return this._instance.validity
  }

  toReadableDate() {
    return this._instance.toReadableDate()
  }

  destroy() {
    if (this._instance) {
      this._instance.destroy()
      delete this._instance
    }
  }
}

export { DateInput }

// // todo: how to disable? => disable fieldset. Not sure about aria-disabled. Read-only instead?
// // todo: example of showing invalid states
// // todo: placement of calendar when off-screen

import { getObjectValue, interpolate } from "../../utilities/objectHelpers";
import { SpecializedGroupName } from "./constants";
import { SelectOption, SelectState } from "./SelectState";
import { IsOptionDisabledCallback } from "./types";

export function getBestMatchIndex(options: SelectOption[], text: string, ignoreOther?: boolean) {
  let index = -1;
  if (text) {
    text = text.toLowerCase();
    for (let i = 0; i < options.length; i++) {
      if (options[i].other && ignoreOther) {
        continue;
      }
      const name = options[i].name.toLowerCase();
      if (name.startsWith(text)) {
        if (index === -1 || (name === text && !options[i].other)) { // give exact match precedence
          index = i;
        }
      }
    }
  }
  return index;
}

export function getExactMatchIndex(options: SelectOption[], text: string) {
  text = text.toLowerCase();
  for (let i = 0; i < options.length; i++) {
    const name = options[i].name.toLowerCase();
    if (name === text) {
      return i;
    }
  }
  return -1;
}

export function isIncluded(option: SelectOption, options: SelectOption[]): boolean {
  return !!options.find(o => isSame(o, option));
}

export function isSame(option1: SelectOption, option2: SelectOption): boolean {
  return option1.option === option2.option || 
    // comparing `other` as well in case text was added that happens to match the id of an existing option 
    (option1.id === option2.id && !!option1.other === !!option2.other);
}

export function indexOfOption(option: SelectOption, options: SelectOption[]): number {
  return options.findIndex(o => isSame(o, option));
}

export function isDisabled(option: SelectOption, context: { optionDisabled: string | IsOptionDisabledCallback, maxSelection?: number, selectedOptions?: SelectOption[] }): boolean {
  const { optionDisabled, maxSelection = 0, selectedOptions = [] } = context;
  if (maxSelection > 0 && selectedOptions.length >= maxSelection && !isIncluded(option, selectedOptions)) {
    return true;
  }
  if (optionDisabled) {
    switch (typeof optionDisabled) {
      case 'string':
        return !!getObjectValue(option.option, optionDisabled as string);
      case 'function':
        return !!(optionDisabled as IsOptionDisabledCallback)(
          option.option,
          {
            isSelected: isIncluded(option, selectedOptions),
            isOther: !!option.other
          });
    }
  }
  return false;
}

export function toSelectOptions(options: any[], def: { optionValue?: string, optionName?: string, optionShortName?: string, optionId?: string, groupBy?: string }): SelectOption[] {
  return options.map(option => toSelectOption(option, def));
}

export function toSelectOption(option: any, def: { optionValue?: string, optionName?: string, optionShortName?: string, optionId?: string, groupBy?: string }): SelectOption {
  const { optionValue = 'value', optionName = 'name', optionShortName, optionId, groupBy } = def;

  if (typeof option === 'string') {
    return {
      id: option,
      value: option,
      name: option,
      groupName: groupBy ? SpecializedGroupName.Other : '',
      option
    }
  }
  const value = (getObjectValue(option, optionValue || optionName) || '').toString();
  const id = optionId ? getObjectValue(option, optionId) : value;
  const name = (getObjectValue(option, optionName) || '').toString();
  const shortName = optionShortName ? (getObjectValue(option, optionShortName) || '').toString() : '';
  const groupName = groupBy ? (getObjectValue(option, groupBy) || '').toString() : '';
  return { id, value, name, shortName, groupName, option };
}

export function resolveAllowOther(allowOther: string) {
  switch (allowOther) {
    case 'true':
    case 'false':
    case 'prompt':
      return allowOther;
    default:
      return (allowOther as any) === '' ? 'true' : 'false';
  }
}

export function getFilterPlacement(filter: boolean, tags: 'inside' | 'outside', allowOther: string): 'inline' | 'withlist' | undefined {
  const allowOtherResolved = resolveAllowOther(allowOther);
  // if filter property not set, default to true when other values can be entered; false if not
  const filterableable = filter ?? (allowOtherResolved !== 'false' || tags === 'outside');
  // If cannot enter other values, place filter in the list (withlist), otherwise in the control (inline);
  return filterableable ? (allowOtherResolved !== 'false' || tags === 'outside' ? 'inline' : 'withlist') : undefined;
}


export function getPopupType(selectAll: boolean, filterPlacement: 'withlist' | 'inline' | undefined): 'listbox' | 'dialog' {
  return selectAll || filterPlacement === 'withlist' ? 'dialog' : 'listbox';
}

export function manageInputAutocomplete() {
  const ATTR_AUTOCOMPLETE = 'autocomplete';
  let savedAutocomplete: string;
  return (event: FocusEvent) => {
    const input = (event.target as HTMLElement).matches(`input[${ATTR_AUTOCOMPLETE}]`) ? (event.target as HTMLInputElement) : undefined;
    if (input) {
      switch (event.type) {
        case 'focus':
          // prevent Chrome from trying to guess autocomplete by setting to an arbitrary value
          savedAutocomplete = input.getAttribute(ATTR_AUTOCOMPLETE);
          input.setAttribute(ATTR_AUTOCOMPLETE, 'none');
          break;
        case 'blur':
          input.setAttribute(ATTR_AUTOCOMPLETE, savedAutocomplete);
          break;
      }
    }
  }
}

export function renderSelectedOptionsList(
  config: { summarizeAfter: number, summarizeFormat: 'all' | 'over', summarizeAllTpl: string, delimiter: string, multiple: boolean },
  state: SelectState,
  translate: (key: string, ...replacements: string[]) => string
): string {
  const { summarizeAfter, summarizeFormat, summarizeAllTpl, delimiter, multiple } = config;
  const { selectedOptions, orderedOptions } = state;
  const length = selectedOptions.length;
  if (multiple && summarizeAfter > -1 && length > summarizeAfter) {
    if (summarizeFormat === 'all') {
      const tpl = summarizeAllTpl || translate('optionsSelectedTpl');
      const values = interpolate({ count: selectedOptions.length, total: orderedOptions.length }, tpl).split(';');
      return (length === 1 && values[1]) || values[0];
    }
    const options = selectedOptions.slice(0, summarizeAfter);
    const difference = selectedOptions.length - summarizeAfter;
    const plusMoreTpl = translate('optionsSPluselectedTpl');
    const plusMore = interpolate({ count: difference }, plusMoreTpl);
    const optionsList = options.map(option => option.shortName || option.name).join(delimiter + ' ');
    return `${optionsList}, ${plusMore}`;
  }
  return selectedOptions.map(option => option.shortName || option.name).join(delimiter + ' ');
}

export function getAriaAutocomplete({ inlineComplete, applyFilter }: { inlineComplete: boolean, applyFilter: boolean }): 'inline' | 'list' | 'both' | 'none' {
  return applyFilter && inlineComplete ? 'both' :
    applyFilter ? 'list' :
      inlineComplete ? 'inline' : 'none';
}

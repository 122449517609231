export const EDIT_MODE_SELECTOR = 'table[data-edit-mode]';
export const CELL_EDITABLE_ATTR = 'data-editable';
export const COLUMN_EDITABLE_ATTR = 'data-editable';
export const CELL_CONTENT_BKUP_ATTR = 'data-cell-content-backup';
export const EDIT_PANEL_ATTR = 'data-cell-editor-panel';
export const EDIT_CONTROL_CONTAINER_ATTR = 'data-cell-editor-control-container';
export const EDIT_PANEL_MESSAGE_CLASS = 'tds-cell-editor__message';
export const EDIT_PANEL_BASE_ID = '__tds-cell-editor-';
export const EDITOR_CONTROL_ATTR = 'data-editor-control';
export const EDITOR_HAS_FOCUS_ATTR = 'data-editor-has-focus'
export const EDITOR_DROPDOWN_OPTIONS_ATTR = 'data-editor-options';
export const EDIT_VALUE_ATTR = 'data-edit-value';
export const CELL_IN_ERROR_ATTR = 'data-in-error';
export const ROW_EDIT_ACTION_ATTR = 'data-row-edit-action';
export const ROW_EDIT_ACTIONS_ATTR = 'data-row-edit-actions';
// these probably should be independent of editing
export const DATA_TABLE_COLUMN_ROW_PROP_ATTR = 'data-row-property';
export const DATA_TABLE_ROW_ID_ATTR = 'data-row-id';

import { createCustomEvent } from "../../utilities/customEvent";
import { NAMESPACE, CSS_NS, FILE_UPLOAD_CLASSES } from '../../utilities/constants';
import { configFromDataAttributes, convertStringRefs } from "../../utilities/helpers";
import { Store } from '../../utilities/store';
import { instances } from "../../utilities/instances";
import onDOMChanges from "../../utilities/onDOMChanges";
import { FileUploadComponent } from "./FileUploadComponent";
import { FileUploadConfig } from "./FileUploadConfig";
import { createStore, FileUploadState } from './FileUploadState';
import { bindFileUpload } from "./FileUploadBehavior";
import { setupFileList, buildFileList } from './FileList';
import { displayErrorAlert } from "./FileAlerts";
import { TdsFile } from './TdsFile';

// Default configuration
const defaultConfig: FileUploadConfig = {
  showErrorAlert: true,
  showFilelist: true,
  allowDuplicates: false,
  confirmRemoveFile: false,
  removeFile: true,
  asyncUpload: false,
  filelistArialive: "polite",
  alertMessageArialive: "polite"
};
const ENHANCED_FLAG = 'enhancedFileUpload';
const INSTANCE_KEY = `${NAMESPACE}FileUpload`;
const PATTERN_SELECTOR = `.${CSS_NS}fileupload`;

class _FileUploadInstance implements FileUploadComponent {
  host: HTMLElement;
  el: HTMLElement;
  config: FileUploadConfig;
  state: FileUploadState;
  store: Store<FileUploadState>;
  onDestroy: Function[] = [];

  constructor(element: HTMLElement, clientConfig?: any) {
    // set enhance element
    element.dataset[ENHANCED_FLAG] = "true";

    // assign
    this.el = this.host = element;

    //set state
    this.store = createStore();
    this.state = { _tdsFileList: [] };

    // ---- Initialize Config ----
    // extract configuration from data attributes on element
    const dataConfig: any = configFromDataAttributes(this.el);
    convertStringRefs(dataConfig, ['removeFileHandler'], 'function')
    const config = processConfig({ ...defaultConfig, ...dataConfig, ...clientConfig });
    this.setConfig(config);
    setInputAttributes(this.el, this.config);

    // set fileList to true if list div is present
    setupFileList(this);

    // Render function
    this.render();

    // destroy
    const unbind = bindFileUpload(this);
    const unsubscribe = this.store.subscribe(this.onStateUpdate.bind(this))

    this.onDestroy = [unbind, unsubscribe];
    instances.set(element, INSTANCE_KEY, this);
  }

  setConfig(config: any) {
    if(config) this.config = { ...config };
  }

  dispatchEvent(eventType: string, eventInit?: any): boolean {
    return this.host.dispatchEvent(createCustomEvent(eventType, eventInit));
  }

  destroy() {
    while (this.onDestroy && this.onDestroy.length) {
      const fn = this.onDestroy.pop();
      fn();
    }
    if (this.el) {
      delete this.el.dataset[ENHANCED_FLAG];
    }
    instances.remove(this.host, INSTANCE_KEY);
    this.el = this.host = null;
  }

  render() {
    if (this.config.showErrorAlert) {
      displayErrorAlert(this);
    }
    if (this.config.showFilelist) {
      buildFileList(this);
    }
  }

  get files(): TdsFile[] {
    const { _tdsFileList } = this.state;
    // do not return files to be removed
    return _tdsFileList && _tdsFileList.filter(f => !f.deleteDisposition);
  }

  set files(fileList: TdsFile[]) {
    this.store.update(() => {
      return ({
        _tdsFileList: fileList
      })
    })
  }

  onStateUpdate(state: FileUploadState) {
    this.state = state;
    this.render();
  }

}

//handles getting config and preProcessing of values
function processConfig(config: any) {
  const newConfig: any = {};

  Object.keys(config).forEach((key) => {
    var value = config[key];
    switch (key) {
      case 'maxSize':
        if (typeof value == 'number') value = value.toString();
        break;
      case 'maxCount':
        if (typeof value == 'string') value = parseInt(value);
        break;
      case 'acceptType':
      case 'rejectType':
        if (typeof value == 'string') value = (value.split(" ").join("")).split(",");
        break;
      case 'showFileList':
      case 'showErrorAlert':
      case 'allowDuplicates':
      case 'removeFile':
      case 'confirmRemoveFile':
        value = (value == true);
        break;
      case 'messageTemplates':
        if(typeof value == 'string') {
          value = JSON.parse(value)
        }
    }
    newConfig[key] = value;
  });

  return newConfig;
}

function setInputAttributes(el: HTMLElement, config: FileUploadConfig) {
  const fileInput = el.querySelector(`.${FILE_UPLOAD_CLASSES.INPUT}`);
  if (fileInput) {
    if (config.acceptType) {
      fileInput.setAttribute('accept', config.acceptType.toString());
    }
    if (config.maxCount === 1) {
      fileInput.removeAttribute('multiple');
    }
    else {
      fileInput.setAttribute('multiple', 'true');
    }
  }
}

class FileUpload {
  _instance: _FileUploadInstance;

  constructor(element: HTMLElement, config?: any) {
    this._instance = <_FileUploadInstance>instances.get(element, INSTANCE_KEY) || new _FileUploadInstance(element, config);
  }

  destroy() {
    const { _instance } = this;
    delete this._instance;
    return _instance.destroy();
  }

  get files(): TdsFile[] {
    return this._instance.files;
  }

  set files(files: TdsFile[]) {
    this._instance.files = files;
  }
}

onDOMChanges(`${PATTERN_SELECTOR}`,
  function onPatternAdded(element: HTMLElement) {
    if (!element.dataset[ENHANCED_FLAG]) {
      new FileUpload(element);
    }
  },
  function onPatternRemoved(element: HTMLElement) {
    if (element.dataset[ENHANCED_FLAG] === "true") {
      new FileUpload(element).destroy();
    }
  }
);

export { FileUpload };


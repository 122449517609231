import onDOMChanges from '../../utilities/onDOMChanges';
import { bindSelectedOptions, watchReturnToFocus } from './selectedOptionsBehavior';
import { SelectedOptionsConfig } from './SelectedOptionsConfig';
import { instances } from '../../utilities/instances';
import { applyAttributes, applyClasses, applyStyles, configFromDataAttributes, hasFocus, htmlEncode, watchDataAttributeChange } from '../../utilities/helpers';
import { NAMESPACE } from '../../utilities/constants';
import debounce from '../../utilities/debounce';
import { createCustomEvent } from '../../utilities/customEvent';
import { SelectedOptionGroupData } from './types';
import { translations } from '../../utilities/i18n';
import devConsole from '../../utilities/devConsole';
import { aggregateSelectionGroups } from './SelectionGroups';
import { isDisabled, toSelectOption } from '../select/utils';
import { interpolate } from '../../utilities/objectHelpers';
import { SelectContext } from '../select/SelectContext';
import { applyContent } from '../../utilities/helpers';
import { ElementPreparerData } from '../select/types';

const BASE_CLASS = 'tds-selected-options';
const ENHANCED_FLAG = 'enhancedSelectedOptions';
const INSTANCE_KEY = `${NAMESPACE}SelectedOptions`;
const PATTERN_SELECTOR = `.${BASE_CLASS}`;
const CLASSES = {
  inline: `${BASE_CLASS}--inline`,
  noLastComma: `${BASE_CLASS}--no-last-comma`,
  summary: `${BASE_CLASS}__summary`,
}

const dataAttributesConversions = [
  {
    names: ['limitTags'],
    convert: 'integer'
  },
  {
    names: ['options'],
    convert: 'object'
  },
  {
    names: ['options', 'forSelect'],
    convert: 'json'
  },
  {
    names: ['prepElement', 'contentRenderer'],
    convert: 'function'
  }
]

let nextId = 1;

class _SelectedOptionsInstance {
  host: HTMLElement;

  onDestroy: Function[] = [];

  _removeLabelId: string;
  _config: SelectedOptionsConfig | undefined;
  _apiConfig: SelectedOptionsConfig = {};
  _rendered: boolean;
  _selectedOptions: SelectedOptionGroupData[];
  _activeIndex: number = -1;
  _noLastComma: boolean;
  _unwatchReturnToFocus: ReturnType<typeof watchReturnToFocus>;
  _aggregateGroupsReturn: ReturnType<typeof aggregateSelectionGroups>
  _clearControl: HTMLElement;
  _translate: (key: string, ...replacements: string[]) => string;

  constructor(element: HTMLElement, config: SelectedOptionsConfig = {}) {
    this.host = element;
    this._apiConfig = config;
    element.dataset[ENHANCED_FLAG] = "true";
    this.clear = this.clear.bind(this);
    const baseId = element.id || `__tds-selected-options-${nextId++}`;
    this._removeLabelId = `${baseId}__removeLabel`;
    const unwatch = watchDataAttributeChange(this.host, (config) => {
      const oldConfig = this._config || {};
      this._config = undefined;
      this.checkConfigChanges(config, oldConfig)
    }, dataAttributesConversions);
    const unbind = bindSelectedOptions(this);
    this.aggregateGroups();
    this.onReturnFocusToChange();
    this.listenForClear();
    this.render();
    this.render = debounce(this.render.bind(this), 0);
    this.onDestroy = [unbind, unwatch];
    instances.set(element, INSTANCE_KEY, this);
  }

  updateConfig(config: SelectedOptionsConfig) {
    if (this._apiConfig !== config) {
      const oldConfig = this._config || {};
      this._apiConfig = { ...this._apiConfig, ...config };
      this._config = undefined;
      this.checkConfigChanges(config, oldConfig)
    }
  }

  aggregateGroups() {
    this.unsubscribe();
    const config = this.config;
    const forSelect = config.forSelect;
    if (!forSelect) return;
    this._aggregateGroupsReturn = aggregateSelectionGroups({
      forSelect,
      updateSelectedOptions: (options: SelectedOptionGroupData[]) => {
        this._selectedOptions = options;
        if (this._rendered) {
          this.render();
        }
      },
      orderAsListed: config.orderAsListed,
      getSelectContext: (el: HTMLElement) => {
        if (!el.matches('.tds-select')) {
          devConsole.error('As of now, only tds-select elements (class="tds-select") can be bound to this component');
          return null;
        }
        return SelectContext.getInstance(el);
      },
      doc: this.host.ownerDocument
    });
  }

  unsubscribe() {
    const listenForClear = this._aggregateGroupsReturn?.listenForClear;
    const clearControl = this._clearControl
    if (listenForClear && clearControl) {
      listenForClear(undefined, clearControl);
      delete this._clearControl;
    }
    const unsub = this._aggregateGroupsReturn?.unsub
    if (unsub) {
      unsub();
      delete this._aggregateGroupsReturn;
    }
  }

  onReturnFocusToChange() {
    const { returnFocusTo, _unwatchReturnToFocus } = this;
    if (_unwatchReturnToFocus) {
      _unwatchReturnToFocus();
    }
    if (returnFocusTo) {
      this._unwatchReturnToFocus = watchReturnToFocus(returnFocusTo, this);
    }
  }

  listenForClear() {
    const listenForClear = this._aggregateGroupsReturn?.listenForClear;
    this._clearControl = listenForClear && listenForClear(this.config.clearControl, this._clearControl);
  }

  checkConfigChanges(newConfig: Partial<SelectedOptionsConfig>, oldConfig: Partial<SelectedOptionsConfig>) {
    if (newConfig.hasOwnProperty('returnFocusTo') && newConfig.returnFocusTo !== oldConfig.returnFocusTo) {
      this.onReturnFocusToChange();
    }
    if (newConfig.hasOwnProperty('forSelect') && newConfig.forSelect !== oldConfig.forSelect) {
      this.aggregateGroups();
    }
    if (newConfig.hasOwnProperty('orderAsListed') && newConfig.orderAsListed !== oldConfig.orderAsListed) {
      this.aggregateGroups();
    }
    if (newConfig.hasOwnProperty('clearControl') && newConfig.clearControl !== oldConfig.clearControl) {
      this.listenForClear();
    }
    if (this._rendered) {
      if (newConfig.hasOwnProperty('notags') && newConfig.notags !== oldConfig.notags) {
        this._rendered = false; // force a re-render
      }
      this.render();
    }

  }

  get config(): SelectedOptionsConfig {
    return this._config || (this._config = {
      optionName: 'name',
      optionValue: 'value',
      delimiter: ',',
      limitTags: -1,
      ...configFromDataAttributes(this.host, {}, dataAttributesConversions),
      ...(this._apiConfig || {})
    })
  }

  dispatchEvent(type: string, detail: { option: any }): boolean {
    return this.host.dispatchEvent(createCustomEvent(type, { cancelable: false, bubbles: true, detail: detail }));
  }

  clear() {
    this._aggregateGroupsReturn?.clear();
  }

  render() {
    const host = this.host;
    if (!host) return; // instance destroyed before debounce timeout
    const config = this.config;
    let options = this.getOptions();
    const limitTags = config.limitTags;
    this._activeIndex = Math.max(Math.min(this._activeIndex, options.length - 1), 0);
    this._translate = translations(this.host).t;
    const refocusIndex = hasFocus(this.host) ? this._activeIndex : -1;
    let summarizeCount = 0;
    if (limitTags > -1) {
      summarizeCount = options.slice(limitTags).length;
      options = options.slice(0, limitTags);
    }
    if (!this._rendered) {
      while (host.lastChild) {
        host.lastChild.remove()
      }
    }

    const classes = {
      [CLASSES.inline]: !!config.inline || !!config.notags,
      [CLASSES.noLastComma]: this.noLastComma
    }
    applyClasses(host, classes);

    this.renderDescriber();
    this.renderList(options, summarizeCount);

    if (refocusIndex > -1) {
      const tag = host.querySelectorAll<HTMLElement>('[role="listitem"] > *')[refocusIndex];
      if (tag) {
        tag.focus();
      }
    };
    this._rendered = true;
  }

  renderDescriber() {
    const host = this.host;
    const removeLabelId = this._removeLabelId
    let span;
    while (!(span = host.querySelector(`#${removeLabelId}`))) {
      this.host.insertAdjacentHTML('afterbegin', `<span id=${this._removeLabelId} style="display: none"></span>`);
    }
    span.textContent = this._translate('optionTagDescription');
  }

  renderList(options: SelectedOptionGroupData[], summarizeCount: number) {
    const config = this.config;
    const { notags, prepElement, contentRenderer, optionDisabled, inline } = config;
    const delimiter = notags ? (config.delimiter + ' ') : undefined;
    const dataDelimiter = delimiter ? `data-delimiter="${htmlEncode(delimiter)}"` : '';
    // We want the list's contents to just read the visible label of each tag. But, when the screen reader
    // lands on the button, we want it to read the purpose of the tag/button. To do this,
    // each tag's' aria-describedby is set to a hidden span that reads 'remove'. When screen reader lands
    // on the tag, it will read "<option name>, click or press delete or backspace to remove"
    const host = this.host;
    const activeIndex = this._activeIndex;
    const describer = host.querySelector(`#${this._removeLabelId}`);
    let listEl = host.querySelector('[role="list"]');
    if (!options.length && summarizeCount < 1) {
      listEl && listEl.remove();
      return;
    }

    if (!listEl) {
      describer.insertAdjacentHTML('afterend', `<span role="list"></span>`);
      listEl = host.querySelector('[role="list"]');
    }
    const listitems = Array.from(listEl.querySelectorAll<HTMLElement>('[role="listitem"]'));
    let summaryListItem: HTMLElement;
    if (listitems[listitems.length -1]?.matches(`.${CLASSES.summary}`)) {
      summaryListItem = listitems.pop()
      summaryListItem.remove();
    }
    while (listitems.length > options.length) {
      listitems.pop().remove();
    }

    options.forEach((flattenedOption, index) => {
      const { option, groupName } = flattenedOption;
      const { name, shortName } = option;
      const disabled = isDisabled(option, { optionDisabled });
      let listitemEl = listitems[index];
      if (!listitemEl) {
        const buttonHTML = notags ?
          `<span role="button"></span>` :
          `<button class="tds-tag tds-tag--dismissable"></button>`;
        listEl.insertAdjacentHTML('beforeend', `<span role="listitem" ${dataDelimiter}>${buttonHTML}</span>`);
        listitemEl = listEl.lastElementChild as HTMLElement;
      }
      const tagEl = listitemEl.querySelector<HTMLElement>('span, button');
      let textContent = groupName ? `${groupName}: ` : '';
      textContent += (shortName || name);
      const title = shortName && shortName !== name ? name : undefined;
      const classes: { [key: string]: boolean } = {};
      const styles: { [key: string]: string | undefined } = {};
      const attributes: { [key: string]: any } = {
        ['aria-describedby']: this._removeLabelId,
        tabindex: index === activeIndex && !inline ? 0 : -1,
        title
      };
      const data: ElementPreparerData = {
        type: 'tag',
        option: option.option,
        isDisabled: disabled,
        groupName: groupName
      }
      if (prepElement && !notags) {
        prepElement(data, classes, styles, attributes);
      }
      if (notags) {
        attributes['aria-disabled'] = disabled ? 'true' : undefined;
      } else {
        attributes.disabled = disabled ? '' : undefined;
      }
      applyClasses(tagEl, classes);
      applyAttributes(tagEl, attributes);
      applyStyles(tagEl, styles);

      const content = (contentRenderer && contentRenderer(data) || textContent);
      applyContent(tagEl, content);
    });

    if (summarizeCount > 0) {
      if (!summaryListItem) {
        listEl.insertAdjacentHTML('beforeend', `<span role="listitem" ${dataDelimiter} class="${CLASSES.summary}"></span>`);
        summaryListItem = listEl.lastElementChild as HTMLElement;
      }
      listEl.appendChild(summaryListItem);
      const plusMoreTpl = this.config.summarizeTemplate || this._translate('optionsSPluselectedTpl');
      const plusMores = interpolate({ count: summarizeCount }, plusMoreTpl).split(';');
      const plusMore = (summarizeCount === 1 && plusMores[1]) || plusMores[0];
      summaryListItem.textContent = plusMore;
    }
  }

  focusOnLast(): boolean {
    const buttons = this.host.querySelectorAll<HTMLElement>(`span[role="button"], button.tds-tag`);
    const button = buttons[buttons.length - 1];
    if (button) {
      button.focus();
      return true;
    }
    return false;
  }


  destroy() {
    while (this.onDestroy && this.onDestroy.length) {
      this.onDestroy.pop()();
    }
    this.unsubscribe();
    if (this._unwatchReturnToFocus) {
      this._unwatchReturnToFocus();
      delete this._unwatchReturnToFocus;
    }

    if (this.host) {
      delete this.host.dataset[ENHANCED_FLAG];
    }
    instances.remove(this.host, INSTANCE_KEY);
    this.host = null;
  }

  get notags(): boolean { return this.config.notags }

  get returnFocusTo(): HTMLElement { return this.config.returnFocusTo }

  get limitTags(): number { return this.config.limitTags }

  getOptions(): SelectedOptionGroupData[] {
    const selectedOptions = this._selectedOptions;
    if (selectedOptions && selectedOptions.length) {
      return selectedOptions;
    }
    // not bound to any select context, return options property instead
    return (this.config.options || []).map(option => {
      const { optionName, optionValue, optionId } = this.config;
      return {
        option: toSelectOption(option, { optionName, optionValue, optionId })
      }
    });
  }

  get activeIndex(): number { return this._activeIndex }
  set activeIndex(activeIndex: number) {
    if (activeIndex !== this._activeIndex) {
      this._activeIndex = activeIndex;
      this.render();
    }
  }

  get noLastComma(): boolean { return this._noLastComma }

  set noLastComma(b: boolean) {
    this._noLastComma = b;
    this.host?.classList[b ? 'add' : 'remove'](CLASSES.noLastComma);
  }
}

class SelectedOptions {
  _instance: _SelectedOptionsInstance;
  constructor(element: HTMLElement, config?: SelectedOptionsConfig) {
    this._instance = <_SelectedOptionsInstance>instances.get(element, INSTANCE_KEY) || new _SelectedOptionsInstance(element, config);
    if (config) {
      this.updateConfig(config);
    }
  }

  updateConfig(config: SelectedOptionsConfig) {
    this._instance?.updateConfig(config);
  }

  focusOnLast() {
    return this._instance?.focusOnLast();
  }

  clear() {
    this._instance?.clear();
  }

  destroy() {
    return this._instance?.destroy();
  }
}

onDOMChanges(`${PATTERN_SELECTOR}`,
  function onPatternAdded(element: HTMLElement) {
    if (!element.dataset[ENHANCED_FLAG]) {
      new SelectedOptions(element)
    }
  },
  function onPatternRemoved(element: HTMLElement) {
    if (element.dataset[ENHANCED_FLAG] === "true") {
      new SelectedOptions(element).destroy();
    }
  }
);

export { SelectedOptions }
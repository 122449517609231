import { htmlEncode } from "./helpers"

const languages: any = {
  default: 'en',
  en: {
    // date-input/calendar
    year: 'Year',
    month: 'Month',
    current: 'current',
    minimum: 'minimum',
    maximum: 'maximum',
    today: 'Today',
    close: 'Close',
    nextYear: 'Next year',
    nextMonth: 'Next month',
    prevYear: 'Previous year',
    prevMonth: 'Previous month',
    calendar: 'Calendar',
    pageUp: 'Page Up',
    pageDown: 'Page Down',
    escapeLabel: "Press escape to close calendar",
    altKey: 'Alt',
    optionKey: 'Option',
    day: 'Day',
    showCalendar: 'show calendar',
    months: [
      {name: 'January', abbr: 'Jan'},
      {name: 'February', abbr: 'Feb'},
      {name: 'March', abbr: 'Mar'},
      {name: 'April', abbr: 'Apr'},
      {name: 'May', abbr: 'May'},
      {name: 'June', abbr: 'Jun'},
      {name: 'July', abbr: 'Jul'},
      {name: 'August', abbr: 'Aug'},
      {name: 'September', abbr: 'Sep'},
      {name: 'October', abbr: 'Oct'},
      {name: 'November', abbr: 'Nov'},
      {name: 'December', abbr: 'Dec'}
    ],
    days: [
      {name: 'Sunday', abbr: 'Sun', initial: 'S'},
      {name: 'Monday', abbr: 'Mon', initial: 'M'},
      {name: 'Tuesday', abbr: 'Tue', initial: 'T'},
      {name: 'Wednesday', abbr: 'Wed', initial: 'W'},
      {name: 'Thursday', abbr: 'Thu', initial: 'T'},
      {name: 'Friday', abbr: 'Fri', initial: 'F'},
      {name: 'Saturday', abbr: 'Sat', initial: 'S'}
    ],
    // accordion
    accordionCollapsed: 'content collapsed, button',
    accordionExpanded: 'content expanded, button',
    // search
    searchButtonLabel: 'submit search',
    searchButtonTitle: "Search",
    searchLabel: 'Enter your search below',
    // sorted content
    ascending: 'ascending',
    descending: 'descending',
    htmlTableIs: 'Table {0} is ',
    tableSortStateDescription: 'sorted by {0}, {1}',
    notSorted: 'not sorted',
    sortColumnDescription: "Sorts by this column",
    dismissAlert: "dismiss message",
    dismissModal: "dismiss dialog",
    menu: 'menu',
    column: 'column',
    // row selection
    rowSelectorHeading: 'Row selector',
    singleRowSelectorHeading: 'Single row selector',
    selectRowLabel: 'Select row',
    selectAllRowsLabel: 'Select all rows',
    // expandable rows
    expandColumnHeader: 'expand or collapse row',
    expandableRowToggleLabel: 'expand this row',
    expandadedRowHeader: 'additional information for the prior row',
    // combo box, et al
    noOptionsMessage: 'No results',
    hasOptionsMessage: '{0} results available',
    hasOneOptionMessage: '1 result available',
    optionsLoadingMessage: 'Loading options...',
    addOtherPrompt: 'add "{name}"',
    optionsSelectedTpl:'{count} items selected; 1 item selected',
    optionsSPluselectedTpl:'+{count} more',
    optionsSearchLabel: "Search list of options",
    optionTagDescription: "click to deselect",
    selectDialogDescriptionBase: "with options list",
    selectDialogDescriptionFilter: 'textbox to filter options',
    selectDialogDescriptionActions: 'buttons to select all and clear selection',
  // multiSelect
    optionsSelected:'{0} options selected',
    allOptionsSelected:'All {0} options selected',
    // for unit test
    color: 'color',
    interpolate: '{0} {1} {2} - {2} {1} {0}',
    //file upload
    uploadStarted: "upload started",
    fileUploadErrorAlert: "Upload failed",
    filePickerLabel: "browse",
    filePickerAriaLabel: "Choose files to upload",
    dragdropLabel: "Drag and drop or",
    fileTitleErrorAlert: "File not selected;Files not selected",
    fileSizeErrorAlert: "exceeds the maximum file size of {maxsize}. Please try selecting a different file.",
    fileTypeErrorAlert: "is not a valid file type. Please try selecting a different file.",
    fileMaxExceededErrorAlert: "Number of files selected ({filecount}) would exceed the maximum files allowed of {maxcount}.",
    fileMaxAllowedErrorAlert: "You have already selected the maximum number of files allowed ({maxcount}). To select other files, remove one or more files.",
    fileDuplicateErrorAlert: "has already been selected",
    fileAsycUploadTitle: "File uploaded;Files uploaded",
    fileSyncUploadTitle: "File upload preview;Files upload preview",
    confirmRemovalOf: 'Remove the file',
    // search pagination
    paginationLabel: 'Pagination',
    paginationDescr: 'page {page} of {total}',
    paginationPageLabel: 'Go to page {page}',
    paginationPrevLabel: 'Previous page',
    paginationNextLabel: 'Next page',
    paginationPlaceholderRange: 'skipping {start} to {end}',
    paginationPlaceholderMany: 'skipping {start} and higher',

    // table pagination
    rowPerPageText: 'Rows per page {size}',
    pageXofY: "Page {page} of {total}",
    pageX: "Page {page}",
    manyText: "many",
    all: "all",
    showingRecordsTextWithoutTotal: "Showing {start}–{end}",
    showingRecordsText: "Showing {start}–{end} of {total}",
    previousLabel: "previous",
    nextLabel: "next",
    // editable table
    nowEditingRow: "Now editing row {0}",
    rowColumnsEditable: "Columns {0} are editable",
    rowColumnEditable: "Column {0} are editable",
    rowActionsColumn: "Save and Cancel buttons are in column {0}",
    applyingUpdates: "Applying updates",
    updatesComplete: "Updates complete",
    updatesCompleteWithErrors: "Updates not applied. Please review columns {0}",
    updatesCompleteWithOneError: "Updates not applied. Please review column {0}",
    // general
    loading: 'Loading',
    undo: 'Undo',
    remove: 'Remove',
    cancel: 'Cancel',
    save: 'Save',
    editable: 'editable',
    readonly: 'read only',
    other: 'Other',
    clear: 'Clear',
    selectAll: 'Select All',
    selected: 'Selected',
    search: 'Search',
    hour: "Hour",
    minute: 'Minute',
    period: "AM PM",
    toggleTimePicker: 'Toggle time picker',
    timePicker: 'Time picker'
  },
  // additional languages are for unit tests now
  "en-gb": {
    color: 'colour',
  },
  fr: {
    year: 'Année',
    ascending: 'ascendant',
    descending: 'descendant',
    sortColumnDescription: "Trie cette colonne",
    tableSortStateDescription: 'trié par {0}, {1}',
  },
  "fr-ca": {
    tire: 'robeur'
  }
}

function translations(lang: string | HTMLElement | ShadowRoot) {
  if ('string' !== typeof lang) {
    // hopefully, it's an Element
    lang = getLang(lang)
  }
  lang = lang.toLowerCase()
  const langParts = lang.split('-')
  const language = langParts.reduce((acc, _, index ) => {
    const langCode = langParts.slice(0, index+1).join('-');
    const l = languages[langCode]
    return l ? {...acc, ...l} : acc
  }, lang.indexOf(languages.default) === -1 ? languages[languages.default] : {})

  return {
    t: (key: string, ...replacements: string[]) => translate(language, key, ...replacements),
    html: (key: string, ...replacements: string[]) => htmlEncode(translate(language, key, ...replacements)),
    language
  }
}

function translate(language: any, key: string, ...replacements: string[]) : string {
  let value = language[key] || '';
  if ('string' === typeof value) {
    // replace positional substitions.
    // e.g. {0} is replaced with replacements[0], {1} is replaced with replacements[1], etc.
    value = replacements.reduce((v, r, i) => {
      return v.replace(new RegExp(`\\{${i}\\}`, 'g'), r)
    }, value);
  }
  return value;
}

function getLang(element: HTMLElement | ShadowRoot) : string {
  let lang;
  let node: Node = element;
  while (!lang && node) {
    lang = (<HTMLElement>node).lang;
    node = node.parentNode || (<ShadowRoot>node).host;
  }
  return (lang || window.navigator.language || 'en-US'); //default is fallback for unit tests
}

export { getLang, translations }

import { EventListeners } from "../EventListeners";
// import doWhileEventing from "../doWhileEventing";
import { normalizeKey } from "../keyboard";

type PopoverPlacement =
  'top' | 'top-start' | 'top-end' | 'top-left-center' | 'top-right-center' |
  'bottom' | 'bottom-start' | 'bottom-end' | 'bottom-left-center' | 'bottom-right-center' |
  'left' | 'left-start' | 'left-end' | 'left-top-center' | 'left-bottom-center' |
  'right' | 'right-start' | 'right-end' | 'right-top-center' | 'right-bottom-center';

type PopoverHideReason = 'escape' | 'clickoutside';

interface PopoverOptions {
  placement?: PopoverPlacement,
  alternatePlacements?: PopoverPlacement[],
  hideOn?: {
    escapeKey?: boolean,
    clickOutside?: boolean,
  },
  transitionName?: string,
  transitionEnterTimeout?: number,
  transitionLeaveTimeout?: number,
  onHide?: (reason: PopoverHideReason) => boolean
}

interface PositioningResult {
  refRect: DOMRect,
  popoverWidth: number,
  popoverHeight: number,
  viewportWidth: number,
  viewportHeight: number,
  placementApplied: PopoverPlacement,
  adjX: number,
  adjY: number,
  pause: boolean
}

const TOP = 'top';
const TOP_START = 'top-start';
const TOP_END = 'top-end';
const TOP_LEFT_CENTER = 'top-left-center';
const TOP_RIGHT_CENTER = 'top-right-center';
const BOTTOM = 'bottom';
const BOTTOM_START = 'bottom-start';
const BOTTOM_END = 'bottom-end';
const BOTTOM_LEFT_CENTER = 'bottom-left-center';
const BOTTOM_RIGHT_CENTER = 'bottom-right-center';
const LEFT = 'left';
const LEFT_START = 'left-start';
const LEFT_END = 'left-end';
const LEFT_TOP_CENTER = 'left-top-center';
const LEFT_BOTTOM_CENTER = 'left-bottom-center';
const RIGHT = 'right';
const RIGHT_START = 'right-start';
const RIGHT_END = 'right-end';
const RIGHT_TOP_CENTER = 'right-top-center';
const RIGHT_BOTTOM_CENTER = 'right-bottom-center';

const defaultOptions: PopoverOptions = {
  placement: BOTTOM_START,
  transitionName: 'popover',
  transitionEnterTimeout: 800,
  transitionLeaveTimeout: 0
}

export function createPopover(element: HTMLElement, reference: HTMLElement, options: PopoverOptions = {}) {
  options = { ...defaultOptions, ...options };
  let elementParent = element.parentNode;
  let elementSibling = element.nextSibling;
  let container = createPopoverContainer(element);
  // initialize dataset.placementApplied (data-placement-applied) to start 
  // in case this attribute is needed for CSS purposes. Will be set properly when positioned  
  element.dataset.placementApplied = 'tbd';
  onEnter(element, options);
  let runPoll = false;
  let lastResult: PositioningResult;
  function reposition() {
    if (!reference.isConnected) {
      // reference has been removed from DOM
      destroyPopover();
      return;
    }
    
    if (runPoll && container) {
      const result = postionPopover(container, reference, options, lastResult);
      if (result) {
        lastResult = result;
        element.dataset.placementApplied = reference.dataset.popoverPlacementApplied = result.placementApplied;

        if (result.pause) {
          setTimeout(reposition, 800); // what until window.scrollBy completes
          return;
        }
      }
      /*********************************************************************************************
       * This seems OK. Need to better peformance test. If not, then we would have to listen 
       * for scroll and resize  events using doWhileEventing. But, scroll events would also have 
       * to be listened for in any ancestors that may be scrollable. (Scroll event does not bubble). 
       * But, changes to the size of elements on the page may cause a reflow. So we would have to 
       * watch for anything that may cause a reflow. So, for now, try to make requestAnimationFrame 
       * as efficient as possible
       *********************************************************************************************/
      requestAnimationFrame(reposition);
    }
  }

  let hideEventListeners = new EventListeners();

  runPoll = true;
  reposition();

  async function destroyPopover() {
    runPoll = false;
    if (container) {
      await onLeave(element, options);
      delete element.dataset.placementApplied;
      delete reference.dataset.popoverPlacementApplied;
      if (container) { // could have been removed during await onLeave(...) 
        container.remove();
        if (elementParent) {
          elementParent.insertBefore(element, elementSibling);
        } else if (element.parentNode === container) {
          container.removeChild(element);
        }
        container = elementParent = elementSibling = hideEventListeners = undefined;
      }
    }
  }

  setupHideHandlers(element, reference, options, hideEventListeners, destroyPopover);

  return destroyPopover;
}

function createPopoverContainer(element: HTMLElement) {
  const doc = element.ownerDocument;
  const container = doc.createElement('div');
  const style = container.style
  style.position = 'absolute';
  style.zIndex = '5000';
  style.top = '0';
  style.left = '0';
  container.dataset.popoverContainer = '';
  container.appendChild(element);
  return doc.body.appendChild(container);
}

function postionPopover(popover: HTMLElement, reference: HTMLElement, options: PopoverOptions, lastResult: PositioningResult): PositioningResult | void {
  const refRect = reference.getBoundingClientRect();
  const popoverWidth = popover.offsetWidth;
  const popoverHeight = popover.offsetHeight;
  const docElement = reference.ownerDocument.documentElement;
  const viewportHeight = docElement.clientHeight;
  const viewportWidth = docElement.clientWidth;
  const { top: adjY, left: adjX } = popover.parentElement.getBoundingClientRect();
  if (lastResult) {
    const lastRect = lastResult.refRect;
    // To keep requestAnimationFrame as efficient as possible,
    // only run calculations if any of the variable have changed
    if (
      lastRect.x === refRect.x &&
      lastRect.y === refRect.y &&
      lastRect.width === refRect.width &&
      lastRect.height === refRect.height &&
      lastResult.popoverWidth === popoverWidth &&
      lastResult.popoverHeight === popoverHeight &&
      lastResult.viewportWidth === viewportWidth &&
      lastResult.viewportHeight === viewportHeight &&
      lastResult.adjX === adjX &&
      lastResult.adjY === adjY
    ) return;
  }
  let x = 0;
  let y = 0;
  let x1: number;
  let y1: number;
  const placement = options.placement || BOTTOM_START;
  const alternatePlacements = options.alternatePlacements && options.alternatePlacements.length ?
    options.alternatePlacements : getAlternatePostions(placement);

  // start with the desired placement, if none fit, default to the desired placement
  let placements = [placement, ...alternatePlacements, placement];
  if (lastResult && lastResult.placementApplied !== placement) {
    const lastApplied = lastResult.placementApplied
    // To keep the popup from unnecessarily moving once it is shown,
    // start with the current placement. If it still fits, use it 
    placements = [lastApplied, ...placements.filter(p => p !== lastApplied), lastApplied];
  }
  let placementApplied: PopoverPlacement;
  for (let i = 0; i < placements.length; i++) {
    placementApplied = placements[i];
    x = calculateOffsetX(refRect, popoverWidth, placementApplied);
    y = calculateOffsetY(refRect, popoverHeight, placementApplied);
    x1 = x + popoverWidth;
    y1 = y + popoverHeight;
    if (x >= 0 && y >= 0 && x1 <= viewportWidth && y1 < viewportHeight) {
      break;
    }
  }
  popover.style.transform = `translate(${(x - adjX)}px, ${(y - adjY)}px)`;
  let pause = false;
  if (!lastResult) { // first positioning only
    const scrollX = x1 > viewportWidth ? x1 - viewportWidth : x < 0 ? x : 0; 
    const scrollY = y1 > viewportHeight ? y1 - viewportHeight : y < 0 ? y : 0; 
    if (scrollX || scrollY) { 
      window.scrollBy({top: scrollY, left: scrollX, behavior: 'smooth'});
      pause = true; // pause the polling while scroll happens to prevent unnecessary recalcs
    }
  }
  return { refRect, popoverWidth, popoverHeight, viewportWidth, viewportHeight, placementApplied, adjX, adjY, pause };
}

function calculateOffsetX(refRect: DOMRect, popoverWidth: number, placement: PopoverPlacement): number {
  switch (placement) {
    case LEFT:
    case LEFT_START:
    case LEFT_END:
    case LEFT_TOP_CENTER:
    case LEFT_BOTTOM_CENTER:
      return refRect.x - popoverWidth;

    case RIGHT:
    case RIGHT_START:
    case RIGHT_END:
    case RIGHT_TOP_CENTER:
    case RIGHT_BOTTOM_CENTER:
      return refRect.right;

    case TOP:
    case BOTTOM:
      return refRect.x + ((refRect.width - popoverWidth) / 2);

    case TOP_START:
    case BOTTOM_START:
    default:
      return refRect.x;

    case TOP_END:
    case BOTTOM_END:
      return refRect.right - popoverWidth;

    case TOP_LEFT_CENTER:
    case BOTTOM_LEFT_CENTER:
      return refRect.x + (refRect.width / 2);

    case TOP_RIGHT_CENTER:
    case BOTTOM_RIGHT_CENTER:
      return refRect.x - popoverWidth + (refRect.width / 2);

  }
}

function calculateOffsetY(refRect: DOMRect, popoverHeight: number, placement: PopoverPlacement): number {
  switch (placement) {
    case TOP:
    case TOP_START:
    case TOP_END:
    case TOP_LEFT_CENTER:
    case TOP_RIGHT_CENTER:
      return refRect.y - popoverHeight;

    case BOTTOM:
    case BOTTOM_START:
    case BOTTOM_END:
    case BOTTOM_LEFT_CENTER:
    case BOTTOM_RIGHT_CENTER:
    default:
      return refRect.bottom;

    case LEFT:
    case RIGHT:
      return refRect.y + ((refRect.height - popoverHeight) / 2);

    case LEFT_START:
    case RIGHT_START:
      return refRect.y;

    case LEFT_END:
    case RIGHT_END:
      return refRect.bottom - popoverHeight;

    case LEFT_TOP_CENTER:
    case RIGHT_TOP_CENTER:
      return refRect.y + (refRect.height / 2);

    case LEFT_BOTTOM_CENTER:
    case RIGHT_BOTTOM_CENTER:
      return refRect.y - popoverHeight + (refRect.height / 2);
  }
}

function getAlternatePostions(placement: PopoverPlacement): PopoverPlacement[] {
  switch (placement) {
    case TOP:
      return [TOP_START, TOP_END, BOTTOM, BOTTOM_START, BOTTOM_END];

    case TOP_START:
      return [TOP, TOP_END, BOTTOM_START, BOTTOM, BOTTOM_END];

    case TOP_END:
      return [TOP, TOP_START, BOTTOM_END, BOTTOM, BOTTOM_START];

    case TOP_LEFT_CENTER:
      return [TOP_RIGHT_CENTER, BOTTOM_LEFT_CENTER, BOTTOM_RIGHT_CENTER];

    case TOP_RIGHT_CENTER:
      return [TOP_LEFT_CENTER, BOTTOM_RIGHT_CENTER, BOTTOM_LEFT_CENTER];

    case BOTTOM:
      return [BOTTOM_START, BOTTOM_END, TOP, TOP_START, TOP_END];

    case BOTTOM_START:
    default:
      return [BOTTOM, BOTTOM_END, TOP_START, TOP, TOP_END];

    case BOTTOM_END:
      return [BOTTOM, BOTTOM_START, TOP_END, TOP, TOP_START];

    case BOTTOM_LEFT_CENTER:
      return [BOTTOM_RIGHT_CENTER, TOP_LEFT_CENTER, TOP_RIGHT_CENTER];

    case BOTTOM_RIGHT_CENTER:
      return [BOTTOM_LEFT_CENTER, BOTTOM_RIGHT_CENTER, BOTTOM_LEFT_CENTER];

    case LEFT:
      return [LEFT_START, LEFT_END, RIGHT, RIGHT_START, RIGHT_END];

    case LEFT_START:
      return [LEFT, LEFT_END, RIGHT_START, RIGHT, RIGHT_END];

    case LEFT_END:
      return [LEFT, LEFT_START, RIGHT_END, RIGHT, RIGHT_START];

    case LEFT_TOP_CENTER:
      return [LEFT_BOTTOM_CENTER, RIGHT_TOP_CENTER, RIGHT_BOTTOM_CENTER];

    case LEFT_BOTTOM_CENTER:
      return [LEFT_TOP_CENTER, RIGHT_BOTTOM_CENTER, RIGHT_TOP_CENTER];

    case RIGHT:
      return [RIGHT_START, RIGHT_END, LEFT, LEFT_START, LEFT_END];

    case RIGHT_START:
      return [RIGHT, RIGHT_END, LEFT_START, LEFT, LEFT_END];

    case RIGHT_END:
      return [RIGHT, RIGHT_START, LEFT_END, LEFT, LEFT_START];

    case RIGHT_TOP_CENTER:
      return [RIGHT_BOTTOM_CENTER, LEFT_TOP_CENTER, LEFT_BOTTOM_CENTER];

    case RIGHT_BOTTOM_CENTER:
      return [RIGHT_TOP_CENTER, LEFT_BOTTOM_CENTER, LEFT_TOP_CENTER];
  }
}

function setupHideHandlers(popover: HTMLElement, reference: HTMLElement, options: PopoverOptions, eventListeners: EventListeners, destroy: Function) {
  const hideOn = options.hideOn;
  const hide = (reason: PopoverHideReason) => {
    const onHide = options.onHide;
    if (typeof onHide === 'function') {
      onHide(reason) && destroy();
    }
  }

  if (hideOn?.escapeKey) {
    eventListeners.addListener(document, 'keydown', (e: KeyboardEvent) => {
      const key = normalizeKey(e);
      if (key === 'Escape') {
        hide('escape');
        e.stopPropagation();
      }
    });
  }
  if (hideOn?.clickOutside) {
    eventListeners.addListener(document, 'mousedown', (e: Event) => {
      const target = e.target as Element;
      if (!popover.contains(target) && !reference.contains(target)) {
        hide('clickoutside');
      }
    });

  }
  // We need one for when popover or reference loses focus, but that is more complicated
  // address it when there is a need in the code 
}

function onEnter(element: HTMLElement, options: PopoverOptions) {
  const enterClass = `${options.transitionName}-enter`;
  const enterActiveClass = `${options.transitionName}-enter-active`;
  element.classList.add(enterClass);
  setTimeout(() => {
    element.classList.add(enterActiveClass)
    setTimeout(() => {
      element.classList.remove(enterClass, enterActiveClass)
    }, options.transitionEnterTimeout);
  });
}

async function onLeave(element: HTMLElement, options: PopoverOptions) {
  const leaveClass = `${options.transitionName}-leave`;
  const leaveActiveClass = `${options.transitionName}-leave-active`;
  element.classList.add(leaveClass);
  return new Promise<void>((resolve) => {
    setTimeout(() => {
      element.classList.add(leaveActiveClass);
      setTimeout(() => {
        element.classList.remove(leaveClass, leaveActiveClass);
        resolve();
      }, options.transitionLeaveTimeout);
    });
  })
}
import { getTargetElements } from '../getTargetElement';
import { runAnimation } from '../animations';
import { createCustomEvent } from '../customEvent';
import { configFromDataAttributes } from "../helpers";

const TRIGGER_TARGET_ATTRIBUTE = 'data-toggle-collapse';
const TRIGGER_SELECTOR = `[${TRIGGER_TARGET_ATTRIBUTE}]`;

const inTransition: HTMLElement[] = [];

const durations = {
  'fast': '200ms',
  'medium-fast': '300ms',
  'medium': '400ms',
  'medium-slow': '600ms',
  'slow': '1s'
}

const events = {
  collapsing: 'tdsCollapsing',
  collapsed: 'tdsCollapsed',
  expanding: 'tdsExpanding',
  expanded: 'tdsExpanded'
}

// TODO: move to own file and export
interface CollapsibleConfig {
  collapsibleDuration?: 'fast' | 'medium-fast' | 'medium' | 'medium-slow' | 'slow' | number;
  collapsibleNoOpacity?: boolean
}

const defaultDuration = 'medium-fast';
const defaultConfig: CollapsibleConfig = {
  collapsibleDuration: defaultDuration,
  collapsibleNoOpacity: false
}

class Collapsible {
  el: HTMLElement;
  _triggerBy: HTMLElement;
  _config = defaultConfig;

  constructor(el: HTMLElement, config?: CollapsibleConfig) {
    this.el = el;
    this.setConfig(configFromDataAttributes(el));
    if (config) {
      this.setConfig(config);
    }
  }

  setConfig(config: CollapsibleConfig) {
    this._config = { ...this._config, ...config }
  }

  toggle(trigger?: HTMLElement): boolean {
    return doToggle(this, !this.isShown(), trigger);
  }

  show(trigger: HTMLElement = null): boolean {
    return doToggle(this, true, trigger);
  }

  hide(trigger: HTMLElement = null): boolean {
    return doToggle(this, false, trigger);
  }

  isShown(): boolean {
    const { el } = this;
    return !!el && !el.hidden;
  }

  get transitioning(): boolean {
    return inTransition.indexOf(this.el) > -1;
  }
}

function getTriggers(el: Element): HTMLElement[] {
  const allTriggers = document.querySelectorAll(TRIGGER_SELECTOR);
  return Array.prototype.filter.call(allTriggers, (trigger: Element) => {
    const elements = getTargetElements(trigger, TRIGGER_TARGET_ATTRIBUTE);
    try {
      return elements.indexOf(el as HTMLElement) > -1;
    } catch {
      /* istanbul ignore next */
      return false;
    }
  });
}

function doToggle(collapsible: Collapsible, expand: boolean, trigger: HTMLElement): boolean {
  const eventStart = expand ? events.expanding : events.collapsing;
  const eventEnd = expand ? events.expanded : events.collapsed;
  const action = expand ? 'expand' : 'collapse';
  let ret = true;
  if (!collapsible.transitioning && collapsible.isShown() !== expand) {
    collapsible._triggerBy = trigger;
    if (ret = fireEvent(collapsible, eventStart)) {
      setTriggerAria(collapsible, expand);
      toggleCollapsible(collapsible, action, () => {
        fireEvent(collapsible, eventEnd);
      });
    }
  }
  return ret;
}

function fireEvent(collapsible: Collapsible, type: string) {
  const trigger = collapsible._triggerBy;
  const event = createCustomEvent(type, {
    cancelable: type === events.expanding || type === events.collapsing,
    detail: {
      collapsible,
      trigger
    }
  });
  return collapsible.el.dispatchEvent(event);
}

function toggleCollapsible(collapsible: Collapsible, action: string, done?: Function) {
  const { el, _config } = collapsible;
  const show = action !== 'collapse';
  const includeOpacity = !_config.collapsibleNoOpacity;
  const { collapsibleDuration } = _config;
  const duration = prefersReducedMotion() ? 0 : durationToMilliseconds(collapsibleDuration);
  const opacityDuration = show ? duration : Math.ceil(duration * 1.5);
  const computedStyles = window.getComputedStyle(el);
  // window.getComputedStyle returns a live object. Save current settings before
  // updates are applied 
  const savedStyles = {
    paddingTop: computedStyles.paddingTop,
    paddingBottom: computedStyles.paddingBottom,
    borderTopWidth: computedStyles.borderTopWidth,
    borderBottomWidth: computedStyles.borderBottomWidth,
    opacity: computedStyles.opacity
  };
  const savedStyleAttr = el.getAttribute('style');
  const borderTop = savedStyles.borderTopWidth ? parseInt(savedStyles.borderTopWidth) : 0;
  const borderBottom = savedStyles.borderBottomWidth ? parseInt(savedStyles.borderBottomWidth) : 0;
  const styleHeight = el.style.height;
  setTransitioning(collapsible, true);

  const prep = () => {
    el.style.overflow = 'hidden';
    el.style.transitionProperty = 'all, opacity';
    el.style.transitionTimingFunction = 'ease';
    el.style.visibility = 'visible';
    if (show) {
      el.style.height = '0';
      el.style.paddingTop = '0';
      el.style.paddingBottom = '0';
      el.style.borderTopWidth = '0';
      el.style.borderBottomWidth = '0';
      if (includeOpacity) {
        el.style.opacity = '0';
      }
      el.hidden = false;
    } else {
      el.style.height = `${el.offsetHeight}px`;
    }
  }

  const set = () => {
    el.style.transitionDuration = `${duration}ms, ${opacityDuration}ms`;
    if (show) {
      const paddingTop = savedStyles.paddingTop ? parseInt(savedStyles.paddingTop) : 0;
      const paddingBottom = savedStyles.paddingBottom ? parseInt(savedStyles.paddingBottom) : 0;
      const addedHeight = paddingTop + paddingBottom + borderTop + borderBottom;
      const height = el.scrollHeight + addedHeight;
      el.style.height = styleHeight || (`${height}px`);
      el.style.paddingTop = savedStyles.paddingTop;
      el.style.paddingBottom = savedStyles.paddingBottom;
      el.style.borderTopWidth = savedStyles.borderTopWidth;
      el.style.borderBottomWidth = savedStyles.borderBottomWidth;
      if (includeOpacity) {
        el.style.opacity = savedStyles.opacity;
      }
    } else {
      el.style.height =
        el.style.paddingTop =
        el.style.paddingBottom =
        el.style.borderTopWidth =
        el.style.borderBottomWidth = '0';
      if (includeOpacity) {
        el.style.opacity = '0';
      }
    }
  }

  const complete = () => {
    if (!show) {
      el.hidden = true;
    }
    if (savedStyleAttr) {
      el.setAttribute('style', savedStyleAttr);
    } else {
      el.removeAttribute('style');
    }
    setTransitioning(collapsible, false);
    if (done) {
      done();
    }
  }

  if (duration) {
    runAnimation(el, { prep, set, complete }, 'height');
  } else {
    // skip animation
    el.hidden = !show;
    complete();
  }
}

function setTransitioning(collapsible: Collapsible, b: boolean) {
  const { el } = collapsible;
  const index = inTransition.indexOf(el);
  if (b) {
    if (index === -1) {
      inTransition.push(el);
    }
  } else if (index > -1) {
    inTransition.splice(index, 1);
  }
}

function setTriggerAria(collapsible: Collapsible, show: boolean) {
  const { _triggerBy } = collapsible;
  const triggers = getTriggers(collapsible.el);
  if (_triggerBy && triggers.indexOf(_triggerBy) === -1) {
    triggers.push(_triggerBy);
  }
  triggers.forEach(trigger => {
    trigger.setAttribute('aria-expanded', `${show}`);
  });
}

function durationToMilliseconds(collapsibleDuration: string | number): number {
  // convert duration to ms to simply toggle logic
  let duration = collapsibleDuration;
  if (typeof collapsibleDuration === 'string') {
    const match = /^(\d*\.?\d*)(s|ms)?$/.exec((durations as any)[collapsibleDuration] || collapsibleDuration);
    if (match) {
      duration = parseFloat(match[1]);
      if (match[2] === 's') {
        duration *= 1000;
      }
    } else {
      duration = durationToMilliseconds((durations as any)[defaultDuration]);
    }
  }
  return duration as number;
}

let prefersReducedMotionResult: boolean = undefined;
function prefersReducedMotion(): boolean {
  if (typeof prefersReducedMotionResult === 'undefined') {
    prefersReducedMotionResult = typeof window.matchMedia !== 'undefined' && window.matchMedia('(prefers-reduced-motion: reduce)').matches
  }
  return prefersReducedMotionResult;
}

/**
 * If an application uses both Core and web components (like the doc site), this logic is called twice because this code 
 * is also compiled into the web components library. When this happens, the two event handlers cancel each other breaking
 * collapsible implementation using data configuration. To prevent this from happening, we set a flag on the document object.
 * This is the simplest fix for now. In our next major version, we will consider a better way of preventing this duplicate 
 * code overall. For instance, perhaps make @trv-tds/webcomponents dependent on @trv-tds/core.
 */
if (typeof document !== 'undefined' && !(document as any).__tdsCollapsibleRegistered) { 
  (document as any).__tdsCollapsibleRegistered = true;
  document.addEventListener('click', (e: Event) => {
    const target = e.target as HTMLElement;
    const trigger = target.closest(TRIGGER_SELECTOR) as HTMLElement;
    const elements = trigger && getTargetElements(trigger, TRIGGER_TARGET_ATTRIBUTE);
    if (elements && elements.length) {
      elements.forEach((el: HTMLElement) => {
        new Collapsible(el).toggle(trigger);
      });
      if (trigger.matches('a, area')) {
        e.preventDefault();
      }
    }
  });
}

export { Collapsible }
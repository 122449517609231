import { DataTableComponent } from './DataTableComponent';
import { getExpansionRows, getElementsFromThisTable, inNestedTable } from './dataTableUtils';
import { ROW_CHECKBOX_SELECTOR, ROW_RADIO_SELECTOR, ROW_TOGGLE_SELECTOR} from './dataTableConstants';

export function bindRowSelection(dataTable: DataTableComponent): Function {
  const { root } = dataTable;
  const clickHandler = onRowSelection(dataTable);
  root.addEventListener('click', clickHandler);
  setRowSelectionState(dataTable);
  return function unbindRowSelection() {
    root.removeEventListener('click', clickHandler);
  }
}

function onRowSelection(dataTable: DataTableComponent) {
  return (e: Event) => {
    const target = e.target as HTMLElement;
    if (target.closest("[data-row-selector='true']") && !inNestedTable(target, dataTable.root)) {
      const input = target.closest<HTMLInputElement>('input[type="checkbox"], input[type="radio"]');
      const toggle = target.closest<HTMLButtonElement>('button[aria-pressed="false"]');
      if (input || toggle) {
        if (target.closest('thead')) {
          if (input && input.type === 'checkbox') {
            selectAllRows(dataTable, (target as HTMLInputElement).checked);
          }
        } else {
          if ((input && input.type === 'radio') || toggle) {
            clearCurrentSelection(dataTable, target);
          }
          if (toggle) {
            toggle.setAttribute('aria-pressed', 'true');
          }
        }  
        setRowSelectionState(dataTable);
      }
    }
  }
}

function selectAllRows(dataTable: DataTableComponent, selectAll: boolean) {
  const root = dataTable.root;
  const checkGroup = getElementsFromThisTable(root, ROW_CHECKBOX_SELECTOR);
  checkGroup.forEach((item: HTMLInputElement) => item.checked = selectAll);
}

function setRowSelectionState(dataTable: DataTableComponent) {
  const { root, config } = dataTable;
  const selectorGroup = getElementsFromThisTable(root, [ROW_CHECKBOX_SELECTOR, ROW_RADIO_SELECTOR, ROW_TOGGLE_SELECTOR].join(","));
  let selectedCount = 0;
  selectorGroup.forEach((item: HTMLElement) => {
    const selected = item.matches('input[type="checkbox"], input[type="radio"]') ? 
      (item as HTMLInputElement).checked : item.getAttribute('aria-pressed') === 'true';
    const tr = item.closest('tr') as HTMLElement;
    if (tr) {
      [tr].concat(getExpansionRows(tr)).forEach(row => {
        row.classList[selected ? 'add' : 'remove'](config.rowSelectedClass);
      });
    }
    if (selected) {
      selectedCount++;
    }
  })
  const selectAllCheck: HTMLInputElement = root.querySelector('thead [data-row-selector="true"] input[type="checkbox"], thead input[type="checkbox"][data-row-selector="true"]');
  if (selectAllCheck && !inNestedTable(selectAllCheck, root)) {
    selectAllCheck.indeterminate = selectedCount && selectedCount !== selectorGroup.length;
    selectAllCheck.checked = selectedCount && selectedCount === selectorGroup.length;
  }
}

function clearCurrentSelection(dataTable: DataTableComponent, target: HTMLElement) {
  const root = dataTable.root;
  const radioGroup = getElementsFromThisTable(root, ROW_RADIO_SELECTOR);
  radioGroup.forEach((item: HTMLInputElement) => {
    if (item !== target) {
      item.checked = false
    }
  });
  const toggleGroup = getElementsFromThisTable(root, ROW_TOGGLE_SELECTOR);
  toggleGroup.forEach((item: HTMLElement) => {
    if (item !== target) {
      item.setAttribute('aria-pressed', 'false');
    }
  });
}

import { instances } from '../../utilities/instances';
import { NAMESPACE, CSS_NS, MULTISELECT_CLASSES } from '../../utilities/constants';
import { Store } from '../../utilities/store';
import { configFromDataAttributes, htmlEncode } from '../../utilities/helpers';
import onDOMChanges from '../../utilities/onDOMChanges';
import watchForChanges from '../../utilities/watchForChanges';
import { createCustomEvent } from '../../utilities/customEvent';
import { MultiSelectState, createStore } from './MultiSelectState';
import { MultiSelectComponent } from './MultiSelectComponent';
import { MultiSelectConfig } from './MultiSelectConfig';
import {
  bindMultiSelect,
  getOptions,
  getSelectedOptions,
  initWithConfig,
  setSelectedOptions
} from './MultiSelectBehavior';

// Default configuration for multiselect
const defaultConfig = {
  groupLimit: 99999999,
};
const ENHANCED_FLAG = 'enhancedMultiSelect';
const INSTANCE_KEY = `${NAMESPACE}MultiSelect`;
const PATTERN_SELECTOR = `${CSS_NS}multiselect`;

let nextId = 1;

class _MultiSelectInstance implements MultiSelectComponent {
  el: HTMLElement;
  host: HTMLElement;
  state: MultiSelectState;
  store: Store<MultiSelectState>;
  config: MultiSelectConfig = defaultConfig;
  options: any;
  onDestroy: Function[] = [];
  _unwatchForChanges: Function;
  _disabled: boolean = false;

  constructor(element: HTMLElement, clientConfig?: any) {
    this.el = this.host = element;
    // create store
    const store = this.store = createStore();
    element.dataset[ENHANCED_FLAG] = "true";

    // config
    const dataConfig: any = configFromDataAttributes(this.el);
    this.setConfig({ ...defaultConfig, ...dataConfig, ...clientConfig });

    // create multiselect
    createMultiselect(this);
    const unbind = bindMultiSelect(this);
    const unsubscribe = store.subscribe(this.onStateUpdate.bind(this));

    // initialize multiselect with client configuration
    initWithConfig(this);

    this.watchAttributeChanges();

    this.onDestroy = [unbind, unsubscribe];
    instances.set(element, INSTANCE_KEY, this);
  }

  setConfig(config: any = {}) {
    const newConfig = { ...this.config, ...config };
    if (newConfig.limitOptions) {
      newConfig.groupLimit = newConfig.limitOptions;
    }
    if (typeof newConfig.groupLimit === 'string') {
      newConfig.groupLimit = parseInt(newConfig.groupLimit);
    }
    this.config = newConfig;
  }

  get _options() {
    return getOptions(this);
  }

  get selectedOptions(): string | string[] {
    return getSelectedOptions(this).map(option => option.id);
  }

  set selectedOptions(options: string | string[]) {
    setSelectedOptions(options, this);
  }

  get disabled() {
    return this._disabled;
  }

  set disabled(value: boolean) {
    if (this._disabled !== value) {
      this._disabled = value;
      const { host, el } = this;
      const selectElement = host.querySelector('select') || <HTMLSelectElement>el as HTMLSelectElement;
      const root = (el === selectElement) ? el.parentNode : el;
      const control = root.querySelector(`.${MULTISELECT_CLASSES.CONTROL}`);
      if (control) {
        if (value) {
          control.setAttribute('aria-disabled', "true");
        } else {
          control.removeAttribute('aria-disabled');
        }
      }
    }
  }

  dispatchEvent(eventType: string, eventInit?: any): boolean {
    return this.host.dispatchEvent(createCustomEvent(eventType, eventInit));
  }

  watchAttributeChanges() {
    const { host, el } = this;
    const selectElement = host.querySelector('select') || <HTMLSelectElement>el as HTMLSelectElement;
    const attributeFilter = ['disabled', 'aria-disabled'];
    const targets = [host];
    if (selectElement && selectElement !== host) {
      targets.push(selectElement);
    }
    this._unwatchForChanges = watchForChanges(targets, { attributes: true, attributeFilter }, this.syncDisabledProperty.bind(this));
    this.syncDisabledProperty();
  }

  onStateUpdate(state: MultiSelectState) {
    this.state = state;
  }

  syncDisabledProperty() {
    const { host, el } = this;
    const selectElement = host.querySelector('select') || <HTMLSelectElement>el as HTMLSelectElement;
    const hostDisabled = host.getAttribute('aria-disabled') === 'true';
    const selectDisabled = !!selectElement && (selectElement.disabled || selectElement.getAttribute('aria-disabled') === 'true');
    this.disabled = hostDisabled || selectDisabled;
  }

  destroy() {
    while (this.onDestroy && this.onDestroy.length) {
      const fn = this.onDestroy.pop();
      fn();
    }
    if (this._unwatchForChanges) {
      this._unwatchForChanges();
    }

    if (this.el) {
      delete this.el.dataset[ENHANCED_FLAG];
    }
    instances.remove(this.host, INSTANCE_KEY);
    this.el = this.host = this._unwatchForChanges = null;
  }
}

class MultiSelect {
  _instance: _MultiSelectInstance;
  constructor(element: HTMLElement, config?: any) {
    this._instance = <_MultiSelectInstance>instances.get(element, INSTANCE_KEY) || new _MultiSelectInstance(element, config);
  }
  getOptions() {
    return this._instance._options;
  }
  getSelectedOptions() {
    return getSelectedOptions(this._instance);
  }

  get selectedOptions(): string | string[] {
    return this._instance.selectedOptions;
  }

  set selectedOptions(options: string | string[]) {
    this._instance.selectedOptions = options;
  }

  get disabled() {
    return this._instance.disabled
  }

  set disabled(value: boolean) {
    this._instance.disabled = value;
  }

  destroy() {
    const { _instance } = this;
    delete this._instance;
    return _instance.destroy();
  }
}

onDOMChanges(`.${PATTERN_SELECTOR}-dropdown`,
  function onPatternAdded(element: HTMLElement) {
    if (!element.dataset[ENHANCED_FLAG]) {
      new MultiSelect(element)
    }
  },
  function onPatternRemoved(element: HTMLElement) {
    if (element.dataset[ENHANCED_FLAG] === "true") {
      new MultiSelect(element).destroy();
    }
  }
);

// Create Multiselect
function createMultiselect(multiselect: MultiSelectComponent) {
  const { host, el, config } = multiselect;
  const selectElement = host.querySelector('select') || <HTMLSelectElement>el;
  const id = `${PATTERN_SELECTOR}-${nextId++}`;
  const label = ariaLabel((el === selectElement) ? el.parentElement : el, (selectElement.id) ? selectElement.id : id);
  const CONTROL_CLASSNAME = MULTISELECT_CLASSES.INPUT + " " + MULTISELECT_CLASSES.CONTROL;

  // Hide <select> element
  selectElement.hidden = true;

  // control markup
  const ariaDisabled = multiselect.disabled ? 'aria-disabled="true"' : '';
  const controlHtml = `
    <div class="${CONTROL_CLASSNAME}" ${label}
      role="combobox" aria-controls="${id}" aria-haspopup="grid"
      aria-autocomplete="none" aria-expanded="false" tabindex="0"></div>
  `;

  // popup markup
  const options = getOptions(multiselect);
  const POPUP_CLASSNAME = MULTISELECT_CLASSES.POPUP;
  const selectAll = config.noSelectAll ? '' : ` <div role="row" id="${id + "_0"}"  class="${MULTISELECT_CLASSES.SELECT_ALL}">
  <div role="gridcell">
    <label class="${MULTISELECT_CLASSES.CHECK}">
      <input class="${MULTISELECT_CLASSES.CHECKBOX}" type="checkbox" value="Select All" tabindex="-1" />
      <span class="${MULTISELECT_CLASSES.CHECKLABEL}">Select All</span>
    </label>
  </div>
</div>`
  const popupHtml = `
    <div id="${id}" role="grid" aria-multiselectable="true" class="${POPUP_CLASSNAME}" ${ariaDisabled}>
       ${selectAll}

        ${options.map((option: any, index: number) => {
    const value = htmlEncode(option.value);
    const text = htmlEncode(option.text);
    return `<div role="row" id="${id + "_" + (index + 1)}">
              <div role="gridcell">
                <label class="${MULTISELECT_CLASSES.CHECK}">
                  <input tabindex="-1" type="checkbox" data-index="${index}"
                  class="${MULTISELECT_CLASSES.CHECKBOX}" value="${value}" data-val="${value}" />
                  <span class="${MULTISELECT_CLASSES.CHECKLABEL}">${text}</span>
                </label>
              </div>
            </div>`;
  }).join("")}
    </div>
  `;

  var wrapper = document.createElement('div');
  wrapper.innerHTML = controlHtml + popupHtml;
  wrapper.className = 'tds-multiselect';

  // insert wrapper right after the <select> element
  if (el === selectElement) el.parentNode.insertBefore(wrapper, el.nextElementSibling);
  else el.insertBefore(wrapper, selectElement.nextElementSibling);
}

function ariaLabel(el: HTMLElement, id: string) {
  const selectElement = el.querySelector('select');
  const arialabel = selectElement.getAttribute('aria-label');
  if (!arialabel) {
    const ariaLabelledby = selectElement.getAttribute('aria-labelledby');
    if (!ariaLabelledby) {
      const label = document.querySelector(`label[for="${selectElement.id || ''}"]`) || el.querySelector(`label`);
      if (!label) {
        console.error('Label element or [aria-label] or [aria-labelledby] attribute on select element must specified');
        return "";
      }
      const labelId = label.getAttribute('id');
      if (!labelId) {
        const labelFor = label.getAttribute('for');
        if (!labelFor || labelFor === id) {
          const newLabelId = `${id + "_label"}`;
          label.setAttribute('id', `${newLabelId}`);
          return `aria-labelledby="${newLabelId}"`;
        }

        if (selectElement.previousElementSibling.getAttribute('for') !== id) {
          console.error('Correct value in [for] attribute for label');
        }
        return "";
      }
      return `aria-labelledby="${labelId}"`;
    }
    return `aria-labelledby="${ariaLabelledby}"`;
  }
  return `aria-label="${arialabel}"`;
}

export { MultiSelect }

import { EventListeners } from "../../utilities/EventListeners";
import { CSS_NS } from '../../utilities/constants';
import { normalizeKey } from "../../utilities/keyboard";
import doWhileEventing from "../../utilities/doWhileEventing";
import { TablePaginationComponent } from "./TablePaginationComponent";

const PATTERN_SELECTOR = `${CSS_NS}table-pagination`;
export const PAGINATION = {
  SIZE: `${PATTERN_SELECTOR}__size`,
  COUNT: `${PATTERN_SELECTOR}__count`,
  PAGE: `${PATTERN_SELECTOR}__page`,
  PAGE_NUMBER: `${PATTERN_SELECTOR}__page-num`,
  LAST_PAGE: `${PATTERN_SELECTOR}__page-num--last`,
  PAGE_NAV: `${PATTERN_SELECTOR}__page-nav`,
  PREVIOUS_BTN: `${PATTERN_SELECTOR}__previous-page`,
  NEXT_BTN: `${PATTERN_SELECTOR}__next-page`,
}

export function bindTablePagination(pagination: TablePaginationComponent): Function {
  const { root } = pagination;

  // Add Event Listeners
  const eventListeners = new EventListeners();

  eventListeners.addListener(root, 'click', pageNavigationHandler(pagination));
  eventListeners.addListener(root, 'keydown', keyDownHandler(pagination));
  eventListeners.addListener(root, 'change', changeHandler(pagination));

  const undoWhile = doWhileEventing(window, 'resize', () => {
    checkStacked(pagination);
  });

  const unbind = () => {
    eventListeners.removeListeners();
    undoWhile();
  }
  return unbind;
}

function keyDownHandler(pagination: TablePaginationComponent): EventListener {
  return ((event: KeyboardEvent) => {
    const previousBtn = (event.target as HTMLElement).closest(`.${PAGINATION.PREVIOUS_BTN}`);
    const nextBtn = (event.target as HTMLElement).closest(`.${PAGINATION.NEXT_BTN}`);

    if (previousBtn || nextBtn) {
      const key = normalizeKey(event);
      switch (key) {
        case 'ArrowLeft':
          event.preventDefault();
          previousButtonHandler(pagination);
          break;
        case 'ArrowRight':
          event.preventDefault();
          nextButtonHandler(pagination);
          break;
      }
    }
  })
}

const pageNavigationHandler = (pagination: TablePaginationComponent) => {
  return ((event: any) => {
    const previousBtn = (event.target as HTMLElement).closest(`.${PAGINATION.PREVIOUS_BTN}`);
    const nextBtn = (event.target as HTMLElement).closest(`.${PAGINATION.NEXT_BTN}`);
    if (previousBtn) {
      previousButtonHandler(pagination);
    } else if (nextBtn) {
      nextButtonHandler(pagination);
    }
  })
}

const previousButtonHandler = (pagination: TablePaginationComponent) => {
  const { page } = pagination; // page getter
  if (page !== 1) {
    handleSelect(pagination, page - 1);
  }
};

const nextButtonHandler = (pagination: TablePaginationComponent) => {
  const { page } = pagination;
  if (page !== getTotalPages(pagination)) {
    handleSelect(pagination, page + 1);
  }
}

const changeHandler = (pagination: TablePaginationComponent) => {
  return ((event: Event) => {
    const select: HTMLSelectElement = (event.target as HTMLElement).closest(`.${PAGINATION.SIZE} select`);
    const input: HTMLInputElement = (event.target as HTMLElement).closest(`.${PAGINATION.PAGE_NUMBER} input`);
    if (input) {
      handlePageNumberChange(pagination, input);
    }
    if (select) {
      handlePageSizeChange(pagination, select);
    }
  })
}

function handleSelect(pagination: TablePaginationComponent, page: number): number {
  const totalPages = getTotalPages(pagination);
  let newPage = Math.max(page, 1);
  if (totalPages !== -1) newPage = Math.min(newPage, totalPages);

  if (pagination.page !== newPage) {
    // const { noAutoUpdate } = pagination.config;
    // if (!noAutoUpdate) pagination.page = newPage;
    pageEvent('tdsPageChanged', pagination, { page: newPage });
  }
  return newPage;
}

// Page number value change event
function handlePageNumberChange(pagination: TablePaginationComponent, PAGE_NUMBER_INPUT: HTMLInputElement) {
  const page = parseInt(PAGE_NUMBER_INPUT.value);
  const edittedPage = handleSelect(pagination, isNaN(page) ? 1 : page).toString();
  if (PAGE_NUMBER_INPUT.value !== edittedPage) {
    PAGE_NUMBER_INPUT.value = edittedPage;
  }
}

// Page size value change event
function handlePageSizeChange(pagination: TablePaginationComponent, pageOptionSelect: HTMLSelectElement) {
  let newPageSize = parseInt(pageOptionSelect.value);

  if (pagination.pageSize !== newPageSize) {
    // const { noAutoUpdate } = pagination.config;
    // if (!noAutoUpdate) {
    //   pagination.pageSize = newPageSize;
    //   pagination.page = 1;
    // }
    pageEvent('tdsPageSizeChanged', pagination, { page: 1, pageSize: newPageSize });
  }
}

// Emits page change event
function pageEvent(eventName: string, pagination: TablePaginationComponent, detail: any) {
  const { page, pageSize } = pagination;
  pagination.onPaginationUpdate(eventName, {
    page: page,
    pageSize: pageSize,
    userAction: true,
    ...detail
  });
}

function checkStacked(pagination: TablePaginationComponent) {
  const { root } = pagination;
  if (root) {
    // filtering root.childNodes because web component IE polyfill breaks root.children
    const totalWidth = Array.from(root.childNodes)
      .filter((node: Node) => node.nodeType === Node.ELEMENT_NODE)
      .reduce((acc: number, node: HTMLElement) => {
        return acc + node.offsetWidth;
      }, 0)
    const stacked = totalWidth > root.clientWidth;
    pagination.stacked = stacked;
  }
}

// export functions
export function getTotalPages(pagination: TablePaginationComponent) {
  const { config } = pagination;
  const { totalItems, pageSize } = config;
  return totalItems > 0 ? (pageSize ? Math.ceil(totalItems / pageSize) : 1) : -1;
}

export function calculatePageInputWidth(pagination: TablePaginationComponent) {
  const { root, config } = pagination;
  const totalPages = getTotalPages(pagination);
  const PAGE_NUMBER_INPUT: HTMLInputElement = root.querySelector(`.${PAGINATION.PAGE_NUMBER} > input`);
  if (PAGE_NUMBER_INPUT) {
    // if total items are unknown, set width for 3 digits by default
    PAGE_NUMBER_INPUT.style.width = 2 + ((config.totalItems > 0 ? totalPages.toString().length : 3) * 0.7) + "rem";
  }
}
import { store, Store } from "../../utilities/store";
import { SelectChangeAction } from "./types";

export interface SelectOption {
  id: any,
  value: string,
  name: string,
  shortName?: string,
  groupName: string,
  option: any,
  other?: boolean
  otherHasBeenSelected?: boolean
}

export interface SelectState {
  showList: boolean,
  filteredOptions: SelectOption[],
  orderedOptions: SelectOption[],
  selectedOptions: SelectOption[],
  groupedOptions?: { name: any, options: SelectOption[] }[],
  activeIndex?: number,
  activeOptionId?: string,
  filterText: string,
  loading?: boolean
  optionsStatus?: string,
  selectChangeAction?: SelectChangeAction
}


export function createStore(initialState: Partial<SelectState> = {}) : Store<SelectState> {
  return store({
    showList: false,
    filteredOptions: [],
    orderedOptions: [],
    selectedOptions: [],
    selectionTick: 0,
    activeIndex: -1,
    filterText: '',
    ...initialState
    });
}

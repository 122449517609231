import { createPopover } from "../../utilities/popover";
import { EventListeners } from "../../utilities/EventListeners";
import { translations } from "../../utilities/i18n";
import { isTyping, normalizeKey } from "../../utilities/keyboard";
import bindFilter from "./optionsFilter";
import { SelectOption, SelectState } from "./SelectState";
import { getBestMatchIndex, getExactMatchIndex, indexOfOption, isIncluded, toSelectOption, toSelectOptions } from "./utils";
import { bindListbox } from "./listbox/listboxBehavior";
import { interpolate } from "../../utilities/objectHelpers";
import { SelectContext } from "./SelectContext";
import { hasFocus } from "../../utilities/helpers";
import { LoadOptions, SelectChangeActionType, SelectChangeDetail, SelectChangeValues } from "./types";

export interface SelectComponent {
  readonly host: HTMLElement,
  // properties
  readonly multiple: boolean,
  readonly optionName: string
  readonly filterDelay: number
  readonly applyFilter: boolean,
  readonly inlineComplete: boolean,
  readonly disabled: boolean,
  readonly readonly: boolean,
  readonly options: any[] | LoadOptions,
  readonly delimiter: string,
  readonly loadingText: string,
  readonly noOptionsText: string,
  readonly placeholder: string,
  readonly initialValue: string,
  readonly initialValues: string[],
  readonly initialSelectedOptions: any[],
  readonly value: string,
  readonly values: string[],
  readonly selectedOptions: any[],
  readonly tags: 'inside' | 'outside',

  // internals
  readonly selectContext: SelectContext;
  readonly combobox: HTMLElement,
  readonly inputControl: HTMLInputElement,
  readonly clearButton: HTMLElement,
  readonly popup: HTMLElement,
  readonly popoverReference: HTMLElement,
  readonly inputSpacer: HTMLElement,
  readonly filterPlacement: 'inline' | 'withlist' | undefined,
  readonly allowOtherResolved: 'true' | 'false' | 'prompt',
  focused: boolean;

  onShowPopup: () => void,
  onHidePopup: () => void,
  updateSelectedProps: (values: SelectChangeValues) => void,
  focusCombobox: () => void,
  focusDialog: () => void,
  focusLastDeselectOption: () => boolean,
  dispatchEvent: (eventType: 'tdsChange' | 'tdsBlur' | 'tdsFocus', detail?: SelectChangeDetail) => boolean,
}

export function bindSelect(select: SelectComponent) {
  const host = select.host;
  select.focused = hasFocus(host);
  const eventListeners = new EventListeners();
  const popupListeners = new EventListeners();
  eventListeners.addListener(host, 'click', ifEnabled(onClick));
  eventListeners.addListener(host, 'mousedown', ifEnabled(onMousedown));
  eventListeners.addListener(host, 'keydown', ifEnabled(onKeydown));
  eventListeners.addListener(host, 'input', ifEnabled(onInput));
  const noop = () => { };
  // these next 2 don't do anything when enabled, just prevent default when enabled 
  eventListeners.addListener(host, 'paste', ifEnabled(noop));
  eventListeners.addListener(host, 'cut', ifEnabled(noop));
  eventListeners.addListener(host, 'focusin', onFocus);
  eventListeners.addListener(host, 'focusout', onBlur);
  const onUnbind: Function[] = [];
  onUnbind.push(bindFilter(select, host, onFilterApplied));
  const translate = translations(host).t;

  const { selectContext } = select;
  let state: SelectState;
  const store = selectContext.store;
  onUnbind.push(store.subscribe(onStateUpdate));
  initializeSelectionState();

  let ignoreInlineComplete: boolean;
  let ignoreBlur = false;
  let unbindListbox: Function;

  function unbind() {
    eventListeners.removeListeners();
    popupListeners.removeListeners();
    onUnbind.forEach(fn => fn());
    if (unbindListbox) {
      unbindListbox();
    }
  }

  function onStateUpdate(newState: SelectState) {
    const oldState = state;
    state = newState;
    if (oldState) {
      if (oldState.showList !== state.showList) {
        state.showList ? onShowPopup() : onHidePopup();
      }
      if (oldState.selectChangeAction !== state.selectChangeAction) {
        onSelectedOptionsChanged(state.selectChangeAction.type);
      } else if (oldState.selectedOptions !== state.selectedOptions) {
        onSelectedOptionsChanged();
      }
      deriveStatusMessages();
    }
  }

  function ifEnabled(handler: (event: Event) => any) {
    return (event: Event) => {
      if (!select.disabled && !select.readonly) {
        handler(event);
      } else {
        const isTab = event.type === 'keydown' && normalizeKey(event as KeyboardEvent) === 'Tab';
        if (!isTab) {
          event.preventDefault();
        }
      }
    }
  }

  /////////////////////////////////////////////////
  // selection property initializers and updaters

  async function initializeSelectionState() {
    const {
      options,
      initialValue,
      initialValues,
      value,
      values,
      initialSelectedOptions = [],
      selectedOptions = [],
      multiple,
      allowOtherResolved
    } = select;
    const stateSelectedOptions =
      initialSelectedOptions.length ? initialSelectedOptions :
        selectedOptions.length ? selectedOptions : [];
    const selectedValue = initialValue ?? value;
    const selectedValues = initialValues?.length ? initialValues : values?.length ? values : [];
    if (selectedValues.length === 0 && selectedValue) {
      selectedValues.push(selectedValue);
    }

    let initialSelection: SelectOption[];
    // selectedObjects take precendence over value/values/initialValue/initialValues
    if (stateSelectedOptions.length) {
      initialSelection = toSelectOptions(stateSelectedOptions, select);
    } else if (selectedValues.length) {
      initialSelection = await valuesToSelectOptions(selectedValues);
    } else if (selectedValue === '' && typeof options !== 'function' && options.length && !select.multiple) {
      // if there is an option that is blank, select it
      const blankOption = (options as any[]).find(o => toSelectOption(o, select).value === '');
      if (blankOption) {
        initialSelection = toSelectOptions([blankOption], select);
      }
    }
    if (initialSelection && initialSelection.length) {
      store.update({ selectedOptions: initialSelection });
    } else if (selectedValue && !multiple && allowOtherResolved !== 'false') {
      updateInputValue(selectedValue);
      const changeValues = getChangeEventDetail();
      select.updateSelectedProps(changeValues);
    }
  }

  async function onSelectedPropsChanged(prop: 'value' | 'values' | 'selectedOptions') {
    let values: string[];
    let selectedOptions: SelectOption[];
    if (prop === 'value') {
      values = [select.value];
    } else if (prop === 'values') {
      values = select.values;
    } else {
      selectedOptions = toSelectOptions(select.selectedOptions, select);
    }
    if (values) {
      selectedOptions = await valuesToSelectOptions(values);
    }
    store.update({ selectedOptions });
  }

  function onSelectedOptionsChanged(action?: SelectChangeActionType) {
    const { inputControl, multiple, selectContext } = select;
    const { selectedOptions, filterText } = state;
    if (inputControl) {
      if (multiple) {
        updateInputValue('');
        if (filterText) {
          // clear filter
          selectContext.getOptions();
        }
      } else if (selectedOptions[0]) {
        updateInputValue(selectedOptions[0].name);
      } else if (action !== 'text-entry') {
        updateInputValue('');
      }
    }
    const changeValues = getChangeEventDetail();
    select.updateSelectedProps(changeValues);

    // maybe we should always throw event, with action assumed 'api' if not provided?
    if (action) {
      fireChangeEvent(changeValues);
    }
  }

  async function valuesToSelectOptions(values: string[], count = 1): Promise<SelectOption[]> {
    const { selectContext: context, multiple, allowOtherResolved } = select;
    const optionsProp = select.options;
    const noOptionsProp = !optionsProp || (typeof optionsProp !== 'function' && !optionsProp.length);
    if (noOptionsProp && count <= 5) {
      // initial selected values set before options property is set. Re-try up to 5 times
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(valuesToSelectOptions(values, count + 1));
        });
      })
    }
    const options = (context && await context.getOptions()) || [];
    return values.map(value => {
      let option = options.find(so => so.value === value);
      if (!option && multiple && allowOtherResolved !== 'false') {
        option = toSelectOption(value, select);
        option.other = true;
        option.otherHasBeenSelected = true;
      }
      return option;
    }).filter(Boolean);
  }

  /////////////////////////////////////////////////
  // event handlers

  function onClick(event: Event) {
    const target = event.target as HTMLElement;
    const clearButton = select.clearButton;
    if (clearButton && clearButton.contains(target)) {
      onClear();
      return;
    }
    const tags = target.closest('tds-selected-options, .tds-selected-options');
    if (tags) {
      if (state.showList) {
        hideOptionsPopup(false);
      }
    } else {
      toggleOptionsPopup('click');
    }
  }

  function onMousedown(event: Event) {
    const combobox = select.combobox;
    const target = event.target as HTMLElement;
    const tags = target.closest('tds-selected-options, .tds-selected-options');
    // looking for a click in the combobox container that
    if (combobox && !tags && target !== combobox && target.contains(combobox)) {
      select.focusCombobox();
      event.preventDefault();
    }
  }

  function onKeydown(event: KeyboardEvent) {
    ignoreInlineComplete = false;
    const key = normalizeKey(event);
    const target = event.target as HTMLElement;
    const combobox = select.combobox;
    const clearButton = select.clearButton;
    if (clearButton && clearButton.contains(target)) {
      if (key === 'Enter' || key === ' ') {
        return;
      }
      else if (key === 'ArrowLeft') {
        combobox && combobox.focus();
        return;
      }
    }
    if (onKeydownCommon(event)) {
      return;
    };
    let preventDefault = false;
    switch (key) {
      case 'ArrowLeft':
        preventDefault = onMoveFocus(target, 'backward');
        break;
      case 'ArrowRight':
        preventDefault = onMoveFocus(target, 'forward');
        break;
      case 'Tab':
        preventDefault = onTab(event);
        break;
      case 'Backspace':
      case 'Delete':
        preventDefault = onBackspaceDelete(target, key);
        break;
      default:
        preventDefault = isTyping(event) && onTyping(target, key);
    }

    if (preventDefault) {
      event.preventDefault();
    }
  }

  // keydown events to handle for both select and popup
  function onKeydownCommon(event: KeyboardEvent): boolean {
    const target = event.target as HTMLElement;
    const key = normalizeKey(event);
    let preventDefault = false;
    let handled = true;
    switch (key) {
      case 'ArrowUp':
      case 'ArrowDown':
        preventDefault = onKeydownTogglePopup(event.altKey);
        break;
      case 'Enter':
        if (target.closest('[role="combobox"], [role="listbox"]')) {
          preventDefault = commitEntry(key);
        }
        break;
      case 'Escape':
        preventDefault = onEscape(target);
        break;
      default:
        handled = false;
    }
    if (preventDefault) {
      event.preventDefault();
    }
    return handled;
  }

  function onKeydownTogglePopup(altKey: boolean): boolean {
    if (state.showList) {
      if (altKey) {
        hideOptionsPopup();
        return true;
      }
      const popup = select.popup;
      if (popup && popup.matches('[role="dialog"]') && !hasFocus(popup)) {
        if (state.activeIndex < 0) {
          store.update({ activeIndex: 0 });
        }
        select.focusDialog();
        return true;
      }
    } else {
      showOptionsPopup();
      return true;
    }
    return false;
  }

  function onMoveFocus(target: HTMLElement, direction: 'forward' | 'backward') {
    const combobox = select.combobox;
    if (combobox && combobox.contains(target)) {
      const input = target.matches('input') ? target as HTMLInputElement : null;
      const selectionPos = input && direction === 'forward' ? input.value.length : 0;
      if (!input || (input.selectionStart === input.selectionEnd && input.selectionEnd === selectionPos)) {
        const clearButton = select.clearButton;
        if (direction === 'forward' && clearButton) {
          clearButton.focus();
          return true;
        }
        if (direction === 'backward') {
          return select.focusLastDeselectOption();
        }
      }
    }
    return false;
  }

  function onTab(event: KeyboardEvent): boolean {
    const target = event.target as HTMLElement;
    const clearButton = select.clearButton
    if (event.shiftKey) {
      if (clearButton && clearButton.contains(target)) {
        return false;
      }
      if (onMoveFocus(target, 'backward')) {
        return true;
      }
    }
    commitEntry('Tab');
    hideOptionsPopup(false);
    return false;
  }

  function onEscape(target: HTMLElement): boolean {
    if (state.showList && !(target.matches('input[type="search"]') && (target as HTMLInputElement).value)) {
      hideOptionsPopup();
      return true;
    }
    return false;
  }

  function onBackspaceDelete(target: HTMLElement, key: string): boolean {
    const { inlineComplete } = select;
    const input = target === select.inputControl ? target as HTMLInputElement : null;
    ignoreInlineComplete = !!(inlineComplete && input && input.value);
    if (key === 'Backspace' && input && !input.value) {
      hideOptionsPopup();
      select.focusLastDeselectOption();
      return true
    }
    return false;
  }

  function onTyping(target: HTMLElement, char: string): boolean {
    let preventDefault = false;
    const input = target === select.inputControl ? target as HTMLInputElement : null;
    if (input) {
      const delimiter = select.delimiter || '';
      if (char === ' ' && !input.value) {
        preventDefault = true; // if starting the input with spaces, prevent the typing. (Messes with inline complete)
      } else if (char === delimiter[0]) {
        preventDefault = onDelimiterKeyDown(input);
      }
    } else if (select.filterPlacement === 'withlist') {
      // show the popup and handoff the character typed
      showOptionsPopup({ filterHandoff: char });
      preventDefault = true;
    } else if (!select.inputControl) {
      findByTyping(char);
      if (select.multiple) {
        showOptionsPopup({ keepActiveIndex: true });
      }
      preventDefault = true;
    }
    return preventDefault;
  }

  function onDelimiterKeyDown(input: HTMLInputElement): boolean {
    const { multiple, tags } = select;
    // all these need to be true to continue
    if (!(multiple && !tags)) {
      return false;
    }
    const selectionAtEnd = !!input && input.selectionEnd === input.value.length;
    if (input.value && selectionAtEnd) {
      if (commitEntryMultiple(input)) {
        hideOptionsPopup(false);
      }
    }
    // cancel the event regardless of outcome
    return true;
  }

  function onFocus() {
    if (!select.focused) {
      select.focused = true;
      select.dispatchEvent('tdsFocus');
    }
  }

  function onBlur() {
    if (ignoreBlur) {
      return;
    }
    // if focus does not return to control immediately, hide the popup
    setTimeout(() => {
      const { host, popup } = select;
      const hostHasFocus = hasFocus(host);
      const popupHasFocus = !!popup && hasFocus(popup);
      if (!hostHasFocus && !popupHasFocus) {
        select.focused = false;
        hideOptionsPopup(false);
        select.dispatchEvent('tdsBlur');
      }
      // reset ignoreBlur in onPopupMousedown timeout rather than here because mousedown will not always
      // result in a blur event. e.g. clicking an element already in focus
    });
  }

  function onPopupMousedown(event: Event) {
    ignoreBlur = true;
    setTimeout(() => {
      ignoreBlur = false;
      let focusOn: HTMLElement;
      const dialog = (event.target as Element).closest('[role="dialog"]');
      if (dialog) {
        if (!hasFocus(dialog)) {
          // need to keep focus on an element so blur logic will work to close the dialog
          focusOn = dialog.querySelector<HTMLElement>('[role="listbox"]');
        }
      } else {
        // managed listbox, always keep focus on the select control
        focusOn = select.combobox;
      }
      focusOn && focusOn.focus();
    });
  }

  function onInput(event: InputEvent) {
    const value = (event.target as HTMLInputElement).value;
    updateInputSpacerAndPlaceholder();
    if (!select.applyFilter) {
      onInputChanged(value);
    } // otherwise, we'll catch it on the flip side after the filter is applied
  }

  function onFilterApplied(filteredOptions: SelectOption[], filterText: string) {
    onInputChanged(filterText);
    const showList = filteredOptions.length > 0 || !!state.optionsStatus;
    if (state.showList !== showList) {
      toggleOptionsPopup('filter');
    }
  }

  function onInputChanged(inputValue: string) {
    const { multiple, inlineComplete, inputControl } = select;
    const orderedOptions = state.orderedOptions;
    const unselectedOptions = orderedOptions.filter(o => !isIncluded(o, state.selectedOptions));

    // typing is presumed to be for the purpose of adding to the selecion. So look for the best **unselected** option (multiselect only) 
    let bestMatchOption = multiple ? unselectedOptions[getBestMatchIndex(unselectedOptions, inputValue)] : null;
    if (!bestMatchOption) {
      // if combobox (multiple is false) and not inline complete, look for an exact match, otherwise look for best match
      // we don't want to unintentionally select an item when single-select
      const getIndex = multiple || (inlineComplete && !ignoreInlineComplete) ? getBestMatchIndex : getExactMatchIndex;
      bestMatchOption = orderedOptions[getIndex(orderedOptions, inputValue)];
    }
    const bestMatchIndex = bestMatchOption ? orderedOptions.indexOf(bestMatchOption) : -1;
    if (bestMatchIndex !== state.activeIndex) {
      store.update({ activeIndex: bestMatchIndex });
    }
    if (inputValue) {
      doInlineComplete(bestMatchOption);
    }
    if (!multiple && inputControl) {
      select.selectContext.selectionActions.textEntry(inputControl.value);
    }
  }

  function commitEntry(key: string) {
    const { inputControl, multiple } = select;
    const { updateOption } = selectContext.selectionActions;
    if (multiple) {
      if (inputControl && inputControl.value) {
        if (key !== 'Enter' || !state.showList || state.activeIndex < 0) {
          // if enter key and lisbox is active, let listbox interaction take precendent
          return commitEntryMultiple(inputControl);
        }
        return false;
      }

    } else if (state.showList) {
      if (key === 'Tab') {
        // single select. On Tab, select the currently selected option
        // NOTE: similar logic is implemented in selectDialogBehavior when tab trap receives focus.
        const option = state.orderedOptions[state.activeIndex];
        if (option && !state.selectedOptions.includes(option)) {
          updateOption(option);
          return true;
        }
      }
      if (state.activeIndex < 0) {
        // listbox Enter key handler would not kick, so...
        hideOptionsPopup(key === 'Enter');
      }
    }
    return false
  }

  function commitEntryMultiple(input: HTMLInputElement): boolean {
    const { updateOption } = select.selectContext.selectionActions;
    const value = input.value;
    const selectedOptions = state.selectedOptions;
    const unselectedOptions = state.orderedOptions.filter(o => !o.other && !isIncluded(o, selectedOptions));
    const exactMatchOption = unselectedOptions[getExactMatchIndex(unselectedOptions, value)];
    updateInputValue('');
    if (exactMatchOption) {
      updateOption(exactMatchOption);
      return true;
    } else if (select.allowOtherResolved === 'true') {
      const alreadySelected = selectedOptions[getExactMatchIndex(selectedOptions, value)];
      if (!alreadySelected) {
        const newOption = toSelectOption(value, select);
        newOption.other = true;
        updateOption(newOption);
        return true;
      }
    }
    return false;
  }

  function onClear() {
    // For single-select, combo box, when the input contains of value not in the options list
    // selectionActions.clear() does not register a change. SelectContext and selectionActions
    // should be aware of that. For now, replicate it here;
    const { multiple, inputControl } = select;
    if (state.selectedOptions.length === 0 && !multiple && inputControl && inputControl.value) {
      store.update({selectChangeAction: {
        type: 'clear',
        affectedOptions: []
      }})
    } else {
      select.selectContext.selectionActions.clear();
    }
    select.focusCombobox();
    hideOptionsPopup();

  }

  function doInlineComplete(option: SelectOption) {
    const { inlineComplete, inputControl } = select;
    if (inlineComplete && inputControl && option && !ignoreInlineComplete) {
      // do not do inline complete if option is already selected
      if (select.multiple && isIncluded(option, state.selectedOptions)) return;
      const fullValue = option.name;
      const selectionStart = inputControl.selectionStart;
      updateInputValue(fullValue);
      inputControl.setSelectionRange(selectionStart, fullValue.length);
    }
  }

  function fireChangeEvent(changeValues: SelectChangeValues) {
    const detail = { ...changeValues, selectChangeAction: state.selectChangeAction };
    select.dispatchEvent('tdsChange', detail);
  }

  function getChangeEventDetail(): SelectChangeValues {
    const { inputControl, multiple } = select;
    const selectedOptions = state.selectedOptions;
    const values = selectedOptions.map(o => o.value);
    const inputValue = inputControl?.value;
    // if combobox with input value and no matching selection, use input as value; otherwise use 1st selected option
    const value = selectedOptions[0] ? selectedOptions[0].value : multiple ? '' : inputValue || '';
    const selected = selectedOptions.map(o => o.option);
    const otherSelectedOptions = selectedOptions.filter(o => o.other).map(o => o.option);
    return {
      value,
      values,
      selectedOptions: selected,
      otherSelectedOptions
    }
  }

  function updateInputValue(value: string) {
    const inputControl = select.inputControl
    // call this function whenever updating the input value because of user action so we can fire the change event
    if (value !== inputControl.value) {
      // why this nonsense? React overrides input value setter, which, in the end, prevents the synthetic onChange event
      // from firing. Going to the native
      const valueDescriptor = typeof HTMLInputElement !== 'undefined' && Object.getOwnPropertyDescriptor(HTMLInputElement.prototype, "value");
      const valueSetter = valueDescriptor && valueDescriptor.set;
      if (valueSetter) {
        valueSetter.call(inputControl, value);
      } else {
        /* istanbul ignore next */
        inputControl.value = value
      }
      updateInputSpacerAndPlaceholder();
    }
  }

  function updateInputSpacerAndPlaceholder() {
    const { inputSpacer, inputControl, placeholder } = select;
    if (inputSpacer && inputControl) {
      const inputValue = inputControl.value;
      const values = select.values || [];
      const noPlaceholder = !placeholder || inputValue || values.length > 0;
      inputSpacer.textContent = noPlaceholder ? inputValue : placeholder;
      inputControl.placeholder = noPlaceholder ? '' : placeholder;
    }
  }

  //////////////////////////////////////////////////////////////////
  // manage options popup

  function toggleOptionsPopup(action: 'filter' | 'click') {
    if (state.showList) {
      hideOptionsPopup();
    } else {
      // don't refresh list if invoked by filter. The handoff will apply filter later
      // not a big deal with in memory options but prevents an unnecessary call for asynchronous loading of options
      showOptionsPopup({ refreshList: action !== 'filter' });
    }
  }

  async function showOptionsPopup(params: { refreshList?: boolean, keepActiveIndex?: boolean, filterHandoff?: string } = {}) {
    const { refreshList = true, keepActiveIndex, filterHandoff = '' } = params;
    const { showList } = state;
    if (!showList) {
      if (refreshList) {
        // For asynchronous loads that take > 300ms, a loading status message may need to display in the popup
        // before this call returns. To ensure its seen, deriveStatusMessages() also sets showList:true 
        // when there is a loading message to display.
        await select.selectContext.getOptions(filterHandoff);
      }
      const activeIndex = keepActiveIndex ? state.activeIndex : getStartingActiveIndex(filterHandoff);
      store.update({ showList: true, activeIndex });
    }
  }

  function hideOptionsPopup(focus = true) {
    selectContext.hideOptionsPopup(focus);
  }

  function getStartingActiveIndex(filterHandoff?: string) {
    // For single-select, only set active index to an exact match, otherwise leaving focus may cause an unintended selection.
    // For single-select combo that is not filterable, use best match since list is opening to make a selection 
    // For multi-select, it is OK to set active index to a best match, or 0 since selection must be explicit
    const { inputControl, multiple, applyFilter } = select;
    const getIndex = multiple || (!!inputControl && !applyFilter) ? getBestMatchIndex : getExactMatchIndex;
    let startingIndex = multiple ? 0 : -1;
    if (inputControl) {
      const value = inputControl.value;
      startingIndex = value ? getIndex(state.orderedOptions, value) : -1;
    } else if (filterHandoff) {
      startingIndex = getIndex(state.orderedOptions, filterHandoff);
    } else if (!multiple) { //standard single select
      const selectedOption = state.selectedOptions[0];
      startingIndex = selectedOption ? indexOfOption(selectedOption, state.orderedOptions) : -1;
    }
    return multiple ? Math.max(startingIndex, 0) : startingIndex;
  }

  let hidePopover: Function;
  let resizeObserver: ResizeObserver;
  let garage: HTMLElement | undefined;
  function onShowPopup() {
    const { host, multiple, popup } = select;
    if (!select.focused) {
      // do not show popup. This may occur on an asynchronous load that returns after the component has lost focus
      store.update({ showList: false });
      return;
    }
    // using getBoundingClientRect().width rather than offsetWidth because the
    // latter rounds causing slight jiggling on retina displays
    if (typeof ResizeObserver !== 'undefined') { // in case not defined in unit test
      resizeObserver = new ResizeObserver(() => popup.style.width = `${host.getBoundingClientRect().width}px`);
      resizeObserver.observe(host);
    }
    garage = popup.parentElement;
    if (garage) {
      garage.style.minWidth = `${host.getBoundingClientRect().width}px`;
    }
    hidePopover = createPopover(popup, select.popoverReference, { placement: 'bottom-start' });
    select.onShowPopup();
    const listbox = popup.matches('[role="listbox"]') ? popup : popup.querySelector<HTMLElement>('[role="listbox"]');
    unbindListbox = bindListbox({
      listbox,
      multiple,
      selectContext: select.selectContext,
      controller: listbox === popup ? host : listbox
    });
    popupListeners.addListener(popup, 'mousedown', onPopupMousedown);
    popupListeners.addListener(popup, 'focusout', onBlur);
    popupListeners.addListener(popup, 'keydown', (e: KeyboardEvent) => onKeydownCommon(e));
  }

  function onHidePopup() {
    const { multiple, popup, inputControl } = select;
    if (multiple && inputControl) {
      updateInputValue('');
    }
    if (popup && hidePopover) {
      if (resizeObserver) {
        resizeObserver.disconnect();
        resizeObserver = undefined;
      }
      hidePopover();
      hidePopover = undefined;
    }
    popupListeners.removeListeners();
    if (unbindListbox) {
      unbindListbox();
      unbindListbox = undefined;
    }
    select.onHidePopup();
    if (popup) {
      popup.style.removeProperty('width');
    }
    if (garage) {
      garage.style.removeProperty('min-width');
      garage = undefined;
    }
  }

  function deriveStatusMessages() {
    const { orderedOptions, loading, filterText } = state;
    const { loadingText, noOptionsText } = select;
    const optionCount = (orderedOptions && orderedOptions.length) || 0;

    let optionsStatus = '';
    let showList = state.showList;
    if (loading && loadingText !== 'false') {
      optionsStatus = loadingText || translate('optionsLoadingMessage');
      showList = true;
    } else if (filterText && optionCount === 0 && noOptionsText !== 'false') {
      if (select.multiple || select.allowOtherResolved === 'false' || noOptionsText === 'true') {
        const optionsStatusTpl = (noOptionsText !== "true" && noOptionsText) || translate('noOptionsMessage');
        optionsStatus = interpolate({ value: filterText }, optionsStatusTpl);
      }
    }
    if (optionsStatus !== state.optionsStatus) {
      store.update({ optionsStatus, showList });
    }
  }

  ///////////////////////////////////////////
  // non-editable typeahead
  let startsWith = ''
  let startsWithTimeout: any;

  function findByTyping(char: string) {
    startsWith += char.toLowerCase();
    startsWithTimeout && clearTimeout(startsWithTimeout);
    startsWithTimeout = setTimeout(resetStartsWith, 400);
    const { multiple } = select;
    const { orderedOptions, activeIndex, showList } = state;
    let matchedOption: any;
    let matchedIndex: number;

    function findOption(s: string) {
      // Only start at the next index when starting to type; otherwise start from current index
      const start = (multiple && !showList) ? 0 : // if multiple and list is closed, always start from 0
        s.length === 1 ? activeIndex + 1 : // if it's the 1st char typed, start from the next option (or 0 if active index is -1) so that hitting key repeatedly cycles through items starting with char
          Math.max(activeIndex, 0); // if multiple chars typed, start from where we are (or 0 if active index is -1) in case active option still matches text string.
      const length = orderedOptions.length;
      const end = start + length;
      for (let i = start; i < end; i++) { // will wrap around to the start. Modulus will resolve index
        const index = i % length;
        const option = orderedOptions[index];
        const optionText = (option.name || '').toLowerCase();
        if (optionText.startsWith(s)) {
          matchedOption = option;
          matchedIndex = index;
          break;
        }
      }
    }

    findOption(startsWith);
    if (!matchedOption && Array.from(startsWith).every(c => c === startsWith[0])) {
      // user typed same letter 2x. Unless looking for aardvark, assume repeating the same letter to quickly
      // scroll through items starting with that letter
      findOption(startsWith[0]);
    }
    if (matchedOption) {
      const context = select.selectContext;
      context.store.update({ activeIndex: matchedIndex })
      if (!multiple) {
        context.selectionActions.updateOption(matchedOption);
      }
    }
  }

  function resetStartsWith() {
    startsWith = '';
    startsWithTimeout = undefined;
  }

  //////////////////////////////////////////////////////

  return { unbind, onSelectedPropsChanged, updateInputSpacerAndPlaceholder };
}


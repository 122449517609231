import { Store } from '../../utilities/store';
import { FileUploadComponent } from './FileUploadComponent';
import { statusCodes, validateFile } from './FileUtility';
import { FileUploadState } from './FileUploadState';
import { populateErrorAlerts, messageFormatter } from './FileAlerts';
import { TdsFile, TdsFileInstance } from './TdsFile';

// Getters and Setters
function getFiles(fileUpload: FileUploadComponent, filterDeleteted = false) {
  const { state } = fileUpload;
  return filterDeleteted ? state._tdsFileList.filter(file => !file.deleteDisposition) :  [...state._tdsFileList]
}

function setFiles(fileList: TdsFile[], store: Store<FileUploadState>) {
  store.update((s) => {
    return { ...s, _tdsFileList: fileList }
  })
}

function setErrorStatus(errorMsg: string, errorList: any[], store: Store<FileUploadState>) {
  store.update((s) => {
    return { ...s, _errorMsg: errorMsg, _errorList: errorList }
  })
}

/**
 * checks if there is maximum allowed or exceed error.
 * @param fileUpload this file upload component
 * @param fileListCount this is the list of the files currently selected by user
 * @param tdsFileListCount this is the list of the files already selected by user
 */

function checkIfMaxErrorCode(maxCount: number, fileListCount: number, tdsFileListCount: number): [string, boolean] {
  // Single Fileupload
  if(maxCount === 1) {
    if(fileListCount > 1) return ["maxExceededError", true];
  }

  // Multiple Fileupload
  else {
    const totalFilesCount = tdsFileListCount + fileListCount;
    // 'maxAllowedError' is only valid for Multiple file upload, 
    // Single file upload replaces the exisiting file
    if(tdsFileListCount === maxCount) return ["maxAllowedError", true];
    // tdsFileListCount is the list of files already on the selected list
    // fileListCount is the file list currently selected by user from file dialog
    // check if files selected are above maximum allowed
    else if (totalFilesCount > maxCount) return ["maxExceededError", true];
  } 

  return ["", false];
}

/**
 * adds a single file to this instance's file list. Default behavior is to append at the end of list, but an index can be passed in to replace an element in the list.
 * @param fileUpload this file upload component
 * @param fileList this is the list of the selected files
 */
export function addFilesToList(fileUpload: FileUploadComponent, fileList: FileList) {
  const { maxCount } = fileUpload.config;
  const { store } = fileUpload;

  // reset error list on state
  setErrorStatus('', [], store);

  var tdsFileList = getFiles(fileUpload);
  const fileListCount = fileList.length, tdsFileListCount = tdsFileList.length;
  const [maxErrorCode, hasMaxErrorCode] = checkIfMaxErrorCode(maxCount, fileListCount, tdsFileListCount);

  var errorList;
  if(hasMaxErrorCode) {
    errorList = populateErrorAlerts(fileUpload, null, maxErrorCode, fileListCount, tdsFileListCount);
  }
  else {
    const { maxSize, acceptType, rejectType } = fileUpload.config;
    const { sizeError, typeError, uploadError } = statusCodes;
    // Each selected file from the currently selected list
    // validate, set error status and populate error list for alert
    for (let i = 0; i < fileList.length; i++) {
      const newFile = new TdsFileInstance(fileList[i], fileUpload);
      newFile.updateStatus(validateFile(newFile, maxSize, acceptType ?? rejectType, acceptType ? false : !!rejectType));
      const code = newFile.statusCode;

      // reset errorCode
      let errorCode = ""; 
      if(code == sizeError || code == typeError || code == uploadError) {
        errorCode = code;
      }
      
      // check for duplicates
      if (tdsFileListCount) {
        for (let j = 0; j < tdsFileListCount; j++) {
          const { name, size } = tdsFileList[j].file;
          if (name == newFile.file.name && size == newFile.file.size) {
            errorCode = "duplicateError";
            break;
          }
        }
      }

      // If errorCode set add to the errorList array
      if(errorCode !== "") {
        errorList = populateErrorAlerts(fileUpload, newFile, errorCode, fileListCount, tdsFileListCount);
      } else {
        if (maxCount == 1) addFile(fileUpload, newFile, 0); // single file upload
        else addFile(fileUpload, newFile); // multiple file upload
      }
    }
  }
  
  // update errorMsg and errorList
  if(errorList && errorList.length > 0) {
    // if the errors are maximum allowed or exceeded error, use selected files number to update the error title
    const hasMultipleFiles = (hasMaxErrorCode ? fileList : errorList).length > 1 ? true : false;
    setErrorStatus(`${messageFormatter(fileUpload, 'fileTitleErrorAlert', hasMultipleFiles)}`, errorList, store);
  }
}

/**
 * adds a single file to this instance's file list. Default behavior is to append at the end of list, but an index can be passed in to replace an element in the list.
 * @param fileUpload this file upload component
 * @param file the file that will be added to the end of the file list
 * @param replaceIndex optional argument that, if passed, will replace this index with the new file, instead of appending to the end. Used for replacing the first element when maxCount is 1; 
 */
export function addFile(fileUpload: FileUploadComponent, newFile: TdsFileInstance, replaceIndex?: number) {
  const tdsFileList = getFiles(fileUpload);

  if (!!replaceIndex || replaceIndex >= 0) {
    tdsFileList[replaceIndex] = newFile;
  }
  else {
    tdsFileList.push(newFile);
  }
  setFiles(tdsFileList, fileUpload.store);
  
  //dispatch file added event
  fileUpload.dispatchEvent('tdsFileAdded', {
    detail: {
      fileAdded: newFile,
      newFileList: getFiles(fileUpload, true)
    }
  });
}

/**
 * Remove a single file to this instance's file list. An index can be passed in to remove an element in the list.
 * File starts with deleteDispoistion = 'deleted', After some seconds, the disposition changes to 'remove'. Then after
 * 1 second (giving the DOM element time to collapse), the file is removed. These transitions occur provided the user does not undo the delete
 * @param fileUpload this file upload component
 * @param file the selected file for removal
 * @param index optional argument that, if passed, will remove file with this index position. Used for removing the first element when maxCount is 1; 
 */
export async function doFileRemoval(
  fileUpload: FileUploadComponent,
  file?: TdsFile,
  index: number = -1
) {
  const removeHandlerSucceeded = await fileUpload.config.removeFileHandler?.(file);
  if (removeHandlerSucceeded !== false) {
    const tdsFileList = getFiles(fileUpload);
    if (file) {
      index = tdsFileList.indexOf(file);
    } else {
      file = tdsFileList[index];
    }

    if (file && index > -1) {
      // prepare for delete file
      file.deleteDisposition = 'remove';
      setFiles(tdsFileList, fileUpload.store);
      // allow for animation before removing
      setTimeout(() => {
        const tdsFileList2 = getFiles(fileUpload);
        const index2 = tdsFileList2.indexOf(file);
        if (index2 > -1 && file.deleteDisposition) {
          tdsFileList2.splice(index2, 1);
          setFiles(tdsFileList2, fileUpload.store);
        }
      }, 1000);

      fileUpload.dispatchEvent('tdsFileRemoved', {
        detail: {
          fileRemoved: file,
          newFileList: getFiles(fileUpload, true),
        },
      });
    }

    // clear max file allowed and exceeded error message only
    const { state, store } = fileUpload;
    const errorList = state._errorList;
    if(errorList && errorList.length > 0) {
      errorList.forEach((error, index) => {
        if(error.type === 'maxAllowedError' || error.type === 'maxExceededError') {
          errorList.splice(index, 1);
          store.update((s) => {
            return { ...s, _errorList: errorList }
          })
        }
      });
    }

    return true;
  }
  return false;
}

import { FileUploadComponent } from './FileUploadComponent';
import { statusCodes } from './FileUtility';

type deleteDisposition = '' | 'remove';

let nextId = 1;

//holds a file, and information about the error and upload status of the file
export interface TdsFile {
  id: number;
  file: File;
  readonly statusMessage: string;
  readonly statusCode: string;
  readonly isInError: boolean;
  deleteDisposition: deleteDisposition;
  updateStatus(code: string, statusMessage: string): void;
  allowRemoval: boolean;
}

export class TdsFileInstance implements TdsFile {
  id: number = nextId++;
  fileUpload: FileUploadComponent;
  file: File;
  _statusMessage: string = '';
  _statusCode: string;
  _deleteDisposition: deleteDisposition = '';
  _allowRemoval: boolean;

  constructor(file: File, fileUpload?: FileUploadComponent) {
    this.fileUpload = fileUpload;
    this.file = file;
    this._allowRemoval = fileUpload?.config?.removeFile ?? true;
  }

  //holds ref to component
  //status setter that triggers state update/ re-rendering
  get statusCode(): string {
    return this._statusCode;
  }

  get statusMessage(): string {
    return this._statusMessage;
  }

  get isInError(): boolean {
    if (this._statusCode === statusCodes.typeError
      || this._statusCode === statusCodes.sizeError
      || this._statusCode === statusCodes.uploadError 
      || this._statusCode === statusCodes.maxExceededError
      || this._statusCode === statusCodes.maxAllowedError
      || this._statusCode === statusCodes.duplicateError) {
      return true;
    } 
    else {
      return false;
    }
  }

  get deleteDisposition(): deleteDisposition { 
    return this._deleteDisposition;
  }

  set deleteDisposition(value: deleteDisposition ) { 
    this._deleteDisposition = value;
    this.refreshState()
  }

  get allowRemoval(): boolean {
    return this._allowRemoval;
  }

  set allowRemoval(value: boolean) {
    this._allowRemoval = value;
    this.refreshState()
  }

  updateStatus(code: string, statusMessage: string = '') {
    this._statusCode = code;
    this._statusMessage = statusMessage;
    this.refreshState()
  }

  refreshState() {
    if (!!this.fileUpload) {
      const { store } = this.fileUpload;
      store.update((s) => {
        return { ...s }
      })
    }
  }
}





import { store, Store } from '../../utilities/store';
import { TdsFile } from './TdsFile';

export interface fileconstraints {
  /** 
   * {filetype}: The extension of the file, eg., .docx, .pdf, .jpg etc
  */
  filetype: string,
  /** 
   * {filecount}: The number of files the user is attempting to add to the list
  */
  filecount: number,
  /** 
   * {listcount}: The number of files already added to the list
  */
  listcount: number,
  /** 
   * {available}: The number of files that can be added without exceeding the maximum allowed
  */
  available: number,
  /** 
   * {maxcount}: The maximum number of of files allowed, if configured
  */
  maxcount: string,
  /** 
   * {maxsize}: The maximum file size allowed, if configured
  */
  maxsize: string,
  /** 
   * {filesize}: The size of the file
  */
  filesize: string,
}
export interface errorInfo {
  /** 
   * type of errors like uploadError, duplicateError, sizeError, maxAllowed or maxExceeded Error
  */
  type: string;
  /** 
   * name of the file which caused the error
  */
  filename?: string;
  /** 
   * error message
  */
  message: string;
  /** 
   * file constraints like size, counts and type of the file
  */
  fileconstraints?: fileconstraints;
}

export interface FileUploadState {
  /** 
   * List of the files added to the list
  */
  _tdsFileList: TdsFile[];
  /** 
   * error message if error occured
  */
  _errorMsg?: string;
  /** 
   * if there are one or more errors lists on this array with related information
  */
  _errorList?: errorInfo[];
}

export function createStore(files: TdsFile[] = []) : Store<FileUploadState> {  
  return store({
    _tdsFileList: files
  });
}


import { ToggleIconInstance, INSTANCE_KEY } from './ToggleIconInstance';
import { instances } from '../../utilities/instances';
import onDOMChanges from '../../utilities/onDOMChanges';

class ToggleIcon {
  _instance: ToggleIconInstance;
  constructor(icon: HTMLElement) {
    this._instance = <ToggleIconInstance>instances.get(icon, INSTANCE_KEY) || new ToggleIconInstance(icon);
  }

  get icon() {
    return this._instance.icon;
  }

  destroy() {
    return this._instance.destroy();
  }

  /**
   * Automatically enhances and destroys elements that match the selector
   * @param selector The selector for the elements to watch for
   */
  static autoEnhance(selector: string) {
    if (typeof document !== 'undefined') {
      onDOMChanges(selector,
        function onToggleAdded(element: HTMLElement) {
          if (!element.closest('label')) {
            new ToggleIcon(element);
          }
        },
        function onToggleRemoved(element: HTMLElement) {
          if (element.dataset.toggleHasSvg) {
            new ToggleIcon(element).destroy();
          }
        }
      );
    }
  }
}

ToggleIcon.autoEnhance(`.tds-accordion__toggle-icon`);

export {
  ToggleIcon
}
import checkCircle1_18 from '@trv-ebus/tds-icons/icons/check-circle-1-18';
import ban1_18 from '@trv-ebus/tds-icons/icons/ban-1-18';
import trashBin1_18 from '@trv-ebus/tds-icons/icons/trash-bin-1-18';
import { hasShadowDom, htmlEncode } from '../../utilities/helpers';
import { FILE_UPLOAD_CLASSES } from '../../utilities/constants';
import { Collapsible } from '../../utilities/collapsible';
import { getLang, translations } from '../../utilities/i18n';
import { getClassNameByStatusCode, messageFormatter } from './FileAlerts';
import { doFileRemoval } from './FileListBehavior';
import { statusCodes, convertFromBytes, setAriaLive } from './FileUtility';
import { FileUploadComponent } from './FileUploadComponent';
import { TdsFile } from './TdsFile';

export function setupFileList(fileUpload: FileUploadComponent) {
  const { el, config } = fileUpload;

  const listOutputEl = el.querySelector(`.${FILE_UPLOAD_CLASSES.LIST}`);
  if (!listOutputEl) {
    if (config.showFilelist) {
      const div = document.createElement('div');
      div.classList.add(FILE_UPLOAD_CLASSES.LIST);
      div.innerHTML = `<div class="${FILE_UPLOAD_CLASSES.LIST_TITLE} tds-h5"></div><ol></ol>`;
      el.appendChild(div);
    }
  } else {
    const title: HTMLDivElement = listOutputEl.querySelector(`.${FILE_UPLOAD_CLASSES.LIST_TITLE}`)
    if (title) title.dataset.custom = 'true'
    if (!listOutputEl.querySelector('ol')) {
      listOutputEl.appendChild(document.createElement('ol'))
    }
    config.showFilelist = true;
  }
}

export function buildFileList(fileUpload: FileUploadComponent) {
  const { el, host, config } = fileUpload;
  const root = hasShadowDom(host) ? host.shadowRoot : el;
  const previewContainer: HTMLElement = root.querySelector(`.${FILE_UPLOAD_CLASSES.LIST}`);
  const fileList: TdsFile[] = !!fileUpload.state ? fileUpload.state._tdsFileList : [];
  const itemsToCollapse: HTMLElement[] = [];
  if (!!previewContainer) {
    previewContainer.hidden = fileList.length === 0;
    previewContainer.setAttribute('aria-hidden', `${fileList.length === 0}`);
    
    // aria-live = assertive | polite | off, default is polite
    setAriaLive(previewContainer, config.filelistArialive);

    const title: HTMLDivElement = previewContainer.querySelector(`.${FILE_UPLOAD_CLASSES.LIST_TITLE}`)
    const listEl = previewContainer.querySelector('ol');

    if (!title.dataset.custom) {
      title.innerText = messageFormatter(fileUpload, config.asyncUpload ? 'fileAsycUploadTitle' : 'fileSyncUploadTitle', fileList.length > 1)
    }

    // build the file list. 
    // Rather than rewrite the list with each state update, we instead run this logic to ensure existing elements remain in place
    // This is so deleted items that are transitioning out are allowed to completion

    // 1: delete any items no longer in file list
    let listItems = Array.from(listEl.querySelectorAll('li'));
    listItems.forEach(item => {
      const file = fileList.find(f => `${f.id}` === item.dataset.fileId);
      if (!file) {
        listEl.removeChild(item);
      }
    });

    //2: insert files not in list. (NOTE: this logic assumes items are added to the end and order never changes)
    let insertHTML = '';
    listItems = Array.from(listEl.querySelectorAll('li'));
    fileList.forEach(file => {
      const item = listItems.find(item => item.dataset.fileId === `${file.id}`);
      
      if (!item) {
        insertHTML +=
        `<li class="tds-fileupload__list-element" data-file-id="${file.id}">
          <div class="tds-file">
            <div class="tds-file__detail">
              <span class="${FILE_UPLOAD_CLASSES.FILE_NAME}">${htmlEncode(file.file.name)}</span>
            </div>
          </div>
        </li>`;
      }
    });
    if (insertHTML) {
      listEl.insertAdjacentHTML('beforeend', insertHTML);
    }

    //3: Update elements with current state
    listItems = Array.from(listEl.querySelectorAll('li'));
    listItems.forEach((item, index) => {
      const file = fileList[index];
      updateFileListItem(item, file, fileUpload);
      if (file.deleteDisposition === 'remove') {
        itemsToCollapse.push(item);
      }
    });

    // depends of confirmRemoveFile setup it binds delete event to 
    // confirm dialog delete button or trash icon itself
    if (config.removeFile) bindFileRemove(listEl, fileUpload);

    if (itemsToCollapse.length) {
      requestAnimationFrame(() => {
        itemsToCollapse.forEach(item => {
          const collapsible = new Collapsible(item, {collapsibleDuration: 'medium-slow'});
          if (!collapsible.transitioning) {
            collapsible.hide();
          }
        })
      })
    }
  }
}

// returns svg icons for error or success statusCode
function statusIconByStatusCode(statusCode: string) {
  let svgIcon;
  if(statusCode === statusCodes.uploadError) svgIcon = ban1_18.svg({focusable: false});
  if(statusCode === statusCodes.success) svgIcon = checkCircle1_18.svg({focusable: false});

  return (svgIcon) ? `${svgIcon}` : '';
}

function updateFileListItem(item: HTMLElement, tdsFile: TdsFile, fileUpload: FileUploadComponent) {
  const { el } = fileUpload;
  const { file: { name, size }, allowRemoval } = tdsFile;
  const {t: translate, html: translateHTML} = translations(getLang(item));
  const fileListEl = item.querySelector('.tds-file');  
  fileListEl.className = `tds-file ${getClassNameByStatusCode(tdsFile.statusCode)}`
 
  // File Detail:  Size and Error message
  const fileDetail = fileListEl.querySelector(`.${FILE_UPLOAD_CLASSES.FILE_DETAIL}`);
  const statusMessage = (tdsFile.isInError ? tdsFile.statusMessage : '').trim() || `${convertFromBytes(size)}`;
  var fileDetailInfoEl = fileListEl.querySelector(`.${FILE_UPLOAD_CLASSES.FILE_SIZE}`) || fileListEl.querySelector(`.${FILE_UPLOAD_CLASSES.FILE_ERROR_MESSAGE}`);
  if(!fileDetailInfoEl) {
    fileDetailInfoEl = document.createElement('span');
    fileDetail.appendChild(fileDetailInfoEl);
  }
  if(tdsFile.statusCode == statusCodes.uploadError) {
    fileDetailInfoEl.textContent = tdsFile.statusMessage ? tdsFile.statusMessage : translate('fileUploadErrorAlert');
    fileDetailInfoEl.classList.add(FILE_UPLOAD_CLASSES.FILE_ERROR_MESSAGE);
    fileDetailInfoEl.classList.remove(FILE_UPLOAD_CLASSES.FILE_SIZE);
  } else {
    fileDetailInfoEl.textContent = statusMessage;
    fileDetailInfoEl.classList.add(FILE_UPLOAD_CLASSES.FILE_SIZE);
    fileDetailInfoEl.classList.remove(FILE_UPLOAD_CLASSES.FILE_ERROR_MESSAGE);
  }

  // File Status: Success, Error and Loading
  if(tdsFile.statusCode === statusCodes.uploadError || tdsFile.statusCode === statusCodes.success || tdsFile.statusCode === statusCodes.uploading) {
    var fileStatusEl = fileListEl.querySelector('.tds-file--status');
    if(!fileStatusEl) {
      fileStatusEl = document.createElement('div');
      fileStatusEl.className = 'tds-file--status'
    }
    if(tdsFile.statusCode === statusCodes.uploading) {
      const loadingIcon = fileStatusEl.querySelector('.tds-loading-spinner');
      if(!loadingIcon) {
        const loadingTemplate = `
          <div class="tds-loading-spinner tds-loading-spinner--x-small" aria-live="polite" aria-busy="true" role="status">
            <span class="tds-sr-only">${translateHTML('uploadingStarted', tdsFile.file.name)}</span>
          </div>`;
        fileStatusEl.innerHTML = loadingTemplate;
        fileListEl.insertBefore(fileStatusEl, fileDetail);
      }
    } else {
      fileStatusEl.innerHTML = statusIconByStatusCode(tdsFile.statusCode);
      fileListEl.insertBefore(fileStatusEl, fileDetail);
    }
  }
  // File Actions: remove
  if(allowRemoval) {
    const removeFileTemplate = `
    <button class="tds-iconbutton tds-file--delete" data-trigger-modal="#${el.id}--confirm-dialog" aria-label="${translateHTML('remove', name)}">
      ${trashBin1_18.svg({ focusable: false })}
    </button>`;
    let fileActions = fileListEl.querySelector(`.${FILE_UPLOAD_CLASSES.FILE_ACTIONS}`);
    if(!fileActions) {
      fileActions = document.createElement('div');
      fileActions.className = FILE_UPLOAD_CLASSES.FILE_ACTIONS;
    }
    fileActions.innerHTML = removeFileTemplate;
    fileListEl.appendChild(fileActions);
  } else {
    fileListEl.querySelector(`.${FILE_UPLOAD_CLASSES.FILE_ACTIONS}`)?.remove()
  }
}

/**
 * binds the delete event to each file from the file list.
 * @param fileUpload this file upload component
 * @param listEl the list of file list
 * @param index optional argument that, if passed, will remove file with this index position. Used for removing the first element when maxCount is 1; 
 */
function bindFileRemove(listEl: HTMLElement, fileUpload: FileUploadComponent) {
  const { confirmRemoveFile } = fileUpload.config;
  const { _tdsFileList } = fileUpload.state;
  const children = listEl.children;
  for (let i = 0; i < children.length; i++) {
    const btnDelete = children[i].querySelector(`.${FILE_UPLOAD_CLASSES.DELETE_BUTTON}`);
    if (btnDelete) {
      btnDelete.addEventListener('click', () => {
        const tdsFile = _tdsFileList[i];
        if (confirmRemoveFile) {
          confirmModalDialog(fileUpload, tdsFile)
        } else {
          doFileRemoval(fileUpload, tdsFile);
        }
      });
    }
  }
}

/**
 * Delete confirmation dialog
 * @param fileUpload this file upload component
 * @param file the selected file for removal
 */
function confirmModalDialog(fileUpload: FileUploadComponent, file?: TdsFile,) {
  const { el } = fileUpload;
  const translateHTML = translations(getLang(el)).html;
  const dialog = el.querySelector(`#${el.id}--confirm-dialog`);
  const deleteModalDialog = `
  <div class="tds-modal__dialog-container" aria-describedby="${el.id}--confirm-dialog-description" role="document">
    <div class="tds-modal__dialog-body-content" id="${el.id}--confirm-dialog-description">
      <p>${translateHTML('confirmRemovalOf')} <strong>${htmlEncode(file.file.name)}</strong>?</p>
    </div>
    <div class="tds-modal__dialog-footer">
      <div class="tds-modal__dialog-button-container">
        <div class="tds-modal__dialog-button-layout">
          <button class="tds-button--primary tds-file--delete-confirm" data-dismiss="modal">${translateHTML('remove')}</button>
          <button class="tds-button--secondary" data-dismiss="modal">${translateHTML('cancel')}</button>
        </div>
      </div>
    </div>
  </div>`;
  const confirmDialog = document.createElement('div');
  confirmDialog.setAttribute('id', `${el.id}--confirm-dialog`);
  confirmDialog.setAttribute('class', 'tds-modal tds-modal__dialog--small');
  confirmDialog.setAttribute('hidden', 'true');
  confirmDialog.setAttribute('role', 'dialog');
  confirmDialog.innerHTML = deleteModalDialog;
  const confirmBtn = confirmDialog.querySelector('.tds-file--delete-confirm');
  confirmBtn.addEventListener('click', () => {
    doFileRemoval(fileUpload, file);
  });

  if (!!dialog) el.replaceChild(confirmDialog, dialog);
  else el.appendChild(confirmDialog);
}
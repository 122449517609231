import { CSS_NS, NAMESPACE } from '../../utilities/constants'
import onDOMChanges from '../../utilities/onDOMChanges'
import { instances } from '../../utilities/instances'
import { CalendarComponent } from './CalendarComponent'
import { Store } from '../../utilities/store'
import { CalendarState, createStore, getCalendarData } from './calendarState'
import { CalendarConfig } from './CalendarConfig'
import { bindCalendar, focusOnActiveDate, setActiveDate } from './calendarBehavior'
import { toISODate, parseDate, isValidDateInstance, configureDates } from '../../utilities/date-utils'
import { getLocale } from '../../utilities/localization'
import { getLang } from '../../utilities/i18n'
import { convertStringRefs, configFromDataAttributes, htmlEncode } from '../../utilities/helpers'
import watchForChanges from '../../utilities/watchForChanges'

const CALENDAR_CLASS = `${CSS_NS}calendar`;
const CALENDAR_MONTH_CLASS = `${CALENDAR_CLASS}__month`;
const CALENDAR_HEADER_CLASS = `${CALENDAR_CLASS}__header`;
const CALENDAR_FOOTER_CLASS = `${CALENDAR_CLASS}__footer`;
const CALENDAR_BODY_CLASS = `${CALENDAR_CLASS}__body`;
const SELECTABLE_DAY_CLASS = `${CALENDAR_CLASS}__select-date`;
const SELECTED_DAY_CLASS = `${SELECTABLE_DAY_CLASS}--selected`;
const CURRENT_DATE_CLASS = `${SELECTABLE_DAY_CLASS}--current-date`;
const DUE_DATE_CLASS = `${SELECTABLE_DAY_CLASS}--due-date`;
const DAY_OUTSIDE_MONTH_CLASS = `${SELECTABLE_DAY_CLASS}--outside-month`;
const INSTANCE_KEY = `${NAMESPACE}Calendar`;
const defaultConfig = {};

class _CalendarInstance implements CalendarComponent {
  el: HTMLElement;
  state: CalendarState;
  store: Store<CalendarState>;
  config: CalendarConfig;
  onDestroy: Function[] = [];
  _rendered = false;
  _activeDateStr: string = '';

  constructor(element: HTMLElement) {
    this.el = element;
    element.dataset.enhancedCalendar = "true";
    init(this);
    const unwatchForChanges = watchForChanges(
      element, 
      { attributes: true, attributeFilter: ['selected-date', 'active-date']}, 
      (records: MutationRecord[]) => {
        records.forEach((record: MutationRecord) => {
          switch (record.attributeName) {
            case 'selected-date':
              this.onSelectedDateUpdate();
              break;
            case 'active-date':
              this.onActiveDateUpdate();
              break;
          }
        })
      }
    );
    const store = this.store = createStore(this);
    this.onDestroy = [
      store.subscribe(this.onStateUpdate.bind(this)),
      bindCalendar(this),
      unwatchForChanges
    ]
    instances.set(element, INSTANCE_KEY, this);
  }

  onStateUpdate(state: CalendarState) {
    this.state = state;
    // defer the re-render to allow current click handlers to work with current DOM
    window.setTimeout(() => {
      if (this.el) {  // may have been destroyed since
        this.render();
      }
    }, 10);
  }

  render() {
    const data: any = getCalendarData(this);
    renderCalendarTemplate(this, data);
    const monthRendered = renderMonth(this, data);
    updateCalendarDays(this, data);
    enableHeaderActions(this, data);
    if (monthRendered) {
      onDayCreatedHook(this);
    }
    this._rendered = true;
  }

  get selectedDate(): string {
    return this.el ? this.el.getAttribute('selected-date') : '';
  }

  set selectedDate(dateStr: string) {
    if (dateStr) {
      this.el.setAttribute('selected-date', dateStr);
    } else {
      this.el.removeAttribute('selected-date');
      this.setActiveDate(new Date());
    }
  }

  get activeDate(): string {
    return this.el ? this.el.getAttribute('active-date') : '';
  }

  set activeDate(dateStr: string) {
    if (dateStr) {
      this.el.setAttribute('active-date', dateStr);
    } else {
      this.el.removeAttribute('active-date');
      this.setActiveDate(new Date());
    }
  }

  onSelectedDateUpdate() {
    const selectedDateStr = this.selectedDate;
    const { format } = getLocale(this.el);
    const activeDateStr = toISODate(this.state.activeDate);
    let selectedDate = parseDate(selectedDateStr, format);
    if (isValidDateInstance(selectedDate)) {
      const selectedDateStr = toISODate(selectedDate);
      if (selectedDateStr !== activeDateStr) {
        this.setActiveDate(selectedDate);
      }
    } else {
      this.el.removeAttribute('selected-date');
      this.setActiveDate(new Date());
    }
  }

  onActiveDateUpdate() {
    const dateStr = this.activeDate;
    const { format } = getLocale(this.el);
    const newActiveDate = parseDate(dateStr, format);
    if (isValidDateInstance(newActiveDate)) {
      const newActiveDateStr = toISODate(newActiveDate);
      const activeDateStr = toISODate(this.state.activeDate);
      if (newActiveDateStr !== activeDateStr) {
        this.setActiveDate(newActiveDate);
      }
    } else {
      this.el.removeAttribute('active-date');
      this.setActiveDate(new Date());
    }
  }

  setConfig(config: CalendarConfig = {}) {
    const currentOptions = this.config || {};
    this.config = { ...currentOptions, ...configureDates(config, getLocale(this.el).format) };
    if (this._rendered) {
      this.render();
    }
  }

  setActiveDate(date: Date) {
    setActiveDate(this, date || new Date());
  }

  /**
   * Focuses on the selected date; otherwise first button
   */
  focus() {
    focusOnActiveDate(this.el);
  }

  destroy() {
    if (this.el) {
      while (this.onDestroy && this.onDestroy.length) {
        this.onDestroy.pop()();
      }
      instances.remove(this.el, INSTANCE_KEY);
      delete this.el.dataset.enhancedCalendar;
      this.el = this.state = this.store = this.config = null;
      this._rendered = false;
    }
  }
}

function init(calendar: _CalendarInstance) {
  const element: HTMLElement = <HTMLElement>calendar.el;

  //initialize config with default config and data attributes
  const config: CalendarConfig = { ...defaultConfig };
  const dataConfig: any = configFromDataAttributes(element);
  convertStringRefs(dataConfig, ['onDayCreated'], 'function');
  calendar.setConfig({ ...config, ...dataConfig });
}

////////////////////////////////////////////////
// - Render functions

function renderCalendarTemplate(calendar: _CalendarInstance, data: any) {
  const container: HTMLElement = calendar.el;
  const prevRenderState = (<any>calendar)._prevRenderState || {};
  const lang = getLang(container);
  const notoday = (calendar.config || {}).notoday
  const calendarMonth = container.querySelector(`.${CALENDAR_MONTH_CLASS}`)
  if (calendarMonth && lang === prevRenderState.lang && notoday === prevRenderState.notoday) {
    return
  }
  (<any>calendar)._prevRenderState = { lang, notoday };

  const { weekdays, controls } = data;
  const calendarTemplate = `
      ${renderCalendarHeader(data)}
      <table class="${CALENDAR_BODY_CLASS}">
        <thead>
          <tr>
            ${weekdays.map((weekday: any) => {
    return `<th scope="col"><abbr title="${htmlEncode(weekday.name)}">${htmlEncode(weekday.abbreviation)}</abbr></th>`;
  }).join(' ')}
          </tr>
        </thead>
        <tbody></tbody>
      </table>
      ${renderCalendarFooter(controls)}`

  if (calendarMonth) {
    calendarMonth.innerHTML = calendarTemplate
  }
  else {
    container.insertAdjacentHTML('afterbegin', `<div class="${CALENDAR_MONTH_CLASS}">${calendarTemplate}</div>`)
    container.setAttribute('tabindex', '-1')
  }
}

function renderCalendarHeader(data: any) {
  const { controls, monthName, year } = data;
  const { prevYear, nextYear, prevMonth, nextMonth } = controls;
  return `
    <div class="${CALENDAR_HEADER_CLASS}">
      ${renderActionButton('-year', prevYear)}
      ${renderActionButton('-month', prevMonth)}
      <span class="${CALENDAR_CLASS}__title">${monthName} ${year}</span>
      ${renderActionButton('+month', nextMonth)}
      ${renderActionButton('+year', nextYear)}
    </div>`;
}

function renderActionButton(action: string, actionData: any) {
  const { title, label } = actionData;
  return `
    <button class="${CALENDAR_CLASS}__action" type="button" data-action="${action}" title="${htmlEncode(title)}" aria-label="${htmlEncode(label)}"></button>
  `
}
function renderCalendarFooter(controls: any) {
  const { today } = controls;
  const include = today && today.include;
  return !include ? '' : `
    <div class="${CALENDAR_FOOTER_CLASS}">
      <button type="button" class="${CSS_NS}button--tertiary ${CSS_NS}button--small" data-action="${today.action}"  data-date="${today.date}" accesskey="${today.accessKey}" title="${htmlEncode(today.title)}">${htmlEncode(today.label)}</button>
    </div>
  `;
}

function renderMonth(calendar: _CalendarInstance, data: any): boolean {
  const element = calendar.el;
  const activeDate = calendar.state.activeDate
  const prevState = (<any>calendar)._prevState || {}
  const prevActiveDate = prevState.activeDate
  const tbody = element.querySelector(`.${CALENDAR_MONTH_CLASS} tbody`)

  if (tbody.innerHTML && activeDate && prevActiveDate &&
    activeDate.getMonth() === prevActiveDate.getMonth() &&
    activeDate.getFullYear() === prevActiveDate.getFullYear()) {
    return false;
  }

  (<any>calendar)._prevState = calendar.state
  const { monthName, year, weeks } = data;
  const title = <HTMLElement>element.querySelector(`.${CALENDAR_CLASS}__title`)
  title.innerText = `${monthName} ${year}`;
  tbody.innerHTML = weeks.map(renderWeek).join('');
  return true;
}

function renderWeek(week: any[]) {
  return `
    <tr>${week.map(renderDay).join(' ')}</tr>
  `;
}

function renderDay(day: any) {
  return `
      <td data-date="${day.attributes['data-date']}">
        <span class="${CALENDAR_CLASS}__day">${day.date.getDate()}
      </span></td>
      `;
}

function enableHeaderActions(calendar: _CalendarInstance, data: any) {
  const container = calendar.el;
  const { prevYear, nextYear, prevMonth, nextMonth } = data.controls;
  const actionMap: any = {
    '-year': prevYear,
    '-month': prevMonth,
    '+year': nextYear,
    '+month': nextMonth,
  }
  Object.keys(actionMap).forEach((action: string) => {
    const control: any = actionMap[action];
    const button = container.querySelector(`button[data-action="${action}"]`);
    if (control.disabled) {
      button.setAttribute('aria-disabled', 'true');
    } else {
      button.removeAttribute('aria-disabled');
    }
  });
}

function updateCalendarDays(calendar: _CalendarInstance, data: any) {
  const container = calendar.el;
  const { weeks } = data;
  for (let w = 0; w < weeks.length; w++) {
    const days: any[] = weeks[w];
    for (let i = 0; i < days.length; i++) {
      const day = days[i];
      const dayEl = container.querySelector(`[data-date="${day.attributes['data-date']}"]`);
      if (dayEl) {
        const classes: any = {
          [SELECTED_DAY_CLASS]: day.selected,
          [SELECTABLE_DAY_CLASS]: true,
          [CURRENT_DATE_CLASS]: day.isCurrentDate,
          [DUE_DATE_CLASS]: day.isDueDate,
          [DAY_OUTSIDE_MONTH_CLASS]: day.isOutsideOfMonth
        }

        for (let cls in classes) {
          const method = classes[cls] ? 'add' : 'remove';
          dayEl.classList[method](cls);
        }

        const attributes = dayEl.attributes;
        for (let i = 0; i < attributes.length; i++) {
          if (attributes[i].name !== 'class' && !day.attributes[attributes[i].name]) {
            dayEl.removeAttribute(attributes[i].name);
          }
        }

        for (let attr in day.attributes) {
          if (day.attributes[attr]) {
            dayEl.setAttribute(attr, day.attributes[attr]);
          } else {
            dayEl.removeAttribute(attr);
          }
        }
      }
    }
  }
}

function onDayCreatedHook(calendar: _CalendarInstance) {
  const { config } = calendar;
  const { onDayCreated } = config || {};
  if (onDayCreated) {
    const container = calendar.el;
    const days = container.querySelectorAll('td[data-date]')
    for (let i = 0; i < days.length; i++) {
      const dayEl = days[i];
      const dataStr = dayEl.getAttribute('data-date');
      const date = parseDate(dataStr, getLocale(container).format);
      onDayCreated(dayEl, date, dataStr);
    }
  }
}

//////////////////////////////////////////////////////////////////////////////////////////////////////

onDOMChanges(`.${CALENDAR_CLASS}`,
  function onCalendarAdded(element: HTMLElement) {
    if (!element.dataset.enhancedCalendar) {
      new _CalendarInstance(element);
    }
  },
  function onCalendarRemoved(element: HTMLElement) {
    if (element.dataset.enhancedCalendar === 'true') {
      new Calendar(element).destroy();
    }
  });

///////////////////////////////////////////////////////////////////////////////////////////////////////
class Calendar {
  _instance: _CalendarInstance;

  constructor(element: HTMLElement, config?: CalendarConfig) {
    this._instance = instances.get(element, INSTANCE_KEY) || new _CalendarInstance(element);
    if (config) {
      this.setConfig(config);
    }
  }

  setConfig(config = {}) {
    this._instance.setConfig(config)
  }

  get element() {
    return this._instance.el;
  }

  get selectedDate() {
    return this._instance.selectedDate;
  }

  setActiveDate(date: Date) {
    this._instance.setActiveDate(date);
  }

  /**
   * Focuses on the selected date; otherwise first button
   */
  focus() {
    this._instance.focus();
  }

  destroy() {
    if (this._instance) {
      this._instance.destroy();
      delete this._instance
    }
  }

}

export { Calendar }


import { parseTime } from '../../utilities/date-utils';
import { store, Store } from '../../utilities/store';
import { HourCycle } from './constants';

export type DialogState =
  | 'ready-open'
  | 'opening'
  | 'opened'
  | 'closing'
  | 'closed';

export type ChangeMethod = 'input' | 'dialog' | 'paste' | 'api';

export interface TimeInputState {
  hourValue: string;
  minuteValue: string;
  periodValue: string;
  previousHour?: string;
  previousMinute?: string;
  previousPeriod?: string;
  dialogOpenState: DialogState;
  onDialogClose: Function[];
  changeMethod?: ChangeMethod;
  hasFocus: boolean;
}

export function createStore(
  el?: HTMLElement,
  cycle?: HourCycle
): Store<TimeInputState> {
  let hourValue = '',
    minuteValue = '',
    periodValue = '';
  if (el) {
    const inputs = el.querySelectorAll('input[data-time-part]');
    for (let i = 0; i < inputs.length; i++) {
      const input = <HTMLInputElement>inputs[i];
      const value = input.value;
      switch (input.dataset.timePart) {
        case 'hour':
          hourValue = value;
          break;
        case 'minute':
          minuteValue = value;
          break;
        case 'period':
          periodValue = value;
          break;
      }
    }

    if (!hourValue && !minuteValue && !periodValue) {
      // see if it it initialized using a hidden element
      const hiddenInput = el.querySelector<HTMLInputElement>(
        'input[type="hidden"]'
      );
      if (hiddenInput && hiddenInput.value && cycle) {
        const {
          parts: { hour12, hour24, minute, period },
        } = parseTime(hiddenInput.value);
        hourValue = cycle === '12' ? hour12 : hour24;
        minuteValue = minute;
        periodValue = cycle === '12' ? period : '';
      }
    }
  }

  return store({
    hourValue,
    minuteValue,
    periodValue,
    dialogOpenState: 'closed',
    onDialogClose: [],
    hasFocus: false,
  });
}

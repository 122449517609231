import { hasFocus } from '../../utilities/helpers';
import { EventListeners } from '../../utilities/EventListeners';
import { normalizeKey } from '../../utilities/keyboard';
import { SelectedOptionGroupData } from './types';

const BUTTON_SELECTOR = 'span[role="button"], tds-tag, button.tds-tag';

interface SelectedOptionsComponent {
  readonly host: HTMLElement,
  readonly notags: boolean,
  readonly limitTags: number,
  readonly returnFocusTo: HTMLElement,
  readonly getOptions: () => SelectedOptionGroupData[],
  activeIndex: number,
  noLastComma: boolean,
  dispatchEvent: (type: string, detail: { option: any }) => boolean,

}
export function bindSelectedOptions(selectedOptions: SelectedOptionsComponent) {
  const { host } = selectedOptions;
  const eventListeners = new EventListeners();
  eventListeners.addListener(host, 'focusin', onFocus);
  eventListeners.addListener(host, 'focusout', onBlur);
  eventListeners.addListener(host, 'keydown', onKeydown);
  eventListeners.addListener(host, 'click', onClick);
  eventListeners.addListener(host, 'mousedown', onMousedown);
  eventListeners.addListener(host, 'mouseup', onMouseup);
  eventListeners.addListener(host, 'mouseout', onMouseout);
  let ignoreClickOn: Element;

  return unbind;

  function unbind() {
    eventListeners.removeListeners();
  }

  function onFocus(event: Event) {
    const target = event.target as HTMLElement;
    const buttons = Array.from(selectedOptions.host.querySelectorAll(BUTTON_SELECTOR));
    const activeIndex = Math.max(buttons.indexOf(target), 0);
    if (activeIndex !== selectedOptions.activeIndex) {
      selectedOptions.activeIndex = activeIndex;
    }
    const button = buttons[selectedOptions.activeIndex];
    // Because a span button looks like static text and has no visual affordance as being clickable,
    // the intent is to select the text when it receives focus, so it looks like selected text
    // that can be deleted.
    if (selectedOptions.notags && button) {
      selectButton(button);
    }
  }

  function onBlur() {
    setTimeout(() => {
      const host = selectedOptions.host;
      if (host && !hasFocus(selectedOptions.host)) {
        selectedOptions.activeIndex = 0;
        if (typeof window.getSelection !== 'undefined') {
          const selection = window.getSelection();
          const anchor = selection.anchorNode;
          if (anchor && host.contains(anchor)) {
            selection.empty();
          }
        }
      }
    })
  }

  function selectButton(button: Node, delay = 0) {
    if (typeof window.getSelection !== 'undefined') {
      setTimeout(() => {
        window.getSelection().selectAllChildren(button);
      }, delay);
    }
  }

  function onKeydown(event: KeyboardEvent) {
    ignoreClickOn = undefined;
    const limitTags = selectedOptions.limitTags
    let length = selectedOptions.getOptions().length;
    if (limitTags > 0) {
      length = Math.min(length, limitTags);
    }
    if (!length) return;
    let activeIndex = selectedOptions.activeIndex;
    const spanButton = (event.target as HTMLElement).closest<HTMLElement>('span[role="button"]');
    const key = normalizeKey(event);
    switch (key) {
      case 'Home':
        activeIndex = 0;
        event.preventDefault();
        break;

      case 'End':
        activeIndex = Math.max(length - 1, 0);
        event.preventDefault();
        break;

      case 'ArrowLeft':
        if (activeIndex > 0) activeIndex--;
        event.preventDefault();
        break;

      case 'ArrowRight':
        if (activeIndex < length - 1) {
          activeIndex++;
        } else {
          returnFocus();
        }
        event.preventDefault();
        break;

      case 'Enter':
      case ' ':
        if (spanButton) {
          spanButton.click();
          event.preventDefault();
        }
        return;

      case 'Backspace':
      case 'Delete':
        event.preventDefault();
        if (spanButton) {
          const lastChild = spanButton.matches(':last-child'); // save before click in case element removed on click
          spanButton.click();
          if (lastChild) {
            // if clicking the last span button, should feel like deleting text,
            // so return focus to select element rather than keeping focus in selected options element
            returnFocus();
          }
        }
        return;
    }
    if (activeIndex !== selectedOptions.activeIndex) {
      selectedOptions.activeIndex = activeIndex;
      const button = selectedOptions.host.querySelectorAll<HTMLElement>(BUTTON_SELECTOR)[activeIndex];
      button && button.focus();
    }
  }

  function onClick(event: Event) {
    const button = (event.target as HTMLElement).closest<HTMLElement>(BUTTON_SELECTOR);
    if (button === ignoreClickOn) {
      // 1) Ignore click on a span button that has just received focus, it will be selected instead
      // 2)  A click could close the popup, which causes the span button selection to collapse, so reselect after a brief timeout
      selectButton(button, 50);
      return;
    }
    if (button) {
      ignoreClickOn = undefined;
      const buttons = Array.from(selectedOptions.host.querySelectorAll<HTMLElement>(BUTTON_SELECTOR));
      const index = buttons.indexOf(button);
      const toBeLength = buttons.length - 1;
      if (toBeLength < 1) {
        returnFocus();
      }
      const selectedOptionGroupData = selectedOptions.getOptions()[index];
      const context = selectedOptionGroupData?.context;
      const option = selectedOptionGroupData?.option;
      if (context && option) {
        context.selectionActions.deselectOption(option);
      }
      selectedOptions.dispatchEvent('tdsRemove', { option: option.option });

      // when deselecting an option not last in the list, re-rendering may repurpose the same button 
      // for a different option. This change is not registered to the screen reader because focus does not
      // change. So, after a timeout, if the same button as focus, blur, then re-focus on the button 
      // so its new option is announced. This will also reapply the selected apearance for span buttons
      if(hasFocus(button)) {
        let lostFocus = false;
        const onBlur = () => lostFocus = true;
        button.addEventListener('blur', onBlur);
        setTimeout(() => {
          button.removeEventListener('blur', onBlur);
          if (hasFocus(button) && !lostFocus) {
            button.blur();
            setTimeout(() => button.focus(), 50);
          }
        }, 50);
      }
    }
  }

  ////////////////////////////////////////////////////////
  // mouse event handlers
  //
  // The mouse event handlers are intended to prevent an initial click of
  // a span button that does not have focus from deselecting the option
  // The first click sets focus
  // The second click deselects option

  function onMousedown(event: Event) {
    ignoreClickOn = undefined;
    const button = (event.target as HTMLElement).closest<HTMLElement>(BUTTON_SELECTOR);
    if (button && !button.matches(':focus') && selectedOptions.notags) {
      ignoreClickOn = button;
    }
  }

  function onMouseup() {
    setTimeout(() => {
      ignoreClickOn = undefined;
    }, 50);
  }

  function onMouseout(event: MouseEvent) {
    if (event.target === ignoreClickOn) {
      ignoreClickOn = undefined;
    }
  }
  ////////////////////////////////////////////////////////

  function returnFocus() {
    const returnFocusTo = selectedOptions.returnFocusTo;
    if (returnFocusTo) {
      returnFocusTo.focus();
    }
  }
}

export function watchReturnToFocus(returnFocusTo: HTMLElement, selectedOptions: SelectedOptionsComponent) {
  const eventListeners = new EventListeners();
  eventListeners.addListener(returnFocusTo, 'focus', checkReturnToState);
  eventListeners.addListener(returnFocusTo, 'blur', checkReturnToState);
  if (returnFocusTo.matches('input')) {
    eventListeners.addListener(returnFocusTo, 'input', checkReturnToState);
    eventListeners.addListener(returnFocusTo, 'change', checkReturnToState);
  }

  checkReturnToState();

  return unwatch;

  function unwatch() {
    eventListeners.removeListeners();
  }
  function checkReturnToState() {
    const value = returnFocusTo.matches('input') ? (returnFocusTo as HTMLInputElement).value : '';
    const focused = returnFocusTo.matches(':focus');
    selectedOptions.noLastComma = !focused && !value;
  }
}

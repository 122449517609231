import { TimeInputComponent } from './TimeInputComponent';
import { ChangeMethod, TimeInputState } from './TimeInputState';
import { CSS_NS } from '../../utilities/constants';
import { containTabFocus } from '../../utilities/dialogs';
import { isTyping, normalizeKey } from '../../utilities/keyboard';
import { EventListeners } from '../../utilities/EventListeners';
import { hasShadowDom, to2Digits } from '../../utilities/helpers';
import { PART_ATTR, TimeParts, TRIGGER_SELECTOR } from './constants';
import {
  getDisplayValue,
  getValidityState,
  getValue,
  getValueAsDate,
  TimeInputValidity,
} from './utils';
import { createPopover } from '../../utilities/popover';
import { focusOnFirstList } from './time-picker/timePickerBehavior';
import { getLang } from '../../utilities/i18n';
import debounce from '../../utilities/debounce';
import { parseTime } from '../../utilities/date-utils';

const TIME_FORMAT_HINT_SELECTOR = `[data-time-format-label]:not([data-time-format-label="done"])`;
const DIALOG_ANIM_TIMING = 200; // this should match $a-dialog-transition-duration in time-input.yml

/////////////////////////////////////////////////////
// - exports

export function bindTimeInput(timeInput: TimeInputComponent): Function {
  const { el, store, dialog } = timeInput;

  const inputs = getInputs(timeInput);
  inputs.forEach((input: HTMLInputElement) => {
    if (
      input.dataset.timePart === TimeParts.Period &&
      timeInput.cycle === '24'
    ) {
      input.hidden = true;
    }
  });
  setFormatHints(timeInput);
  getA11ySpan(timeInput);

  const eventListeners = new EventListeners();
  eventListeners.addListener(el, 'paste', pasteHandler(timeInput));
  eventListeners.addListener(el, 'input', inputHandler(timeInput, inputs));
  eventListeners.addListener(el, 'change', changeHandler(timeInput, inputs));
  eventListeners.addListener(el, 'keydown', keydownHandler(timeInput));
  eventListeners.addListener(el, 'keyup', keyupHandler(timeInput));
  eventListeners.addListener(el, 'focusin', focusHandler(timeInput));
  const manageFocusState = debounce(
    () => {
      setFocusState(timeInput);
    },
    100,
    false
  );
  eventListeners.addListener(el, 'focusin', manageFocusState);
  eventListeners.addListener(el, 'focusout', manageFocusState);
  const trigger = getDialogTrigger(timeInput);
  if (trigger) {
    eventListeners.addListener(trigger, 'click', triggerHandler(timeInput));
  }
  eventListeners.addListener(
    document,
    'click',
    documentClickHandler(timeInput)
  );
  if (dialog) {
    eventListeners.addListener(
      dialog,
      'keydown',
      dialogKeydownHandler(timeInput)
    );
    eventListeners.addListener(dialog, 'focusin', manageFocusState);
    eventListeners.addListener(dialog, 'focusout', manageFocusState);
    // stop propagation of these events to prevent loss focus handlers when used as editable table cell editor
    eventListeners.addListener(dialog, 'mousedown', stopPropagation);
    eventListeners.addListener(dialog, 'touchstart', stopPropagation);
    eventListeners.addListener(dialog, 'click', stopPropagation);
  }

  const unsubscribe = store.subscribe(onStateUpdate(timeInput));
  const hiddenInput = getHiddenInput(timeInput);
  if (hiddenInput && !hiddenInput.value) {
    syncHiddenRelatedElements(timeInput);
  }

  const unbind = () => {
    eventListeners.removeListeners();
    unsubscribe();
    timeInput.state.onDialogClose.forEach((f) => f());
    timeInput.store.update((state) => ({ ...state, onDialogClose: [] }));
  };
  return unbind;
}

export function setDateValue(
  value: string | Date,
  timeInput: TimeInputComponent
) {
  setValue(value, timeInput, 'api');
}

///////////////////////////////////////////////////////////
// - internal functions

function setValue(
  value: Date | string,
  timeInput: TimeInputComponent,
  changeMethod: ChangeMethod
) {
  const { cycle, store } = timeInput;
  let hourValue: string = '';
  let minuteValue: string = '';
  let periodValue: string = '';
  let valid = false;

  if (value instanceof Date) {
    if (cycle === '12') {
      if (value.getHours() === 0 || value.getHours() > 12) {
        hourValue = to2Digits(Math.abs(value.getHours() - 12));
      }
      periodValue = value.getHours() >= 12 ? 'PM' : 'AM';
    } else {
      hourValue = to2Digits(value.getHours());
    }
    minuteValue = to2Digits(value.getMinutes());
    valid = true;
  } else {
    const parsed = parseTime(value);
    if (parsed?.parts) {
      const {
        parts: { hour12, hour24, minute, period },
      } = parsed;
      hourValue = cycle === '12' ? hour12 : hour24;
      minuteValue = minute;
      periodValue = cycle === '12' ? period : '';
      valid = !!(hourValue || minuteValue || periodValue);
    }
  }

  store.update((state) => ({
    ...state,
    hourValue,
    minuteValue,
    periodValue,
    changeMethod,
  }));

  return valid;
}

function setFormatHints(timeInput: TimeInputComponent) {
  const hintLabels = document.querySelectorAll(TIME_FORMAT_HINT_SELECTOR);
  for (let i = 0; i < hintLabels.length; i++) {
    const hintLabel = <HTMLElement>hintLabels[i];
    hintLabel.innerText = `(--:--${timeInput.cycle === '12' ? ' --' : ''})`;
    hintLabel.setAttribute(
      'aria-label',
      `hour minute${timeInput.cycle === '12' ? ' AM PM' : ''}`
    );
    hintLabel.setAttribute('data-time-format-label', 'done');
  }
}

function getHiddenInput(timeInput: TimeInputComponent): HTMLInputElement {
  const { el, host } = timeInput;
  const selector = 'input[type="hidden"]';
  return el.querySelector(selector) ?? host?.querySelector(selector);
}

function getA11ySpan(timeInput: TimeInputComponent) {
  const container = timeInput.el;
  let a11ySpan: HTMLElement = container.querySelector(`[data-a11y="true"]`);
  if (!a11ySpan) {
    a11ySpan = document.createElement('span');
    a11ySpan.setAttribute('aria-live', 'assertive');
    a11ySpan.dataset.a11y = 'true';
    a11ySpan.classList.add(`${CSS_NS}sr-only`);
    container.appendChild(a11ySpan);
  }
  return a11ySpan;
}

////////////////////////////////////////////////
// - calendar actions

function toggleDialog(timeInput: TimeInputComponent) {
  if (isDialogOpenState(timeInput, 'opened')) {
    closeDialog(timeInput);
  }
  if (isDialogOpenState(timeInput, 'closed')) {
    openDialog(timeInput);
  }
}

function openDialog(timeInput: TimeInputComponent) {
  const { store } = timeInput;
  if (!isDialogOpenState(timeInput, 'opened', 'opening')) {
    // Setting dialogOpenState to 'ready-open' puts the calendar in a state
    // ready to transition open. As there is no other reference this state
    // in the code, this may be unclear. It basically makes the calendar "not closed"
    // which effectively changes the CSS display from 'none' to 'block' but keeps
    // visibility to hidden.
    store.update((v: TimeInputState) => {
      return { ...v, dialogOpenState: 'ready-open' };
    });

    // timeout is used to let 'display:block' establish before transitioning from hidden to visible
    setTimeout(() => {
      store.update((v: TimeInputState) => {
        return { ...v, dialogOpenState: 'opening' };
      });
      setTimeout(() => {
        store.update((state: TimeInputState) => ({
          ...state,
          dialogOpenState: 'opened',
        }));
      }, DIALOG_ANIM_TIMING);
    }, 50);
  }
}

function closeDialog(timeInput: TimeInputComponent) {
  const { store } = timeInput;
  if (isDialogOpenState(timeInput, 'opened')) {
    store.update((v: TimeInputState) => {
      return { ...v, dialogOpenState: 'closing' };
    });
    setTimeout(() => {
      store.update((state: TimeInputState) => ({
        ...state,
        dialogOpenState: 'closed',
      }));
    }, DIALOG_ANIM_TIMING);
  }
}

function onDialogReadyOpen(timeInput: TimeInputComponent) {
  const { dialog, el, store } = timeInput;
  const destroyPopover = createPopover(dialog, el, {
    transitionEnterTimeout: 0,
    transitionLeaveTimeout: 0,
    placement: 'bottom-start',
  });
  store.update((state) => ({
    ...state,
    onDialogClose: [...state.onDialogClose, destroyPopover],
    previousHour: state.hourValue,
    previousMinute: state.minuteValue,
    previousPeriod: state.periodValue,
  }));
}

function onDialogOpening(timeInput: TimeInputComponent) {
  focusOnFirstList(timeInput.dialog, timeInput.store);
}

function onDialogOpened(timeInput: TimeInputComponent) {
  const uncontainTabFocus = containTabFocus(timeInput.dialog);
  timeInput.store.update((state) => ({
    ...state,
    onDialogClose: [...state.onDialogClose, uncontainTabFocus],
  }));
}

function onDialogClosing(timeInput: TimeInputComponent) {
  const trigger = getDialogTrigger(timeInput);
  if (trigger) {
    const { dialog } = timeInput;
    let focusedEl = dialog.querySelector(':focus');
    if (!focusedEl && hasShadowDom(dialog)) {
      focusedEl = dialog.shadowRoot.querySelector(':focus');
    }
    // only focus on trigger if focus is inside dialog
    if (focusedEl) {
      trigger.focus();
    }
  }
}

function onDialogClosed(timeInput: TimeInputComponent) {
  const { store, state } = timeInput;
  state.onDialogClose.forEach((f) => f());
  store.update((state) => ({
    ...state,
    onDialogClose: [],
  }));
}

function getDialogTrigger(timeInput: TimeInputComponent): HTMLElement {
  return timeInput.el.querySelector(TRIGGER_SELECTOR);
}

//////////////////////////////////////////////////
// - event handlers

function inputHandler(
  timeInput: TimeInputComponent,
  inputs: HTMLInputElement[]
): EventListener {
  return (event) => {
    if (
      event.target instanceof HTMLInputElement &&
      inputs.indexOf(event.target) > -1
    ) {
      applyTimeInputUpdate(timeInput);
    }
  };
}

function changeHandler(
  timeInput: TimeInputComponent,
  inputs: HTMLInputElement[]
): EventListener {
  return (event) => {
    if (
      event.target instanceof HTMLInputElement &&
      inputs.indexOf(event.target) > -1
    ) {
      const input = event.target;
      const state = timeInput.state;
      const value = input.value;
      const resolvedValue =
        value && input.dataset.timePart !== TimeParts.Period
          ? to2Digits(value)
          : value;
      if (resolvedValue !== value) {
        input.value = resolvedValue;
      }
      if (
        state[`${<TimeParts>input.dataset.timePart}Value`] !== resolvedValue
      ) {
        applyTimeInputUpdate(timeInput);
      }
    }
  };
}

function keydownHandler(timeInput: TimeInputComponent): EventListener {
  return (event: KeyboardEvent) => {
    const { target } = event;
    if (target instanceof HTMLInputElement) {
      const key = normalizeKey(event);
      const inputs = getInputs(timeInput);
      const inputIX = inputs.indexOf(target);
      if (
        inputIX > -1 &&
        !target.disabled &&
        !target.readOnly &&
        !(target.getAttribute('aria-disabled') === 'true') &&
        !(target.getAttribute('aria-readonly') === 'true')
      ) {
        const nextInput = inputs[inputIX + 1];
        const prevInput = inputs[inputIX - 1];
        switch (key) {
          case ':':
            event.preventDefault();
            if (
              target === inputs[0] &&
              target.value.length &&
              target.selectionStart === target.value.length
            ) {
              if (nextInput) {
                nextInput.focus();
                nextInput.select();
              }
            }
            break;

          case 'ArrowLeft':
            if (prevInput && target.selectionStart === 0) {
              prevInput.focus();
              prevInput.selectionStart = prevInput.value.length;
              event.preventDefault();
            }
            break;

          case 'ArrowRight':
            if (nextInput && target.selectionStart === target.value.length) {
              nextInput.focus();
              nextInput.selectionStart = 0;
              event.preventDefault();
            }
            break;

          case 'ArrowUp':
          case 'ArrowDown':
          case 'Home':
          case 'End':
            event.preventDefault();
            if (target.dataset.timePart !== TimeParts.Period) {
              incrementTimeField(key, target, timeInput);
            }
            break;

          case 'Backspace':
            //if backspacing from 2nd or 3rd empty input, shift focus to previous input
            if (prevInput && target.value.length === 0) {
              prevInput.focus();
              prevInput.selectionStart = prevInput.value.length;
              // by not preventing default, backspace will continue at start of previous field
            }
            break;

          default: {
            if (isTyping(event)) {
              if (
                target.dataset.timePart !== TimeParts.Period &&
                !key.match(/\d/)
              ) {
                event.preventDefault();
              }
              if (target.dataset.timePart === TimeParts.Period) {
                if (!key.match(/A|P/i)) {
                  event.preventDefault();
                } else {
                  event.preventDefault();
                  target.value = key.match(/A/i) ? 'AM' : 'PM';
                  applyTimeInputUpdate(timeInput);
                }
              }
            }
          }
        }
      }
    }
  };
}

function resetValue(timeInput: TimeInputComponent) {
  timeInput.store.update((state) => ({
    ...state,
    hourValue: state.previousHour,
    minuteValue: state.previousMinute,
    periodValue: state.previousPeriod,
  }));
}

function dialogKeydownHandler(timeInput: TimeInputComponent): EventListener {
  return (event: KeyboardEvent) => {
    const key = normalizeKey(event);
    if (key === 'Enter' || key === ' ' || key === 'Escape') {
      closeDialog(timeInput);
    }
    if (key === 'Escape') {
      resetValue(timeInput);
      event.stopPropagation();
    }
  };
}

function incrementTimeField(
  key: string,
  input: HTMLInputElement,
  timeInput: TimeInputComponent
) {
  let inputValue: number = input.value
    ? parseInt(input.value, 10)
    : timeInput.cycle === '12'
    ? 0
    : -1;
  let maxValue: number;
  let minValue: number;
  if (input.dataset.timePart === TimeParts.Hour) {
    if (timeInput.cycle === '12') {
      maxValue = 12;
      minValue = 1;
    } else {
      maxValue = 23;
      minValue = 0;
    }
  }
  if (input.dataset.timePart === TimeParts.Minute) {
    minValue = 0;
    maxValue = 59;
  }
  switch (key) {
    case 'ArrowUp':
      if (inputValue < maxValue) {
        inputValue++;
      }
      break;
    case 'ArrowDown':
      if (inputValue > minValue) {
        inputValue--;
      }
      break;
    case 'Home':
      inputValue = minValue;
      break;
    case 'End':
      inputValue = maxValue;
      break;
  }
  const value = !isNaN(inputValue) ? to2Digits(inputValue) : '';
  if (value !== input.value) {
    input.value = value;
    applyTimeInputUpdate(timeInput);
  }
}

function applyTimeInputUpdate(timeInput: TimeInputComponent) {
  const { store } = timeInput;
  const inputs = getInputs(timeInput);
  const updates: Partial<TimeInputState> = {};
  inputs.forEach(
    (input) =>
      (updates[`${<TimeParts>input.dataset.timePart}Value`] =
        input.value.trim())
  );
  store.update((v) => {
    return {
      ...v,
      ...updates,
      changeMethod: 'input',
    };
  });
}

function keyupHandler(timeInput: TimeInputComponent): EventListener {
  return (event: KeyboardEvent) => {
    const target = <HTMLInputElement>event.target;
    const inputs = getInputs(timeInput);
    const inputIX = inputs.indexOf(target);
    const key = normalizeKey(event);
    if (inputIX > -1) {
      const nextInput = inputs[inputIX + 1];
      if (
        key.match(/\d/) &&
        target.selectionStart === target.maxLength &&
        nextInput
      ) {
        nextInput.focus();
        nextInput.select();
      }
    }
  };
}

function triggerHandler(timeInput: TimeInputComponent) {
  return () => {
    const trigger = getDialogTrigger(timeInput);
    if (trigger && !trigger.closest('[aria-disabled="true"]')) {
      toggleDialog(timeInput);
    }
  };
}

function onStateUpdate(timeInput: TimeInputComponent) {
  let prevState: TimeInputState = null;
  return (state: TimeInputState) => {
    const oldState = prevState;
    prevState = state;
    onDateUpdate(timeInput, state, oldState);
    onDialogUpdate(timeInput, state, oldState);
  };
}
function onDateUpdate(
  timeInput: TimeInputComponent,
  state: TimeInputState,
  oldState: TimeInputState
) {
  const events: any[] = [];
  if (oldState) {
    const { hourValue = '', minuteValue = '', periodValue = '' } = state;
    const {
      hourValue: oldHour = '',
      minuteValue: oldMinute = '',
      periodValue: oldPeriod = '',
    } = oldState;
    if (
      hourValue === oldHour &&
      minuteValue === oldMinute &&
      periodValue === oldPeriod
    ) {
      return;
    }
    syncHiddenRelatedElements(timeInput, state);
    const changeMethod = state.changeMethod || 'api';
    events.push(['change', { detail: { timeInput: timeInput, changeMethod } }]);
    const validity = getValidityState(timeInput, getDisplayValue(timeInput, state));
    const oldValidity = getValidityState(
      timeInput,
      getDisplayValue(timeInput, oldState)
    );
    const props = (
      Object.keys(validity) as Array<keyof TimeInputValidity>
    ).concat(Object.keys(oldValidity) as Array<keyof TimeInputValidity>);
    const changed = props.find((prop) => oldValidity[prop] !== validity[prop]);
    if (changed) {
      events.push([
        validity.valid ? 'valid' : 'invalid',
        { detail: { validity: validity } },
      ]);
    }
    events.forEach(([type, eventInit]) => {
      timeInput.sendEvent(type as string, eventInit);
    });
  }
}

function onDialogUpdate(
  timeInput: TimeInputComponent,
  state: TimeInputState,
  oldState: TimeInputState
) {
  if (oldState && oldState.dialogOpenState !== state.dialogOpenState) {
    let eventType: string;
    switch (state.dialogOpenState) {
      case 'ready-open':
        onDialogReadyOpen(timeInput);
        break;
      case 'opening':
        eventType = 'dialogopening';
        onDialogOpening(timeInput);
        break;
      case 'opened':
        eventType = 'dialogopened';
        onDialogOpened(timeInput);
        break;
      case 'closing':
        eventType = 'dialogclosing';
        onDialogClosing(timeInput);
        break;
      case 'closed':
        eventType = 'dialogclosed';
        onDialogClosed(timeInput);
        break;
    }
    if (eventType) {
      timeInput.sendEvent(eventType);
    }
  }
}

function pasteHandler(timeInput: TimeInputComponent): EventListener {
  return (event: ClipboardEvent) => {
    if (event.target instanceof HTMLInputElement) {
      const { target } = event;
      const inputs = getInputs(timeInput);
      const order = inputs.indexOf(target);
      if (
        order === 0 &&
        !target.disabled &&
        !target.readOnly &&
        !(target.getAttribute('aria-disabled') === 'true') &&
        !(target.getAttribute('aria-readonly') === 'true')
      ) {
        if (event.clipboardData) {
          event.preventDefault();
          const text = event.clipboardData.getData('text');
          setTimeout(() => {
            const valid = setValue(text, timeInput, 'paste');
            // if valid date, focus on end of date, else focus on beginning
            const focusOn = valid ? inputs[2] : inputs[0];
            focusOn.focus();
            const cursorPos = valid
              ? focusOn.maxLength || focusOn.value.length
              : 0;
            focusOn.setSelectionRange(cursorPos, cursorPos);
          }, 0);
        }
      } else if (order > -1) {
        event.preventDefault();
      }
    }
  };
}

function focusHandler(timeInput: TimeInputComponent): EventListener {
  // todo: needed if we have contrain focus and document click handler?
  return ({ target }) => {
    if (isDialogOpenState(timeInput, 'opening', 'opened')) {
      const inputs = <EventTarget[]>getInputs(timeInput);
      if (inputs.indexOf(target) > -1) {
        closeDialog(timeInput);
      }
    }
  };
}

function documentClickHandler(timeInput: TimeInputComponent): EventListener {
  /**
   * Checks for clicks outside of the date input component to close the calendar
   * Checks inside the component handled by next handler
   */
  return (event: MouseEvent) => {
    const { target } = event;
    if (isDialogOpenState(timeInput, 'opened')) {
      if (
        !timeInput.el.contains(target as Node) &&
        !timeInput.dialog.contains(target as Node)
      ) {
        closeDialog(timeInput);
      }
    }
  };
}

function syncHiddenRelatedElements(
  timeInput: TimeInputComponent,
  state?: TimeInputState
) {
  const hiddenInput = getHiddenInput(timeInput);
  if (hiddenInput) {
    hiddenInput.value = getValue(timeInput, state);
  }
  const a11ySpan = getA11ySpan(timeInput);
  if (a11ySpan) {
    const date = getValueAsDate(timeInput, state);
    a11ySpan.innerText = date
      ? Intl.DateTimeFormat(getLang(timeInput.el), {
          hourCycle: timeInput.cycle === '12' ? 'h12' : 'h23',
          timeStyle: 'short',
        }).format(date)
      : '';
  }
}

function isDialogOpenState(
  timeInput: TimeInputComponent,
  ...openState: string[]
): boolean {
  const { state } = timeInput;
  const result = openState.find((s) => s === state.dialogOpenState);
  return !!result;
}

////////////////////////////////////////////////
// - Helper functions
function getInputs(timeInput: TimeInputComponent): HTMLInputElement[] {
  return Array.from(
    timeInput.el.querySelectorAll(
      `[${PART_ATTR}="${TimeParts.Hour}"],[${PART_ATTR}="${TimeParts.Minute}"],[${PART_ATTR}="${TimeParts.Period}"]`
    )
  );
}

function setFocusState(timeInput: TimeInputComponent) {
  const { dialog, state, store, el } = timeInput;
  let focusedEl = el.querySelector(':focus');
  if (!focusedEl && dialog) {
    const dialogRoot = hasShadowDom(dialog) ? dialog.shadowRoot : dialog;
    focusedEl = dialogRoot.querySelector(':focus');
  }
  const hasFocus = !!focusedEl;
  if (hasFocus !== state.hasFocus) {
    const eventType = hasFocus ? 'tdsFocus' : 'tdsBlur';
    timeInput.sendEvent(eventType, { cancelable: false, bubbles: true });
    store.update({ hasFocus });
  }
}

function stopPropagation(event: Event) {
  event.stopPropagation();
}

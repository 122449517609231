const TRIGGER_SELECTOR = '[data-trigger="dialog"]';
const TimeParts = {
  Hour: 'hour',
  Minute: 'minute',
  Period: 'period',
} as const;
type TimeParts = (typeof TimeParts)[keyof typeof TimeParts];
const PART_ATTR = 'data-time-part';
type HourCycle = '12' | '24'

export { TRIGGER_SELECTOR, TimeParts, PART_ATTR, HourCycle };

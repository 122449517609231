import { EventListeners } from "../../../utilities/EventListeners";
import { isTyping, normalizeKey } from "../../../utilities/keyboard";
import bindFilter from "../optionsFilter";
import { SelectContext } from "../SelectContext";
import { getBestMatchIndex, getExactMatchIndex } from "../utils";

interface SelectDialogComponent {
  readonly host: HTMLElement,
  readonly optionName: string,
  readonly multiple: boolean,
  readonly selectContext: SelectContext,
  readonly searchInput: HTMLInputElement,
  readonly filterDelay?: number
  readonly applyFilter: boolean,
  readonly disabled?: boolean,

}
export function bindSelectDialog(dialog: SelectDialogComponent) {
  const unbindFilter = bindFilter(dialog, dialog.host, onFilter);
  const eventListeners = new EventListeners();
  eventListeners.addListener(dialog.host, 'keydown', onKeydown);
  eventListeners.addListener(dialog.host, 'focusin', onFocus);

  return unbind;

  function unbind() {
    unbindFilter();
    eventListeners.removeListeners();
  }

  function onKeydown(event: KeyboardEvent) {
    const target = event.target as HTMLElement;
    const key = normalizeKey(event);
    if (target.matches('input') && ['ArrowDown', 'Enter'].includes(key)) {
      event.preventDefault();
      const listbox = dialog.host.querySelector<HTMLElement>('[role="listbox"]');
      if (listbox) {
        listbox.focus();
        const store = dialog.selectContext?.store;
        if (store && store && store.get().activeIndex < 0) {
          store.update({activeIndex: 0});
        }
      }
      return;
    }
    if (isTyping(event) && key !== ' ') { //space is for selecting an option
      const listbox = target.closest<HTMLElement>('[role="listbox"]');
      const searchInput = dialog.searchInput;
      if (listbox && searchInput) {
        // redirect to search input, typing should continue to input
        // do not prevent default
        searchInput.value='';
        searchInput.focus();
        return;
      }
    }
  }

  function onFocus(event: Event) {
    const target = event.target as HTMLElement;
    if (target.closest('[data-returnto-trap]')) {
      const context = dialog.selectContext;
      if (!dialog.multiple) {
        const { orderedOptions, activeIndex } = context.store.get();
        const option = orderedOptions[activeIndex];
        if (option) {
          // if tabbing away from single select, and item is active, select it
          // this is to make dialog consistent with tabbing from listbox
          // same logic as in selectBehavior's commitEntry
          context.selectionActions.updateOption(option);
        }
      }
      context.hideOptionsPopup(true);
    }
  }
  function onFilter(_: any[], filterText: string) {
    const orderedOptions = dialog.selectContext.store.get().orderedOptions;
    const getIndex = dialog.multiple ? getBestMatchIndex : getExactMatchIndex;
    const activeIndex = filterText ? getIndex(orderedOptions, filterText) : -1;
    const store = dialog.selectContext.store;
    if (activeIndex !== store.get().activeIndex) {
      store.update({ activeIndex });
    }
  }
}


import { getLang } from "../../../../utilities/i18n";
import { CellValidationData } from "./CellValidationData";
import { CellValidator } from "./CellValidator";
import { ValidationMessage } from "./validatorsFactory";
import { getTimeValidity } from "../../../../utilities/date-utils";
import { TimeFormatter, TimeFormatterOptions } from "../../formatters/TimeFormatter";

export interface TimeCellValidationOptions extends ValidationMessage, TimeFormatterOptions {
  min?: string,
  max?: string,
  minuteIncrement?: number,
  convert?: boolean,
  format?: boolean
}

export class TimeCellValidator implements CellValidator {
  _options: TimeCellValidationOptions;

  constructor(options: TimeCellValidationOptions) {
    this._options = options;
  }

  validate(cellData: CellValidationData): CellValidationData {
    const options = this._options;
    const { convert, format, message } = options;
    let newValue = (cellData.newValue === 0 ? '0' : cellData.newValue || '').trim();
    if (newValue === '') {
      return cellData;
    }
    const validity = getTimeValidity(newValue, options);
    if (validity.valid) {
      const displayValue = format ? this.format(newValue, cellData.cell) : cellData.displayValue;
      newValue = (convert ? 
        new TimeFormatter({ hourCycle: '24' }).format(newValue) : 
        cellData.newValue);
      return { ...cellData, newValue, displayValue };
    }

    const errorReason =
      validity.rangeOverflow ? 'rangeOverflow' :
        validity.rangeUnderflow ? 'rangeUnderflow' : 'badInput'

    return { ...cellData, errorMessage: message, errorReason };
  }

  format(value: any, cell: HTMLTableCellElement): string {
    const { hourCycle, hour2Digit } = this._options;
    const formatter = new TimeFormatter({ hourCycle, hour2Digit })
    return formatter.format(value, getLang(cell));
  }

  get type() { return 'time'; }
  get options() { return this._options; }

}

import { CSS_NS } from '../../utilities/constants';
export const DTABLE_CLASSNAME = `${CSS_NS}data-table`;
export const ROW_CHECKBOX_SELECTOR = 'tbody [data-row-selector="true"] input[type="checkbox"], tbody input[type="checkbox"][data-row-selector="true"]';
export const ROW_RADIO_SELECTOR = 'tbody [data-row-selector="true"] input[type="radio"], tbody input[type="radio"][data-row-selector="true"]';
export const ROW_TOGGLE_SELECTOR = 'tbody [data-row-selector="true"] button[aria-pressed], tbody button[aria-pressed][data-row-selector="true"]';
export const ROW_EXPANDER_DATA_ATTR = 'data-row-expander';
export const EXPANSION_ROW_DATA_ATTR = 'data-expansion-row';
export const EXPANDED_ROW_CLASS = `${DTABLE_CLASSNAME}__row--expanded`;
export const ROW_HOVERED_CLASS = `${DTABLE_CLASSNAME}__row--hovered`;
export const EXPANDING_ROW_CLASS = `${DTABLE_CLASSNAME}__row--expanding`;
export const COLLAPSING_ROW_CLASS = `${DTABLE_CLASSNAME}__row--collapsing`;
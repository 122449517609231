import { Store } from "../../utilities/store";
import { SelectOption, SelectState } from "./SelectState";
import { getExactMatchIndex, isIncluded, resolveAllowOther, toSelectOption, toSelectOptions } from "./utils";
import { orderOptions } from "./orderOptions";
import { FilterPredicateCallback, LoadOptions } from "./types";

interface OptionsGetterContext {
  options: any[] | LoadOptions,
  optionName: string,
  optionShortName?: string,
  optionValue?: string,
  optionId?: string,
  groupBy?: string,
  multiple?: boolean,
  selectedFirst?: boolean,
  filter?: boolean,
  filterPredicate?: FilterPredicateCallback,
  defaultOptions?: any[],
  allowOther?: 'true' | 'false' | 'prompt'
}

export function createOptionsGetter(context: OptionsGetterContext, store: Store<SelectState>): (filterText?: string) => Promise<SelectOption[]> {
  let loadingTimeout: string | number | NodeJS.Timeout;
  let lastOptionsRequest: {
    filterText: string,
    response: any[] | Promise<any[]>,
    resolved: boolean;
  };
  const { options } = context;
  const asyncOptions = typeof options === 'function';
  const startingOptions = asyncOptions ? [] : options as any[];
  const filteredOptions = toSelectOptions(startingOptions, context);
  if (filteredOptions.length) {
    store.update({ filteredOptions });
  }

  return function getOptions(filterText = ''): Promise<SelectOption[]> {
    const { options: configOptions } = context;
    filterText = filterText.trim();
    const promise = (typeof configOptions === 'function') ?
      getOptionsAsync(configOptions as LoadOptions, filterText).then(options => toSelectOptions(options, context)) :
      Promise.resolve(filterOptions(toSelectOptions(configOptions as any, context), filterText));

    return promise.then(selectOptions => {
      selectOptions = addCreatedSelectedOptions(selectOptions, filterText);
      selectOptions = addCreateOption(selectOptions, filterText);
      return setOptions(selectOptions, filterText);
    })
      .catch(() => {
        return setOptions([], filterText);
      })
  }

  function addCreatedSelectedOptions(options: SelectOption[], filterText: string): SelectOption[] {
    const createdOptions = store.get().selectedOptions.filter(o => {
      return o.other && optionMatches(o, filterText) && !isIncluded(o, options);
    });
    return [...options, ...createdOptions];
  }

  function addCreateOption(options: SelectOption[], filterText: string): SelectOption[] {
    const allowOther = resolveAllowOther(context.allowOther);
    if (allowOther === 'prompt' && filterText) {
      const exactIndex = getExactMatchIndex(options, filterText);
      if (exactIndex < 0) {
        const newOption = toSelectOption(filterText, context);
        newOption.other = true;
        return [...options, newOption];
      }
    }
    return options;
  }

  function setOptions(options: SelectOption[], filterText: string): SelectOption[] {
    const selectedOptions = store.get().selectedOptions;
    store.update({ filteredOptions: options, filterText: filterText, ...orderOptions(options, selectedOptions, context) });
    return options;
  }

  function getOptionsAsync(loadOptions: LoadOptions, filterText: string): Promise<any[]> {
    if (lastOptionsRequest && lastOptionsRequest.filterText === filterText) {
      //  is a way to prevent unintentional multiple calls for the same set of options
      return Promise.resolve(lastOptionsRequest.response);
    }

    const lastRequest = lastOptionsRequest && { ...lastOptionsRequest };
    const thisRequest = lastOptionsRequest = {
      filterText,
      response: undefined as any[] | Promise<any[]>,
      resolved: false as boolean
    }
    // updating here so handoff will work
    store.update({ filterText: filterText });
    showLoading(true);
    return thisRequest.response = Promise.resolve((!filterText && context.defaultOptions) || loadOptions(filterText, lastRequest))
      .then(response => {
        thisRequest.response = response;
        thisRequest.resolved = true;

        if (thisRequest !== lastOptionsRequest && lastOptionsRequest.resolved) {
          return lastOptionsRequest.response; // did not complete before later request, so return latest 
        }
        return response;
      })
      .catch((err) => {
        //todo: how to respond to error?
        thisRequest.response = [];
        thisRequest.resolved = true;
        if (thisRequest !== lastOptionsRequest && lastOptionsRequest.resolved) {
          return lastOptionsRequest.response; // did not complete before later request, so return latest 
        }
        throw err;
      })
      .finally(() => {
        if (thisRequest === lastOptionsRequest) {
          showLoading(false);
        }
      });
  }

  function showLoading(loading: boolean) {
    if (loadingTimeout) {
      clearTimeout(loadingTimeout);
      loadingTimeout = undefined;
    }
    if (loading === store.get().loading) return;

    if (loading) {
      loadingTimeout = setTimeout(() => {
        store.update({ loading: true, filteredOptions: [], orderedOptions: [], groupedOptions: [] });
        loadingTimeout = undefined;
      }, 300);  // why 300? See https://lawsofux.com/doherty-threshold/
    } else {
      store.update({ loading: false });
    }
  }

  function filterOptions(options: SelectOption[], filterText: string): SelectOption[] {
    return options.filter((option: SelectOption) => optionMatches(option, filterText));
  }

  function optionMatches(option: SelectOption, filterText: string): boolean {
    return applyFilterPredicate(option, filterText) ?? isMatch(option, filterText);
  }

  function applyFilterPredicate(option: SelectOption, filterText: string): boolean | undefined {
    const { filterPredicate } = context;
    return (typeof filterPredicate === 'function') ?
      filterPredicate(option.option, filterText, {
        isSelected: isIncluded(option, store.get().selectedOptions),
        isOther: option.other
      }) : undefined
  }

  function isMatch(option: SelectOption, filterText: string): boolean {
    const text = filterText.toLowerCase();
    let name = option.name.toLowerCase();
    let matches = (name.includes(text));
    if (!matches) {
      const rx = /[^\w\d\s]|_/g;
      const dashes = ['-', '_', '–', '—'];
      if (rx.test(name) && !rx.test(text)) {
        // if name contains punction and user has not typed punctuation, strip punctuation from name and try again
        const nopunctuation = name.replace(rx, (match) => dashes.includes(match) ? ' ' : '');
        matches = (nopunctuation.includes(text));
      }
    }
    return matches;
  }
}


import { AbstractCellEditor } from "./AbstractCellEditor";
import { CellEditorComponent, CellEditorRenderData } from "./CellEditorComponent";
import { getLocale } from "../../../../utilities/localization";
import { TimeInput } from "../../../../components/time-input/TimeInput";
import { normalizeKey } from "../../../../utilities/keyboard";
import { htmlEncode } from "../../../../utilities/helpers";
import { HourCycle } from "../../../../components/time-input/constants";

export type TimeInputCellEditorOptions = {
  minuteIncrement?: number,
  hourCycle?: HourCycle,
  nopicker?: boolean,
}

export class TimeInputCellEditor extends AbstractCellEditor implements CellEditorComponent {
  options: TimeInputCellEditorOptions;

  constructor(options: TimeInputCellEditorOptions | string = {}) {
    super();
    if (options && typeof options === 'string') {
      try {
        options = JSON.parse(options);
      } catch {
        throw Error('Invalid TimeInputCellEditorOptions options ' + options);
      }
    }
    this.options = options as TimeInputCellEditorOptions;
  }

  focus() {
    const { editorElement } = this;
    const input = editorElement && editorElement.querySelector<HTMLInputElement>('input[data-time-part]');
    if (input) {
      input.focus();
      input.select();
    }
  }

  render({ cell, cellValue, initialValue, action, container, columnHeader }: CellEditorRenderData): void {
    const { minuteIncrement, hourCycle, nopicker } = this.options;
    const { lang } = getLocale(cell);
    const timeParts: any[] = [
      {
        part: 'hour',
        label: lang.hour,
        inputmode: 'numeric',
        pattern: '\\d*'
      },
      {
        part: 'minute',
        label: lang.minute,
        inputmode: 'numeric',
        pattern: '\\d*'
      },
    ]
    if (hourCycle !== '24') {
      timeParts.push({
        part: 'period',
        label: lang.period,
        pattern: 'AM|PM'
      })
    }

    const timeInputs = timeParts.map((entry) => {
      const {part, label, pattern, inputmode } = entry;
      // we initialize each input rather than the hidden input so that, when focused, the input's value is in place
      // and the text selected. If we used the hidden input to initialize, the input field is initialized after
      // focus is called and the input field will not be selected.
      return `
      <span class="tds-time-input__time-part">
        <input 
          type="text" 
          ${ inputmode ? 'inputmode="numeric"' :''} 
          pattern="${pattern}" 
          placeholder="--" 
          maxlength="2" 
          data-time-part="${part}" 
          aria-label="${label}" 
          autocomplete="off" />
      </span>`;

    });

    const trigger = nopicker ? '' : 
      `<button 
        class="tds-time-input__trigger" 
        aria-expanded="false" 
        type="button" 
        data-trigger="dialog" 
        aria-label="${htmlEncode(lang.toggleTimePicker)}" 
      >
      </button>`
    // const dateValidator = validators && validators.find(v => v.type === 'date');
    let dataConfig = '';
    // let dataConfig =  dateValidator ? configureValidator(dateValidator.options || {}) : '';
    if (minuteIncrement) {
      dataConfig += ` data-minute-increment=${minuteIncrement}`;
    }
    if (!hourCycle) {
      dataConfig += ` data-use-locale-cycle`;
    }
    const html = `
    <div class="tds-time-input tds-editable-table__cell-editor" role="group" aria-label="${columnHeader}" ${dataConfig}>
      ${timeInputs.join('')}
      ${trigger}
      <input type="hidden"/>
    </div>`;
    container.innerHTML = html;
    const timeInput = this.editorElement = container.querySelector('div.tds-time-input');
    timeInput.addEventListener('tdsChange', this.reportUpdate);
    timeInput.addEventListener('tdsFocus', () => { this.reportFocusChange(true) });
    timeInput.addEventListener('tdsBlur', () => { this.reportFocusChange(false) });
    timeInput.addEventListener('keydown', this.onTabKey.bind(this));
    // if typing, set value to empty string as the key press will continue to the input element
    const value = typeof initialValue === 'undefined' ? cellValue : (action === 'typing' ? '' : initialValue); 
    if (value) {
      this.setValue(value);
    }
  }

  getValue() {
    const { editorElement } = this;
    const input = editorElement && editorElement.querySelector<HTMLInputElement>('input[type="hidden"]');
    return input ? input.value : '';
  }

  setValue(value: any = '') {
    const { editorElement } = this;
    if (editorElement) {
      new TimeInput(editorElement).value = value;
    }
  }

  getDisplayValue() {
    // this is mainly for displaying an invalid time. When the time is valid, and a formatter is involved,
    // the formatter will format it correctly 
    const { editorElement } = this;
    const inputs = editorElement ? Array.from(editorElement.querySelectorAll<HTMLInputElement>('input[data-time-part]')) : [];
    if (inputs.length === 0) return '';
    let displayValue = inputs[0].value + ':' + inputs[1].value;
    if (inputs[2] && inputs[2].value) {
      displayValue += ' ' + inputs[2].value;
    }
    return displayValue === ':' ? 
      '' // no input values entered
      : displayValue;
  }

  onTabKey(event: KeyboardEvent) {
    const key = normalizeKey(event);
    if (key === 'Tab') {
      const timeInput = this.editorElement
      const controls: HTMLElement[] = Array.from(
        timeInput.querySelectorAll<HTMLElement>('input[data-time-part], button[data-trigger]')
      ).filter(el => !el.hidden);
      const endControl = event.shiftKey ? controls[0] : controls[controls.length - 1];
      if (event.target !== endControl) {
        event.stopPropagation();
      }
    }
  }
}


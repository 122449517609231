
import { createCustomEvent } from '../../utilities/customEvent'
import onDOMChanges from '../../utilities/onDOMChanges';
import { open, close } from './modalBehavior';
import { ModalComponent } from './ModalComponent';
import { ModalState, createStore } from './ModalState';
import { ModalConfig } from './modalConfig';
import { instances } from '../../utilities/instances';
import { Store } from "../../utilities/store";
import { CSS_NS, NAMESPACE } from '../../utilities/constants';
import getTargetElement from '../../utilities/getTargetElement';
import { configFromDataAttributes } from "../../utilities/helpers";
import { ScrollShadow } from '../../utilities/scrollShadow/ScrollShadow';


const MODAL_TRIGGER_ATTR = 'data-trigger-modal'
const ENHANCED_FLAG = 'enhancedModal';
const ENHANCED_DATA_FLAG = 'data-enhanced-modal';
const INSTANCE_KEY = `${NAMESPACE}Modal`;
const MODAL_SELECTOR = `.${CSS_NS}modal`;
const MODAL_DISMISS_SELECTOR = `.${CSS_NS}modal__dismiss`;
const MODAL_BODY_SELECTOR = `.${CSS_NS}modal__dialog-body`


const defaultConfig: ModalConfig = {
  backdropClose: true,
  escapeClose: true,
  modeless: false
}

class _ModalInstance implements ModalComponent {
  cleanUp: Function;
  positioned: boolean;

  host: HTMLElement;
  root: HTMLElement;
  store: Store<ModalState>;
  state: ModalState;

  onDestroy: Function[] = [];
  returnValue: string = '';
  config: ModalConfig = { ...defaultConfig };
  returnTo: Element;
  dismissButtonSelector = MODAL_DISMISS_SELECTOR;
  scrollShadow: ScrollShadow;


  constructor(element: HTMLElement) {
    instances.set(element, INSTANCE_KEY, this);
    this.root = this.host = element;
    const store = this.store = createStore();
    this.onDestroy.push(store.subscribe(this.onStateUpdate.bind(this)));
    const dataConfig = configFromDataAttributes(element);
    const config: any = { backdropClose: !!(element.querySelector(MODAL_DISMISS_SELECTOR)), ...dataConfig };
    this.setConfig(config);
    element.dataset[ENHANCED_FLAG] = "true";
  }

  open(config?: any) {
    if (config) {
      this.setConfig(config);
    }
    this.cleanUp = open(this);
  }

  close(val?: string) {
    close(this, val);
  }

  onStateUpdate(state: ModalState) {
    const { host, root, scrollShadow } = this;
    const { hidden, transitioning } = state;
    this.state = state;
    host.hidden = hidden;
    host.classList[transitioning ? 'add' : 'remove']('transitioning');

    if (!transitioning) {
      if (hidden) {
        if (scrollShadow) {
          scrollShadow.destroy();
          delete this.scrollShadow;
        }
      } else {
        const body = root.querySelector(MODAL_BODY_SELECTOR);
        if (body) {
          this.scrollShadow = new ScrollShadow(body as HTMLElement);
        }
      }
    }
  }

  dispatchEvent(eventType: string, eventInit?: any): boolean {
    return this.host.dispatchEvent(createCustomEvent(eventType, eventInit));
  }

  setConfig(config: ModalConfig) {
    this.config = { ...this.config, ...(config || {}) };
  }

  destroy() {
    if (this.host) {
      const { onDestroy, cleanUp } = this;
      while (onDestroy.length) {
        const fn = onDestroy.pop();
        fn();
      }
      if (cleanUp) {
        cleanUp();
        delete this.cleanUp;
      }
      instances.remove(this.host, INSTANCE_KEY);
      const element = this.host;
      delete element.dataset[ENHANCED_FLAG];
      delete this.host;
      delete this.root;
    }
  }
}

/**
 * @description A class to initialize a modal element
 * @class
 * @param {DomElement} element - the dom element
 * @param {config} object - config
 */

class Modal {
  _instance: _ModalInstance;
  constructor(element: HTMLElement, config?: ModalConfig) {
    this._instance = <_ModalInstance>instances.get(element, INSTANCE_KEY) || new _ModalInstance(element);
    if (config) {
      this._instance.setConfig(config);
    }
  }

  /**
     * Sets or resets the configuration
     * When resetting, only properties included in the config object will be updated
     * @param {object} config
     */
  setConfig(config: any) {
    this._instance.setConfig(config)
  }

  /**
   * Opens (shows) the modal
   */
  open(config?: any) {
    this._instance.open(config);
    return this
  }

  /**
   * Closes the modal
   * @param {*} returnValue The value to set as the returnValue and the value passed in the 'modal.close' event
   */
  close(val?: any) {
    this._instance.close(val)
    return this
  }

  /**
   * Detaches from the nav element, removes event listeners, and frees resources.
   */
  destroy() {
    return this._instance.destroy()
  }

  /**
   * The returnValue passed to close
   */
  get returnValue() {
    return this._instance.returnValue
  }
}

onDOMChanges(`[${ENHANCED_DATA_FLAG}]`,
  null,
  function onModalRemoved(element: HTMLElement) {
    new Modal(element).destroy();
  }
);

document.addEventListener('click', function (e: any) {
  const trigger = e.target.closest(`[${MODAL_TRIGGER_ATTR}]`)
  const modalEl = trigger && getTargetElement(trigger, MODAL_TRIGGER_ATTR);
  if (modalEl && modalEl.matches(MODAL_SELECTOR)) {
    new Modal(modalEl).open({ trigger: trigger });
    if (trigger.tagName === 'A' || trigger.tagName === 'AREA') {
      e.preventDefault();
    }
  }
})

export { Modal }

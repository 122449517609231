import { toISODate, parseDate } from "../../../../utilities/date-utils";
import { AbstractCellEditor } from "./AbstractCellEditor";
import { CellEditorComponent, CellEditorRenderData } from "./CellEditorComponent";
import { getLocale } from "../../../../utilities/localization";
import { DateInput } from "../../../../components/date-input/DateInput";
import { normalizeKey } from "../../../../utilities/keyboard";
import { htmlEncode } from "../../../../utilities/helpers";

export type DateInputCellEditorOptions = {
  notoday?: boolean
  nopicker?: boolean
}

export class DateInputCellEditor extends AbstractCellEditor implements CellEditorComponent {
  options: DateInputCellEditorOptions;

  constructor(options: DateInputCellEditorOptions | string = {}) {
    super();
    if (options && typeof options === 'string') {
      try {
        options = JSON.parse(options);
      } catch {
        throw Error('Invalid DateInputCellEditorOptions options' + options);
      }
    }
    this.options = options as DateInputCellEditorOptions;
  }

  focus() {
    const { editorElement } = this;
    const input = editorElement && editorElement.querySelector<HTMLInputElement>('input[data-date-part]');
    if (input) {
      input.focus();
      input.select();
    }
  }

  render({ cell, cellValue, initialValue, action, container, columnHeader, validators }: CellEditorRenderData): void {
    const { nopicker, notoday } = this.options;
    const { format, lang } = getLocale(cell);
    const datePartMap: any = {
      'YYYY': {
        part: 'year',
        label: lang.year,
        value: ''
      },
      'MM': {
        part: 'month',
        label: lang.month,
        value: ''
      },
      'DD': {
        part: 'day',
        label: lang.day,
        value: ''
      }
    }

    // if typing, set value to empty string as the key press will continue to the input element
    const valueToParse = typeof initialValue === 'undefined' ? cellValue : (action === 'typing' ? '' : initialValue); 
    const values = this.toDatePartArray(valueToParse, format);
    datePartMap.YYYY.value = values[0] || '';
    datePartMap.MM.value = values[1] || '';
    datePartMap.DD.value = values[2] || '';

    const dateParts = format.split('/').map((part: string) => {
      const entry = datePartMap[part];
      const value = entry.value;
      // we initialize each input rather than the hidden input so that, when focused, the input's value is in place
      // and the text selected. If we used the hidden input to initialize, the input field is initialized after
      // focus is called and the input field will not be selected.
      return `
      <span class="tds-date-input__date-part">
        <input type="text" inputmode="numeric" pattern="\\d*" placeholder="${part.toLowerCase()}" maxlength="${part.length}"
          data-date-part="${entry.part}" aria-label="${htmlEncode(entry.label)}" ${value ? `value="${htmlEncode(value)}"` : ''}/>
        </span>`;
    });

    const trigger = nopicker ? '' : `
    <button class="tds-date-input__trigger" aria-expanded="false" type="button" data-trigger="calendar"
    aria-label="${htmlEncode(lang.calendar)}" hidden></button>`;
    const dateValidator = validators && validators.find(v => v.type === 'date');
    let dataConfig =  dateValidator ? configureValidator(dateValidator.options || {}) : '';
    dataConfig += ' data-optimized-popover';
    if (notoday) {
      dataConfig += ' data-no-today'
    }
    const html = `
    <div class="tds-date-input tds-editable-table__cell-editor" role="group" ${dataConfig}>
      ${dateParts.join('')}
      ${trigger}
      <input type="hidden"/>
    </div>`;
    container.innerHTML = html;
    const dateInput = this.editorElement = container.querySelector('div.tds-date-input');
    dateInput.setAttribute('aria-label', columnHeader);
    dateInput.addEventListener('tdsChange', this.reportUpdate);
    dateInput.addEventListener('tdsFocus', () => { this.reportFocusChange(true) });
    dateInput.addEventListener('tdsBlur', () => { this.reportFocusChange(false) });
    dateInput.addEventListener('keydown', this.onTabKey.bind(this));
  }

  getValue() {
    const { editorElement } = this;
    const input = editorElement && editorElement.querySelector<HTMLInputElement>('input[type="hidden"]');
    return input ? input.value : '';
  }

  setValue(value: any = '') {
    const { editorElement } = this;
    if (editorElement) {
      new DateInput(editorElement).value = value;
    }
  }

  getDisplayValue() {
    // this is mainly for displaying an invalid date. When the date is valid, and a formatter is involved,
    // the formatter will format it correctly 
    const { editorElement } = this;
    const inputs = editorElement ? Array.from(editorElement.querySelectorAll<HTMLInputElement>('input[data-date-part]')) : [];
    const displayValue = inputs.map(input => input.value).join('/');
    return displayValue === '//' ? 
      '' // no input values entered
      : displayValue;
  }

  toDatePartArray(value: any, format: string): string[] {
    const dblDashRx = /^([^-]*)-([^-]*)-([^-]*)$/
    value = value instanceof Date ? toISODate(value) : value.toString();
    if (value && !value.match(dblDashRx)) {
      const date = parseDate(value, format);
      value = date ? toISODate(date) : '';
    }
    return value.split('-');
  }
  
  onTabKey(event: KeyboardEvent) {
    const key = normalizeKey(event);
    if (key === 'Tab') {
      const dateInput = this.editorElement
      const controls = dateInput.querySelectorAll('input[data-date-part], button[data-trigger="calendar"]');
      const endControl = event.shiftKey ? controls[0] : controls[controls.length - 1];
      if (event.target !== endControl) {
        event.stopPropagation();
      }
    }
  }
}

function configureValidator(options: any): string {
  let { max, min, disabledDates } = options;
  if ( min instanceof Date) {
    min = toISODate(min);
  }
  if ( max instanceof Date) {
    max = toISODate(max);
  }
  if ( typeof disabledDates === 'object') {
    disabledDates = JSON.stringify(disabledDates);
  }

  let dataConfig: string[] = []

  if (min) {
    dataConfig.push(`data-min="${min}"`);
  }
  if (max) {
    dataConfig.push(`data-max="${max}"`);
  }
  if (disabledDates) {
    dataConfig.push(`data-disabled-dates='${disabledDates}'`); // use sinle quote in case it is JSON
  }
  return dataConfig.join(' ');
}


import { TimeInputComponent } from './TimeInputComponent';
import { TimeInputState, createStore } from './TimeInputState';
import { bindTimeInput, setDateValue } from './timeInputBehavior';
import { CSS_NS, NAMESPACE } from '../../utilities/constants';
import { createCustomEvent } from '../../utilities/customEvent';
import onDOMChanges from '../../utilities/onDOMChanges';
import { instances } from '../../utilities/instances';
import { Store } from '../../utilities/store';
import getTargetElement from '../../utilities/getTargetElement';
import { configFromDataAttributes } from '../../utilities/helpers';
import { TimeInputConfig } from './TimeInputConfig';
import { PART_ATTR, TRIGGER_SELECTOR, TimeParts, HourCycle } from './constants';
import { TimePicker } from './time-picker/TimePicker';
import {
  getValidityState,
  getValueAsDate,
  getCycle,
  getValue,
  getDisplayValue,
} from './utils';
import { getLang } from '../../utilities/i18n';

const DIALOG_CLASS = `${CSS_NS}time-picker`;
const TIME_INPUT_CLASS = `${CSS_NS}time-input`;
const WITH_DIALOG_CLASS = `${TIME_INPUT_CLASS}--with-dialog`;
const DIALOG_OPEN_CLASS = `${TIME_INPUT_CLASS}--dialog-open`;
const HAS_FOCUS_CLASS = `${TIME_INPUT_CLASS}--focus-within`;
const INSTANCE_KEY = `${NAMESPACE}TimeInput`;

let nextId = 1;

class _TimeInputInstance implements TimeInputComponent {
  el: HTMLElement;
  state: TimeInputState;
  store: Store<TimeInputState>;
  _dialog: HTMLElement;
  config: TimeInputConfig;

  onDestroy: Function[] = [];

  constructor(element: HTMLElement) {
    this.el = element;
    this.setConfig(
      configFromDataAttributes(element, {}, [
        { names: ['minuteIncrement'], convert: 'integer' },
      ])
    );
    this.store = createStore(element, this.cycle);
    element.dataset.enhancedTime = 'true';
    // it is important that subscribe occurs before bind so that, when behavior throws a change event
    // when the store updates, this module will have the current state when calling get value or valueAsDate
    const unsubscribe = this.store.subscribe(this.onStateUpdate.bind(this));
    const unbind = bindTimeInput(this);
    this.onDestroy = [unbind, unsubscribe];
    instances.set(element, INSTANCE_KEY, this);

    const trigger: HTMLElement = element.querySelector(TRIGGER_SELECTOR);
    if (trigger) {
      trigger.hidden = false;
      element.classList.add(WITH_DIALOG_CLASS);
    }
    const dialogEl =
      (trigger && getTargetElement(trigger)) ||
      element.querySelector(`.${DIALOG_CLASS}`);
    if (dialogEl) {
      dialogEl.hidden = true;
    }
  }

  get dialog(): HTMLElement {
    return this._dialog || (this._dialog = getDialogElement(this));
  }

  get cycle(): HourCycle {
    return this.config.useLocaleCycle
      ? getCycle(this.el)
      : !!this.el.querySelector(`[${PART_ATTR}=${TimeParts.Period}]`)
      ? '12'
      : '24';
  }

  setConfig(config: TimeInputConfig = {}) {
    const currentConfig = this.config || {};
    this.config = this.configureRequired({
      ...currentConfig,
      ...config,
    });
  }

  configureRequired(config: TimeInputConfig): TimeInputConfig {
    const inputs = Array.from(
      this.el.querySelectorAll<HTMLInputElement>(`[${PART_ATTR}]`)
    );
    // this logic accounts for required being set to false afterwards
    // assuming if any input is required, the date is required. Not getting into complexity of only some inputs required
    const required =
      typeof config.required === 'boolean'
        ? config.required
        : !!inputs.find((input) => input.required);
    inputs.forEach((input) => (input.required = required));
    return { ...config, required };
  }

  get value() {
    return getValue(this);
  }

  set value(value) {
    setDateValue(value || '', this);
  }

  get valueAsDate() {
    return getValueAsDate(this);
  }

  get isValid() {
    return getValidityState(this, getDisplayValue(this)).valid;
  }

  get validity() {
    return getValidityState(this, getDisplayValue(this));
  }

  toReadableTime() {
    return this.valueAsDate
      ? new Intl.DateTimeFormat(getLang(this.el), {
          hour: '2-digit',
          minute: '2-digit',
          hourCycle: this.cycle === '12' ? 'h12' : 'h23',
        }).format(this.valueAsDate)
      : null;
  }

  onStateUpdate(state: TimeInputState) {
    const { el } = this;
    const prevState = this.state;
    this.state = state;
    const trigger: HTMLElement = this.el.querySelector(TRIGGER_SELECTOR);
    const dialog = this.dialog;
    if (dialog) {
      dialog.hidden = state.dialogOpenState === 'closed';
      dialog.dataset.dialogOpenState = state.dialogOpenState;
    }
    const expanded = `${
      state.dialogOpenState === 'opening' || state.dialogOpenState === 'opened'
    }`;
    if (trigger) {
      if (expanded !== trigger.getAttribute('aria-expanded')) {
        trigger.setAttribute('aria-expanded', expanded);
      }
    }
    el.classList[expanded === 'true' ? 'add' : 'remove'](DIALOG_OPEN_CLASS);
    el.classList[state.hasFocus ? 'add' : 'remove'](HAS_FOCUS_CLASS);
    const parts = Object.values(TimeParts);
    parts.forEach((part: string) => {
      const input = <HTMLInputElement>(
        el.querySelector(`[${PART_ATTR}="${part}"]`)
      );
      const value = (<any>state)[`${part}Value`];
      const prevValue = (<any>prevState)?.[`${part}Value`];
      if (value !== prevValue && input && input.value !== value) {
        input.value = value;
      }
    });

    const validity = getValidityState(this, getDisplayValue(this));
    const validityErrors = (Object.keys(validity) as (keyof typeof validity)[])
      .filter((key) => key !== 'valid' && validity[key])
      .join();
    if (validityErrors) {
      el.dataset.validity = validityErrors;
    } else {
      el.removeAttribute('data-validity');
    }
  }

  sendEvent(eventType: string, eventInit: any): boolean {
    return this.el.dispatchEvent(createCustomEvent(eventType, eventInit));
  }

  destroy() {
    while (this.onDestroy && this.onDestroy.length) {
      const fn = this.onDestroy.pop();
      fn();
    }
    if (this.el) {
      delete this.el.dataset.enhancedTime;
    }
    instances.remove(this.el, INSTANCE_KEY);
    this.el = this.state = this.store = this._dialog = this.config = null;
  }
}
/////////////////////////////////////////////////////////////////
// - helper functions

function getDialogElement(timeInput: TimeInputComponent): HTMLElement {
  const container = timeInput.el;
  let dialogEl: HTMLElement = null;
  let trigger: HTMLElement = container.querySelector(TRIGGER_SELECTOR);
  if (trigger) {
    dialogEl =
      getTargetElement(trigger) || container.querySelector(`.${DIALOG_CLASS}`);
    if (!dialogEl) {
      dialogEl = document.createElement('div');
      new TimePicker(
        dialogEl,
        {
          cycle: timeInput.cycle,
          minuteIncrement: timeInput.config.minuteIncrement,
        },
        timeInput.store
      );
      container.appendChild(dialogEl);
    }
    if (!dialogEl.id) {
      dialogEl.id = container.id
        ? `${container.id}__${DIALOG_CLASS}`
        : `${DIALOG_CLASS}-${nextId++}`;
    }
    trigger.setAttribute('aria-controls', dialogEl.id);
    dialogEl.hidden = true;
  }
  return dialogEl;
}

//////////////////////////////////////////////////////////////////////////////////////////////////////////

onDOMChanges(
  `.${TIME_INPUT_CLASS}`,
  function onTimeInputAdded(element: HTMLElement) {
    if (!element.dataset.enhancedTime) {
      new _TimeInputInstance(element);
    }
  },
  function onTimeInputRemoved(element: HTMLElement) {
    if (element.dataset.enhancedTime === 'true') {
      new TimeInput(element).destroy();
    }
  }
);

// ////////////////////////////////////////////////////////////////////////////////////////////////////////

class TimeInput {
  _instance: _TimeInputInstance;
  constructor(element: HTMLElement, config?: any) {
    this._instance =
      <_TimeInputInstance>instances.get(element, INSTANCE_KEY) ||
      new _TimeInputInstance(element);
    if (config) {
      this.setConfig(config);
    }
  }

  setConfig(config: any) {
    this._instance.setConfig(config);
  }

  get value() {
    return this._instance.value;
  }

  set value(date) {
    this._instance.value = date;
  }

  get valueAsDate() {
    return this._instance.valueAsDate;
  }

  get isValid() {
    return this._instance.isValid;
  }

  get validity() {
    return this._instance.validity;
  }

  toReadableTime() {
    return this._instance.toReadableTime();
  }

  destroy() {
    if (this._instance) {
      this._instance.destroy();
      delete this._instance;
    }
  }
}

export { TimeInput };

import { EventListeners } from "../../../utilities/EventListeners";
import { normalizeKey } from "../../../utilities/keyboard";
import { SelectContext } from "../SelectContext";
import { SelectState } from "../SelectState";

interface ListBoxContext {
  listbox: HTMLElement
  multiple: boolean
  selectContext: SelectContext
  controller: HTMLElement
}
export function bindListbox(listBoxContext: ListBoxContext) {
  const { listbox, controller } = listBoxContext;
  let state: SelectState
  const unsub = listBoxContext.selectContext.store.subscribe(s => state = s);
  const eventListeners = new EventListeners();
  eventListeners.addListener(listbox, 'click', onClick);
  eventListeners.addListener(listbox, 'mousedown', onMousedown);
  eventListeners.addListener(controller, 'keydown', onKeydown);

  function unbind() {
    unsub();
    eventListeners.removeListeners();
  }

  function onMousedown(event: MouseEvent) {
    if (event.shiftKey && listBoxContext.multiple) {
      // prevent selection of text when shift key pressed for group selection
      event.preventDefault();
    }
  }

  function onClick(event: MouseEvent) {
    const optionSelector = '[role="option"]';
    const optionEl = (event.target as HTMLElement).closest<HTMLElement>(optionSelector);
    if (!optionEl) return;
    const optionEls = Array.from(listBoxContext.listbox.querySelectorAll<HTMLElement>(optionSelector));
    const optionIndex = optionEls.indexOf(optionEl); 
    const { orderedOptions } = state;
    const option = orderedOptions[optionIndex]
    const activeIndex = state.activeIndex; // save this before updating state
    const context = listBoxContext.selectContext;
    context.store.update({ activeIndex: optionIndex });
    if (event.shiftKey && listBoxContext.multiple) {
      context.selectionActions.selectRange(Math.max(activeIndex || 0, 0), option);
    } else {
      context.selectionActions.updateOption(option);
      checkClose();
    }  
  }

  function onKeydown(event: KeyboardEvent) {
    const key = normalizeKey(event);
    const target = event.target as HTMLElement;
    const inputEl = target.matches('input') ? target as HTMLInputElement : null;
    if (inputEl && inputEl.value && ['ArrowDown', 'ArrowUp', 'Enter'].indexOf(key) === -1) {
      return; // if target is input and it's not one of the above keys, ignore
    }
    const { altKey, shiftKey, ctrlKey } = event;
    const { multiple, selectContext } = listBoxContext;
    const { activeIndex, orderedOptions } = state;
    let updateActiveIndex = activeIndex;
    const noOfOptions = orderedOptions ? orderedOptions.length : 0;
    const actions = selectContext.selectionActions;
    switch (key) {
      case 'ArrowDown':
        event.preventDefault();
        if (activeIndex < noOfOptions - 1) {
          updateActiveIndex = activeIndex + 1;
          if (multiple && shiftKey) {
            actions.shiftArrow(true);
          }
        }
        break;

      case 'ArrowUp':
        event.preventDefault();
        if (activeIndex > 0 && !altKey) {
          updateActiveIndex = activeIndex - 1;
          if (multiple && shiftKey) {
            actions.shiftArrow(false);
          }
        }
        break;

      case 'Home':
        event.preventDefault();
        updateActiveIndex = 0;
        if (multiple && shiftKey) {
          actions.selectRange(0, activeIndex);
        }
        break;

      case 'End':
        event.preventDefault();
        updateActiveIndex = noOfOptions - 1;
        if (multiple && shiftKey) {
          actions.selectRange(activeIndex, noOfOptions - 1);
        }
        break;

      case 'Enter':
      case ' ':
        if (target.matches('button')) {
          return;
        }
        if (activeIndex > -1 && !(event.defaultPrevented && key === 'Enter')) { // enter not handled
          actions.onEnter(shiftKey);
          event.preventDefault();
          event.stopPropagation();
          event.stopImmediatePropagation();
          checkClose();
        }
        break;

      case 'a':
      case 'A':
        if (multiple && ctrlKey) {
          event.preventDefault();
          actions.toggleSelectAll();
          break;
        }
    }

    if (updateActiveIndex !== activeIndex) {
      selectContext.store.update({activeIndex: updateActiveIndex});
    }
  }

  function checkClose() {
    const { multiple, selectContext } = listBoxContext;
    if (!multiple) {
      selectContext.hideOptionsPopup(true); 
    }
  }

  return unbind;

}

import onDOMChanges from '../../../utilities/onDOMChanges';
import { bindEditing } from './editableDataTableBehavior';
import { DATA_TABLE_COLUMN_ROW_PROP_ATTR, DATA_TABLE_ROW_ID_ATTR, EDIT_MODE_SELECTOR } from './editableDataTableConstants';
import { CellData, EditableDataTableComponent, RowData, RowUpdateValidation } from './EditableDataTableComponent';
import { createCustomEvent } from '../../../utilities/customEvent';
import { getChildElements, getLabelledByLabel } from '../../../utilities/helpers';
import { cellToTextValue, getColumnHeader, getColumnIndex, isEditableRow, isRowGroupHeaderRow, textToCellNodes } from './utils';
import { CellEditorComponent } from './editors/CellEditorComponent';
import { CellValidator } from './validators/CellValidator';
import { EditableTableValidity, EditModeManager } from './EditModeManager';
import cellEditorFactory from './editors/cellEditorFactory';
import { validatorsFactory } from './validators/validatorsFactory';
import { DateInputCellEditor } from './editors/DateInputCellEditor';
import { TimeInputCellEditor } from './editors/TimeInputCellEditor';

const instances = new WeakMap();
const ENHANCED_EDITABLE_FLAG = 'enhancedEditableDataTable';
const ENHANCED_EDITABLE_DATA_ATTR = 'data-enhanced-editable-data-table';

class _EditableDataTableInstance implements EditableDataTableComponent {
  table: HTMLTableElement;
  onDestroy: Function[] = [];
  startCellEdit: (cell: HTMLTableCellElement) => void;
  startRowEdit: (row: HTMLTableRowElement) => void;
  onCellEditorCreated: (editor: HTMLElement, cellData: CellData) => void;
  customCellEditor: CellEditorComponent | ((cellData: CellData) => CellEditorComponent | null);
  cellValidator: CellValidator;
  valueGetter: (cellData: CellData) => any | void;
  applyRowUpdate: (rowData: RowData, rowUpdates: any) => RowUpdateValidation[] | Promise<RowUpdateValidation[] | void> | void;
  applyRowDelete: (rowData: RowData) => boolean | Promise<boolean>;
  editModeManager: EditModeManager;
  cellEditors: {[columnName: string]: {editorControl: string, editorOptions?: any}};
  validity: EditableTableValidity;
  _lastRowUpdatesApplied: any;

  constructor(element: HTMLTableElement) {
    element.dataset[ENHANCED_EDITABLE_FLAG] = "true";
    this.table = element;
    this.validity = {valid: true, cellValidities: []};
    const { unbind, startCellEdit, startRowEdit } = bindEditing(this);
    this.onDestroy = [unbind];
    this.startCellEdit = startCellEdit;
    this.startRowEdit = startRowEdit;
    instances.set(element, this);
  }

  get editMode() {
    const { table } = this;
    return (table && table.dataset.editMode) || 'cell';
  }

  set editMode(mode: string) {
    const { table } = this;
    if (mode) {
      table.dataset.editMode = mode;
    } else {
      table.removeAttribute('data-edit-mode');
    }
  }

  get editValidationMode() {
    const { table } = this;
    return (table && table.dataset.editValidationMode) || '';
  }

  set editValidationMode(mode: string) {
    const { table } = this;
    if (mode) {
      table.dataset.editValidationMode = mode;
    } else {
      table.removeAttribute('data-edit-validation-mode');
    }
  }

  dispatchEvent(eventType: string, detail: any): boolean {
    return this.table.dispatchEvent(createCustomEvent(eventType, { cancelable: true, bubbles: true, detail }))
  }

  getCellValue(cell: HTMLTableCellElement, cellData?: CellData): any {
    let value = this.valueGetter && this.valueGetter(cellData || this.getCellData(cell));
    if (value === null || typeof value === 'undefined') {
      value = cellToTextValue(cell);
    }
    return value;
  }

  setCellValue(cell: HTMLTableCellElement, value: any, displayValue: any = value) {
    textToCellNodes(cell, value, displayValue);
  }

  getValueSetter(): (cellData: CellData, value: any, displayValue: any ) => any {
    return null;
  }

  processRowUpdate(rowData: RowData, rowUpdates: any) : Promise<RowUpdateValidation[] | void> {
    const { applyRowUpdate } = this;
    this._lastRowUpdatesApplied = rowUpdates;
    try {
      return Promise.resolve(applyRowUpdate ? applyRowUpdate(rowData, rowUpdates) : []);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  getCellEditorComponent(cell: HTMLTableCellElement): CellEditorComponent {
    const column = getColumnHeader(cell);
    const columnName = (column && column.getAttribute(DATA_TABLE_COLUMN_ROW_PROP_ATTR)) || getColumnIndex(cell).toString();
    const cellEditor = (this.cellEditors && this.cellEditors[columnName]) || {} as {editorControl: string, editorOptions?: any};
    const editorControl = cellEditor.editorControl || cell.dataset.editorControl || column.dataset.editorControl;
    const editorOptions = cellEditor.editorOptions || cell.dataset.editorOptions || column.dataset.editorOptions;
    let editor: CellEditorComponent;
    switch (editorControl) {
      case 'custom':
        editor = this.getCustomCellEditor(this.getCellData(cell));
        break;
      case 'date':
        editor = new DateInputCellEditor(editorOptions);
        break;
      case 'time':
        editor = new TimeInputCellEditor(editorOptions);
        break;
    }
    return editor || cellEditorFactory({ editorControl, editorOptions });
  }

  getCustomCellEditor(cellData: CellData): CellEditorComponent {
    let { customCellEditor } = this;
    if (typeof customCellEditor === 'function') {
      customCellEditor = customCellEditor(cellData);
    }
    return customCellEditor;
  }

  getCellValidators(cell: HTMLTableCellElement): CellValidator[] {
    const column = getColumnHeader(cell);
    const dataset = { ...column.dataset, ...cell.dataset };
    const prefix = 'validator';
    const config = Object.keys(dataset).reduce((acc: any, key) => {
      const name = key.toLowerCase();
      if (name.indexOf(prefix) === 0) {
        acc[name.substring(prefix.length)] = dataset[key];
      }
      return acc;
    }, {});

    const validators = validatorsFactory(config);
    if (this.cellValidator) {
      validators.push(this.cellValidator);
    }
    return validators;
  }

  getCellData(cell: HTMLTableCellElement): CellData {
    const row = cell.closest('tr') as HTMLTableRowElement;
    const columnIndex = getColumnIndex(cell);
    const colHdr = getColumnHeader(cell);
    const columnHeader = (colHdr && (colHdr.getAttribute('aria-label') || getLabelledByLabel(colHdr) || colHdr.textContent.trim())) || '';
    const columnName = (colHdr && colHdr.getAttribute(DATA_TABLE_COLUMN_ROW_PROP_ATTR)) || columnIndex.toString();
    return {
      ...this.getRowData(row),
      columnName,
      columnIndex,
      columnHeader,
      cell
    }
  }

  getRowData(rowEl: HTMLElement): RowData {
    const row = rowEl.closest('tr') as HTMLTableRowElement;
    let rowGroupIndex = -1;
    // check if row groups are involved
    const tbody = row.closest('tbody');
    const firstRow = tbody.firstElementChild as HTMLTableRowElement;
    const tbodies = getChildElements(tbody.parentElement, 'tbody');
    if (tbodies.length && isRowGroupHeaderRow(firstRow)) {
      rowGroupIndex = tbodies.indexOf(tbody);
    }
    const rows = (row ? getChildElements(row.parentElement) : [])
      .filter((row: HTMLTableRowElement) => isEditableRow(row));
    const rowIndex = rows.indexOf(row);
    const rowId = (row && row.getAttribute(DATA_TABLE_ROW_ID_ATTR)) || rowIndex.toString();
    const rowItem = this.getRowItemFromRow(row, rowId);
    return {
      rowId,
      rowIndex,
      rowGroupIndex,
      row,
      rowItem
    }
  }

  getRowItemFromRow(row: HTMLTableRowElement, rowId: any): any {
    const cells = getChildElements(row) as HTMLTableCellElement[];
    return cells.reduce((acc: any, cell) => {
      const colHdr = getColumnHeader(cell);
      const columnName = (colHdr && colHdr.getAttribute(DATA_TABLE_COLUMN_ROW_PROP_ATTR)) || getColumnIndex(cell).toString();
      acc[columnName] = cellToTextValue(cell);
      return acc;
    }, { rowId: rowId });
  }

  getValidity() {
    return this.validity;
  }

  setValidity(validity: EditableTableValidity) {
    this.validity = validity;
  };

  deleteRow(rowData: RowData): Promise<boolean> {
    const { applyRowDelete } = this;
    return Promise.resolve(applyRowDelete ? applyRowDelete(rowData) : true)
      .then((ok: boolean) => {
        const { row } = rowData;
        if (ok && row.parentNode) {
          row.parentNode.removeChild(row);
        }
        return ok;
      });
  }

  beginEditing(startingCell: HTMLTableCellElement, initialValue?: string, action?: string) {
    this._lastRowUpdatesApplied = undefined;
    const mgr = this.editModeManager = new EditModeManager(startingCell, this);
    mgr.begin(initialValue, action);
  }

  endEditing(save: boolean): Promise<boolean> {
    const { editModeManager } = this;
    return editModeManager ?
      editModeManager.end(save).then(ended => {
        if (ended) {
          delete this.editModeManager;
        }
        return ended;
      }) : Promise.resolve(true);
  }

  hasChanged(): boolean {
    const { editModeManager } = this;
    return !!editModeManager && editModeManager.hasChanged();
  }

  getUpdatesApplied(): any {
    return this._lastRowUpdatesApplied;
  }

  isClickOutside(event: MouseEvent): boolean {
    const { editModeManager } = this;
    return !!editModeManager && editModeManager.isClickOutside(event);
  }

  isTabbingAway(event: KeyboardEvent): boolean {
    const { editModeManager } = this;
    return !!editModeManager && editModeManager.isTabbingAway(event);
  }

  destroy() {
    while (this.onDestroy.length) {
      const fn = this.onDestroy.pop();
      fn();
    }
    if (this.table) {
      delete this.table.dataset[ENHANCED_EDITABLE_FLAG];
    }
    instances.delete(this.table);
    this.table = null;
  }
}


class EditableDataTable {
  _instance: _EditableDataTableInstance;
  constructor(element: HTMLTableElement) {
    this._instance = <_EditableDataTableInstance>instances.get(element) || new _EditableDataTableInstance(element);
  }

  get editMode(): string {
    return this._instance && this._instance.editMode;
  }

  set editMode(mode: string) {
    this._instance && (this._instance.editMode = mode);
  }

  get editValidationMode(): string {
    return this._instance && this._instance.editValidationMode;
  }

  set editValidationMode(mode: string) {
    this._instance && (this._instance.editValidationMode = mode);
  }

  get customCellEditor(): CellEditorComponent | ((cellData: CellData) => CellEditorComponent | null) {
    return this._instance && this._instance.customCellEditor;
  }

  set customCellEditor(editor: CellEditorComponent | ((cellData: CellData) => CellEditorComponent | null)) {
    this._instance && (this._instance.customCellEditor = editor);
  }

  get valueGetter(): (cellData: CellData) => any | void {
    return this._instance && this._instance.valueGetter;
  }

  set valueGetter(getter: (cellData: CellData) => any | void) {
    this._instance && (this._instance.valueGetter = getter);
  }

  get cellValidator(): CellValidator {
    return this._instance && this._instance.cellValidator;
  }

  set cellValidator(validator: CellValidator) {
    this._instance && (this._instance.cellValidator = validator);
  }

  get applyRowUpdate(): (rowData: RowData, rowUpdates: any) => RowUpdateValidation[] | Promise<RowUpdateValidation[] | void> | void {
    return this._instance && this._instance.applyRowUpdate;
  }

  set applyRowUpdate(applyRowUpdate: (rowData: RowData, rowUpdates: any) => RowUpdateValidation[] | Promise<RowUpdateValidation[] | void> | void) {
    this._instance && (this._instance.applyRowUpdate = applyRowUpdate)
  }

  get applyRowDelete(): (rowData: RowData) => boolean | Promise<boolean> {
    return this._instance && this._instance.applyRowDelete;
  }

  set applyRowDelete(applyRowDelete: (rowData: RowData) => boolean | Promise<boolean>) {
    this._instance && (this._instance.applyRowDelete = applyRowDelete)
  }

  get validity() : EditableTableValidity {
    return this._instance && this._instance.getValidity();
  }

  configureCellEditor(columnName: string, editorControl: string, editorOptions?: any) {
    const {_instance} = this;
    if (_instance) {
      const cellEditors = _instance.cellEditors || {};
      _instance.cellEditors = {...cellEditors, [columnName]: {editorControl, editorOptions}};
    }
  }

  onCellEditorCreated(callback: (editor: HTMLElement, cellData: CellData) => void) {
    this._instance && (this._instance.onCellEditorCreated = callback);
  }

  /**
   * Initiates cell editing for the cell.
   * @param cell The cell to put into edit mode
   */
  startCellEdit(cell: HTMLTableCellElement) {
    this._instance && this._instance.startCellEdit(cell);
  }

  /**
   * Initiates cell editing for the row. Edit mode must be 'row'.
   * @param row The row to put into edit mode 
   */
  startRowEdit(row: HTMLTableRowElement) {
    this._instance && this._instance.startRowEdit(row);
  }

  deleteRow(row: HTMLTableRowElement): Promise<boolean> {
    const { _instance } = this;
    return Promise.resolve(!!_instance && _instance.deleteRow(_instance.getRowData(row)));
  }

  /**
   * Detaches from the nav element, removes event listeners, and frees resources.
   */

  destroy() {
    const { _instance } = this;
    delete this._instance;
    return _instance.destroy();
  }
}

onDOMChanges(EDIT_MODE_SELECTOR,
  function onDataTableAdded(element: HTMLElement) {
    const table = element.closest('table');
    if (table && table.dataset[ENHANCED_EDITABLE_FLAG] !== "true") {
      new EditableDataTable(table);
    }
  });

onDOMChanges(`table[${ENHANCED_EDITABLE_DATA_ATTR}]`,
  null,
  function onDataTableRemove(element: HTMLElement) {
    new EditableDataTable(element as HTMLTableElement).destroy();
  });

export { EditableDataTable }
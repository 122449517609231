import { hasShadowDom } from './helpers';

export const TAB_TRAP_ATTRIBUTE = 'data-dialog-tab-trap';

export function containTabFocus(element: HTMLElement) {
  const tabTraps = getTabTraps(element);
  const tabTrapFocusHandler = onTabTrapFocus(element, tabTraps);
  tabTraps.forEach(tabTrap => tabTrap.addEventListener('focus', tabTrapFocusHandler));
  return function uncontainTabFocus() {
    tabTraps.forEach(tabTrap => tabTrap.removeEventListener('focus', tabTrapFocusHandler));
  }
}

const FOCUSABLE_ELEMENTS = [
  'a[href]:not([tabindex^="-"]):not([inert])',
  'area[href]:not([tabindex^="-"]):not([inert])',
  'input:not([disabled]):not([inert])',
  'select:not([disabled]):not([inert])',
  'textarea:not([disabled]):not([inert])',
  'button:not([disabled]):not([inert])',
  'iframe:not([tabindex^="-"]):not([inert])',
  'audio:not([tabindex^="-"]):not([inert])',
  'video:not([tabindex^="-"]):not([inert])',
  '[contenteditable]:not([tabindex^="-"]):not([inert])',
  `[tabindex]:not([tabindex^="-"]):not([inert]):not([${TAB_TRAP_ATTRIBUTE}])`
];

export function getFocusableChildren(element: Element) {
  // This logic only checks for shadow root elements one level deep.
  // If a shadow root has an element with a shadow DOM, those won't be picked up
  // Also does not account for the possibility that slotted children may be placed 
  // in a different order, but not sure this changes the tab order anyhow.
  const shadowRoots: any[] = Array.from(element.querySelectorAll('*')).concat([element])
    .filter(el => hasShadowDom(el))
    .map(el => el.shadowRoot);
  const rootElements = [element].concat(shadowRoots);
  const focusables = rootElements.reduce((arr, root) => {
    return arr.concat(Array.from(root.querySelectorAll(FOCUSABLE_ELEMENTS.join(','))));
  }, []);

  return focusables
    .filter(child => {
      return !!(
        child.offsetWidth ||
        child.offsetHeight ||
        child.getClientRects().length
      ) && getComputedStyle(child).visibility !== 'hidden'
    });
}

export function getConfigElement(id_selector_or_node: any, context: Document | Element) {
  return id_selector_or_node && (id_selector_or_node instanceof HTMLElement ? id_selector_or_node : context.querySelector(id_selector_or_node) || document.getElementById(id_selector_or_node));
}

export function focus(el: HTMLElement, dlg: HTMLElement) {
  let focusOn = el
  // if focusing on a radio button, focus on the selected radio button
  if (el.getAttribute('type') === 'radio' && !(<HTMLInputElement>el).checked) {
    const name = el.getAttribute('name');
    focusOn = dlg.querySelector(`input[name="${name}"]:checked`) || dlg.querySelector(`input[name="${name}"]`) || el;
  }
  if (focusOn.focus) {
    focusOn.focus();
  }
}

export function createTabTrap(): HTMLElement {
  const span = document.createElement('span');
  span.innerHTML = tabTrapHTML();
  return span.firstElementChild as HTMLElement;
}

export function tabTrapHTML() {
  return `<span ${TAB_TRAP_ATTRIBUTE} tabindex="0" style="height: 1px; width: 1px; position: absolute; overflow:hidden"></span>`
}

// ===============================================================================

function getTabTraps(element: HTMLElement): HTMLElement[] {
  let parent: Element | ShadowRoot = element;
  let tabTraps: HTMLElement[] = Array.from(parent.querySelectorAll(`[${TAB_TRAP_ATTRIBUTE}]`));
  if (!tabTraps.length && hasShadowDom(parent)) {
    parent = parent.shadowRoot;
    tabTraps = Array.from(parent.querySelectorAll(`[${TAB_TRAP_ATTRIBUTE}]`));
  }
  if (!tabTraps.length) {
    tabTraps.push(parent.insertBefore(createTabTrap(), parent.firstChild));
    tabTraps.push(parent.appendChild(createTabTrap()));
  }
  return tabTraps;
}


function onTabTrapFocus(element: HTMLElement, tabTraps: HTMLElement[]): EventListener {
  return (event: FocusEvent) => {
    const { target, relatedTarget } = event;
    const focusables = getFocusableChildren(element);
    // if on the last tabTrap, or focusing from the dialog into its contents, focus on first
    // else focus on last
    const index = target === tabTraps[1] || (relatedTarget && (<HTMLElement>relatedTarget).contains(<HTMLElement>target)) ? 0 : (focusables.length - 1);
    if (focusables[index]) {
      focus(focusables[index], element);
    }
  }
}


import { bindListbox } from './listboxBehavior';
import { instances } from '../../../utilities/instances';
import { SelectOption, SelectState } from '../SelectState';
import { Store } from "../../../utilities/store";
import { applyAttributes, applyClasses, applyContent, applyStyles, configFromDataAttributes, maintainScrollVisibility } from '../../../utilities/helpers';
import { NAMESPACE } from '../../../utilities/constants';
import { translations } from '../../../utilities/i18n';
import { ListboxConfig } from './ListboxConfig';
import { SelectContext } from '../SelectContext';
import { SpecializedGroupName } from '../constants';
import { isDisabled, isIncluded } from '../utils';
import debounce from '../../../utilities/debounce';
import { renderOptionContent } from './listboxOptionRenderer';
import { ElementPreparerData, IsOptionDisabledCallback, LoadOptions } from '../types';

const ENHANCED_FLAG = 'enhancedListbox';
const INSTANCE_KEY = `${NAMESPACE}Listbox`;
const BASE_CLASS = 'tds-listbox';
const CLASSES = {
  status: `${BASE_CLASS}__status`,
  groupLabel: `${BASE_CLASS}__groupLabel`,
  multiple: `${BASE_CLASS}--multiple`,
  selectedFirst: `${BASE_CLASS}--selectedFirst`,
  condensed: `${BASE_CLASS}--condensed`,
  activeOption: `${BASE_CLASS}__option--active`
}

let nextId = 1;

class _ListboxInstance {
  listbox: HTMLElement;
  store: Store<SelectState>;
  state: SelectState;

  onDestroy: Function[] = [];

  _selectContext: SelectContext;
  _baseId: string;
  _clearListbox = true;
  _rendered: boolean;
  _apiConfig: ListboxConfig;
  _config: ListboxConfig;
  _translate: (key: string, ...replacements: string[]) => string;

  constructor(element: HTMLElement, config: ListboxConfig = {}) {
    this.listbox = element;
    this._apiConfig = config;
    element.dataset[ENHANCED_FLAG] = "true";
    this._baseId = this.listbox.id || `__tds-listbox-${nextId++}`;
    const passedContext = this.config.selectContext;
    const context = this._selectContext = passedContext || SelectContext.getInstance(element, this);
    this.store = context.store;
    this.onDestroy = [this.store.subscribe(this.onStateUpdate.bind(this))];
    if (!passedContext) {
      this.onDestroy.push(
        bindListbox(this),
        context.destroy.bind(context)
      )
    }
    this.render();
    this.render = debounce(this.render.bind(this), 0);
    instances.set(element, INSTANCE_KEY, this);
  }

  onStateUpdate(state: SelectState) {
    const groupedOptions = this.state?.groupedOptions;
    const activeIndex = this.state?.activeIndex;
    const orderedOptions = this.state?.orderedOptions;
    this.state = state;
    if (this._rendered) {
      if (!!groupedOptions !== !!state.groupedOptions) {
        this._clearListbox = true; // to force a restructing of the listbox
      }
      if (activeIndex !== state.activeIndex || orderedOptions !== state.orderedOptions) {
        const activeOption = state.orderedOptions[state.activeIndex];
        const activeOptionId = activeOption ? this.getOptionElementId(activeOption) : '';
        if (activeOptionId !== state.activeOptionId) {
          this.store.update({ activeOptionId });
          return; // this will be called again after store.update 
        }
      }
      this.render();
    }
  }

  updateConfig(config: ListboxConfig) {
    if (this._apiConfig !== config) {
      const lastGroupBy = this.config.groupBy;
      this._apiConfig = { ...this._apiConfig, ...config };
      this._config = undefined;
      if (this._rendered) {
        if (lastGroupBy !== this.config.groupBy) {
          this._clearListbox = true; // to force a restructing of the listbox
        }
        this.render();
      }
    }
  }

  get config(): ListboxConfig {
    return this._config || (this._config = {
      optionName: 'name',
      optionValue: 'value',
      options: [],
      // only using data attributes for testing as standalone component, so not bothering with string conversions
      // watching for attribue changes
      ...configFromDataAttributes(this.listbox),
      ...(this._apiConfig || {})
    })
  }

  render() {
    if (!this.listbox) return; // instance destroyed before debounce timeout
    const { multiple, selectedFirst, condensed, label, labelledby, groupBy } = this.config;
    const { listbox, controller } = this;
    const { groupedOptions, orderedOptions } = this.state;
    this._translate = translations(this.listbox).t;
    if (this._clearListbox) {
      while (this.listbox.lastChild) {
        this.listbox.lastChild.remove();
      }
      this._clearListbox = false;
    }
    const classes = {
      [CLASSES.multiple]: multiple,
      [CLASSES.selectedFirst]: selectedFirst,
      [CLASSES.condensed]: condensed
    }
    applyClasses(listbox, classes);
    const attributes = {
      role: "listbox",
      tabindex: controller === listbox ? 0 : undefined,
      'aria-multiselectable': multiple ? 'true' : undefined,
      'aria-label': label,
      'aria-labelledby': labelledby
    }
    applyAttributes(listbox, attributes);
    this.renderOptionsStatus();
    if (groupedOptions && groupBy) {
      this.renderGroups();
    } else {
      this.renderOptions(orderedOptions, listbox, this.optionStatusItem)
    }
    this._rendered = true;
    this.postRender();
  }

  renderGroups() {
    const groupedOptions = this.state.groupedOptions;
    const listbox = this.listbox;
    const optionStatusEl = this.optionStatusItem;
    const groups = Array.from(listbox.querySelectorAll<HTMLElement>('div[role="group"]'));
    while (groups.length > groupedOptions.length) {
      groups.pop().remove();
    }
    groupedOptions.forEach((group, i) => {
      const id = `${this._baseId}__listgrouplabel-${i}`;
      const display = this.formatGroupName(group.name);
      let groupEl = groups[i];
      if (!groupEl) {
        groupEl = listbox.ownerDocument.createElement('div');
        groupEl.setAttribute('role', 'group');
        groupEl.setAttribute('aria-labelledby', id);
        listbox.insertBefore(groupEl, optionStatusEl);
      }
      let groupLabelEl: HTMLElement;
      while (!(groupLabelEl = groupEl.querySelector<HTMLElement>(`div.${CLASSES.groupLabel}`))) {
        groupEl.insertAdjacentHTML('afterbegin', `<div class="${CLASSES.groupLabel}" role="presentation"></div>`);
      }
      groupLabelEl.id = id;
      groupLabelEl.textContent = display;
      this.renderOptions(group.options, groupEl);
    })
  }

  renderOptions(options: SelectOption[], parent: HTMLElement, insertBefore?: Element) {
    const optionEls = Array.from(parent.querySelectorAll<HTMLElement>('div[role="option"]'));
    while (optionEls.length > options.length) {
      optionEls.pop().remove();
    }
    options.forEach((option, i) => {
      let optionEl = optionEls[i];
      if (!optionEl) {
        optionEl = parent.ownerDocument.createElement('div');
        optionEl.setAttribute('role', 'option');
        parent.insertBefore(optionEl, insertBefore);
      }
      this.renderOption(optionEl, option)
    })
  }

  renderOption(optionEl: HTMLElement, option: SelectOption) {
    const { multiple, optionDisabled, maxSelection, prepElement } = this.config;
    const { selectedOptions, filterText } = this.state;
    const isSelected = isIncluded(option, selectedOptions);
    const name = option.name;
    const ariaSelected = isSelected ? 'true' : multiple ? 'false' : undefined;
    const ariaDisabled = isDisabled(option, { optionDisabled, maxSelection, selectedOptions }) ? 'true' : undefined;
    const id = this.getOptionElementId(option);
    const active = id === this.state.activeOptionId;
    optionEl.id = id;
    const classes = {
      [CLASSES.activeOption]: active,
      disabled: !!ariaDisabled
    };

    const styles: { [key: string]: string | undefined } = {};
    const attributes: { [key: string]: string | undefined } = {};
    const optionData: ElementPreparerData = {
      type: 'option',
      option: option.option,
      isSelected,
      isActive: active,
      isDisabled: !!ariaDisabled,
      isOther: !!option.other && !option.otherHasBeenSelected,
      filterText
    }

    if (prepElement) {
      prepElement(optionData, classes, styles, attributes);
    }
    attributes.ariaSelected = ariaSelected;
    attributes.ariaDisabled = ariaDisabled;
    applyClasses(optionEl, classes);
    applyAttributes(optionEl, attributes);
    applyStyles(optionEl, styles);
    const content = renderOptionContent(name, optionData, this.config, this._translate);
    applyContent(optionEl, content);
  }

  renderOptionsStatus() {
    const { optionStatusRenderer, prepElement } = this.config;
    const { optionsStatus, loading, filterText } = this.state;
    const listbox = this.listbox;
    let optionStatusEl;
    while (!(optionStatusEl = this.optionStatusItem)) {
      listbox.insertAdjacentHTML('beforeend', `<div class="${CLASSES.status}" role="presentation"></div>`);
    }
    optionStatusEl.hidden = !optionsStatus;
    if (optionsStatus) {
      const classes: { [key: string]: boolean } = {};
      const styles: { [key: string]: string | undefined } = {};
      const attributes: { [key: string]: string | undefined } = {};
      const data: ElementPreparerData = {
        type: loading ? 'loading' : 'nooptions', // if not loading, only other reason is nooptions. May have to add to this
        filterText,
        message: optionsStatus
      }
      if (prepElement) {
        prepElement(data, classes, styles, attributes);
        applyClasses(optionStatusEl, classes);
        applyAttributes(optionStatusEl, attributes);
        applyStyles(optionStatusEl, styles);
      }
      const renderedOptionStatus = optionStatusRenderer && optionStatusRenderer(data);
      applyContent(optionStatusEl, renderedOptionStatus || optionsStatus);
    } else {
      optionStatusEl.textContent = '';
    }
  }

  postRender() {
    const { listbox } = this;
    const isVisible = !!(listbox.offsetHeight && listbox.offsetWidth);
    const activeOption = listbox.querySelector<HTMLElement>(`.${CLASSES.activeOption}[role="option"]`);
    const activedescendant = activeOption ? activeOption.id : '';
    if (activeOption && isVisible) {
      maintainScrollVisibility(activeOption, listbox);
    }
    if (!this.config.controller) {
      listbox.setAttribute('aria-activedescendant', activedescendant);
    }
  }

  formatGroupName(name: string) {
    if (name === SpecializedGroupName.Other) {
      return this.config.otherGroupName || this._translate('other');
    } else if (name === SpecializedGroupName.Selected) {
      return this.config.selectedGroupName || this._translate('selected');
    } else {
      return name;
    }
  }

  getOptionElementId(option: any): string {
    const optionIndex = this.state.orderedOptions.indexOf(option);
    return `${this._baseId}__listboxoption-${optionIndex}`;
  }

  destroy() {
    while (this.onDestroy && this.onDestroy.length) {
      const fn = this.onDestroy.pop();
      fn();
    }
    if (this.listbox) {
      delete this.listbox.dataset[ENHANCED_FLAG];
    }
    instances.remove(this.listbox, INSTANCE_KEY);
    this.listbox = this.store = null;
  }

  get selectContext(): SelectContext { return this._selectContext }

  get multiple(): boolean { return this.config.multiple }

  get groupBy(): string { return this.config.groupBy }

  get selectedFirst(): boolean { return this.config.selectedFirst }

  get optionName(): string { return this.config.optionName }

  get optionValue(): string { return this.config.optionValue }

  get optionId(): string { return this.config.optionId }

  get optionDisabled(): string | IsOptionDisabledCallback { return this.config.optionDisabled }

  get maxSelection(): number { return this.config.maxSelection }

  get options(): any[] | LoadOptions { return this.config.options }

  get controller(): HTMLElement { return this.config.controller || this.listbox }

  get optionStatusItem(): HTMLElement { return this.listbox.querySelector<HTMLElement>(`div.${CLASSES.status}`) }
}

class Listbox {
  _instance: _ListboxInstance;
  constructor(element: HTMLElement, config?: ListboxConfig) {
    this._instance = <_ListboxInstance>instances.get(element, INSTANCE_KEY) || new _ListboxInstance(element, config);
    if (config) {
      this.updateConfig(config);
    }
  }

  updateConfig(config?: ListboxConfig) {
    this._instance?.updateConfig(config);
  }

  destroy() {
    return this._instance.destroy();
  }
}

export { Listbox }
import { hasFocus } from "../helpers"
import { EventListeners } from "../EventListeners"

export interface FocusHoverState {
  focus?: boolean,
  hover?: boolean
}

interface FocusHoverOptions {
  focus?: boolean
  hover?: boolean,
  hoverInDelay?: number
  hoverOutDelay?: number
}

const defaultOptions: FocusHoverOptions = {
  focus: true,
  hover: true,
  hoverInDelay: 300,
  hoverOutDelay: 0
}

/**
 * Notifies when the hover and focus state channge for one or more elements. Used for components with popovers (such as tooltips)
 * where the popover is not a child of the controlling element (so focus-within would not apply) and focus and hover state 
 * should perist between the two. 
 * @param elements {HTMLElement[]} an arrya of elements to watch colllectively. Most likely 2 elements. Could be 
 * @param onChange {(state: FocusHoverState) => void} A callback function with the focus/hover state
 * @param options {FocusHoverOptions} options to apply. When not supplied, defaults to `{focus: true, hover: true, hoverInDelay: 300, hoverOffDelay: 0}`
 * @param options.focus {boolean} Manages focus state
 * @param options.hover {boolean} Manages hover state
 * @param options.hoverInDelay {boolean} Delay in ms to wait until reporting hover state. This prevent an immediate notification 
 * when the user is just "passing number". Defaults to 300.
 * @param options.hoverOutDelay {number} Delay in milliseconds to wait until reporting end of hover state. Useful if there is a significant
 * gap between the 2 elements and the hover state should persist when passing between them. Defaults to 0.
 * @returns Function to remove all listeners
 */
export function onFocusHover(elements: HTMLElement[], onChange: (state: FocusHoverState) => void, options?: FocusHoverOptions): () => void {
  options = {
    ...defaultOptions,
    ...(options || {})
  }
  const eventListeners = new EventListeners();
  elements.forEach(el => {
    if (options.focus) {
      eventListeners.addListener(el, 'focusin', onEvent);
      eventListeners.addListener(el, 'focusout', onEvent);
    }
    if (options.hover) {
      eventListeners.addListener(el, 'mouseover', onEvent);
      eventListeners.addListener(el, 'mouseout', onEvent);
    }
  })

  let focusHoverState: FocusHoverState = getState();
  if (focusHoverState.focus || focusHoverState.hover) {
    onChange(focusHoverState);
  }

  let eventTimeout: any;

  return function offFocusHover() {
    eventListeners.removeListeners();
  }

  function onEvent(event: Event) {
    if (eventTimeout) {
      clearTimeout(eventTimeout);
    }
    const delay = event.type === 'mouseover' ? options.hoverInDelay :
    event.type === 'mouseout' ? options.hoverOutDelay : 0;
    eventTimeout = setTimeout(() => {
      eventTimeout = undefined;
      reportChange(getState());
    }, delay)
  }


  function getState(): FocusHoverState {
    let focus = options.focus ? false : undefined;
    let hover = options.hover ? false : undefined;
    for (let i = 0; i < elements.length; i++) {
      const el = elements[i];
      if (options.focus) {
        focus = focus || hasFocus(el);
      } 
      if (options.hover) {
        hover = hover || el.matches(':hover');
      } 
    }
    return {focus, hover}
  }

  function reportChange(state: FocusHoverState) {
    if (state.focus !== focusHoverState.focus || state.hover !== focusHoverState.hover) {
      focusHoverState = state;
      onChange(focusHoverState);
    }
  }
}
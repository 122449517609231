import {
  hasShadowDom,
  maintainScrollVisibility,
} from '../../../utilities/helpers';
import { EventListeners } from '../../../utilities/EventListeners';
import { normalizeKey } from '../../../utilities/keyboard';
import { Store } from '../../../utilities/store';
import { TimeParts } from '../constants';
import { TimeInputState } from '../TimeInputState';
import { TimePickerComponent } from './TimePickerComponent';

type Direction = 'up' | 'down' | 'left' | 'right';
const directionMap: { [key: string]: Direction } = {
  ArrowUp: 'up',
  ArrowDown: 'down',
  ArrowLeft: 'left',
  ArrowRight: 'right',
};

function moveFocus(el: Element, direction: Direction) {
  let next: HTMLElement;
  if (direction === 'up' || direction === 'down') {
    next = el[
      direction === 'up' ? 'previousElementSibling' : 'nextElementSibling'
    ] as HTMLElement;
  } else {
    const nextParentSibling =
      el.parentElement[
        direction === 'left' ? 'previousElementSibling' : 'nextElementSibling'
      ];
    next =
      nextParentSibling?.tagName === 'UL'
        ? nextParentSibling.querySelector('[tabindex="0"]')
        : null;
  }
  if (next) {
    maintainScrollVisibility(next, next.parentElement, true);
    next.focus({ preventScroll: true });
  }
  return next;
}

function updateTimePart(el: Element, store: Store<TimeInputState>) {
  const part = el.parentElement.dataset.part as TimeParts;
  store.update((state) => {
    const newState = { ...state };
    newState[`${part}Value`] = el.innerHTML;
    newState.changeMethod = 'dialog';
    return newState;
  });
}

function keydownHandler(timePicker: TimePickerComponent): EventListener {
  const { store } = timePicker;
  return (event: KeyboardEvent) => {
    const key = normalizeKey(event);
    const { target } = event;
    if (target instanceof Element) {
      if (target.tagName === 'LI') {
        const direction = directionMap[key];
        if (direction) {
          event.preventDefault()
          const next = moveFocus(target, direction);
          if (next) updateTimePart(next, store);
        }
      }
    }
  };
}

function clickHandler(timePicker: TimePickerComponent): EventListener {
  const { store } = timePicker;
  return (event: Event) => {
    const { target } = event;
    if (target instanceof Element) {
      if (target.tagName === 'LI') {
        updateTimePart(target, store);
      }
    }
  };
}

function makeClosestMinuteFocusable(list: Element, value: string) {
  const childrenArr = Array.from(list.children);
  const diffs = childrenArr.map((child) =>
    Math.abs(parseInt(child.innerHTML, 10) - (parseInt(value, 10) || 0))
  );
  const lowest = Math.min(...diffs);
  childrenArr.forEach((child, i) => {
    if (i === diffs.indexOf(lowest)) {
      child.setAttribute('tabindex', '0');
    }
  });
}

export function updateActiveOption(
  el: Element,
  part: TimeParts,
  value: string,
  className: string
) {
  const list = el.querySelector(`[data-part="${part}"]`);
  if (list) {
    const item = Array.from(list.children).find(
      (child) => child.innerHTML === value
    );
    const currentActive = list.querySelector('[tabindex="0"]');
    currentActive?.classList.remove(className);
    currentActive?.setAttribute('tabindex', '-1');
    currentActive?.setAttribute('aria-selected', 'false');
    if (item) {
      item.setAttribute('tabindex', '0');
      item.classList.add(className);
      item.setAttribute('aria-selected', 'true');
    } else {
      if (part === TimeParts.Minute) {
        makeClosestMinuteFocusable(list, value);
      } else {
        list.firstElementChild.setAttribute('tabindex', '0');
      }
    }
  }
}

export function focusOnFirstList(el: Element, store: Store<TimeInputState>) {
  const lists = hasShadowDom(el)
    ? el.shadowRoot.querySelectorAll('ul')
    : el.querySelectorAll('ul');
  const activeItems = Array.from(lists).map(
    (list) => list.querySelector('[aria-selected="true"]') as HTMLElement
  );
  const itemToFocus =
    activeItems[0] ?? lists[0].querySelector(`[tabindex="0"]`);
  activeItems.forEach((item) => {
    if (item) maintainScrollVisibility(item, item.parentElement, true, true);
  });
  itemToFocus?.focus({ preventScroll: true });
  updateTimePart(itemToFocus, store);
}

export function bindTimePicker(timePicker: TimePickerComponent): Function {
  const { el } = timePicker;
  const eventListeners = new EventListeners();
  eventListeners.addListener(el, 'keydown', keydownHandler(timePicker));
  eventListeners.addListener(el, 'click', clickHandler(timePicker));

  return () => {
    eventListeners.removeListeners();
  };
}

import { ListboxConfig } from "./ListboxConfig";
import { splitTextByMatch } from "../../../utilities/helpers";
import { interpolate } from "../../../utilities/objectHelpers";
import { OptionData } from "../types";

export function renderOptionContent( name: string, data: OptionData, config: Partial<ListboxConfig>, translate: (key: string, ...replacements: string[]) => string) {
  const filterText = data.filterText?.trim();
  const optionRenderer = config.optionRenderer;
  const renderedOption = optionRenderer && optionRenderer(data);
  const otherPrompt = data.isOther && formatOtherPrompt(name, config.addOtherTpl, translate);
  const highlighted = config.highlight && filterText && highlightMatches(name, filterText); 
  const content = renderedOption || otherPrompt || highlighted || name;
  return content;
}

function formatOtherPrompt(name: string, addOtherTpl: string, translate: (key: string, ...replacements: string[]) => string) {
  const tpl = addOtherTpl || translate('addOtherPrompt');
  return interpolate({ name }, tpl);
}

function highlightMatches(name: string, filterText: string) {
  const parts = splitTextByMatch(name, filterText);
  const elements: HTMLElement[] = [];
  parts.forEach((part: string, i: number) => {
    if (part) {
      const tag = (i % 2) ? 'mark' : 'span';
      const el = document.createElement(tag);
      el.textContent = part
      elements.push(el);
    }
  });
  return elements;
}
import { Store } from "../../utilities/store";
import { SelectState, createStore, SelectOption } from "./SelectState";
import { createOptionsGetter } from "./optionsGetter";
import { updateOrderedOptions } from "./orderOptions";
import { createSelectActions } from "./selectActions";
import { FilterPredicateCallback, IsOptionDisabledCallback, LoadOptions } from "./types";

export interface OptionSelector {
  options: any[] | LoadOptions,
  optionName: string,
  optionShortName?: string,
  optionValue: string,
  optionId: string,
  optionDisabled: string | IsOptionDisabledCallback;
  multiple: boolean,
  groupBy: string,
  selectedFirst: boolean,
  maxSelection?: number;
  filter?: boolean,
  filterPredicate?: FilterPredicateCallback,
  defaultOptions?: any[],
  focusCombobox?: Function
}
const contextMap = new WeakMap<HTMLElement, SelectContext>();

export class SelectContext {
  private _el: HTMLElement;
  private _select: OptionSelector;
  private _unsub: Function;
  private _state: SelectState;
  private _store: Store<SelectState>;
  private _getOptions: ReturnType<typeof createOptionsGetter>;
  private _selectionActions: ReturnType<typeof createSelectActions>;

  static getInstance(el: HTMLElement, selector?: OptionSelector) {
    if (!contextMap.has(el)) {
      contextMap.set(el, new SelectContext(el));
    }
    const context = contextMap.get(el);
    if (selector) {
      context.init(selector);
    }
    return context;
  }

  private constructor(el: HTMLElement) {
    this._el = el;
    this._store = createStore();
    this._unsub = this._store.subscribe(this.onStateUpdate.bind(this));
  }

  private init(select: OptionSelector) {
    this._select = select;
    this._getOptions = createOptionsGetter(select, this._store);
    this._selectionActions = createSelectActions(select, this._store);
    updateOrderedOptions(this._store, select);
  }

  get store(): Store<SelectState> {
    return this._store;
  }

  get state(): SelectState {
    return this._state;
  }

  get selectionActions(): ReturnType<typeof createSelectActions> {
    return this._selectionActions
  }

  getOptions(filterText = ''): Promise<SelectOption[]> {
    return this._getOptions(filterText);
  }

  hideOptionsPopup(focus = true) {
    const { showList, activeIndex } = this._state;
    const { _select } = this;
    const { multiple } = _select;
    if (showList) {
      // if multiple, reset activeIndex to 0. Will be reset if needed before showing
      this._store.update({ showList: false, activeIndex: multiple ? 0 : activeIndex, activeOptionId: undefined });
      if (focus && _select.focusCombobox) {
        _select.focusCombobox();
      }
    }
  }

  destroy() {
    this._unsub && this._unsub();
    contextMap.delete(this._el);
  }

  private onStateUpdate(s: SelectState) {
    const oldState = this._state;
    this._state = s;
    if (oldState && oldState.selectedOptions !== s.selectedOptions && this._select.selectedFirst) {
      updateOrderedOptions(this._store, this._select);
    }
  }
}



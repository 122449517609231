export const NAMESPACE = 'tds';
export const CSS_NS = `${NAMESPACE}-`;

export const MULTISELECT_CLASSES = {
  MULTISELECT: `${CSS_NS}multiselect`,
  CONTROL: `${CSS_NS}multiselect__control`,
  POPUP: `${CSS_NS}multiselect__popup`,
  SELECT_ALL: `${CSS_NS}multiselect__select-all`,
  SELECT_ALL_CHECKBOX: `checkbox-ind-all`,
  CHECK: `${CSS_NS}check`,
  CHECKBOX: `${CSS_NS}check__box`,
  CHECK_SELECTED: `${CSS_NS}check--selected`,
  CHECKLABEL: `${CSS_NS}check__label`,
  INPUT: `${CSS_NS}field__input`,
  FIELD: `${CSS_NS}field`,
  DISPLAY_SELECTED: `${CSS_NS}multiselect__input-div`,
  FIELDLABEL: `${CSS_NS}field__label`,
  COUNT: `item--count`,
  CARET: `item--caret`,
  EXPANDED: `expanded`,
  SELECTED: `selected`
};

export const FILE_UPLOAD_CLASSES = {
  FILEUPLOAD: `${CSS_NS}fileupload`,
  DROPAREA: `${CSS_NS}filepicker`,
  INPUT: `${CSS_NS}filepicker__input`,
  BUTTON: `${CSS_NS}filepicker__trigger`,
  LABEL: `${CSS_NS}filepicker__label`,
  LIST: `${CSS_NS}fileupload__list`,
  LIST_TITLE: `${CSS_NS}fileupload__list-title`,
  DELETE_BUTTON: `${CSS_NS}file--delete`,
  FILE_STATUS: `${CSS_NS}file--status`,
  FILE_ACTIONS: `${CSS_NS}file__actions`,
  FILE_DETAIL: `${CSS_NS}file__detail`,
  FILE_NAME: `${CSS_NS}file__detail-name`,
  FILE_SIZE: `${CSS_NS}file__detail-size`,
  FILE_ERROR_MESSAGE: `${CSS_NS}file__detail-error-message`
}

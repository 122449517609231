import { DateInputComponent } from './DateInputComponent';
import { DateInputState } from './DateInputState';
import { focusOnActiveDate } from '../calendar/calendarBehavior';
import { getLocale } from '../../utilities/localization';
import {
  isValidDateInstance,
  parseDate,
  toISODate,
  toReadableDate,
  getDateValidity
} from '../../utilities/date-utils';
import { CSS_NS } from '../../utilities/constants';
import { containTabFocus } from '../../utilities/dialogs';
import { isTyping, normalizeKey } from '../../utilities/keyboard';
import { EventListeners } from '../../utilities/EventListeners';
import { hasShadowDom } from '../../utilities/helpers';
import { createPopover } from '../../utilities/popover';
import debounce from '../../utilities/debounce';

const CALENDAR_TRIGGER_SELECTOR = '[data-trigger="calendar"]';
const DATE_FORMAT_HINT_SELECTOR = `[data-date-format-label]:not([data-date-format-label="done"])`

/////////////////////////////////////////////////////
// - exports

export function bindDateInput(dateInput: DateInputComponent): Function {
  const { el, store } = dateInput;

  const inputs = orderDateInputs(dateInput);
  inputs.forEach((input: HTMLInputElement) => {
    input.maxLength = input.dataset.datePart === 'year' ? 4 : 2;
  });
  setFormatHints();
  getA11ySpan(dateInput);

  const eventListeners = new EventListeners();
  eventListeners.addListener(el, 'paste', pasteHandler(dateInput))
  eventListeners.addListener(el, 'input', inputHandler(dateInput, inputs));
  eventListeners.addListener(el, 'change', changeHandler(dateInput, inputs));
  eventListeners.addListener(el, 'keydown', keydownHandler(dateInput));
  eventListeners.addListener(el, 'keyup', keyupHandler(dateInput));
  eventListeners.addListener(el, 'focusin', focusHandler(dateInput));
  const manageFocusState = debounce(() => { setFocusState(dateInput)}, 100, false);
  eventListeners.addListener(el, 'focusin', manageFocusState);
  eventListeners.addListener(el, 'focusout', manageFocusState);
  const trigger = getCalendarTrigger(dateInput);
  if (trigger) {
    eventListeners.addListener(trigger, 'click', triggerHandler(dateInput));
  }
  eventListeners.addListener(el, 'click', dateInputClickHandler(dateInput));
  eventListeners.addListener(document, 'click', documentClickHandler(dateInput));
  const calendarEventListeners = new EventListeners();
  const unsubscribe = store.subscribe(onStateUpdate(dateInput, calendarEventListeners, manageFocusState));
  let currentState: DateInputState;
  // subscribe / unsubscribe to get current state
  store.subscribe((s => currentState = s))();
  const hiddenInput = getHiddenInput(dateInput);
  if (hiddenInput && !hiddenInput.value) {
    synchHiddenRelatedElements(currentState, dateInput);
  }
  const validity = getValidityState(dateInput, currentState);
  if (!validity.valid) {
    // this will fire 'invalid' event if date input starts invalid because occurs after subscribe
    store.update(s => { return { ...s, validity } });
  }

  const unbind = () => {
    closeCalendar(dateInput);
    eventListeners.removeListeners();
    calendarEventListeners.removeListeners();
    unsubscribe();
  }
  return unbind;
}

export function getValueAsISOString(state: DateInputState) {
  const { yearValue = '', monthValue = '', dayValue = '' } = state;
  const value = `${yearValue}-${monthValue}-${dayValue}`
  return value === '--' ? '' : value
}

export function getValueAsDate(state: DateInputState): Date {
  const { yearValue = '', monthValue = '', dayValue = '' } = state;
  try {
    const year = parseInt(yearValue.trim())
    const month = parseInt(monthValue.trim())
    const day = parseInt(dayValue.trim())
    if (isNaN(year + month + day)) {
      return null;
    }
    else if (month < 1 || month > 12) {
      return null;
    }
    else if (day < 1 || day > 31) {
      return null
    }
    else if (yearValue.trim().length < 4) {
      return null;
    }
    const date = new Date(year, month - 1, day, 0, 0, 0, 0);
    // this is here as a sanity check, but can't really create an invalid date when the
    // above checks pass. 
    /* istanbul ignore next */
    if (!isValidDateInstance(date)) {
      return null;
    }

    // if date entered resolves to a different date, then an invalid day value was
    // entered. For instance, 4/31/20XX would resolved to 5/1/20XX.
    // Consider this bad input and return null
    return (date.getDate() === (day)) ? date : null
  } catch (err) {
    /* istanbul ignore next */
    return null;
  }
}

export function setDateValue(value: string | Date, dateInput: DateInputComponent) {
  const { format } = getLocale(dateInput.el);
  setChangeMethod('api');
  setValue(value, dateInput, format);
}

///////////////////////////////////////////////////////////
// - internal functions


function setValue(value: Date | string, dateInput: DateInputComponent, dateFormat?: string) {
  let date: Date;
  if (value && !(value instanceof Date)) {
    date = parseDate(value.toString(), dateFormat);
  } else {
    date = <Date>value;
  }

  let yearValue = date ? date.getFullYear() + '' : ''
  let monthValue = date ? to2Digits(date.getMonth() + 1) : ''
  let dayValue = date ? to2Digits(date.getDate()) : ''

  const ISO_DASH_RX = /^([^-]*)-([^-]*)-([^-]*)$/;
  if (!date && typeof value === 'string' && ISO_DASH_RX.test(value)) {
    const parts = value.split('-');
    const nonDigitRx = /\D/g
    yearValue = (parts[0] || '').replace(nonDigitRx, '');
    monthValue = (parts[1] || '').replace(nonDigitRx, '');
    dayValue = (parts[2] || '').replace(nonDigitRx, '');
  }

  dateInput.store.update(s => {
    const newState = { ...s, yearValue, monthValue, dayValue };
    return { ...newState, validity: getValidityState(dateInput, newState) };
  });
}

function getValidityState(dateInput: DateInputComponent, state: DateInputState): any {
  const { config } = dateInput;
  const valueAsDate = getValueAsDate(state);
  const valueAsISOString = getValueAsISOString(state);
  return getDateValidity(config, valueAsDate, valueAsISOString);
}

function orderDateInputs(dateInput: DateInputComponent): Array<HTMLInputElement> {
  const { format } = getLocale(dateInput.el);
  const map: any = {
    'MM': 'month',
    'DD': 'day',
    'YYYY': 'year',
  }
  const inputs = getInputs(dateInput);
  const dateParts = format.toUpperCase().split('/').map((part: string) => {
    return map[part] || part;
  });
  const outOfOrder = dateParts.find((part: string, ix: number) => !inputs[ix] || part !== inputs[ix].getAttribute('data-date-part'))
  if (outOfOrder) {
    inputs.sort((a, b) => {
      const orderA = dateParts.indexOf(a.getAttribute('data-date-part'))
      const orderB = dateParts.indexOf(b.getAttribute('data-date-part'))
      return orderA - orderB;
    })
    inputs
      .map(input => input.parentElement)
      .forEach((input, ix, arr) => {
        const lastIX = arr.length - 1;
        if (ix < lastIX) {
          const lastInput = arr[lastIX];
          lastInput.parentNode.insertBefore(input, lastInput);
        }
      })
  }
  return inputs;
}

function setFormatHints() {
  //todo: how to cross shadow dom
  //todo: do not change if in correct format
  const hintLabels = document.querySelectorAll(DATE_FORMAT_HINT_SELECTOR);
  for (let i = 0; i < hintLabels.length; i++) {
    const hintLabel = <HTMLElement>hintLabels[i];
    const { format, dateFormatLabel } = getLocale(hintLabel);
    const hint = format.toUpperCase();
    const date_format_label_new = hintLabel.textContent && hintLabel.textContent.trim().match(/^\(.*\)$/) ? "(" + hint + ")" : hint
    hintLabel.innerText = date_format_label_new
    hintLabel.setAttribute("aria-label", dateFormatLabel)
    hintLabel.setAttribute("data-date-format-label", "done")
  }
}

function getHiddenInput(dateInput: DateInputComponent): HTMLInputElement {
  const { el, host } = dateInput;
  const selector = 'input[type="hidden"]';
  return el.querySelector(selector) || host.querySelector(selector);
}

function getA11ySpan(dateInput: DateInputComponent) {
  const container = dateInput.el;
  let a11ySpan: HTMLElement = container.querySelector(`[data-a11y="true"]`)
  if (!a11ySpan) {
    a11ySpan = document.createElement('span')
    a11ySpan.setAttribute("aria-live", "assertive")
    a11ySpan.dataset.a11y = 'true';
    a11ySpan.classList.add(`${CSS_NS}sr-only`)
    container.appendChild(a11ySpan)
  }
  return a11ySpan;
}

////////////////////////////////////////////////
// - calendar actions

function toggleCalendar(dateInput: DateInputComponent) {
  if (isCalendarOpenState(dateInput, 'opened', 'opening')) {
    closeCalendar(dateInput);
  } else {
    openCalendar(dateInput);
  }
}

function openCalendar(dateInput: DateInputComponent) {
  const { state, store, calendar, calendarDialog } = dateInput;
  if (!isCalendarOpenState(dateInput, 'opened', 'opening')) {
    const selectedDate = getValueAsDate(state) || null;
    if (selectedDate) {
      const selectedDateStr = toISODate(selectedDate);
      calendar.setAttribute('selected-date', selectedDateStr);
      calendar.setAttribute('active-date', selectedDateStr);
    } else {
      calendar.removeAttribute('selected-date');
      calendar.setAttribute('active-date', toISODate(new Date()));
    }
    // Setting calendarOpenState to 'ready-open' puts the calendar in a state
    // ready to transition open. As there is no other reference this state 
    // in the code, this may be unclear. It basically makes the calendar "not closed"
    // which effectively changes the CSS display from 'none' to 'block' but keeps 
    // visibility to hidden. 
    store.update((v: DateInputState) => {
      return { ...v, calendarOpenState: 'ready-open' }
    });

    // timeout is used to let 'display:block' establish before transitioning from hidden to visible 
    setTimeout(() => {
      updateCalendarOpenState(dateInput, 'opening');
      (<any>calendarDialog).uncontainTabFocus = containTabFocus(calendarDialog);
    }, 50);
  }
}

function closeCalendar(dateInput: DateInputComponent) {
  const { calendarDialog } = dateInput;
  if (isCalendarOpenState(dateInput, 'opened')) {
    updateCalendarOpenState(dateInput, 'closing');
    if (calendarDialog && (calendarDialog as any).uncontainTabFocus) {
      (calendarDialog as any).uncontainTabFocus();
      delete (calendarDialog as any).uncontainTabFocus;
    }
  }
}

function onCalendarReadyOpen(dateInput: DateInputComponent) {
  const { calendarDialog, legacyPopover } = dateInput;
  const method = legacyPopover ? 'add' : 'remove';
  calendarDialog.classList[method]('legacy-popover');
  if (!legacyPopover) {
    (calendarDialog as any).destroyPopover = createPopover(calendarDialog, dateInput.host, {
      transitionEnterTimeout: 0,
      transitionLeaveTimeout: 0,
      placement: 'bottom-start'
    });
  }
}

function onCalendarOpening(dateInput: DateInputComponent, calendarEventListeners: EventListeners, manageFocusState: () => void) {
  const calendar = dateInput.calendar;
  calendarEventListeners.addListener(calendar, 'selected', dateSelectedHandler(dateInput));
  calendarEventListeners.addListener(calendar, 'keydown', keydownHandlerCalendar(dateInput));
  // stop propagation of these events to prevent loss focus handlers when used as editable table cell editor 
  calendarEventListeners.addListener(calendar, 'mousedown', stopPropagation);
  calendarEventListeners.addListener(calendar, 'touchstart', stopPropagation);
  calendarEventListeners.addListener(calendar, 'click', stopPropagation);
  calendarEventListeners.addListener(calendar, 'focusin', manageFocusState);
  calendarEventListeners.addListener(calendar, 'focusout', manageFocusState);
  focusOnActiveDate(dateInput.calendar);
}

function onCalendarClosing(dateInput: DateInputComponent) {
  // setCalendarFocusState(dateInput);
  const trigger = getCalendarTrigger(dateInput);
  if (trigger) {
    const { calendar } = dateInput;
    let focusedEl = calendar.querySelector(':focus');
    if (!focusedEl && hasShadowDom(calendar)) {
      focusedEl = calendar.shadowRoot.querySelector(':focus');
    }
    // only focus on trigger if focus is inside calendar 
    if (focusedEl) {
      trigger.focus();
    }
  }
}

function onCalendarClosed(dateInput: DateInputComponent) {
  const { calendarDialog } = dateInput;
  if (calendarDialog && (calendarDialog as any).destroyPopover) {
    (calendarDialog as any).destroyPopover();
    delete (calendarDialog as any).destroyPopover;
  }
}

function getCalendarTrigger(dateInput: DateInputComponent): HTMLElement {
  return dateInput.el.querySelector(CALENDAR_TRIGGER_SELECTOR) ||
    dateInput.host.querySelector(CALENDAR_TRIGGER_SELECTOR);
}

//////////////////////////////////////////////////
// - event handlers

function inputHandler(dateInput: DateInputComponent, inputs: HTMLInputElement[]): EventListener {
  return event => {
    const input = <HTMLInputElement>event.target;
    if (inputs.indexOf(input) > -1) {
      setChangeMethod('input');
      applyDateInputUpdate(dateInput);
    }
  }
}

function changeHandler(dateInput: DateInputComponent, inputs: HTMLInputElement[]): EventListener {
  return event => {
    const input = <HTMLInputElement>event.target;
    if (inputs.indexOf(input) > -1) {
      const state = dateInput.state;
      const value = input.value;
      const resolvedValue = value && input.dataset.datePart !== 'year' ? to2Digits(value) : value;
      if (resolvedValue !== value) {
        input.value = resolvedValue;
      }
      if ((state as any)[`${input.dataset.datePart}Value`] !== resolvedValue) {
        setChangeMethod('input');
        applyDateInputUpdate(dateInput);
      }
    }
  }
}

function keydownHandler(dateInput: DateInputComponent): EventListener {
  return (event: KeyboardEvent) => {
    const target = <HTMLInputElement>event.target
    const key = normalizeKey(event);
    const inputs = getInputs(dateInput);
    const inputIX = inputs.indexOf(target);
    if (inputIX > -1) {
      const nextInput = inputs[inputIX + 1];
      const prevInput = inputs[inputIX - 1];
      switch (key) {
        case '/':
        case '-':
        case '.':
          event.preventDefault()
          if (target.value.length && target.selectionStart === target.value.length) {
            if (nextInput) {
              nextInput.focus();
              nextInput.select();
            }
          }
          break;

        case 'ArrowLeft':
          if (prevInput && target.selectionStart === 0) {
            prevInput.focus();
            prevInput.selectionStart = prevInput.value.length;
            event.preventDefault();
          }
          break;

        case 'ArrowRight':
          if (nextInput && target.selectionStart === target.value.length) {
            nextInput.focus();
            nextInput.selectionStart = 0;
            event.preventDefault();
          }
          break;

        case 'ArrowUp':
        case 'ArrowDown':
        case 'Home':
        case 'End':
          incrementDateField(key, target, dateInput);
          event.preventDefault();
          break;

        case 'Backspace':
          //if backspacing from 2nd or 3rd empty input, shift focus to previous input
          if (prevInput && target.value.length === 0) {
            prevInput.focus();
            prevInput.selectionStart = prevInput.value.length;
            // by not preventing default, backspace will continue at start of previous field
          }
          break;

        default: {
          if (isTyping(event) && !key.match(/\d/)) {
            event.preventDefault();
          }
        }
      }
    }
  }
}

function keydownHandlerCalendar(dateInput: DateInputComponent): EventListener {
  return (event: KeyboardEvent) => {
    const key = normalizeKey(event);
    if (key === 'Escape') {
      closeCalendar(dateInput);
      event.stopPropagation();
    }
  }
}

function stopPropagation(event: Event) {
  event.stopPropagation();
}

function incrementDateField(key: string, input: HTMLInputElement, dateInput: DateInputComponent) {
  let inputValue: number = input.value ? parseInt(input.value) : 0;
  if (isNaN(inputValue)) {
    inputValue = 0;
  }
  const maxValue = input.dataset.datePart === 'month' ? 12 : input.dataset.datePart === 'day' ? 31 : 9999;
  switch (key) {
    case 'ArrowUp':
      if (inputValue < maxValue) {
        inputValue++;
      }
      break;
    case 'ArrowDown':
      if (inputValue > 1) {
        inputValue--;
      }
      break;
    case 'Home':
      if (input.dataset.datePart !== 'year') {
        inputValue = 1;
      }
      break;
    case 'End':
      if (input.dataset.datePart !== 'year') {
        inputValue = maxValue;
      }
      break;
  }
  const value = inputValue ? (input.dataset.datePart === 'year' ? `${inputValue}` : to2Digits(inputValue)) : '';
  if (value !== input.value) {
    input.value = value;
    setChangeMethod('input');
    applyDateInputUpdate(dateInput);
  }
}

function applyDateInputUpdate(dateInput: DateInputComponent) {
  const { store, state } = dateInput;
  const inputs = getInputs(dateInput);
  const updates: any = {}
  inputs.forEach(input => updates[`${input.dataset.datePart}Value`] = input.value.trim());
  updates.validity = getValidityState(dateInput, { ...state, ...updates });
  store.update(v => {
    return { ...v, ...updates }
  });
}

function keyupHandler(dateInput: DateInputComponent): EventListener {
  return (event: KeyboardEvent) => {
    const target = <HTMLInputElement>event.target
    const inputs = getInputs(dateInput);
    const inputIX = inputs.indexOf(target);
    if (inputIX > -1) {
      const key = normalizeKey(event);
      const nextInput = inputs[inputIX + 1];
      if (key && key.match(/\d/) && target.selectionStart === target.maxLength && nextInput) {
        nextInput.focus();
        nextInput.select();
      }
    }
  }
}

function triggerHandler(dateInput: DateInputComponent) {
  return () => {
    const trigger = getCalendarTrigger(dateInput);
    if (trigger && !trigger.closest('[aria-disabled="true"]')) {
      toggleCalendar(dateInput);
    }
  }
}

function updateCalendarOpenState(dateInput: DateInputComponent, openState: 'opening' | 'closing') {
  dateInput.store.update((v: DateInputState) => {
    return { ...v, calendarOpenState: openState, calendarHasFocus: openState === 'opening' };
  });
  setTimeout(() => {
    const endOpenState = (openState === 'opening') ? 'opened' : 'closed';
    dateInput.store.update((v: DateInputState) => {
      return { ...v, calendarOpenState: endOpenState };
    });
  }, 200); // NOTE: 200ms maps to 'a-calendar-transition-duration' in data-input.yml.
}

function onStateUpdate(dateInput: DateInputComponent, calendarEventListeners: EventListeners, manageFocusState: () => void) {
  let prevState: DateInputState = null;
  return (state: DateInputState) => {
    const oldState = prevState;
    prevState = state;
    onDateUpdate(dateInput, state, oldState);
    onCalendarUpdate(dateInput, state, oldState, calendarEventListeners, manageFocusState);
    setChangeMethod(undefined);
  }
}
function onDateUpdate(dateInput: DateInputComponent, state: DateInputState, oldState: DateInputState) {
  const events: any[] = [];
  if (oldState) {
    const { yearValue = '', monthValue = '', dayValue = '' } = state
    const { yearValue: oldYear = '', monthValue: oldMonth = '', dayValue: oldDay = '' } = oldState
    if (yearValue === oldYear && monthValue === oldMonth && dayValue === oldDay) {
      return;
    }
    synchHiddenRelatedElements(state, dateInput);
    const changeMethod = getChangeMethod() || 'api'; //if not set, assume it was programmatic
    events.push(['change', { detail: { dateInput: dateInput, changeMethod } }]);
    const validity: any = state.validity;
    const oldValidity: any = oldState.validity;
    const props = Object.keys(validity).concat(Object.keys(oldValidity));
    const changed = props.find(prop => oldValidity[prop] !== validity[prop]);
    if (changed) {
      events.push([validity.valid ? 'valid' : 'invalid', { detail: { validity: validity } }]);
    }
    events.forEach(([type, eventInit]) => {
      dateInput.dispatchEvent(type as string, eventInit);
    });
  }
}

function onCalendarUpdate(dateInput: DateInputComponent, state: DateInputState, oldState: DateInputState, calendarEventListeners: EventListeners, manageFocusState: () => void) {
  if (oldState && oldState.calendarOpenState !== state.calendarOpenState) {
    let eventType: string;
    switch (state.calendarOpenState) {
      case 'ready-open':
        onCalendarReadyOpen(dateInput);
        break;
      case 'opening':
        eventType = 'calendaropening';
        onCalendarOpening(dateInput, calendarEventListeners, manageFocusState);
        break;
      case 'opened':
        eventType = 'calendaropened';
        break;
      case 'closing':
        calendarEventListeners.removeListeners();
        eventType = 'calendarclosing';
        onCalendarClosing(dateInput);
        break;
      case 'closed':
        eventType = 'calendarclosed';
        onCalendarClosed(dateInput);
        break;
    }
    if (eventType) {
      dateInput.dispatchEvent(eventType);
    }
  }
}


function pasteHandler(dateInput: DateInputComponent): EventListener {
  return (event: ClipboardEvent) => {
    const target = event.target as HTMLElement;
    const inputs = <any[]>getInputs(dateInput);
    const order = inputs.indexOf(target);
    if (order === 0 && !target.matches(':read-only, :disabled, [aria-disabled="true"], [aria-readonly="true"]')) {
      const clipboardData = event.clipboardData || (window as any).clipboardData;
      if (clipboardData) {
        event.preventDefault();
        const text = clipboardData.getData('text');
        setTimeout(() => {
          const date = parseDate(text, getLocale(dateInput.el).format);
          setChangeMethod('paste');
          setValue(date, dateInput);
          // if valid date, focus on end of date, else focus on beginning
          const focusOn = <HTMLInputElement>(date ? inputs[2] : inputs[0]);
          focusOn.focus();
          const cursorPos = date ? focusOn.maxLength || focusOn.value.length : 0;
          focusOn.selectionStart = focusOn.selectionEnd = cursorPos;
        }, 0);
      }
    }
    else if (order > -1) {
      event.preventDefault();
    }
  }
}

function dateSelectedHandler(dateInput: DateInputComponent) {
  return (event: CustomEvent) => {
    if (event.detail && event.detail.date) {
      setChangeMethod('calendar');
      setValue(event.detail.date, dateInput);
      closeCalendar(dateInput);
    }
  }
}

function focusHandler(dateInput: DateInputComponent): EventListener {
  // todo: needed if we have contrain focus and document click handler?
  return ({ target }) => {
    if (isCalendarOpenState(dateInput, 'opening', 'opened')) {
      const inputs = <EventTarget[]>getInputs(dateInput);
      if (inputs.indexOf(target) > -1) {
        closeCalendar(dateInput);
      }
    }
  }
}

function documentClickHandler(dateInput: DateInputComponent): EventListener {
  /**
   * Checks for clicks outside of the date input component to close the calendar
   * Checks inside the component handled by next handler
   */
  return (event: MouseEvent) => {
    const { target } = event;
    if (isCalendarOpenState(dateInput, 'opened')) {
      const hasTarget = dateInput.host.contains(<Node>target) || dateInput.calendar?.contains(<Node>target);
      if (!hasTarget) {
        closeCalendar(dateInput);
      }
    }
  }
}

function dateInputClickHandler(dateInput: DateInputComponent): EventListener {
  return (event: MouseEvent) => {
    /**
     * Checks for clicks within the date input component that are not 
     * in the calendar or the trigger to close the calendar
     * Separate from document-level click checker so it can work in shadow dom 
     */
    const { target } = event;
    if (isCalendarOpenState(dateInput, 'opened')) {
      const trigger = getCalendarTrigger(dateInput);
      const { calendar } = dateInput
      const keepOpen = (calendar && calendar.contains(<Node>target)) || (trigger && trigger.contains(<Node>target));
      if (!keepOpen) {
        closeCalendar(dateInput);
      }
    }
  }
}

function setFocusState(dateInput: DateInputComponent) {
  const { calendar, state, store, el } = dateInput;
  let focusedEl = el.querySelector(':focus');
  if (!focusedEl && calendar) {
    const calendarRoot = hasShadowDom(calendar) ? calendar.shadowRoot : calendar;
    focusedEl = calendarRoot.querySelector(':focus');
  }
  const hasFocus = !!focusedEl
  if (hasFocus !== state.hasFocus) {
    const eventType =  hasFocus ? 'tdsFocus' : 'tdsBlur';
    dateInput.dispatchEvent(eventType, {cancelable: false, bubbles: true})
    store.update({hasFocus});
  }
}


function synchHiddenRelatedElements(state: DateInputState, dateInput: DateInputComponent) {
  const hiddenInput = getHiddenInput(dateInput);
  if (hiddenInput) {
    hiddenInput.value = getValueAsISOString(state);
  }
  const a11ySpan = getA11ySpan(dateInput);
  if (a11ySpan) {
    a11ySpan.innerText = toReadableDate(getValueAsDate(state), getLocale(dateInput.el).lang);
  }
}

function isCalendarOpenState(dateInput: DateInputComponent, ...openState: string[]): boolean {
  const { state } = dateInput;
  const result = openState.find((s) => s === state.calendarOpenState);
  return !!result;
}

////////////////////////////////////////////////////////////////////////////////////////////////
// This section manages capturing and returning the method used to change a date. This value is 
// passed with the 'change' event. The change event is fired in the state update handler. 
// There is nothing in DateInputState to tell us this information, so we need a way of saving this  
// to be retrieved when firing the event.
let lastChangeMethod: string;
function setChangeMethod(method?: string) {
  lastChangeMethod = method;
}

function getChangeMethod() {
  return lastChangeMethod
}

////////////////////////////////////////////////
// - Helper functions
function getInputs(dateInput: DateInputComponent): HTMLInputElement[] {
  return Array.from(dateInput.el.querySelectorAll('[data-date-part="year"],[data-date-part="month"],[data-date-part="day"]'))
}
function to2Digits(value: string | number) {
  return `00${value}`.slice(-2)
}

import x1_18 from '@trv-ebus/tds-icons/icons/x-1-18';
import { htmlEncode } from '../../utilities/helpers';
import { CSS_NS, FILE_UPLOAD_CLASSES } from "../../utilities/constants";
import { translations, getLang } from "../../utilities/i18n";
import { FileUploadComponent } from "./FileUploadComponent";
import { convertFromBytes, setAriaLive, statusCodes } from "./FileUtility";
import { TdsFileInstance } from "./TdsFile";
import { interpolate } from '../../utilities/objectHelpers';

// creates class name for each error alert by error type
export function getClassNameByStatusCode(statusCode: string) {
  if (statusCode == statusCodes.uploadError || statusCode == statusCodes.sizeError || statusCode == statusCodes.typeError) {
    return `${CSS_NS}file--error`;
  }
  else if (statusCode == statusCodes.success) {
    return `${CSS_NS}file--success`;
  } 
  else if (statusCode == statusCodes.uploading) {
    return `${CSS_NS}file--uploading`;
  }
  return `${CSS_NS}file--ready`;
}

// display error message alert
export function displayErrorAlert(fileUpload: FileUploadComponent) {
  const { el, config, state } = fileUpload;
  const translateHTML = translations(getLang(el)).html;
  const alert = el.querySelector('.tds-alert--error');

  if(alert) {
    const alerts = alert.querySelector('ul');
    if(!alerts) {
      alert.setAttribute('hidden', 'true');
      alert.setAttribute('aria-hidden', 'true');
    }
  }
  // if alert div is present or show alert message is set true 
  // and also error list is present
  if((state._errorList && state._errorList.length > 0) && (config.showErrorAlert || alert)) {
    const dataFocusId = el.querySelector('input').getAttribute('id');
    const newAlert = document.createElement('div');
    newAlert.className = 'tds-alert--error tds-alert--dismissible';
    newAlert.setAttribute('tabindex', "-1");

    // aria-live = assertive | polite | off, default is polite
    setAriaLive(newAlert, config.alertMessageArialive);
    
    newAlert.innerHTML = `
      <div class="tds-alert__title">${htmlEncode(state._errorMsg)}</div>
      <ul class="alert-list"></ul>
      <button hidden aria-label="${translateHTML('dismissAlert')}" class="tds-alert__dismiss" data-focus="#${dataFocusId}">
        ${x1_18.svg({focusable: false})}
      </button>`;
    
    if(!!alert) el.replaceChild(newAlert, alert);
    else {
      const filePicker = el.querySelector(`.${FILE_UPLOAD_CLASSES.DROPAREA}`);
      el.insertBefore(newAlert, filePicker);
    }

    var errorText = '';
    state._errorList.forEach((error: any) => {
      errorText += `<li class="alert-list__item">
        ${error.filename ? `<strong>${htmlEncode(error.filename)}</strong>` : ''}
        <span>${htmlEncode(error.message)}</span>
      </li>`;
    });
    
    const alertArea = newAlert.querySelector('.alert-list');
    alertArea.innerHTML = `${errorText}`;
  } else if (alert) {
    alert.setAttribute('hidden', 'true');
    alert.setAttribute('aria-hidden', 'true');
  }
}

// populating error alert
export function populateErrorAlerts(fileUpload: FileUploadComponent, newFile: TdsFileInstance, errorCode: string, fileCount:number, listCount: number) {
  const { state, config } = fileUpload;
  const isMaxError = errorCode === 'maxExceededError' || errorCode === 'maxAllowedError';

  // for max error new file is null
  const { name, type, size } = isMaxError ? {name: "", type: "", size: 0} : newFile.file;

  const errorList = isMaxError ? [] : state._errorList || [];
  const errorType = `file${errorCode.charAt(0).toUpperCase() + errorCode.slice(1)}Alert`;
  
  const fileConstraints = {
    filetype: type,
    filecount: fileCount,
    listcount: listCount,
    maxcount: `${config.maxCount}`,
    maxsize: `${config.maxSize}`,
    filesize: `${convertFromBytes(size)}`,
    available: config.maxCount > 1 ? config.maxCount - listCount : -1
  };
  
  const hasMultipleFiles = errorList.length > 1 ? true : false;
  errorList.push({
    type: errorCode,
    filename: name,
    message: messageFormatter(fileUpload, errorType, hasMultipleFiles, fileConstraints),
    fileconstraints: fileConstraints
  });

  return errorList;
}

export function messageFormatter(fileUpload: FileUploadComponent, errorType: string, hasMultipleFiles: boolean, replacements?: any) {
  const { el, config } = fileUpload;
  const translateHTML = translations(getLang(el)).html;

  // handling custom templates if available
  const messageTemplates = config.messageTemplates && config.messageTemplates[errorType as keyof typeof config.messageTemplates];
  const tpl: any = messageTemplates && messageTemplates !== "" ? messageTemplates : translateHTML(`${errorType}`);

  // handling singular or plural template
  const values = interpolate(replacements, tpl).split(';');
  return (hasMultipleFiles && values[1]) || values[0];
}
import onDOMChanges from '../../utilities/onDOMChanges';
import { SelectState } from './SelectState';
import { instances } from '../../utilities/instances';
import { applyAttributes, applyClasses, applyStyles, configFromDataAttributes, getChildElements, watchDataAttributeChange } from '../../utilities/helpers';
import { translations } from '../../utilities/i18n';
import { Store } from "../../utilities/store";
import { NAMESPACE } from '../../utilities/constants';
import { SelectConfig } from './SelectConfig';
import { SelectComponent, bindSelect } from './selectBehavior';
import { OptionSelector, SelectContext } from './SelectContext';
import { getAriaAutocomplete, getFilterPlacement, getPopupType, manageInputAutocomplete, renderSelectedOptionsList, resolveAllowOther } from './utils';
import debounce from '../../utilities/debounce';
import { SelectedOptions } from '../selected-options';
import { createCustomEvent } from '../../utilities/customEvent';
import { Listbox } from './listbox';
import { SelectDialog } from './select-dialog';
import { ListboxConfig } from './listbox/ListboxConfig';
import { SelectDialogConfig } from './select-dialog/SelectDialogConfig';
import { SelectedOptionsConfig } from '../selected-options/SelectedOptionsConfig';
import { LoadOptions, SelectChangeDetail, SelectChangeValues } from './types';

const ENHANCED_FLAG = 'enhancedSelect';
const INSTANCE_KEY = `${NAMESPACE}Select`;
const BASE_CLASS = 'tds-select';
const PATTERN_SELECTOR = `.${BASE_CLASS}`;
const CLASSES = {
  wrapper: `${BASE_CLASS}__wrapper`,
  fieldInput: `${BASE_CLASS}__field-input`,
  fieldInputWihTags: `${BASE_CLASS}__field-input--with-tags`,
  fieldInputWihInput: `${BASE_CLASS}__field-input--with-input`,
  fieldInputWihClear: `${BASE_CLASS}__field-input--with-clear`,
  fieldInputCondensed: `${BASE_CLASS}__field-input--condensed`,
  fieldInputDisabled: `${BASE_CLASS}__field-input--disabled`,
  fieldInputReadonly: `${BASE_CLASS}__field-input--readonly`,
  garage: `${BASE_CLASS}__popup-garage`,
  internalLabel: `${BASE_CLASS}__internal-label`,
  control: `${BASE_CLASS}__control`,
  controlNoWrap: `${BASE_CLASS}__control--nowrap`,
  inputControl: `${BASE_CLASS}__input-container`,
  inputSpacer: `${BASE_CLASS}__input-spacer`,
  clear: `${BASE_CLASS}__clear`,
  hiddenInputsContainer: `${BASE_CLASS}__hidden-inputs`,
  selectedOptions: 'tds-selected-options',
  selectedOptionsNoMargin: 'tds-selected-options--no-margin'
}

let nextId = 1;

class _SelectInstance {
  host: HTMLElement;
  store: Store<SelectState>;
  state: SelectState;

  _baseId: string;
  _labelId: string;
  _lastHTMLFrame: string;
  _config: Partial<SelectConfig>;
  _apiConfig: Partial<SelectConfig>;
  _dataConfig: Partial<SelectConfig>;
  _rendered: boolean;
  _context: SelectContext;
  _focused: boolean;
  _onSelectedPropsChanged: (prop: string) => void; 
  _tagsId: string;
  _translate: (key: string, ...replacements: string[]) => string;
  _popup: HTMLElement;
  _updateInputSpacerAndPlaceholder: Function;

  onDestroy: Function[] = [];

  constructor(element: HTMLElement, config: Partial<SelectConfig> = {}) {
    element.dataset[ENHANCED_FLAG] = "true";
    this.host = element;
    this._apiConfig = config;
    this._baseId = this.host.id || `tds-select-${nextId++}`;


    // rather than create a bunch of getters for each config property
    // use a proxy to satisfty both OptionSelector and SelectComponent
    const proxy = new Proxy(this, {
      get(target: any, prop: string) {
        // use target (this) 1st so getters take precedence
        return target[prop] ?? target.config[prop];
      }
    }) as OptionSelector & SelectComponent;

    const context = this._context = SelectContext.getInstance(element, proxy);
    this.store = context.store;
    const { unbind, onSelectedPropsChanged, updateInputSpacerAndPlaceholder } = bindSelect(proxy);
    this._onSelectedPropsChanged = onSelectedPropsChanged;
    this._updateInputSpacerAndPlaceholder = updateInputSpacerAndPlaceholder;
    const unsubscribe = this.store.subscribe(this.onStateUpdate.bind(this));
    const unwatch = watchDataAttributeChange(this.host, (config) => {
      const selectionPropChanges = this.getSelectionPropChanges(config); // must be called before clearing current configs
      this._config = undefined;
      this._dataConfig = undefined; // config getter will re-generate
      this.applySelectionValuesChanges(selectionPropChanges);
      this.render();
    }, [{
      names: [
        'values',
        'selectedOptions'
      ],
      convert: 'json'
    }]);
    this.onDestroy = [unbind, unsubscribe, unwatch];
    instances.set(element, INSTANCE_KEY, this);
    this.render();
    this.render = debounce(this.render.bind(this), 0);
  }

  onStateUpdate(state: SelectState) {
    this.state = state;
    this._rendered && this.render();
  }

  updateConfig(config: Partial<SelectConfig>, doNotCheck = false) {
    if (config !== this._apiConfig) {
      const selectionPropChanges =this.getSelectionPropChanges(config); // must be called before clearing current configs
      this._apiConfig = { ...this._apiConfig, ...config };
      this._config = undefined;
      if (!doNotCheck) {
        this.applySelectionValuesChanges(selectionPropChanges);
      }
      this._rendered && this.render();
    }
  }

  getSelectionPropChanges(config: Partial<SelectConfig>): string[] {
    const selectionPropChanges: string[] = [];
    const { value, values, selectedOptions } = config;
    const { value: currValue, values: currValues = [], selectedOptions: currSelection = [] } = this;
    if (typeof value !== 'undefined' && value !== currValue) {
      selectionPropChanges.push('value');
    }
    if (typeof values !== 'undefined' && values.toString() !== currValues.toString()) {
      selectionPropChanges.push('values');
    }
    if (typeof selectedOptions !== 'undefined' && JSON.stringify(selectedOptions) !== JSON.stringify(currSelection)) {
      selectionPropChanges.push('selectedOptions');
    }
    return selectionPropChanges;
  }

  applySelectionValuesChanges(selectionPropChanges: string[]) {
    const onSelectedPropsChanged = this._onSelectedPropsChanged;
    if (onSelectedPropsChanged) {
      selectionPropChanges.forEach(p => onSelectedPropsChanged(p));
    }
  }

  render() {
    if (!this.host) return; // instance destroyed before debounce timeout
    this._translate = translations(this.host).t;
    this._tagsId = undefined;
    const html = this.renderHTMLFrame();
    if (html !== this._lastHTMLFrame) {
      this.destroySelectedOptions();
      this._lastHTMLFrame = html;
      const wrapper = this.host.querySelector(`.${CLASSES.wrapper}`);
      if (wrapper) {
        wrapper.remove();
      }
      this.host.insertAdjacentHTML('beforeend', html);
    }

    this.updateHiddenLabel();
    this.updateFieldInputContainer();
    this.updateStaticControl();
    this.updateCombobox();
    this.updateClearButton();
    this.updateSelectedOptions();
    this.updateLiveRegion();
    this.updateHiddenInputs();
    this.updatePopupGarage();
    this._updateInputSpacerAndPlaceholder();

    this._rendered = true;
  }

  renderHTMLFrame(): string {
    const html: string[] = [
      `<div class="${CLASSES.wrapper}">`,
      this.renderTags('outside'),
      this.renderHiddenLabel(),
      `<div class="${CLASSES.fieldInput}">`,
      this.renderTags('inside'),
      this.renderControl(),
      this.renderClearButton(),
      '</div>',
      '<div aria-live="polite" role="status" class="tds-sr-only"></div>',
      this.renderHiddenInputs(),
      `<div class="${CLASSES.garage}"></div>`,
      '</div>'
    ].filter(Boolean);
    return html.join('');
  }

  renderHiddenLabel() {
    const { label, labelledby } = this.getLabelAttributes();
    if (label && !labelledby) {
      const id = this._labelId = `${this._baseId}__internallabel__`;
      return `<span class="${CLASSES.internalLabel}" id=${id}></span>`;
    }
    this._labelId = '';
    return '';
  }

  renderTags(placement: 'inside' | 'outside') {
    const { config, _baseId } = this;
    const { multiple, tags } = config;
    if (multiple) {
      if (tags === placement || (!tags && placement === 'inside' && this.shouldRenderInput())) {
        const id = this._tagsId = `${_baseId}__deselectTags`;
        return `<div class="${CLASSES.selectedOptions} ${CLASSES.selectedOptionsNoMargin}" id=${id}></div>`;
      }
    }
    return '';
  }

  renderControl(): string {
    if (this.shouldRenderInput()) {
      return this.renderInputControl();
    }
    return this.renderStaticControl();
  }

  renderInputControl() {
    return `<div class="${CLASSES.control} ${CLASSES.inputControl}"><span class=${CLASSES.inputSpacer} aria-hidden="true"></span><input role="combobox"/></div>`
  }

  renderStaticControl() {
    return `<div class="${CLASSES.control}" role="combobox" tabindex="0"></div>`
  }

  renderClearButton() {
    return this.shouldRenderClear() ?
      `<button type="button" hidden class="${CLASSES.clear}" tabIndex="-1" aria-label=${this._translate('clear')}></button>` :
      '';
  }
  renderHiddenInputs() {
    return this.config.hiddenInputs ?
      `<div class="${CLASSES.hiddenInputsContainer}"></div>` :
      '';
  }

  updateHiddenLabel() {
    const hiddenLabel = this.host.querySelector(`.${CLASSES.wrapper} span.${CLASSES.internalLabel}`);
    if (hiddenLabel) {
      const { label } = this.getLabelAttributes();
      hiddenLabel.textContent = label;
    }
  }

  updateStaticControl() {
    const control = this.host.querySelector<HTMLElement>(`div.${CLASSES.control}[role="combobox"]`);
    if (control) {
      applyClasses(control, { [CLASSES.controlNoWrap]: !this.config.multiple });
    }
  }

  updateFieldInputContainer() {
    const { state, host } = this;
    const { tags, disabled, readonly } = this.config;
    const withInlineTags = tags === 'inside' && state.selectedOptions.length > 0;
    const fieldInput = host.querySelector<HTMLElement>(`.${CLASSES.wrapper} div.${CLASSES.fieldInput}`);
    if (fieldInput) {
      const fieldInputclasses = {
        [CLASSES.fieldInputWihTags]: withInlineTags,
        [CLASSES.fieldInputWihInput]: this.shouldRenderInput(),
        [CLASSES.fieldInputWihClear]: this.shouldRenderClear(),
        [CLASSES.fieldInputCondensed]: this.condensed,
        [CLASSES.fieldInputDisabled]: disabled,
        [CLASSES.fieldInputReadonly]: readonly
      }
      applyClasses(fieldInput, fieldInputclasses);
    }
  }

  updateSelectedOptions() {
    const { config } = this;
    const { tags } = config;
    const selectedOptions = this.selectedOptionsElement;
    if (selectedOptions) {
      const placement = !!selectedOptions.closest(`.${CLASSES.fieldInput}`) ? 'inside' : 'outside';
      applyAttributes(selectedOptions, {
        // set tabindex if "no tags" and placed inside fieldInput container
        tabindex: (!tags && placement === 'inside') ? '-1' : undefined
      });

      const soConfig: SelectedOptionsConfig = {
        optionName: config.optionShortName || config.optionName,
        optionValue: config.optionValue,
        optionId: config.optionId,
        optionDisabled: config.optionDisabled,
        forSelect: this.selectContext,
        inline: placement === 'inside',
        limitTags: config.summarizeAfter,
        delimiter: config.delimiter,
        notags: !tags,
        returnFocusTo: this.combobox,
        prepElement: config.prepElement,
        contentRenderer: config.tagContentRenderer,
      }
      new SelectedOptions(selectedOptions, soConfig);
    }
  }

  updateCombobox() {
    const { config, state, popup, applyFilter } = this;
    const { disabled, multiple, inlineComplete, tags, hiddenInputs, name, form, prepElement } = config;
    const { showList } = state;
    const combobox = this.combobox;
    const popupType = this.getPopupType();
    let attributes: { [key: string]: string | undefined } = {
      "aria-controls": popup.id,
      "aria-expanded": `${showList}`,
      "aria-haspopup": popupType,
      "aria-activedescendant": popupType === 'dialog' ? undefined : showList ? state.activeOptionId : '',
      "aria-labelledby": this.getAriaLabelledBy(),
      "aria-describedby": this.getAriaDescribedBy(),
      "aria-disabled": disabled ? `${disabled}` : undefined
    }
    if (combobox.matches('input')) {
      const inputClasses: { [key: string]: boolean } = {};
      const inputStyles: { [key: string]: string | undefined } = {};
      const otherInputAttributes: { [key: string]: string | undefined } = {
        autocomplete: 'off',
        autocapitalize: 'off',
        autocorrect: 'off',
        spellcheck: 'false',
        name: !hiddenInputs ? name : undefined,
        form: !hiddenInputs ? form : undefined
      };
      if (prepElement) {
        prepElement({ type: 'input' }, inputClasses, inputStyles, otherInputAttributes);
      }
      applyClasses(combobox, inputClasses);
      applyStyles(combobox, inputStyles);
      attributes = {
        ...attributes,
        "aria-autocomplete": getAriaAutocomplete({ inlineComplete, applyFilter }),
        ...otherInputAttributes
      }
      if (!combobox.onfocus) {
        const handler = manageInputAutocomplete();
        combobox.onfocus = handler;
        combobox.onblur = handler;
      }
    } else if (!multiple || !tags) {
      combobox.textContent = renderSelectedOptionsList(config as SelectConfig, state, this._translate);
    }
    applyAttributes(combobox, attributes);
  }

  updateClearButton() {
    const clearButton = this.clearButton;
    if (clearButton) {
      clearButton.hidden = !this.value;
    }
  }

  updateLiveRegion() {
    const liveRegion = this.host.querySelector(`.${CLASSES.wrapper} div[aria-live]`)
    if (liveRegion) {
      liveRegion.textContent = this.state.optionsStatus || '';
    }
  }

  updateHiddenInputs() {
    const host = this.host;
    const hiddenInputs = host.querySelector(`.${CLASSES.wrapper} div.${CLASSES.hiddenInputsContainer}`);
    if (hiddenInputs) {
      const { name, form } = this.config;
      const values = this.values || [];
      if (!values.length) {
        values.push(this.value || '');
      }
      const inputs = Array.from(hiddenInputs.querySelectorAll<HTMLInputElement>('input[type="hidden"]'));
      while (inputs.length > values.length) {
        inputs.pop().remove();
      }
      values.forEach((value, i) => {
        let input = inputs[i];
        if (!input) {
          input = host.ownerDocument.createElement('input');
          input.type = 'hidden';
          hiddenInputs.append(input);
        }
        applyAttributes(input, { name, form });
        input.value = value;
      });
    }
  }

  updatePopupGarage() {
    const { popupGarage, popup } = this;
    if (popupGarage && popup && popup.parentElement !== popupGarage && !this.state.showList) {
      popupGarage.appendChild(popup);
    }
  }

  shouldRenderInput() {
    return this.allowOtherResolved !== 'false' || this.filterPlacement === 'inline';
  }

  shouldRenderClear() {
    const config = this.config;
    return config.clear && !config.disabled && !config.readonly;
  }


  dispatchEvent(eventType: 'tdsChange' | 'tdsBlur' | 'tdsFocus', detail?: SelectChangeDetail): boolean {
    return this.host.dispatchEvent(createCustomEvent(eventType, { cancelable: false, bubbles: true, detail }));
  }

  onShowPopup() {
    const config = this.config;
    const multiple = config.multiple;
    const { activeIndex, filterText } = this.state;
    const popup = this.popup;
    const isListbox = popup.getAttribute('role') === 'listbox';
    const condensedClass = `tds-${isListbox ? 'listbox' : 'select-dialog'}--condensed`;
    popup.classList[this.condensed ? 'add' : 'remove'](condensedClass);
    const Module = isListbox ? Listbox : SelectDialog;
    const popupConfig = {
      ...this.config,
      searchLabel: config.optionsSearchLabel,
      searchPlaceholder: config.optionsSearchPlaceholder
    };
    if (popupConfig.hasOwnProperty('options')) {
      delete popupConfig.options;
    }
    popupConfig.condensed = this.condensed;
    const { labelledby, label } = this.getLabelAttributes();
    popupConfig.labelledby = labelledby || undefined;
    popupConfig.label = label && !labelledby ? label : undefined;
    popupConfig.filter = this.filterPlacement === 'withlist';
    const module = new Module(popup, popupConfig);
    const filterHandoff = popupConfig.filter && filterText && filterText.length === 1 ? filterText : '';
    if (!isListbox && (multiple || activeIndex > -1 || filterHandoff)) {
      (module as SelectDialog).filterHandoff = filterHandoff;
      (module as SelectDialog).focus();
    }
  }

  onHidePopup() {
    const popup = this.popup;
    if (popup?.getAttribute('role') === 'dialog') {
      new SelectDialog(popup).clearFilter();
    }
  }

  updateSelectedProps({ value, values, selectedOptions }: SelectChangeValues) {
    this.updateConfig({ value, values, selectedOptions }, true);
  }

  focusCombobox() {
    const combobox = this.combobox;
    const inputControl = this.inputControl;
    if (combobox) {
      combobox.focus();
      if (combobox === inputControl) {
        const selectionPos = inputControl.value.length;
        inputControl.selectionStart = selectionPos;
        inputControl.selectionEnd = selectionPos;
      }
    }
  }

  focusDialog() {
    const popup = this.popup;
    if (popup && popup.matches('[role="dialog"]')) {
      new SelectDialog(popup).focus();
    }
  }

  focusLastDeselectOption(): boolean {
    const selectedOptions = this.selectedOptionsElement;
    const tags = this.config.tags;
    if (selectedOptions && tags !== 'outside' && this.state.selectedOptions.length) {
      return new SelectedOptions(selectedOptions).focusOnLast();
    }
    return false;
  }

  getLabelAttributes(): { label: string, labelledby: string } {
    const config = this.config;
    let labelAttributes = {
      label: config.label || '',
      labelledby: config.labelledby || ''
    }
    if (!labelAttributes.label && !labelAttributes.labelledby) {
      const input = this.getChildInput();
      if (input) {
        labelAttributes = getInputLabelAttributes(input);
      }
    }
    if (!labelAttributes.label && !labelAttributes.labelledby) {
      const tdsField = this.host.closest('.tds-field');
      const tdsLabel = tdsField?.querySelector('.tds-field__label');
      if (tdsLabel) {
        if (tdsLabel.id) {
          labelAttributes.labelledby = tdsLabel.id;
        } else {
          labelAttributes.label = tdsLabel.textContent;
        }
      }
    }

    return labelAttributes
  }

  getAriaLabelledBy(): string | undefined {
    const ids = [this.getLabelAttributes().labelledby || this._labelId, this._tagsId]
      .filter(Boolean)
      .join(' ')
      .trim();
    return ids || undefined
  }

  getAriaDescribedBy(): string {
    let describedby = this.config.describedby;
    if (describedby) {
      return describedby;
    }
    const input = this.getChildInput();
    describedby = input && input.getAttribute('aria-describedby');
    if (!describedby) {
      const tdsField = this.host.closest('.tds-field');
      const tdsHelpText = tdsField?.querySelector('.tds-field__help-text');
      const tdsMessage = tdsField?.querySelector('.tds-field__message');
      describedby = [tdsHelpText?.id, tdsMessage?.id].filter(Boolean).join(' ');
    }
    return describedby || '';
  }

  getChildInput() {
    return getChildElements(this.host, 'select, input')[0] as HTMLElement;
  }

  getPopupType(): 'listbox' | 'dialog' {
    return getPopupType(this.config.selectAll, this.filterPlacement);
  }

  get config(): Partial<SelectConfig> {
    if (!this._config) {
      if (!this._dataConfig) {
        this._dataConfig = configFromDataAttributes(this.host, {}, [
          {
            names: ['maxItems', 'filterDelay', 'summarizeAfter'],
            convert: 'integer'
          },
          {
            names: ['allowOther'],
            convert: (value: string) => {
              return (value === '') ? 'true' : value
            }
          },
          {
            names: ['loadingText', 'noOptionsText'],
            convert: (value: any) => {
              // configFromDataAttributes will convert "false" and "true" to boolean. These need to stay strings
              return (value || '').toString();
            }
          },
          {
            names: [
              'options',
              'optionDisabled',
              'filterPredicate',
              'prepElement',
              'optionRenderer',
              'optionStatusRenderer',
              'tagContentRenderer'
            ],
            convert: 'function'
          },
          {
            names: [
              'options',
              'values',
              'selectedOptions',
              'defaultOptions'
            ],
            convert: 'object'
          },
          {
            names: [
              'options',
              'values',
              'selectedOptions',
              'defaultOptions'
            ],
            convert: 'json'
          }
        ]) as Partial<SelectConfig>;
      }
      this._config = {
        // defaults
        options: [],
        optionName: 'name',
        optionValue: 'value',
        multiple: false,
        summarizeAfter: -1,
        summarizeFormat: 'over',
        delimiter: ',',
        ...this._dataConfig,
        ...this._apiConfig
      };
    }
    return this._config;
  }

  get condensed(): boolean {
    const host = this.host;
    return (this.config.condensed ?? !!host.closest('.tds-field--condensed, .tds-select--condensed'));
  }

  get applyFilter() { return this.filterPlacement === 'inline' }

  get options(): any[] | LoadOptions {
    const { options: configOptions, optionName, optionValue, groupBy, optionDisabled } = this.config;
    if (configOptions && configOptions.length) return configOptions;
    const derived = deriveOptionsFromOptionElements(this.host, optionName, optionValue);
    if (derived) {
      const { options, useGroupBy, useOptionDisabled } = derived;
      if (useGroupBy && useGroupBy !== groupBy) {
        this.updateConfig({ groupBy: useGroupBy });
      }
      if (useOptionDisabled && useOptionDisabled !== optionDisabled) {
        this.updateConfig({ optionDisabled: useOptionDisabled });
      }
      return options;
    }
    return [];
  }

  get selectedOptions(): any[] {
    const config = this.config;
    const options = config.selectedOptions;
    if (options) return options;
    const { optionName, optionValue } = config;
    const derived = deriveOptionsFromOptionElements(this.host, optionName, optionValue);
    if (derived) {
      return derived.selectedOptions
    }
    return [];
  }

  set selectedOptions(selectedOptions: any[]) {
    this.updateConfig({ selectedOptions });
  }

  get value(): string { return this.config.value ?? '' }

  set value(value: string) {
    this.updateConfig({ value });
  }

  get values(): string[] { return this.config.values ?? [] }

  set values(values: string[]) {
    this.updateConfig({ values });
  }

  get selectContext(): SelectContext { return this._context; }

  get combobox(): HTMLElement { return this.host?.querySelector(`.${CLASSES.wrapper} [role="combobox"]`) }

  get inputControl(): HTMLInputElement { return this.host?.querySelector<HTMLInputElement>(`.${CLASSES.wrapper} input[role="combobox"]`) }

  get inputSpacer(): HTMLElement { return this.host?.querySelector<HTMLElement>(`span.${CLASSES.inputSpacer}`) }

  get clearButton(): HTMLElement { return this.host?.querySelector<HTMLElement>(`.${CLASSES.wrapper} button.${CLASSES.clear}`) }

  get selectedOptionsElement(): HTMLElement { return this.host?.querySelector(`.${CLASSES.wrapper} div.${CLASSES.selectedOptions}`) }

  get popupGarage(): HTMLElement { return this.host?.querySelector<HTMLInputElement>(`.${CLASSES.wrapper} div.${CLASSES.garage}`) }

  get popoverReference(): HTMLElement { return this.host?.querySelector<HTMLInputElement>(`.${CLASSES.wrapper} div.${CLASSES.fieldInput}`) }

  get popup(): HTMLElement {
    const host = this.host;
    const popupRole = this.getPopupType();
    if (!this._popup || this._popup.getAttribute('role') !== popupRole) {
      this._popup && this._popup.remove();
      this.destroyPopup();
      const isListbox = popupRole === 'listbox';
      const popup = this._popup = host.ownerDocument.createElement('div');
      popup.id = `${this._baseId}__popup`;
      const cls = isListbox ? 'tds-listbox' : 'tds-select-dialog';
      popup.classList.add(cls);
      popup.classList.add(`${cls}--popup`);
      popup.setAttribute('role', popupRole);
      const langEl = this.host.closest<HTMLElement>('[lang]');
      if (langEl) {
        popup.lang = langEl.lang;
      }
      let popupConfig: ListboxConfig | SelectDialogConfig = {
        selectContext: this._context,
        ...this.config,
        options: undefined
      };
      if (isListbox) {
        popupConfig = { ...popupConfig, controller: host }
      }

      const Module = isListbox ? Listbox : SelectDialog;
      new Module(popup, popupConfig)
      const popupGarage = this.popupGarage;
      if (popupGarage) {
        popupGarage.appendChild(popup);
      }
    }
    return this._popup
  }

  get filterPlacement(): 'inline' | 'withlist' | undefined {
    const config = this.config;
    return getFilterPlacement(config.filter, config.tags, config.allowOther);
  }

  get allowOtherResolved(): 'true' | 'false' | 'prompt' {
    return resolveAllowOther(this.config.allowOther);
  }

  get focused(): boolean { return this._focused; }

  set focused(b: boolean) {
    this._focused = b;
  }

  destroy() {
    if (this.host) {
      while (this.onDestroy && this.onDestroy.length) {
        const fn = this.onDestroy.pop();
        fn();
      }
      this.destroyPopup();
      this.destroySelectedOptions();
      if (this.host) {
        delete this.host.dataset[ENHANCED_FLAG];
      }
      instances.remove(this.host, INSTANCE_KEY);
      this.host = this.store = null;
    }
  }

  destroyPopup() {
    const popup = this._popup;
    if (popup) {
      const Module = popup.getAttribute('role') === 'listbox' ? Listbox : SelectDialog;
      new Module(popup).destroy();
      delete this._popup;
    }
  }

  destroySelectedOptions() {
    const selectedOptions = this.selectedOptionsElement;
    if (selectedOptions) {
      new SelectedOptions(selectedOptions).destroy();
    }
  }
}


class Select {
  _instance: _SelectInstance;
  constructor(element: HTMLElement, config?: Partial<SelectConfig>) {
    this._instance = <_SelectInstance>instances.get(element, INSTANCE_KEY) || new _SelectInstance(element, config);
    if (config) {
      this.updateConfig(config);
    }
  }

  updateConfig(config: Partial<SelectConfig>) {
    this._instance?.updateConfig(config);
  }

  get value(): string { return this._instance?.value }

  set value(value: string) { this._instance && (this._instance.value = value) }

  get values(): string[] { return this._instance?.values }

  set values(values: string[]) { this._instance && (this._instance.values = values) }

  get selectedOptions(): any[] { return this._instance?.selectedOptions }

  set selectedOptions(selectedOptions: any) { this._instance && (this._instance.selectedOptions = selectedOptions) }

  destroy() {
    return this._instance.destroy();
  }
}

onDOMChanges(`${PATTERN_SELECTOR}`,
  function onPatternAdded(element: HTMLElement) {
    if (!element.dataset[ENHANCED_FLAG]) {
      new Select(element)
    }
  },
  function onPatternRemoved(element: HTMLElement) {
    if (element.dataset[ENHANCED_FLAG] === "true") {
      new Select(element).destroy();
    }
  }
);

// todo: make this a utility function
// we have a similar function in WC, isInputLabelled, which uses similar logic but not in this way
function getInputLabelAttributes(input: HTMLElement): { label: string, labelledby: string } {
  const doc = input.ownerDocument;
  let label = input.getAttribute('aria-label') || '';
  let labelledby = input.getAttribute('aria-labelledby') || '';
  if (!label && !labelledby) {
    const labelEl: HTMLElement = (input.id && doc.querySelector(`label[for="${input.id}"]`)) || input.closest('label');
    if (labelEl) {
      if (labelEl.id) {
        labelledby = labelEl.id;
      } else {
        label = labelEl.textContent;
      }
    }
  }
  return { label, labelledby };
}

function deriveOptionsFromOptionElements(host: HTMLElement, optionName = 'name', optionValue = 'value'): { options: any[], selectedOptions: any[], useGroupBy: string, useOptionDisabled: string } | undefined {
  const optionsParent = host && getChildElements(host, 'select, datalist')[0];
  if (optionsParent) {
    const hasGroups = !!optionsParent.querySelector('optgroup');
    let useGroupBy = hasGroups ? 'groupName' : undefined;
    let useOptionDisabled: string = undefined;
    const selectedOptions: any[] = [];
    const optionsEls = Array.from(optionsParent.querySelectorAll('option'));
    const options = optionsEls
      .map((optionEl: HTMLOptionElement) => {
        const name = optionEl.textContent.trim() || optionEl.value;
        const value = optionEl.value ?? name;
        const option: any = {
          [optionValue]: value,
          [optionName]: name,
        }
        const optgroup = optionEl.closest('optgroup');
        if (optgroup) {
          option[useGroupBy] = optgroup.label;
        } else if (hasGroups) {
          option[useGroupBy] = ''; // no logical default
        }
        if (optionEl.selected) {
          selectedOptions.push(option);
        }
        if (optionEl.disabled || (optgroup && optgroup.disabled)) {
          useOptionDisabled = 'disabled';
          option[useOptionDisabled] = true;
        }
        return option;
      });
    return {
      options,
      selectedOptions,
      useGroupBy,
      useOptionDisabled
    }
  }
  return undefined;
}



export { Select }
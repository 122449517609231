import { Store } from "../../utilities/store";
import { SpecializedGroupName } from "./constants";
import { SelectOption, SelectState } from "./SelectState";
import { isIncluded } from "./utils";

interface OrderOptionsContext {
  groupBy?: string,
  multiple?: boolean,
  selectedFirst?: boolean,
}

export function updateOrderedOptions(store: Store<SelectState>, context: OrderOptionsContext) {
  const { filteredOptions, selectedOptions } = store.get();
  store.update(orderOptions(filteredOptions, selectedOptions, context));
}

export function orderOptions(options: SelectOption[], selectedOptions: SelectOption[], context: OrderOptionsContext): Partial<SelectState> {
  if (context.groupBy) {
    return groupOptions(options, selectedOptions, context);
  } else if (context.selectedFirst && context.multiple) {
    return orderSelectedFirst(options, selectedOptions);
  } else {
    return { orderedOptions: options, groupedOptions: undefined };
  }
}

function groupOptions(options: SelectOption[], selectedOptions: SelectOption[], context: OrderOptionsContext): Partial<SelectState> {
  const { selectedFirst, multiple } = context;
  const groupedOptions = options.reduce((groups, option: SelectOption) => {
    const isSelected = selectedFirst && multiple && isIncluded(option, selectedOptions);
    const groupName = isSelected ? SpecializedGroupName.Selected : option.groupName; // rendering the Selected group name will be a component concern
    let group = groups.find(group => group.name === groupName);
    if (!group) {
      group = { name: groupName, options: [] };
      const method = (selectedFirst && isSelected) ? 'unshift' : 'push';
      groups[method](group);
    }
    group.options.push(option);
    return groups;
  }, []);
  const orderedOptions = groupedOptions.reduce((list, group) => { return [...list, ...group.options] }, []);
  return { orderedOptions, groupedOptions };
}

function orderSelectedFirst(options: SelectOption[], selectedOptions: SelectOption[]): Partial<SelectState>{
  const firstOptions: SelectOption[] = [];
  const lastOptions: SelectOption[] = [];
  options.forEach(option => {
    const arr = isIncluded(option, selectedOptions) ? firstOptions : lastOptions;
    arr.push(option);
  });
  return { orderedOptions: [...firstOptions, ...lastOptions], groupedOptions: undefined };
}

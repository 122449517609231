import watchForChanges from '../../../utilities/watchForChanges';
import { EventListeners } from '../../../utilities/EventListeners';
import { getChildElements } from '../../../utilities/helpers';
import { getLang, translations } from '../../../utilities/i18n';
import { isTyping, normalizeKey } from '../../../utilities/keyboard';
import { EditableDataTableComponent } from './EditableDataTableComponent';
import { CELL_EDITABLE_ATTR, COLUMN_EDITABLE_ATTR, ROW_EDIT_ACTION_ATTR } from './editableDataTableConstants';
import { manageErrorPopovers } from './errorPopoverManager';
import makeGrid from './makeGrid';
import { announce, getColumnHeader, isEditableRow } from './utils';

enum EndEditSaveAction {
  No = 0,
  Yes,
  IfChanged
}


export function bindEditing(editableDataTable: EditableDataTableComponent) {
  const table = editableDataTable.table;
  const beginEditListeners = new EventListeners();
  const endEditListeners = new EventListeners();
  let startingEditCell: HTMLTableCellElement;
  let editing = false;

  const unwatchForChanges = watchForChanges(table, { childList: true, subtree: true }, () => setCellEditableStates(table));
  setCellEditableStates(table);
  const { stop: stopGrid, resume: resumeGrid } = makeGrid(table);
  const endManageErrorPopovers = manageErrorPopovers(table);
  beginEditHandlers();

  function beginEditHandlers() {
    beginEditListeners.addListener(table, 'keydown', beginEditKeydownHandler);
    beginEditListeners.addListener(table, 'mousedown', onMousedownBeginEditHandler, { capture: true });
    beginEditListeners.addListener(table, 'touchstart', onMousedownBeginEditHandler, { capture: true, passive: true });
    // beginEditListeners.addListener(document, 'paste', beginEditPasteHandler) // not 100%
    if (editableDataTable.editMode === 'row') {
      beginEditListeners.addListener(table, 'click', beginEditClickHandler);
    }
  }

  function endEditHandlers() {
    endEditListeners.addListener(table, 'keydown', endEditKeyHandler);
    endEditListeners.addListener(table, 'click', endEditButtonClick);
    endEditListeners.addListener(document, 'mousedown', endEditMouseHandler);
    endEditListeners.addListener(document, 'touchstart', endEditMouseHandler, { passive: true });
  }

  function beginEditClickHandler(event: Event) {
    const targetEl = event.target as Element;
    const actionEl = targetEl.closest(`[${ROW_EDIT_ACTION_ATTR}]`);
    const row = actionEl && actionEl.closest('tr');
    if (row) {
      switch (actionEl.getAttribute(ROW_EDIT_ACTION_ATTR)) {
        case 'edit':
          startRowEdit(row, 'edit-row');
          break;

        case 'delete':
          deleteRow(row, 'delete-row');
          break;
      }
    }
  }

  function beginEditKeydownHandler(event: KeyboardEvent) {
    let startAction = false;
    let initialValue: string;
    let action = normalizeKey(event as KeyboardEvent);
    let preventDefault = false;
    switch (action) {
      case 'Enter':
      case 'Backspace':
      case 'Delete':
      case 'F2':
        const { ctrlKey, metaKey, altKey, shiftKey } = event as KeyboardEvent;
        startAction = !(ctrlKey || metaKey || altKey || shiftKey);
        preventDefault = true;
        break;

      default:
        if (isTyping(event as KeyboardEvent)) {
          // initialValue is set to the character typed. But the event is not cancelled
          // the cell editor should know whether to apply the character when initializing
          // or to initialize the component blank and let the key press continue to the component
          initialValue = action;
          action = 'typing'
          startAction = true;
        }
    }
    if (startAction) {
      const targetEl = (event.target as Element);
      let cell = targetEl.closest(`[${CELL_EDITABLE_ATTR}='true']`) as HTMLTableCellElement;
      if (!cell && editableDataTable.editMode === 'row' && action === 'Enter') {
        const row = targetEl.closest('tr');
        cell = row && row.querySelector(`[${CELL_EDITABLE_ATTR}='true']`);
      }
      if (cell) {
        if (preventDefault) {
          event.preventDefault();
        }
        startEditMode(cell, action, initialValue);
      }
    }
  }

  // Not working 100%. Not clear why. Paste event only fires for the cell when the cell was clicked to receive focus
  // When keyboard used, paste event is fired at the document level, even though cell clearly has focus
  // function beginEditPasteHandler(event: ClipboardEvent) {
  //   const targetEl = event.target as Element;
  //   const cell = targetEl.closest(`[${CELL_EDITABLE_ATTR}='true']`) as HTMLTableCellElement;
  //   if (cell) {
  //     const clipboardData = event.clipboardData || (window as any).clipboardData;
  //     if (clipboardData) {
  //       event.preventDefault();
  //       const text = clipboardData.getData('text');
  //       startEditMode(cell, 'paste', text);
  //     }
  //   }
  // }

  function onMousedownBeginEditHandler(event: MouseEvent) {
    const targetEl = (event.target as Element);
    // if the cell already has focus on mousedown, initiate edit
    // this will act as a 'dblclick' and prevent selection of text that dblclick causes
    // delayed click will also initiate editing, but this is common 
    let cell = targetEl.closest(`[${CELL_EDITABLE_ATTR}]:focus`) as HTMLTableCellElement;
    if (cell && cell.getAttribute(CELL_EDITABLE_ATTR) !== 'true' && editableDataTable.editMode === 'row') {
      const row = targetEl.closest('tr');
      cell = row && row.querySelector(`[${CELL_EDITABLE_ATTR}='true']`);
    }
    if (cell && cell.getAttribute(CELL_EDITABLE_ATTR) === 'true') {
      event.preventDefault();
      event.stopPropagation();
      startEditMode(cell, 'dblclick');
    }
  }


  function endEditKeyHandler(event: KeyboardEvent) {
    //todo: active cell may not be accurate
    const activeCell = (event.target as Element).closest(`[${CELL_EDITABLE_ATTR}='true']`) as HTMLTableCellElement;
    let saveAction: EndEditSaveAction;
    const key = normalizeKey(event);
    let navigateTo: 'up' | 'down' | 'previous' | 'next';
    switch (key) {
      case 'Enter':
        if (!event.shiftKey && !event.altKey && !event.ctrlKey && !event.metaKey && activeCell) {
          saveAction = EndEditSaveAction.Yes;
          navigateTo = 'down';
        }
        break;
      case 'Escape':
        saveAction = EndEditSaveAction.No;
        break;
      case 'Tab':
        if (editableDataTable.isTabbingAway(event)) {
          saveAction = EndEditSaveAction.IfChanged;
          navigateTo = event.shiftKey ? 'previous' : 'next';
        }
        break;
    }
    if (typeof saveAction !== 'undefined') {
      event.preventDefault();
      endEditMode(key, saveAction, activeCell, true, navigateTo);
    }
  }

  function endEditMouseHandler(event: MouseEvent) {
    if (editableDataTable.isClickOutside(event)) {
      let activeCell: HTMLTableCellElement;
      const targetEl = event.target as Element;
      let focus = false;
      if (table.contains(targetEl)) {
        //todo: active cell may not be accurate
        activeCell = targetEl.closest(`tbody [${CELL_EDITABLE_ATTR}='true']`) as HTMLTableCellElement;
        focus = !!activeCell;
      }
      if (!activeCell) {
        //reset to first cell
        activeCell = table.querySelector('tbody > tr > *') as HTMLTableCellElement;
      }
      endEditMode('ClickAway', EndEditSaveAction.IfChanged, activeCell, focus);
    }
  }

  function endEditButtonClick(event: MouseEvent) {
    const targetEl = event.target as Element;
    const saveButton = targetEl.closest(`[${ROW_EDIT_ACTION_ATTR}="save"]`)
    const cancelButton = targetEl.closest(`[${ROW_EDIT_ACTION_ATTR}="cancel"]`)
    if (saveButton || cancelButton) {
      // todo: need to account for row editing
      const activeCell = targetEl.closest(`[${CELL_EDITABLE_ATTR}='true']`) as HTMLTableCellElement;
      const action = saveButton ? 'save-button' : 'cancel-button';
      const saveAction = saveButton ? EndEditSaveAction.Yes : EndEditSaveAction.No;
      endEditMode(action, saveAction, activeCell, !!activeCell);
    }
  }

  function startEditMode(cell: HTMLTableCellElement, action: string, initialValue?: string) {
    if (editing) {
      endEditMode('api', EndEditSaveAction.No, null, false);
    }
    switch (action) {
      case 'Backspace':
      case 'Delete':
        initialValue = '';
        break;
    }

    const data = editableDataTable.editMode === 'row' ? editableDataTable.getRowData(cell) : editableDataTable.getCellData(cell);
    let allow = true;
    const eventType = editableDataTable.editMode === 'row' ? 'tdsRowEditStart' : 'tdsCellEditStart';
    allow = editableDataTable.dispatchEvent(eventType, { action, data });

    if (allow) {
      editing = true;
      stopGrid();
      beginEditListeners.removeListeners();
      // this was originally wrapped in timeout. By removing timeout, the key press started 
      // in the cell is sent to the editor instead. This works especially nice for select
      // where typing can be used to select element in the list
      startingEditCell = cell;
      editableDataTable.beginEditing(cell, initialValue, action);
      endEditHandlers();
      const eventType2 = editableDataTable.editMode === 'row' ? 'tdsRowEditStarted' : 'tdsCellEditStarted';
      editableDataTable.dispatchEvent(eventType2, { action, data });
      if (editableDataTable.editMode === 'row') {
        const message = getRowEditingAnnouncement(cell.closest('tr'));
        announce(message, table);
      }
    }
  }

  function endEditMode(action: string, saveAction: EndEditSaveAction, activeCell: HTMLTableCellElement, focus: boolean, navigateTo?: 'up' | 'down' | 'previous' | 'next'): Promise<void> {
    activeCell = activeCell || startingEditCell;
    const eventType = editableDataTable.editMode === 'row' ? 'tdsRowEditEnd' : 'tdsCellEditEnd';
    const data = editableDataTable.editMode === 'row' ? editableDataTable.getRowData(startingEditCell) : editableDataTable.getCellData(startingEditCell);
    const save = saveAction === EndEditSaveAction.Yes || (saveAction === EndEditSaveAction.IfChanged && editableDataTable.hasChanged());
    const allow = editableDataTable.dispatchEvent(eventType, { action, cancelled: !save, data });
    if (allow) {
      return editableDataTable.endEditing(save).then(ended => {
        if (ended) {
          editing = false
          endEditListeners.removeListeners();
          beginEditHandlers();
          resumeGrid(activeCell, focus, navigateTo);
          if (editableDataTable.table?.isConnected) {
            const eventType2 = editableDataTable.editMode === 'row' ? 'tdsRowEditEnded' : 'tdsCellEditEnded';
            const data2 = editableDataTable.editMode === 'row' ? editableDataTable.getRowData(startingEditCell) : editableDataTable.getCellData(startingEditCell);
            const updates = save ? editableDataTable.getUpdatesApplied() : undefined;
            editableDataTable.dispatchEvent(eventType2, { action, cancelled: !save, data: data2, updates });
          }
        }
      });
    } else {
      return Promise.resolve();
    }
  }

  function startCellEdit(cell: HTMLTableCellElement) {
    cell.setAttribute(CELL_EDITABLE_ATTR, 'true');
    startEditMode(cell, 'api');
  }

  function startRowEdit(row: HTMLTableRowElement, action = 'api') {
    if (editableDataTable.editMode === 'row') {
      const startingCell = row && row.querySelector(`[${CELL_EDITABLE_ATTR}="true"]`) as HTMLTableCellElement;
      if (startingCell) {
        startEditMode(startingCell, action);
      }
    }
  }

  function endEdit(save: boolean): Promise<void> {
    return editing ? endEditMode('api', save ? EndEditSaveAction.Yes : EndEditSaveAction.No, null, false) : Promise.resolve();    
  }

  function deleteRow(row: HTMLTableRowElement, action: string) {
    const data = editableDataTable.getRowData(row);
    if (editableDataTable.dispatchEvent('tdsRowDelete', { action, data })) {
      Promise.resolve(editableDataTable.deleteRow(data))
        .then((ok: boolean) => {
          if (ok) {
            editableDataTable.dispatchEvent('tdsRowDeleted', { action, data });
          }
        });
    }
  }

  function unbind() {
    stopGrid();
    beginEditListeners.removeListeners();
    endEditListeners.removeListeners();
    unwatchForChanges();
    endManageErrorPopovers();
    clearCellDescribers(table);
  }

  return {
    unbind,
    startCellEdit,
    startRowEdit,
    endEdit
  }
}

function setCellEditableStates(table: HTMLTableElement) {
  const cells = Array.from(table.querySelectorAll(`tbody > tr > :not([${CELL_EDITABLE_ATTR}])`)) as HTMLTableCellElement[];
  const [editableDescriber, noneditableDescriber] = getCellDescribers(table);
  cells.filter(cell => cell.closest('table') === table)
    .filter(cell => {
      const row = cell.closest('tr');
      return !!row && isEditableRow(row);
    })
    .forEach(cell => {
      const column = getColumnHeader(cell);
      const isEditable = !!column &&
        column.hasAttribute(COLUMN_EDITABLE_ATTR) &&
        ['', 'true'].indexOf(column.getAttribute(COLUMN_EDITABLE_ATTR).toLowerCase()) > -1;
      cell.setAttribute(CELL_EDITABLE_ATTR, `${isEditable}`);
      cell.setAttribute('role', 'gridcell');
      cell.setAttribute('aria-readonly', `${!isEditable}`);
      const describedBy = (cell.getAttribute('aria-describedby') || '') + ' ' + (isEditable ? editableDescriber.id : noneditableDescriber.id);
      cell.setAttribute('aria-describedby', describedBy.trim());
    });
}

const EDITABLE_DESCRIBER_ID = "__tds-editable-cell-describer__";
const NONEDITABLE_DESCRIBER_ID = "__tds-noneditable-cell-describer__";

function getCellDescribers(table: HTMLElement) {
  const translation = translations(table).t;
  return [
    getDescriber(EDITABLE_DESCRIBER_ID, translation("editable"), table),
    getDescriber(NONEDITABLE_DESCRIBER_ID, translation("readonly"), table)
  ]

}

function clearCellDescribers(table: HTMLElement) {
  [EDITABLE_DESCRIBER_ID, NONEDITABLE_DESCRIBER_ID].forEach(id => {
    const describer = table.ownerDocument.getElementById(id);
    if (describer && !table.ownerDocument.querySelector(`[aria-describedby~="${id}"]`)) {
      describer.parentNode.removeChild(describer);
    }
  })
}

function getDescriber(id: string, content: string, table: HTMLElement) {
  let describer = table.ownerDocument.getElementById(id);
  if (!describer) {
    describer = table.ownerDocument.createElement('span');
    describer.id = id;
    describer.hidden = true;
    describer.style.display = "none";
    describer.textContent = content;
    table.ownerDocument.body.appendChild(describer);
  }
  return describer;
}

function getRowEditingAnnouncement(row: HTMLTableRowElement) {
  const lang = getLang(row);
  const translation = translations(lang).t;
  const rowIndex = row.getAttribute('aria-rowindex') || (row.rowIndex + 1).toString();
  const columnIndexes = getChildElements(row, `[${CELL_EDITABLE_ATTR}="true"]`).map((cell: HTMLTableCellElement) => {
    return cell.getAttribute('aria-colindex') || (cell.cellIndex + 1).toString();
  });
  const columnList = (typeof (Intl as any).ListFormat === 'undefined') ? columnIndexes.join(',') :
    new (Intl as any).ListFormat(lang, { style: 'long', type: 'conjunction' }).format(columnIndexes);
  const saveAction = row.querySelector(`[${ROW_EDIT_ACTION_ATTR}="save"]`);
  const actionCell = saveAction && saveAction.closest<HTMLTableCellElement>('td,th');

  const messages: string[] = [
    translation('nowEditingRow', rowIndex),
    translation(columnIndexes.length > -1 ? 'rowColumnsEditable' : 'rowColumnEditable', columnList)
  ];
  if (actionCell) {
    const actionCellIndex = actionCell.getAttribute('aria-colindex') || (actionCell.cellIndex + 1).toString();
    messages.push(translation('rowActionsColumn', actionCellIndex));
  }
  const announcement = messages.join('. ');
  return announcement;
}

import { hasFocus } from "../../../utilities/helpers";
import { EventListeners } from "../../../utilities/EventListeners"
import { CELL_IN_ERROR_ATTR, EDIT_PANEL_MESSAGE_CLASS } from "./editableDataTableConstants";
import { createPopover } from "../../../utilities/popover";
import { onFocusHover } from "../../../utilities/onFocusHover";

/**
 * Manages the display of error messages as tooltips. The intent is to only show one error 
 * message at a time when a cell in error is "activated", meaning if is focused or hovered 
 * only. 
 * When a cell in error is "activated", hides current error message if application, then shows error message.
 * When an error message is shown, uses the 'onFocusHover' utility to detect when both cell and error message
 * are no longer "active" and hides the popover.
 * 
 * In the original implementation, it was possible for 2 error messages to appear at once when one cell had 
 * focus and another cell was hovered. This implementation only allows one error message to be shown at a time.
 * Additionally, if a cell in error has focus, and its error message is hidden when another cell in error is
 * hovered, the error message for the cell in focus will not be shown, even though it still has focus, 
 * until it is hovered over.
 * @param table The table element to monitor
 * @returns A function to remove listeners
 */

export const manageErrorPopovers = (table: HTMLTableElement) => {
  let destroyPopover: Function | undefined;
  let popoverCell: HTMLElement | undefined;
  let offFocusHover: Function | undefined;
  const eventListeners = new EventListeners();
  eventListeners.addListener(table, 'mouseover', onHoverFocusIn);
  eventListeners.addListener(table, 'focusin', onHoverFocusIn);

  return function endManageErrorPopovers() {
    hidePopover();
    eventListeners.removeListeners();
  }

  function onHoverFocusIn({ target, type }: Event) {
    const cell = (target as Element).closest<HTMLElement>(`td[${CELL_IN_ERROR_ATTR}],th[${CELL_IN_ERROR_ATTR}]`);
    if (cell && cell !== popoverCell) {
      const delay = type === 'mouseover' ? 300 : 0;
      setTimeout(() => {
        // checking cell again popoverCell again because could have changed during timeout
        if (cell !== popoverCell && (cell.matches(':hover') || hasFocus(cell))) {
          hidePopover();
          showPopover(cell);
        }
      }, delay)
    }

  }

  function showPopover(cell: HTMLElement) {
    const errorMessagePanel = cell.querySelector<HTMLElement>(`.${EDIT_PANEL_MESSAGE_CLASS}`);
    if (errorMessagePanel) {
      popoverCell = cell;
      destroyPopover = createPopover(errorMessagePanel, cell, {
        placement: 'bottom-left-center',
        alternatePlacements: ['bottom-right-center', 'top-left-center', 'top-right-center'],
        transitionLeaveTimeout: 100
      });
      offFocusHover = onFocusHover([cell, errorMessagePanel], ({focus, hover})=> {
        if (!focus && !hover && cell === popoverCell) {
          hidePopover();
        }
      }, {hoverOutDelay: 100});
    }
  }

  function hidePopover() {
    destroyPopover && destroyPopover();
    offFocusHover && offFocusHover();
    destroyPopover = offFocusHover = popoverCell = undefined;
  }
}
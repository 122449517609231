import { to2Digits } from '../../utilities/helpers';
import { getLang } from '../../utilities/i18n';
import { getTimeValidity, parseTime } from '../../utilities/date-utils';
import { TimeInputState } from './TimeInputState';
import { HourCycle } from './constants';
import { TimeInputComponent } from './TimeInputComponent';
import { PartialExceptRequired } from '../../utilities/types';

type TimeInputValidity = PartialExceptRequired<ValidityState, 'valid'>;

type Options = [string[], string[], string[]];

function generateOptions(cycle: HourCycle, minuteIncrement: number): Options {
  const increment =
    isNaN(minuteIncrement) || minuteIncrement > 30 || minuteIncrement < 1
      ? 1
      : minuteIncrement;

  const getMinutes = (increment: number) => {
    const minutes = [];
    for (let i = 0; i < 60; i = i + increment) {
      minutes.push(to2Digits(i));
    }
    return minutes;
  };

  return [
    new Array(parseInt(cycle, 10))
      .fill(undefined)
      .map((_, i) => to2Digits(cycle === '12' ? i + 1 : i)),
    getMinutes(increment),
    cycle === '12' ? ['AM', 'PM'] : [],
  ];
}

function getCycle(el: HTMLElement): HourCycle {
  return new Intl.DateTimeFormat(getLang(el), {
    // need some sort of time config for hour12 to be populated
    hour: 'numeric',
  }).resolvedOptions().hour12
    ? '12'
    : '24';
}

function getValueAsDate(
  timeInput: TimeInputComponent,
  state?: TimeInputState
): Date {
  const { cycle } = timeInput;
  const { hourValue, minuteValue, periodValue } = state ?? timeInput.state;
  return hourValue && minuteValue && (cycle === '24' || periodValue)
    ? parseTime(`${hourValue}:${minuteValue}${periodValue}`)?.date
    : null;
}

function getValue(timeInput: TimeInputComponent, state?: TimeInputState) {
  const date = getValueAsDate(timeInput, state);
  if (date) {
    return new Intl.DateTimeFormat(getLang(timeInput.el), {
      hour: '2-digit',
      minute: '2-digit',
      hourCycle: 'h23',
    }).format(date);
  }
  return getDisplayValue(timeInput, state);
}

function getDisplayValue(timeInput: TimeInputComponent, state?: TimeInputState) {
  const { hourValue, minuteValue, periodValue } = state ?? timeInput.state;
  const { cycle } = timeInput;

  return hourValue || minuteValue || (cycle === '12' && periodValue)
    ? `${hourValue}:${minuteValue}${
        cycle === '12' && periodValue ? ` ${periodValue}` : ''
      }`
    : '';
}


function getValidityState(
  timeInput: TimeInputComponent,
  value: string
): TimeInputValidity {
  const {
    config: { min, max, required, restrictMinutes, minuteIncrement },
    dialog,
    cycle
  } = timeInput;
  return getTimeValidity(value, {
    min,
    max,
    required,
    minuteIncrement: dialog && restrictMinutes ? minuteIncrement : -1,
    cycle
  });
}

export {
  getCycle,
  getValidityState,
  getValueAsDate,
  TimeInputValidity,
  Options,
  generateOptions,
  getValue,
  getDisplayValue,
};

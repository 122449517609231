import { CellData, EditableDataTableComponent, RowData, RowUpdateValidation } from "./EditableDataTableComponent";
import { CELL_CONTENT_BKUP_ATTR, CELL_EDITABLE_ATTR, CELL_IN_ERROR_ATTR, EDITOR_HAS_FOCUS_ATTR, EDIT_CONTROL_CONTAINER_ATTR, EDIT_PANEL_ATTR, EDIT_PANEL_BASE_ID, EDIT_PANEL_MESSAGE_CLASS, ROW_EDIT_ACTIONS_ATTR, ROW_EDIT_ACTION_ATTR } from "./editableDataTableConstants";
import { CellEditorComponent } from "./editors/CellEditorComponent";
import { getChildElements } from "../../../utilities/helpers";
import { AbstractCellEditor } from "./editors/AbstractCellEditor";
import { CellValidatorChain } from "./validators/CellValidatorChain";
import { CellValidationData } from "./validators/CellValidationData";
import { translations } from '../../../utilities/i18n';
import checkCircle1_18 from '@trv-ebus/tds-icons/icons/check-circle-1-18';
import xCircle1_18 from '@trv-ebus/tds-icons/icons/x-circle-1-18';
import { CellValidator } from "./validators/CellValidator";
import { announce } from "./utils";
import uid from "../../../utilities/uid";

const errorStateMap = new WeakMap();

type EditCellValues = { [ColumnName: string]: any };

type CellValidity = {
  columnName: string,
  rowId: any,
  rowGroupIndex?: number,
  validity: {
    badInput?: boolean,
    customError?: boolean,
    patternMismatch?: boolean,
    rangeUnderflow?: boolean,
    rangeOverflow?: boolean,
    unavailable?: boolean,
    tooLong?: boolean,
    tooShort?: boolean,
    typeMismatch?: boolean,
    valueMissing?: boolean
  }
}

export type EditableTableValidity = {
  valid: boolean,
  cellValidities: CellValidity[]
}

export class EditModeManager {
  cellEditors: CellEditor[];
  startingCell: HTMLTableCellElement;
  editableDataTable: EditableDataTableComponent;
  editCellValues: EditCellValues;
  validationHasRun = false;
  rowData: RowData;
  cellValidities: CellValidity[] = [];
  showBusyTimeout: any;
  showBusyOverlay: Element;
  savingInProgress = false;

  constructor(startingCell: HTMLTableCellElement, editableDataTable: EditableDataTableComponent) {
    /* istanbul ignore next */
    if (typeof Promise === 'undefined') {
      throw new Error('Editable Data Table requires the implementation of Promise');
    }
    this.updateCellValue = this.updateCellValue.bind(this);
    this.setCellEditorOptions = this.setCellEditorOptions.bind(this);
    this.startingCell = startingCell;
    this.editableDataTable = editableDataTable;
    this.rowData = editableDataTable.getRowData(this.startingCell);
    const cells = (editableDataTable.editMode === 'row') ?
      getChildElements(startingCell!.parentElement, `[${CELL_EDITABLE_ATTR}="true"]`) as HTMLTableCellElement[]
      : [startingCell]
    this.cellEditors = cells.map(cell => new CellEditor(cell, editableDataTable, this));
    this.editCellValues = this.cellEditors.reduce((acc: EditCellValues, cellEditor) => {
      const cellValue = editableDataTable.getCellValue(cellEditor.cell);
      acc[cellEditor.cellData.columnName] = cellValue;
      return acc;
    }, {});
    this.fireEditCellValuesUpdateEvent();
  }

  begin(initialValue?: string, action?: string) {
    const { startingCell, editableDataTable, editCellValues } = this;
    this.cellEditors && this.cellEditors.forEach((editor) => {
      if (editor.cell === startingCell) {
        editor.begin(editCellValues, true, initialValue, action);
      } else {
        editor.begin(editCellValues, false);
      }
    });
    if (editableDataTable.editMode === 'row') {
      const row = startingCell.parentElement as HTMLTableRowElement;
      row.dataset.editing = '';
      this.renderEndRowEditingButtons(row);
    }
  }

  end(save: boolean): Promise<boolean> {
    if (save) {
      return this.savingInProgress ? Promise.resolve(false) : this.save();
    } else {
      this.endEditing(false);
      return Promise.resolve(true);
    }
  }

  save(): Promise<boolean> {
    this.savingInProgress = true;
    this.cellValidities = [];
    return this.validateCells()
      .then((cellValidationDataItems: CellValidationData[]) => {
        const isValid = !cellValidationDataItems.find(data => !!data.errorMessage);
        return isValid && this.processRowUpdate(cellValidationDataItems);
      })
      .then((valid) => {
        const isConnected = this.editableDataTable.table?.isConnected;
        const endEditing = (valid || this.getValidationMode() === 'passive' || !isConnected);
        if (endEditing) {
          this.endEditing(isConnected); // commit only if still connected
        }
        this.reconcileCellValidities();
        this.validationHasRun = true;
        return endEditing;
      })
      .finally(() => this.savingInProgress = false);
  }

  hasChanged(): boolean {
    return !!this.cellEditors.find(cellEditor => cellEditor.isDirty());
  }

  validateCells(): Promise<CellValidationData[]> {
    const cellEditors = this.cellEditors || [];
    return Promise.all(cellEditors.map(cellEditor => cellEditor.validate()));
  }

  processRowUpdate(cellValidationDataItems: CellValidationData[]): Promise<boolean> {
    const rowUpdates = this.getRowUpdates(cellValidationDataItems);
    const { editableDataTable } = this;
    const cellValidationData = cellValidationDataItems[0];
    const rowData: RowData = {
      rowId: cellValidationData.rowId,
      rowIndex: cellValidationData.rowIndex,
      rowGroupIndex: cellValidationData.rowGroupIndex,
      row: cellValidationData.row,
      rowItem: cellValidationData.rowItem

    }
    this.showBusy();
    return editableDataTable.processRowUpdate(rowData, rowUpdates)
      .then((validations) => this.processRowValidations(validations || [], rowData))
      .catch((err: Error) => {
        console.error(err);
        console.error(err.stack);
        // todo: do something with the error message 
        return false;
      })
      .finally(() => {
        this.hideBusy();
      });
  }

  getRowUpdates(cellValidationDataItems: CellValidationData[]): any {
    const { editableDataTable } = this;
    return cellValidationDataItems.reduce((acc: any, cellValidationData: CellValidationData) => {
      const { newValue, displayValue, cell } = cellValidationData;
      const valueSetter = editableDataTable.getValueSetter(cell);
      const rowUpdate = valueSetter ? valueSetter(cellValidationData, newValue, displayValue) : { [cellValidationData.columnName]: newValue };
      return { ...acc, ...rowUpdate };
    }, {})
  }

  processRowValidations(validations: RowUpdateValidation[], rowData: RowData): boolean {
    validations.forEach(validation => {
      // for each validation object, find matching editor and update cell validation data

      // NOTE: There is one shortcoming here specific to cell edit mode. If this cell has a validation error
      // because of the value in another cell in this row, if the user goes to that other cell to correct the 
      // error, the validation error in this cell will not be cleared. For example, say that Cell A is in error
      // because its value is invalid for the US state entered in Cell B in this row. If instead of updating
      // Cell A to correct the error, the user goes to Cell B and changes the US state so that Cell A is no longer 
      // invalid, Cell A's error will not clear because it is not part of the edit session with Cell B.
      // For now, if this scenario exists, the app should use the row edit mode instead of the cell edit mode so all
      // cells are part of the same editing session. (Phew!)  

      const editor = this.cellEditors.find(({ validationCellData }) => {
        return !!validationCellData && validationCellData.columnName === validation.columnName;
      });
      if (editor) {
        editor.updateValidationData(validation);
      }
      if (validation.errorMessage) {
        this.updateValidity(validation.columnName, rowData.rowId, rowData.rowGroupIndex, 'customError');
      }
    });
    // return true if no validation errors
    const columnsInError = validations
      .filter(validation => !!validation.errorMessage)
      .map(validation => validation.columnName);
    const valid = !columnsInError.length;
    const messageId = valid ? 'updatesComplete' : columnsInError.length > 1 ? 'updatesCompleteWithErrors' : 'updatesCompleteWithOneError';
    const message = translations(rowData.row).t(messageId, columnsInError.join(','));
    announce(message, rowData.row);
    return valid;
  }

  endEditing(commit: boolean) {
    const { editableDataTable, startingCell } = this;
    const cellEditors = this.cellEditors || [];
    cellEditors.forEach(editor => {
      commit ? editor.commit() : editor.cancel();
    });
    this.cellEditors = [];
    if (editableDataTable.editMode === 'row') {
      startingCell!.parentElement.removeAttribute('data-editing');
    }
  }

  getValidationMode(): string {
    const { editableDataTable } = this;
    const { editMode, editValidationMode } = editableDataTable;
    return editValidationMode || (editMode === 'row' ? 'assertive' : 'passive');
  }

  isClickOutside(event: MouseEvent): boolean {
    const { cellEditors, startingCell } = this;
    const el = event.target as Element;
    const selector = [
      `[${ROW_EDIT_ACTION_ATTR}="save"]`,
      `[${ROW_EDIT_ACTION_ATTR}="cancel"]`,
    ].join(',');
    const rowActions = (startingCell.parentNode && Array.from(startingCell.parentNode.querySelectorAll(selector))) || [];
    const elements = [...cellEditors.map(cellEditor => cellEditor.cell), ...rowActions];
    return !elements.find(element => element && element.contains(el)) && !this.isClickingScrollpanel(el);
  }

  isClickingScrollpanel(target: Element) {
    const table = this.editableDataTable.table;
    if (table && table.parentElement === target) {
      const styles = window.getComputedStyle(target);
      // return true if target is table's parent and parent is scrollable
      return styles.overflow === 'auto' || styles.overflow === 'scroll'
        || styles.overflowX === 'auto' || styles.overflowX === 'scroll'
        || styles.overflowY === 'auto' || styles.overflowY === 'scroll'
    }
    return false;
  }

  isTabbingAway(event: KeyboardEvent): boolean {
    const { cellEditors, startingCell } = this;
    const cells = (cellEditors || []).map(ce => ce.cell);
    // assume cells are in dom order
    let endEl: Element = event.shiftKey ? cells[0] : cells[cells.length - 1];
    if (!event.shiftKey) {
      const selector = [
        `[${ROW_EDIT_ACTION_ATTR}="save"]`,
        `[${ROW_EDIT_ACTION_ATTR}="cancel"]`
      ].map(s => s + ':not([tabindex="0"])')
        .join(',');
      const actionButtons = startingCell.parentNode && startingCell.parentNode.querySelectorAll(selector);
      if (actionButtons && actionButtons.length) {
        const lastButton = actionButtons[actionButtons.length - 1];
        if (endEl.compareDocumentPosition(lastButton) === Node.DOCUMENT_POSITION_FOLLOWING) {
          endEl = lastButton;
        }
      }
    }
    return !!endEl && endEl.contains(event.target as Element);
  }

  onCellEditorUpdate(cellEditor: CellEditor, value: any) {
    this.editCellValues[cellEditor.cellData.columnName] = value;
    this.fireEditCellValuesUpdateEvent(cellEditor);
  }

  // collects validity states of each cell to be reconciled at end of validation 
  updateValidity(columnName: string, rowId: any, rowGroupIndex: number, errorReason: string) {
    const cellValidities = this.cellValidities;
    let cellValidity = cellValidities.find(cv => cv.columnName === columnName && cv.rowId === rowId && cv.rowGroupIndex === rowGroupIndex);
    if (!cellValidity) {
      cellValidity = { columnName, rowId, rowGroupIndex, validity: {} }
      cellValidities.push(cellValidity);
    }
    cellValidity.validity = {}
    if (errorReason) {
      (cellValidity.validity as any) = { [errorReason]: true };
    }
    if (!this.savingInProgress) {
      this.reconcileCellValidities();
    }
  }

  reconcileCellValidities() {
    const { editableDataTable } = this;
    const edtValidity = editableDataTable.getValidity();
    const cellValidities = (this.cellValidities || []).filter(v => {
      // return only invalid columns
      const props = Object.keys(v.validity);
      return !!props.find(prop => !!(v.validity as any)[prop])
    });
    const edtCellValidities = edtValidity.cellValidities || [];
    let changed = cellValidities.length !== edtCellValidities.length;
    if (!changed) {
      // array size is the same, see if the columns are the same
      cellValidities.forEach(cellValidity => {
        const edtCellValidity = edtCellValidities.find(edtv => edtv.columnName === cellValidity.columnName && edtv.rowId === cellValidity.rowId && cellValidity.rowGroupIndex === edtv.rowGroupIndex);
        if (edtCellValidity) {
          // same column found, see if validity has changed
          const props = [...Object.keys(edtCellValidity.validity), ...Object.keys(cellValidity.validity)];
          const cellChanged = !!props.find(prop => !!(edtCellValidity.validity as any)[prop] !== !!(cellValidity.validity as any)[prop])
          if (cellChanged) {
            changed = true;
          }
        } else {
          changed = true;
        }
      })
    }

    if (changed) {
      const valid = cellValidities.length === 0;
      const updatedEdtValidity = {
        valid,
        cellValidities: [...cellValidities]
      };
      editableDataTable.setValidity(updatedEdtValidity);
      editableDataTable.dispatchEvent(valid ? 'tdsValid' : 'tdsInvalid', updatedEdtValidity);
    }
  }

  renderEndRowEditingButtons(row: HTMLTableRowElement) {
    const editAction = row.querySelector(`[${ROW_EDIT_ACTION_ATTR}="edit"]`);
    const saveAction = row.querySelector(`[${ROW_EDIT_ACTION_ATTR}="save"]`);
    const cancelAction = row.querySelector(`[${ROW_EDIT_ACTION_ATTR}="cancel"]`);
    if (!saveAction || !cancelAction) {
      const translateHTML = translations(row).html;
      const saveLabel = translateHTML('save');
      const cancelLabel = translateHTML('cancel');
      let container = row.querySelector(`[${ROW_EDIT_ACTIONS_ATTR}]`);
      if (!container) {
        let action = saveAction || cancelAction || editAction;
        container = action && action.parentElement;
      }
      if (container) {
        if (!saveAction) {
          const saveHTML = `<button type="button" data-row-edit-action="save" class="tds-editable-table_row-action" aria-label="${saveLabel}" title="${saveLabel}">${checkCircle1_18.svg({ focusable: false })}</button>`;
          container.insertAdjacentHTML('beforeend', saveHTML)
        }
        if (!cancelAction) {
          const cancelHTML = `<button type="button" data-row-edit-action="cancel" class="tds-editable-table_row-action" aria-label="${cancelLabel}" title="${cancelLabel}">${xCircle1_18.svg({ focusable: false })}</button>`;
          container.insertAdjacentHTML('beforeend', cancelHTML)
        }
      }
    }
  }

  fireEditCellValuesUpdateEvent(cellEditor?: CellEditor) {
    const { editCellValues, editableDataTable, rowData, setCellEditorOptions, updateCellValue } = this
    const columnName = cellEditor && cellEditor.cellData.columnName;
    editableDataTable.dispatchEvent('tdsEditCellValuesUpdate', {
      rowData,
      editCellValues,
      columnName,
      updateCellValue,
      setCellEditorOptions
    });
  }

  /**
   * This function is passed with the tdsEditCellValuesUpdate event detail. It allows develop to change other cell values
   * when an update occurs. Think a dropdown that is dependent on the value of another column
   * @param columnName The name of the column to update
   * @param value The value to set
   */
  updateCellValue(columnName: string, value: any) {
    this.editCellValues = { ...this.editCellValues, [columnName]: value };
    const editor = this.cellEditors.find(editor => editor.cellData.columnName === columnName);
    const editorComponent = editor && editor.cellEditorComponent;
    if (editorComponent) {
      editorComponent.setValue(value);
    }
    if (this.validationHasRun) {
      this.validateCells();
    }
  }

  /**
   * This function is passed with the tdsEditCellValuesUpdate event detail. It allows develop to change editor options 
   * for other cell when an update occurs. Think a dropdown that is dependent on the value of another column   * 
   * @param columnName The name of the column whose editor is to be updated
   * @param options The new options
   */
  setCellEditorOptions = (columnName: string, options: any) => {
    const editor = this.cellEditors.find(editor => editor.cellData.columnName === columnName);
    const editorComponent = editor && editor.cellEditorComponent;
    if (editorComponent && editorComponent.setOptions) {
      editorComponent.setOptions(options);
    }
  }

  showBusy() {
    this.hideBusy(); // this will cancel any timeout in progress
    this.showBusyTimeout = setTimeout(() => {
      const { cellEditors, editableDataTable } = this;
      let el: HTMLElement = cellEditors[0].cell;
      const table: HTMLElement = el.closest('table');
      if (editableDataTable.editMode === 'row') {
        el = el.closest<HTMLElement>('tr');
      }
      const rect = this.calculateBusyOverlayRect(el, table);
      const div = this.showBusyOverlay = el.ownerDocument.createElement('div');
      div.classList.add('tds-editable-table__showBusyOverlay');
      div.style.top = `${rect.top}px`;
      div.style.left = `${rect.left}px`;
      div.style.width = `${rect.width}px`;
      div.style.height = `${rect.height}px`;
      div.innerHTML = `<span></span><div aria-live="polite" aria-atomic="true"></div>`
      table.parentNode.insertBefore(div, table.nextSibling);
      const translate = translations(table.parentElement).t;
      const message = translate('applyingUpdates');
      announce(message, table);
    }, 300);
  }

  hideBusy() {
    const { showBusyTimeout, showBusyOverlay } = this;
    if (showBusyTimeout) {
      clearTimeout(showBusyTimeout);
    }
    if (showBusyOverlay && showBusyOverlay.parentNode) {
      showBusyOverlay.parentNode.removeChild(showBusyOverlay);
    }
    this.showBusyTimeout = undefined;
    this.showBusyOverlay = undefined;
  }

  calculateBusyOverlayRect(el: HTMLElement, table: HTMLElement) {
    const tableOffsetParent = table && table.offsetParent
    let top = 0;
    let left = 0;
    let width = 0;
    let height = 0;
    if (tableOffsetParent) {
      let offsetEl = el;
      do {
        top += offsetEl.offsetTop;
        left += offsetEl.offsetLeft;
        offsetEl = offsetEl.offsetParent as HTMLElement;
        if (offsetEl && offsetEl !== tableOffsetParent) {
          top -= offsetEl.scrollTop;
          left -= offsetEl.scrollLeft;
        }
      } while (offsetEl && offsetEl !== tableOffsetParent)
      width = el.offsetWidth - 1;
      height = el.offsetHeight - 1;
    }
    return { top, left, width, height };
  }
}

type RenderState = {
  errorMessage?: string,
  inEditMode?: boolean,
  editMode?: string
}

class CellEditor {
  cell: HTMLTableCellElement;
  cellData: CellData;
  editableDataTable: EditableDataTableComponent;
  editModeManager: EditModeManager
  cellEditorComponent?: CellEditorComponent;
  renderState: RenderState = {}
  validateOnUpdate = false;
  validationCellData?: CellValidationData;
  validators: CellValidator[];
  startingValue: any;
  wasUpdated: boolean;

  constructor(cell: HTMLTableCellElement, editableDataTable: EditableDataTableComponent, editModeManager: EditModeManager) {
    this.cell = cell;
    this.editableDataTable = editableDataTable;
    this.editModeManager = editModeManager;
    this.cellData = editableDataTable.getCellData(cell);
    this.cellEditorComponent = editableDataTable.getCellEditorComponent(cell);
    this.validators = editableDataTable.getCellValidators(cell);
  }

  begin(editCellValues: EditCellValues, focus: boolean, initialValue?: string, action?: string) {
    const { cell, cellData, editableDataTable, cellEditorComponent, validators } = this;
    this.wasUpdated = false;
    this.backup();
    cell.dataset.editing = '';
    const renderState: RenderState = { inEditMode: true, editMode: editableDataTable.editMode };
    const errorState = errorStateMap.get(cell);
    if (errorState) {
      renderState.errorMessage = errorState.errorMessage;
      this.validateOnUpdate = true;
    }
    this.render(renderState);
    const cellValue = this.startingValue = errorState ? errorState.value : editableDataTable.getCellValue(cell);
    const controlContainer = cell.querySelector(`[${EDIT_CONTROL_CONTAINER_ATTR}]`);
    cellEditorComponent.onUpdate(this.onUpdate.bind(this));
    cellEditorComponent.render({
      ...cellData,
      cellValue,
      editCellValues,
      container: controlContainer!,
      initialValue,
      action,
      validators
    });

    if (typeof initialValue !== 'undefined' && cellEditorComponent.getValue() !== cellValue) {
      // treat as if initial value was typed in, which it was
      this.onUpdate();
    }

    if (cellEditorComponent instanceof AbstractCellEditor) {
      const editorElement = (cellEditorComponent as AbstractCellEditor).editorElement;
      if (editableDataTable.onCellEditorCreated && editorElement) {
        editableDataTable.onCellEditorCreated(editorElement, cellData);
      }
    }

    if (focus) {
      cellEditorComponent.focus();
    }
  }

  validate(): Promise<CellValidationData> {
    const { cell, cellEditorComponent, validators, editableDataTable, editModeManager } = this;
    const newValue = cellEditorComponent.getValue();
    const displayValue = (cellEditorComponent!.getDisplayValue && cellEditorComponent!.getDisplayValue());
    const cellData: CellValidationData = {
      ...editableDataTable.getCellData(cell),
      newValue,
      enteredValue: newValue,
      displayValue
    }
    this.validateOnUpdate = true;
    return new CellValidatorChain(validators).validate(cellData)
      .then((validationCellData: CellValidationData) => {
        this.validationCellData = validationCellData;
        if (validationCellData.errorMessage !== this.renderState.errorMessage) {
          this.render({ errorMessage: validationCellData.errorMessage });
        }
        // if errorMessage set but not errorReason, assume it was from a custom validator
        const errorReason = validationCellData.errorReason || (validationCellData.errorMessage && 'customError') || undefined;
        editModeManager.updateValidity(cellData.columnName, cellData.rowId, cellData.rowGroupIndex, errorReason);
        return validationCellData;
      });
  }

  commit() {
    const { cell, editableDataTable } = this;
    cell.removeAttribute('data-editing');
    cell.removeAttribute(EDITOR_HAS_FOCUS_ATTR);
    this.restore(); // restore 1st so data is placed in correct element
    const cellData = this.validationCellData;
    this.render({ inEditMode: false });
    editableDataTable.setCellValue(cell, cellData.newValue, cellData.displayValue);
    if (cellData.errorMessage) {
      errorStateMap.set(cell, { value: cellData.newValue, errorMessage: cellData.errorMessage });
    } else {
      errorStateMap.delete(cell);
    }
  }

  cancel() {
    const { cell } = this;
    cell.removeAttribute('data-editing');
    cell.removeAttribute(EDITOR_HAS_FOCUS_ATTR);
    const errorState = errorStateMap.get(cell);
    this.render({ inEditMode: false, errorMessage: errorState && errorState.errorMessage });
    this.restore();
  }

  isDirty() {
    return this.wasUpdated || this.cellEditorComponent.getValue() != this.startingValue; // '!=' insted of '!==' is intentional
  }

  onUpdate() {
    this.wasUpdated = true;
    const value = this.cellEditorComponent.getValue();
    this.editModeManager.onCellEditorUpdate(this, value);
    if (this.validateOnUpdate) {
      this.validate();
    }
  }

  onCellEditorFocusChange(focused: boolean) {
    const cell = this.cell;
    if (cell) {
      if (focused) {
        cell.setAttribute(EDITOR_HAS_FOCUS_ATTR, '');
      } else {
        cell.removeAttribute(EDITOR_HAS_FOCUS_ATTR);
      }
    }
  }

  updateValidationData(rowValidation: RowUpdateValidation) {
    const { validationCellData } = this;
    this.validationCellData = {
      ...validationCellData,
      displayValue: rowValidation.newValue || validationCellData.displayValue, //if rowvalidation also has displayValue, it will override 
      ...rowValidation
    };
    if (rowValidation.errorMessage !== this.renderState.errorMessage) {
      this.render({ errorMessage: rowValidation.errorMessage });
    }
  }

  backup() {
    const { cell } = this;
    let backupDiv = cell.querySelector(`[${CELL_CONTENT_BKUP_ATTR}]`);
    if (!backupDiv) { // should never really exist
      backupDiv = cell.ownerDocument.createElement('div')
      backupDiv.setAttribute(CELL_CONTENT_BKUP_ATTR, '');
      backupDiv.setAttribute('aria-hidden', 'true'); // shouldn't be needed as element is hidden, but just in case
      Array.from(cell.childNodes)
        .filter(node => !(node.nodeType === Node.ELEMENT_NODE && (node as Element).matches(`[${EDIT_PANEL_ATTR}]`)))
        .forEach(node => backupDiv.appendChild(node));
      cell.insertBefore(backupDiv, cell.firstChild);
    }
  }

  restore() {
    const { cell } = this;
    const backupDiv = cell.querySelector(`[${CELL_CONTENT_BKUP_ATTR}]`);
    if (backupDiv) {
      Array.from(backupDiv.childNodes).forEach(node => cell.insertBefore(node, backupDiv));
      cell.removeChild(backupDiv);
    }
  }

  render(updates: RenderState) {
    this.renderState = { ...this.renderState, ...updates };
    const { cell } = this;
    const { errorMessage, inEditMode } = this.renderState;
    if (errorMessage) {
      cell.setAttribute(CELL_IN_ERROR_ATTR, '');
    } else {
      cell.removeAttribute(CELL_IN_ERROR_ATTR);
    }
    let editorPanel: HTMLElement | null = cell.querySelector(`[${EDIT_PANEL_ATTR}]`) as HTMLElement;
    if (inEditMode || errorMessage) {
      if (!editorPanel) {
        editorPanel = cell.ownerDocument.createElement('div');
        editorPanel.id = uid(EDIT_PANEL_BASE_ID);
        editorPanel.setAttribute(EDIT_PANEL_ATTR, '');
        cell.appendChild(editorPanel);
        cell.removeAttribute('aria-describedby');
      }
    } else if (editorPanel) {
      editorPanel.parentNode!.removeChild(editorPanel);
      editorPanel = null;
    }

    if (editorPanel) {
      const errorMessagePanelId = `${editorPanel.id}__message-panel`;
      let editorControlContainer = editorPanel.querySelector(`[${EDIT_CONTROL_CONTAINER_ATTR}]`);
      let editorMessageContainer = cell.ownerDocument.getElementById(errorMessagePanelId);
      if (!editorControlContainer && inEditMode) {
        editorControlContainer = cell.ownerDocument.createElement('div');
        editorControlContainer.setAttribute(EDIT_CONTROL_CONTAINER_ATTR, '');
        editorPanel.appendChild(editorControlContainer);
      } else if (editorControlContainer && !inEditMode) {
        editorPanel.removeChild(editorControlContainer);
      }

      // always show error message so aria-live content will be anounced.
      if (!editorMessageContainer) {
        editorMessageContainer = cell.ownerDocument.createElement('div');
        editorMessageContainer.id = errorMessagePanelId;
        cell.setAttribute('aria-describedby', errorMessagePanelId);
        editorMessageContainer.setAttribute('role', 'alert');
        editorMessageContainer.setAttribute('aria-live', 'assertive');
        editorMessageContainer.setAttribute('aria-atomic', 'true');
        editorMessageContainer.classList.add(EDIT_PANEL_MESSAGE_CLASS);
        editorPanel.appendChild(editorMessageContainer);
      }
      editorMessageContainer.textContent = errorMessage || '';
    }
  }
}


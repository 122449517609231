import { CellEditorComponent, CellEditorRenderData } from "./CellEditorComponent";

export class AbstractCellEditor implements CellEditorComponent {
  editorElement?: HTMLElement;
  onUpdateCallback?: (editor: CellEditorComponent) => void;
  onFocusChangeCallback?: (focused: boolean) => void;

  constructor() {
    this.reportUpdate = this.reportUpdate.bind(this);
    this.reportFocusChange = this.reportFocusChange.bind(this);
  }

  render(_: CellEditorRenderData): void {
    throw new Error('render(cellData: CellEditorRenderData) must be implemented');
  }

  focus() {
    if (this.editorElement && this.editorElement.focus) {
      this.editorElement.focus();
    }
  }

  getValue() {
    throw new Error('getValue() must be implemented');    
  }

  setValue(_: any) {
    throw new Error('setValue() must be implemented');
  }

  getDisplayValue() {
    return this.getValue();    
  }

  onUpdate(callback: (editor: CellEditorComponent) => void) {
    this.onUpdateCallback = callback;
  }

  onFocusChange(callback: (focused: boolean) => void) {
    this.onFocusChangeCallback = callback;
  }

  reportUpdate() {
    this.onUpdateCallback && this.onUpdateCallback(this);
  }

  reportFocusChange(focused: boolean) {
    this.onFocusChangeCallback && this.onFocusChangeCallback(focused);
  }
}